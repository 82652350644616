/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard.Announcements {
    import IContractResource = Modules.Housing.IContractResource;
    import ContractFilterModel = Modules.Housing.ContractFilterModel;
    import debounce = Umbrella.Helpers.debounce;

    export interface CustomerAnnouncementFiltersComponentState {
        person: PersonModel;
        selectedContract: Guid;
    }

    @Component('CustomerService', {
        selector: 'customer-announcement-filters',
        templateUrl:
            '/CustomerService/_CustomerCard/_Announcements/CustomerAnnouncementFiltersComponent/CustomerAnnouncementFilters.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('ContractResource', 'CustomerCardAnnouncementInfoService')
    class CustomerAnnouncementFiltersComponent {
        public state: CustomerAnnouncementFiltersComponentState;
        public displayedContracts: ContractFilterModel[] = [];
        public selectedContract: ContractFilterModel;

        constructor(
            private contractResource: IContractResource,
            private announcementInfoService: CustomerCardAnnouncementInfoService
        ) {}

        public $onChanges() {
            const customerId =
                this.state &&
                this.state.person &&
                this.state.person.roles['customer'];
            const contractsLoaded = this.displayedContracts.length > 0;
            if (customerId && !contractsLoaded) this.loadContracts(customerId);
        }

        public searchContract = debounce(
            (query: string) => {
                const customerId = this.getCustomerId();
                this.loadContracts(customerId, query);
            },
            500,
            false
        );

        public filterByContract(contract: ContractFilterModel) {
            if (contract)
                this.announcementInfoService.filterByContractId(contract.id);
            else this.announcementInfoService.ensureLoaded();
        }

        private getCustomerId(): System.Guid {
            return (
                this.state &&
                this.state.person &&
                this.state.person.roles['customer']
            );
        }

        private loadContracts(customerId: System.Guid, query?: string): void {
            this.contractResource
                .getFilterValuesByRoleId({
                    id: customerId,
                    page: 0,
                    pageSize: 255,
                    searchQuery: query
                })
                .$promise.then(data => {
                    this.displayedContracts = data.items;
                });
        }
    }
}
