namespace Umbrella.Modules.Repairs {
    export interface ISearchParams extends PagedRequestParams {
        id?: string;
        actionType?: string;
        failures?: boolean;
        rules?: boolean;
    }

    export interface IDefectResourceClass {
        new (DefectModel): ng.resource.IResource<DefectModel> & DefectModel;
        getById(params: IdRequestParams): ng.resource.IResource<DefectModel>;
        getAll(): ng.resource.IResource<DefectModel[]>;
        search(
            params: ISearchParams
        ): ng.resource.IResource<PagedItemsModel<DefectModel>>;
        getByAnswerId(
            params: IdRequestParams
        ): ng.resource.IResource<DefectModel[]>;
        delete(params: IdRequestParams): ng.resource.IResource<{}>;
        update(defect: DefectModel): ng.resource.IResource<DefectModel>;
        save(defect: DefectModel): ng.resource.IResource<DefectModel>;
    }

    angular.module('Repairs').service('DefectResource', [
        '$resource',
        $resource =>
            $resource(
                '/api/v1/defect/:action/:id',
                {},
                {
                    getAll: {
                        method: 'GET',
                        isArray: true,
                        params: {}
                    },
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: {}
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: {}
                    },
                    delete: {
                        method: 'DELETE',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getPaged: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'paged', page: 0, pageSize: 15 }
                    },
                    getActionTypes: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'ActionTypes' }
                    },
                    getByAnswerId: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'ByAnswer', id: 'id' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'search',
                            id: '@id',
                            actionType: '@actionType',
                            failures: false,
                            page: 0,
                            pageSize: 10
                        }
                    },
                    getFailuresPaged: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'failures', page: 0, pageSize: 15 }
                    }
                }
            )
    ]);
}
