namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import BaseTagModel = Modules.Knowledgebase.BaseTagModel;

    interface Channel {
        title: string;
        name: string;
        type: string;
    }

    interface IOnChannelSelectedParam {
        value: Channel;
    }

    interface IOnTagSelectedParam {
        value: BaseTagModel;
    }

    @Component('Knowledgebase', {
        selector: 'card-knowledgebase-bread-crumb',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/CardKnowledgebaseBreadCrumbComponent/CardKnowledgebaseBreadCrumb.html',
        bindings: {
            rootText: '@',
            channel: '<',
            tag: '<',
            onChannelCleared: '&',
            onChannelSelected: '&',
            onTagSelected: '&',
            cssClass: '@',
            onReset: '&'
        }
    })
    class CardKnowledgebaseBreadCrumbComponent {
        public channel: Channel;
        public tag: BaseTagModel;
        public onChannelCleared: () => void;
        public onReset: () => void;
        public onChannelSelected: (params: IOnChannelSelectedParam) => void;
        public onTagSelected: (params: IOnTagSelectedParam) => void;
        public cssClass: string;

        constructor() {}

        public resetChannel(): void {
            this.onChannelCleared();
        }

        public selectChannel(channel: Channel): void {
            this.onChannelSelected({ value: channel });
        }

        public selectTag(tag: BaseTagModel): void {
            this.onTagSelected({ value: tag });
        }

        public tagWithParents(tag: BaseTagModel): BaseTagModel[] {
            return Modules.Knowledgebase.tagWithParents(tag);
        }

        public reset(): void {
            this.onReset();
        }
    }
}
