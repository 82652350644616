/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Window.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('ifFeatureDisabled', [
        '$rootScope',
        ($rootScope: ng.IRootScopeService) => {
            function calculateVisibility(
                element: ng.IAugmentedJQuery,
                attr: ng.IAttributes
            ) {
                const featureName = attr.ifFeatureDisabled;
                const features =
                    window.clientConfig && window.clientConfig.features;
                const feature = features.find(
                    x => x.name.toLowerCase() === featureName.toLowerCase()
                );
                const isFeatureDisabled = !feature || !feature.enabled;

                if (!isFeatureDisabled) element.hide();
                else element.show();
            }

            return {
                priority: -1,
                restrict: 'A',
                link: (scope: ng.IScope, element, attr) => {
                    calculateVisibility(element, attr);

                    $rootScope.$on(
                        attr.ifFeatureEnabled + 'Changed',
                        (event, data) => {
                            calculateVisibility(element, attr);
                        }
                    );
                }
            };
        }
    ]);
}
