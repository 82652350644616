namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'inboundCall')
    class InboundCallFilter {
        public transform(
            phoneCalls: Umbrella.Telephony.PhoneCallModel[]
        ): boolean {
            if (!phoneCalls) return undefined;

            let inboundCall = undefined;
            for (const call of phoneCalls) {
                switch (call.status.toString().toLowerCase()) {
                    case '3':
                    case 'ringing':
                        inboundCall = call;
                        break;
                    default:
                        break;
                }
            }

            return inboundCall;
        }
    }
}
