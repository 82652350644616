/// <reference path="../../Modules/Chat/ChatUtility.ts"/>

namespace Umbrella.ChatConversationHandling {
    @Component('Umbrella', {
        selector: 'chat-controls',
        templateUrl: '/Top/ChatControlsComponent/ChatControls.html',
        bindings: {
            chatInfo: '<'
        }
    })
    @Inject('ChatStore', 'ChatService')
    class ChatControlsComponent {
        public chatInfo: ChatState;
        public chatAvailability = {};
        public activeConversation: ChatOverviewItemModel;
        public totalAcwTime: number;
        private clearAcwInterval: any;

        constructor(private chatStore: ChatStore, private chatService: ChatService) {}

        public $onInit() {
            this.chatStore.state$.subscribe(s => {
                this.chatAvailability = {
                    isAvailable: s && s.registration && s.registration.status === RegisterStatus.Registered,
                    isEnabled: s && s.enabled
                };

                this.activeConversation =
                    s &&
                    s.conversations &&
                    ((s.activeId && s.conversations.find(x => x.id === s.activeId)) ||
                        (s.conversations.length === 1 && s.conversations[0]));
                const isInAcwState = this.activeConversation && isAcwConversation(this.activeConversation.status);
                if (isInAcwState) {
                    this.calculateAcwTime(this.activeConversation.afterCallWorkStartedOn);
                }
            });
        }

        public endAfterCallWork(id: Guid): void {
            this.chatService.endAfterCallWork(id);
        }

        private calculateAcwTime(afterCallWorkStartedOn): void {
            clearInterval(this.clearAcwInterval);

            if (!afterCallWorkStartedOn) {
                this.totalAcwTime = 0;
                return;
            }

            this.clearAcwInterval = setInterval(() => {
                this.totalAcwTime = (new Date().getTime() - new Date(afterCallWorkStartedOn).getTime()) / 1000;
            }, 500);
        }
    }
}
