namespace Umbrella.Modules.Telephony {
    export interface PhoneButtonStatus {
        hold: boolean;
        transfer: boolean;
        hangup: boolean;
    }

    @Pipe('Telephony', 'phoneButtonStatus')
    class PhoneButtonStatusFilter {
        public transform(
            input: Umbrella.Telephony.PhoneCallStatus | string,
            callCount: number
        ): PhoneButtonStatus {
            if (input) {
                switch (input) {
                    case 2:
                    case 'Established':
                        if (callCount === 1) {
                            return {
                                hold: true,
                                transfer: false,
                                hangup: true
                            };
                        } else if (callCount > 1) {
                            return {
                                hold: false,
                                transfer: true,
                                hangup: true
                            };
                        }
                        break;
                    case 6:
                    case 'OnHold':
                    case 106:
                    case 'TryResume':
                        if (callCount === 1) {
                            return {
                                hold: true,
                                transfer: false,
                                hangup: false
                            };
                        } else if (callCount > 1) {
                            return {
                                hold: true,
                                transfer: false,
                                hangup: false
                            };
                        }
                        break;
                    case 1:
                    case 'Dialing':
                    case 104:
                    case 'TryPickUp':
                    case 103:
                    case 'TryHold':
                        return { hold: false, transfer: false, hangup: true };
                    default:
                        break;
                }
            }

            return { hold: false, transfer: false, hangup: false };
        }
    }
}
