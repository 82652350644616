namespace Umbrella {
    import PersonSearchModel = Umbrella.Modules.PersonSearchModel;

    export interface GetAllByEmail {
        email: string;
    }

    export interface PersonSearchParams extends PagedRequestWithIdParams {
        roles?: string;
        characteristics?: string;
    }

    export interface SearchRequestParams {
        query: string;
        limit: number;
    }

    export declare class PersonResource {
        getById(ps: IdRequestParams): ng.resource.IResource<PersonModel>;
        getByIds(params: IdsRequestParams): ng.resource.IResource<PersonModel[]>;
        search(params: PersonSearchParams): ng.resource.IResource<PagedItemsModel<PersonSearchModel>>;
        update(model: PersonModel): ng.resource.IResource<PersonModel>;
        delete(ps: IdRequestParams): ng.resource.IResource<PersonModel>;
        getWritableFields(params: IdRequestParams): ng.resource.IResource<WritablePersonDataFieldsModel>;
    }

    angular.module('Umbrella').service('PersonResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/people/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getByIds: {
                        method: 'GET',
                        isArray: true,
                        params: { id: 'set' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'search', id: '@id' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: {}
                    },
                    delete: {
                        method: 'DELETE',
                        isArray: false,
                        params: { action: 'delete', id: '@id' }
                    },
                    getWritableFields:{
                        url: '/api/v2/people/:id/:action',
                        method: 'GET',
                        isArray: false,
                        params: {id: '@id', action: 'writablefields'}
                    }
                }
            )
    ]);
}
