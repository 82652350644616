namespace Umbrella.CustomerService {
    export interface QuickMenuButtonDefinition {
        tooltip: string;
        icon: string;
        label: string;
        class: string;
        auth: string;
        action: Function;
    }

    @Component('CustomerService', {
        selector: 'quick-menu',
        templateUrl:
            'CustomerService/QuickMenuComponent/QuickMenuComponent.html',
        bindings: {
            buttons: '<'
        }
    })
    export class QuickMenuComponent {
        public buttons: QuickMenuButtonDefinition[];
    }
}
