﻿namespace Umbrella.Modules.Colleagues {
    @Service('Colleagues', 'PhotoUrlService')
    @Inject('EmptyGuid')
    export class PhotoUrlService {
        constructor(private emptyGuid: System.Guid) {}

        public photoUrl(
            id: System.Guid,
            width?: number,
            height?: number,
            noCache?: boolean,
            useDefault?: boolean
        ): string {
            if (useDefault || !id || id == this.emptyGuid) {
                return Config.makeAbsoluteApiUrl(
                    'api/v1/colleague/photo/default/'
                );
            }

            let url = Config.makeAbsoluteApiUrl(
                'api/v1/colleague/photo/' + (id || this.emptyGuid)
            );

            let seperator = '?';

            if (width) {
                url += seperator + 'width=' + width;
                seperator = '&';
            }

            if (height) {
                url += seperator + 'height=' + height;
                seperator = '&';
            }

            if (noCache) {
                url += seperator + 'ts=' + new Date().getMilliseconds();
                seperator = '&';
            }

            return url;
        }
    }

    @Service('Colleagues', 'PhotoBlobUrlService')
    @Inject('EmptyGuid')
    export class PhotoBlobUrlService {
        constructor(private emptyGuid: System.Guid) {}

        public photoUrl(
            id: System.Guid,
            width?: number,
            height?: number,
            noCache?: boolean,
            useDefault?: boolean
        ): string {
            if (useDefault || !id || id == this.emptyGuid) {
                return Config.makeAbsoluteApiUrl(
                    'api/v1/colleague/photo/default/'
                );
            }

            let url = Config.makeAbsoluteApiUrl(
                'api/v2/media/view/blob/' + (id || this.emptyGuid)
            );
            let seperator = '?';

            if (width) {
                url += seperator + 'width=' + width;
                seperator = '&';
            }

            if (height) {
                url += seperator + 'height=' + height;
                seperator = '&';
            }

            if (noCache) {
                url += seperator + 'ts=' + new Date().getMilliseconds();
                seperator = '&';
            }

            return url;
        }
    }
}
