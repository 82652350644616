/// <reference path="../../../../Decorators.ts" />
/// <reference path="../EditCustomerDataComponent.ts"/>
/// <reference path="../../../../Modules/Umbrella/AgentFacetService.ts" />

namespace Umbrella.CustomerService.CustomerCard {
    import AgentFacetService = Modules.AgentFacetService;
    import CustomerModel = Umbrella.Modules.Customers.CustomerModel;
    import AddressModel = Umbrella.Modules.AddressModel;

    @Component('CustomerService', {
        selector: 'customer-company-data',
        templateUrl: '/CustomerService/_CustomerCard/_Personal/CustomerCompanyDataComponent/CustomerCompanyData.html',
        bindings: {
            person: '<',
            customer: '<',
            writableFields: '<'
        }
    })
    @Inject('AgentFacetService', 'ToastMessageService', 'CustomerCardPersonInfoService')
    class CustomerCompanyDataComponent extends EditCustomerDataComponent {
        public user = window.user;
        public customer: CustomerModel;

        constructor(
            protected agentFacetService: AgentFacetService,
            toastMessageService: ToastMessageService,
            personInfoService: CustomerCardPersonInfoService
        ) {
            super(agentFacetService, toastMessageService, personInfoService);
        }

        public hasUpdateableCompanyFields(): boolean {
            return this.agentFacetService.hasUpdateableCompanyFields();
        }

        public showCountry(address: AddressModel): Boolean {
            return (
                address.country && address.country.toLowerCase() != 'nederland' && address.country.toLowerCase() != 'nl'
            );
        }
    }
}
