namespace Umbrella.Modules.Repairs {
    export interface ISpecificationCodeResourceClass {
        getAll(): ng.resource.IResource<SpecificationCodeModel[]>;
        getPaged(
            params: PagedRequestParams
        ): ng.resource.IResource<PagedItemsModel<SpecificationCodeModel>>;
    }

    angular.module('Repairs').service('SpecificationCodeResource', [
        '$resource',
        $resource =>
            $resource(
                '/api/v1/specificationcode/:action/:id',
                {},
                {
                    getAll: {
                        method: 'GET',
                        isArray: true,
                        params: {}
                    },
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: {}
                    },
                    getPaged: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'getPaged', page: 0, pageSize: 10 }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: {}
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: {}
                    },
                    delete: {
                        method: 'DELETE',
                        isArray: false,
                        params: { id: '' }
                    }
                }
            )
    ]);
}
