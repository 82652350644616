/// <reference path="../../../../Decorators.ts" />
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts" />

namespace Umbrella.CustomerService.UnitCard {
    import ResidentialZoneModel = Modules.Housing.ResidentialZoneModel;
    import UnitModel = Modules.Housing.UnitModel;
    import WorkAreaModel = Modules.SelfService.Models.WorkAreaModel.ContactPerson;

    @Component('CustomerService', {
        selector: 'unitcard-sidebar-contactpersons',
        templateUrl:
            '/CustomerService/_UnitCard/Sidebar/UnitCardSidebarContactPersonsComponent/UnitCardSidebarContactPersons.html',
        bindings: {
            unit: '<'
        }
    })
    @Inject('WorkAreaResource', 'PhoneService')
    class UnitCardSidebarContactPersonsComponent {
        unit: UnitModel;
        workareas: WorkAreaModel[];

        constructor(
            public workAreaResource: Umbrella.CustomerService.IWorkAreaResourceClass,
            public phoneService
        ) {}

        public $onInit() {
            if (this.unit) this.loadWorkAreas(this.unit.id);
        }

        public callNr(nr: string): void {
            this.phoneService.callNr(nr);
        }

        private loadWorkAreas(unitId: System.Guid) {
            this.workareas = [];

            this.workAreaResource
                .getAllForResidentialZone({ id: unitId })
                .$promise.then((workareas: WorkAreaModel[]) => {
                    this.workareas = workareas;
                });
        }
    }
}
