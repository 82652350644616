/// <reference path="../../ChatConversationHandling/ChatStore.ts" />

namespace Umbrella.TopBar {
    import PhoneService = Umbrella.Modules.Telephony.PhoneService;
    import PhoneQueueStatus = Umbrella.TelephonyControl.PhoneQueueStatus;
    import PhoneStatus = Umbrella.TelephonyControl.PhoneStatus;
    import RichPresenceStateOptionModel = Umbrella.TelephonyControl.RichPresenceStateOptionModel;

    @Component('Umbrella', {
        selector: 'phone-availability-controls',
        templateUrl: '/Top/PhoneAvailabilityControlsComponent/PhoneAvailabilityControls.html'
    })
    @Inject('PhoneService', 'TopBarStore')
    class PhoneAvailabilityControlsComponent {
        public collapsed = false;
        public capabilities: Umbrella.TelephonyControl.PhoneCapabilities;
        public phonenumbers: Umbrella.Modules.Telephony.PhoneNumberModel[];
        public selectPhoneConfig: any;
        public telephonyInfo: Telephony.State;
        public telephonyAvailable = false;
        public inTelephonyQueue: boolean;
        public richPresenceState: string;
        public tooltipText: string;
        public currentRichPresenceState: RichPresenceStateOptionModel;
        private phoneSubscription: Rx.IDisposable;
        private menu: any;

        constructor(private phoneService: PhoneService, private topBarStore: TopBarStore) {
            this.selectPhoneConfig = {
                width: 'off',
                formatNoMatches: (term: string) => 'Geen resultaten gevonden.',
                dropdownCssClass: 'select2-drop-telephony',
                formatResult: state => {
                    const text = state.text;
                    if (!state.id) return "<span class='ng-scope'>" + text + '</span>';
                    return "<span class='phone'>" + text + '</span>';
                }
            };
        }

        public $onInit() {
            const notNullOrUndefined = x => x !== null && x !== undefined;
            this.phoneSubscription = this.topBarStore.state$
                .map(s => s && s.telephonyInfo)
                .filter(notNullOrUndefined)
                .distinctUntilChanged()
                .subscribe(phoneInfo => {
                    this.telephonyInfo = phoneInfo;
                    this.tooltipText = this.getTooltip(this.telephonyInfo);

                    const updatedPhone = this.telephonyInfo.phone;
                    if (!updatedPhone) return;

                    this.capabilities = updatedPhone.phoneCapabilities;
                    this.phonenumbers = updatedPhone.phoneNumberOptions;
                    this.updateRichPresenceState(updatedPhone.phoneRichPresenceState);

                    this.telephonyAvailable =
                        updatedPhone.status &&
                        updatedPhone.status.toString() !== 'Offline' &&
                        updatedPhone.status !== 3 &&
                        updatedPhone.status.toString() !== 'Unavailable' &&
                        updatedPhone.status !== 1 &&
                        updatedPhone.status.toString() !== 'TryHide' &&
                        updatedPhone.status !== 101;

                    this.inTelephonyQueue =
                        updatedPhone.queueStatus &&
                        (updatedPhone.queueStatus.toString() === 'Available' || updatedPhone.queueStatus === 2);

                    if (this.menu && this.isAcwOrBusyState(updatedPhone)) this.menu.close();
                });
        }

        public $onDestroy() {
            if (this.phoneSubscription) {
                this.phoneSubscription.dispose();
                this.phoneSubscription = null;
            }
        }

        public selectAvailabilityStatus(status: RichPresenceStateOptionModel) {
            this.setCurrentRichPresenceState(status);
            this.changePhoneRichPresenceState();
        }

        public isAcwOrBusyState(updatedPhone) {
            switch (updatedPhone.status.toString().toLowerCase()) {
                case '4':
                case '5':
                case 'busy':
                case 'acw':
                    return true;
                default:
                    return false;
            }
        }

        public statusChanged() {
            this.phoneService.statusChanged(this.telephonyAvailable ? PhoneStatus.Available : PhoneStatus.Unavailable);
        }

        public queueStatusChanged() {
            this.phoneService.queueStatusChanged(
                this.inTelephonyQueue ? PhoneQueueStatus.Available : PhoneQueueStatus.Unavailable
            );
        }

        public login() {
            this.phoneService.login(this.telephonyInfo.phone.number, this.telephonyInfo.phone.password);
            this.telephonyInfo.phone.password = '';
            this.collapsed = false;
        }

        public changePhoneRichPresenceState() {
            if (
                !this.telephonyInfo.phone.phoneRichPresenceStateOptions ||
                !this.telephonyInfo.phone.phoneRichPresenceStateOptions.length ||
                !this.currentRichPresenceState
            )
                return;

            this.phoneService.setPhoneRichPresenceState(this.currentRichPresenceState.value);
        }

        public logout() {
            this.phoneService.logout();
            this.collapsed = false;
        }

        public show(): void {
            if (
                !this.telephonyInfo ||
                !this.telephonyInfo.phone ||
                !this.telephonyInfo.phone.status ||
                !this.hasPermissionToUsePhone()
            )
                return;

            this.collapsed = true;
        }

        public hide(): void {
            this.collapsed = false;
        }

        public getStateClass(status: Umbrella.TelephonyControl.PhoneStatus) {
            if (!status || !this.hasPermissionToUsePhone()) {
                return 'grijs';
            }

            switch (<PhoneStatus | string>status) {
                case PhoneStatus.Unavailable:
                case 1:
                case 'Unavailable':
                    return 'away';
                case 2:
                case PhoneStatus.Available:
                case 'Available':
                case 4:
                case PhoneStatus.Busy:
                case 'Busy':
                    return 'groen';
                default:
                    return 'blauw';
            }
        }

        public openMenu($mdMenu): void {
            if (!$mdMenu) return;

            this.menu = $mdMenu;
            $mdMenu.open();
        }

        private updateRichPresenceState(statusValue: string): void {
            if (!statusValue) return;

            const currentStatus = this.getRichPresenceStatusByValue(statusValue);
            this.setCurrentRichPresenceState(currentStatus);
        }

        private setCurrentRichPresenceState(status: RichPresenceStateOptionModel): void {
            if (!status) return;

            this.currentRichPresenceState = {
                text: status.text,
                value: status.value
            };
        }

        private hasPermissionToUsePhone() {
            return window.user && window.user.permissions.usePhone;
        }

        private getTooltip(telephonyInfo: Telephony.State): string {
            if (!telephonyInfo) {
                return 'Telefonie niet beschikbaar';
            } else if (!this.hasPermissionToUsePhone()) {
                return 'Telefonie niet beschikbaar: Onvoldoende rechten';
            }

            const status = telephonyInfo.availabilityStatus;

            switch (<TelephonyControl.AvailabilityStatus | string>status) {
                case TelephonyControl.AvailabilityStatus.Offline:
                case 0:
                case 'Offline':
                    return 'Telefonie niet beschikbaar: Verbinding off-line';
                case TelephonyControl.AvailabilityStatus.InvalidUser:
                case 1:
                case 'InvalidUser':
                    return 'Telefonie niet beschikbaar: Ongeldige gebruiker';
                case TelephonyControl.AvailabilityStatus.NotLoggedIn:
                case 2:
                case 'NotLoggedIn':
                    return 'Aanmelden voor telefonie';
                case TelephonyControl.AvailabilityStatus.Online:
                case 3:
                case 'Online':
                    return 'Toestelstatus wijzigen';
                default:
                    return 'Telefonie niet beschikbaar';
            }
        }

        private getRichPresenceStatusByValue(richPresenceStateValue: string): RichPresenceStateOptionModel {
            if (
                !this.telephonyInfo.phone ||
                !this.telephonyInfo.phone.phoneRichPresenceStateOptions ||
                !this.telephonyInfo.phone.phoneRichPresenceStateOptions.length
            )
                return null;

            const status = this.telephonyInfo.phone.phoneRichPresenceStateOptions.filter(
                x => x.value === richPresenceStateValue
            )[0];
            return status;
        }
    }
}
