/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scripts/TypeScript/rxjs/rx.lite.es6.d.ts"/>
/// <reference path="../../RootStore.ts"/>

namespace Umbrella.CustomerService.CustomerCard {
    import ChannelType = Modules.ChannelType;
    import SelfServiceScenarioModel = Umbrella.Modules.SelfService.SelfServiceScenarioModel;
    import ThesaurusTagModel = Umbrella.Modules.Knowledgebase.ThesaurusTagModel;
    import ContractFilterModel = Modules.Housing.ContractFilterModel;

    export namespace Sidebar {
        export interface InitializePanelStateEvent {
            readonly type: 'InitializePanelStateEvent';
            readonly contractorPanelCollapsed: boolean;
            readonly contractPanelCollapsed: boolean;
            readonly contactPanelCollapsed: boolean;
        }

        export interface ToggleContractorPanelEvent {
            readonly type: 'ToggleContractorPanelEvent';
        }

        export interface ToggleContractPanelEvent {
            readonly type: 'ToggleContractPanelEvent';
        }

        export interface ToggleContactPanelEvent {
            readonly type: 'ToggleContactPanelEvent';
        }

        export interface State {
            contractorPanelCollapsed: boolean;
            contractPanelCollapsed: boolean;
            contactPanelCollapsed: boolean;
        }

        export function set(s: CustomerCardState, replacement: State): CustomerCardState {
            return {
                ...s,
                sidebar: replacement
            };
        }
    }

    export namespace Personal {
        export interface LoadingEvent {
            readonly type: 'CustomerCardPersonLoadingEvent';
            readonly id: System.Guid;
        }

        export interface LoadedEvent {
            readonly type: 'CustomerCardPersonLoadedEvent';
            readonly person: PersonModel;
        }

        export interface LoadErrorEvent {
            readonly type: 'CustomerCardPersonLoadErrorEvent';
            readonly error: any;
        }

        export interface UnloadedEvent {
            readonly type: 'CustomerCardPersonUnloadedEvent';
            readonly id: System.Guid;
        }

        export interface UpdatingEvent {
            readonly type: 'CustomerCardPersonUpdatingEvent';
        }

        export interface UpdatedEvent {
            readonly type: 'CustomerCardPersonUpdatedEvent';
            readonly person: PersonModel;
        }

        export interface UpdateErrorEvent {
            readonly type: 'CustomerCardPersonUpdateErrorEvent';
            readonly error: any;
        }

        export interface WritableFieldsLoadedEvent {
            readonly type: 'CustomerCardPersonWritableFieldsLoadedEvent';
            readonly fields: WritablePersonDataFieldsModel;
        }

        export interface PersonSynchronizedObjectsLoadedEvent {
            readonly type: 'CustomerCardPersonSynchronizedObjectsLoadedEvent';
            readonly personIsDeletable: boolean;
        }


        export interface State {
            readonly person: PersonModel;
            readonly personIsDeletable: boolean;
            readonly fields: WritablePersonDataFieldsModel;
            readonly loading: boolean;
            readonly updating: boolean;
            readonly loadError: any;
        }

        export function set(s: CustomerCardState, replacement: State): CustomerCardState {
            return {
                ...s,
                personal: replacement
            };
        }
    }

    export namespace AccountState {
        export interface LoadingEvent {
            readonly type: 'CustomerCardAccountLoadingEvent';
            readonly personId: System.Guid;
        }

        export interface LoadedEvent {
            readonly type: 'CustomerCardAccountLoadedEvent';
            readonly account: Accounts.AccountModel;
        }

        export interface LoadErrorEvent {
            readonly type: 'CustomerCardAccountLoadErrorEvent';
            readonly error: any;
        }

        export interface State {
            readonly account: Accounts.AccountModel;
            readonly loading: boolean;
            readonly loadError: any;
        }

        export function set(s: CustomerCardState, replacement: State): CustomerCardState {
            return {
                ...s,
                accountInfo: replacement
            };
        }
    }

    export namespace CustomerState {
        export interface LoadingEvent {
            readonly type: 'CustomerCardCustomerLoadingEvent';
            readonly id: System.Guid;
        }

        export interface LoadedEvent {
            readonly type: 'CustomerCardCustomerLoadedEvent';
            readonly customer: Modules.Customers.CustomerModel.Detailed | null;
        }

        export interface LoadErrorEvent {
            readonly type: 'CustomerCardCustomerLoadErrorEvent';
            readonly error: any;
        }

        export interface State {
            readonly customer: Modules.Customers.CustomerModel.Detailed;
            readonly loading: boolean;
            readonly loadError: any;
        }

        export function set(s: CustomerCardState, replacement: State): CustomerCardState {
            return {
                ...s,
                customerInfo: replacement
            };
        }
    }

    export namespace Contracts {
        import ContractModel = Umbrella.Modules.Housing.ContractModel;
        import OutstandingBalanceModel = Modules.Housing.OutstandingBalanceModel;
        import ContractorModel = Modules.Housing.ContractorModel;

        export interface LoadingEvent {
            readonly type: 'CustomerCardContractsLoadingEvent';
            readonly page: number;
            readonly pageSize: number;
        }

        export interface LoadedEvent {
            readonly type: 'CustomerCardContractsLoadedEvent';
            readonly contracts: PagedItemsModel<ContractModel.Detailed>;
        }

        export interface LoadErrorEvent {
            readonly type: 'CustomerCardContractsLoadErrorEvent';
            readonly error: any;
        }

        export interface FilteringEvent {
            readonly type: 'CustomerCardContractsFilteringEvent';
        }

        export interface FilteredEvent {
            readonly type: 'CustomerCardContractsFilteredEvent';
            readonly contract: ContractModel;
        }

        export interface FilterErrorEvent {
            readonly type: 'CustomerCardContractsFilterErrorEvent';
            readonly error: any;
        }

        export interface ContractorsLoadedEvent {
            readonly type: 'CustomerCardContractorsLoadedEvent';
            readonly contractors: ContractorModel[];
        }

        export interface OutstandingBalanceLoadedEvent {
            readonly type: 'CustomerCardOutstandingBalanceLoadedEvent';
            readonly outstandingBalance: OutstandingBalanceModel;
        }

        export interface State {
            readonly loading: boolean;
            readonly error: any;
            readonly contracts: PagedItemsModel<ContractModel.Detailed>;
            readonly selectedContract: ContractModel;
            readonly contractors: ContractorModel[];
            readonly outstandingBalance: OutstandingBalanceModel;
        }

        export function set(s: CustomerCardState, replacement: State): CustomerCardState {
            return {
                ...s,
                contractInfo: replacement
            };
        }
    }

    export namespace Announcements {
        export interface LoadingEvent {
            readonly type: 'CustomerCardAnnouncementsLoadingEvent';
            readonly page: number;
            readonly pageSize: number;
            readonly contractId: System.Guid;
        }

        export interface LoadedEvent {
            readonly type: 'CustomerCardAnnouncementsLoadedEvent';
            readonly announcements: PagedItemsModel<Modules.Announcements.AnnouncementModel>;
            readonly contractId: System.Guid;
        }

        export interface LoadErrorEvent {
            readonly type: 'CustomerCardAnnouncementsLoadErrorEvent';
            readonly error: any;
        }

        export interface State {
            readonly loading: boolean;
            readonly error: any;
            readonly announcements: PagedItemsModel<Modules.Announcements.AnnouncementModel>;
            readonly contractId: System.Guid;
        }

        export function set(s: CustomerCardState, replacement: State): CustomerCardState {
            return {
                ...s,
                announcementInfo: replacement
            };
        }
    }

    export namespace Activities {
        import CaseHistoryModel = Umbrella.Modules.CaseHistoryModel;
        import ActivityCategoryModel = Modules.ActivityCategoryModel;
        import StatusFilter = Umbrella.Modules.StatusFilter;

        export interface Filters {
            allActivityCategories: ActivityCategoryModel[];
            allScenarios: SelfServiceScenarioModel[];
            rootTags: ThesaurusTagModel[];
            query: string;
            activityCategory: string;
            contractId: System.Guid;
            selectedTagId: System.Guid;
            selectedScenarioId: System.Guid;
            status: StatusFilter;
            channel: ChannelType;
        }

        export interface LoadingEvent {
            readonly type: 'CustomerCardActivitiesLoadingEvent';
            readonly filters: Filters;
            readonly page: number;
            readonly pageSize: number;
        }

        export interface LoadedEvent {
            readonly type: 'CustomerCardActivitiesLoadedEvent';
            readonly filters: Filters;
            readonly cases: PagedItemsModel<CaseHistoryModel>;
        }

        export interface LoadErrorEvent {
            readonly type: 'CustomerCardActivitiesLoadErrorEvent';
            readonly error: any;
        }

        export interface ResetEvent {
            readonly type: 'CustomerCardActivitiesResetEvent';
        }

        export interface CategoriesLoadedEvent {
            readonly type: 'CustomerCardActivityCategoriesLoadedEvent';
            readonly categories: ActivityCategoryModel[];
        }

        export interface AllScenariosLoaded {
            readonly type: 'CustomerCardAllScenariosLoadedEvent';
            readonly scenarios: SelfServiceScenarioModel[];
        }

        export interface RootTagsLoaded {
            readonly type: 'CustomerCardRootTagsLoadedEvent';
            readonly tags: ThesaurusTagModel[];
        }

        export interface State {
            readonly loading: boolean;
            readonly cases: PagedItemsModel<CaseHistoryModel>;
            readonly contractId: System.Guid;
            readonly error: any;
            readonly filters: Filters;
        }

        export function set(s: CustomerCardState, replacement: State): CustomerCardState {
            return {
                ...s,
                activityInfo: replacement
            };
        }
    }

    export namespace Bills {
        import Payments = Umbrella.Modules.Payments;

        export interface LoadingEvent {
            readonly type: 'CustomerCardBillsLoadingEvent';
            readonly page: number;
            readonly pageSize: number;
        }

        export interface LoadedEvent {
            readonly type: 'CustomerCardBillsLoadedEvent';
            readonly bills: PagedItemsModel<Modules.Payments.Billing.BillModel>;
        }

        export interface LoadErrorEvent {
            readonly type: 'CustomerCardBillsLoadErrorEvent';
            readonly error: any;
        }

        export interface FilteringEvent {
            readonly type: 'CustomerCardBillsFilteringEvent';
            readonly contractId: System.Guid;
            readonly page: number;
            readonly pageSize: number;
        }

        export interface FilteredEvent {
            readonly type: 'CustomerCardBillsFilteredEvent';
            readonly contractId: System.Guid;
            readonly bills: PagedItemsModel<Payments.Billing.BillModel.BillModelWithAddress>;
        }

        export interface FilterErrorEvent {
            readonly type: 'CustomerCardBillsFilterErrorEvent';
            readonly error: any;
        }

        export interface FilterLoadedEvent {
            readonly type: 'CustomerCardBillsContractFilterLoaded';
            readonly contracts: ContractFilterModel[];
        }

        export interface State {
            readonly loading: boolean;
            readonly contractId: System.Guid;
            readonly contracts: ContractFilterModel[];
            readonly error: any;
            readonly hasMultipleContracts: boolean;
            readonly bills: PagedItemsModel<Payments.Billing.BillModel.BillModelWithAddress>;
        }

        export function set(s: CustomerCardState, replacement: State): CustomerCardState {
            return {
                ...s,
                billInfo: replacement
            };
        }

        export function addAddressLinesToBills(
            bills: PagedItemsModel<Modules.Payments.Billing.BillModel>,
            contracts: ContractFilterModel[]
        ): PagedItemsModel<Payments.Billing.BillModel.BillModelWithAddress> {
            if (!bills) return null;

            if (!contracts) contracts = [];

            const getAddress = (bill: Modules.Payments.Billing.BillModel, contracts: ContractFilterModel[]) => {
                const contract = contracts.find(c => c.id == bill.contractId);
                if (contract) return contract.addressLine;

                return '';
            };

            return {
                page: bills.page,
                items: bills.items.map(b => ({ ...b, fullAddress: getAddress(b, contracts) })),
                pageSize: bills.pageSize,
                total: bills.total
            };
        }
    }

    export namespace Registrations {
        import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;

        export interface LoadingEvent {
            readonly type: 'CustomerCardRegistrationsLoadingEvent';
        }

        export interface LoadedEvent {
            readonly type: 'CustomerCardRegistrationsLoadedEvent';
            readonly registrations: RegistrationModel[];
        }

        export interface LoadErrorEvent {
            readonly type: 'CustomerCardRegistrationsLoadErrorEvent';
            readonly error: any;
        }

        export interface State {
            readonly loading: boolean;
            readonly error: any;
            readonly registrations: RegistrationModel[];
        }

        export function set(s: CustomerCardState, replacement: State): CustomerCardState {
            return {
                ...s,
                registrationInfo: replacement
            };
        }
    }

    export interface ActivityRegistrationChangedEvent {
        readonly type: 'CustomerCardActivityRegistrationChangedEvent';
        readonly newState: Activities.Registration.ActivityRegistrationState;
    }

    export type CustomerCardEvent =
        | Personal.LoadingEvent
        | Personal.LoadedEvent
        | Personal.LoadErrorEvent
        | Personal.UnloadedEvent
        | Personal.UpdatingEvent
        | Personal.UpdatedEvent
        | Personal.UpdateErrorEvent
        | Personal.WritableFieldsLoadedEvent
        | Personal.PersonSynchronizedObjectsLoadedEvent
        | AccountState.LoadedEvent
        | AccountState.LoadErrorEvent
        | AccountState.LoadingEvent
        | CustomerState.LoadedEvent
        | CustomerState.LoadErrorEvent
        | CustomerState.LoadingEvent
        | Contracts.LoadingEvent
        | Contracts.LoadedEvent
        | Contracts.LoadErrorEvent
        | Contracts.FilteringEvent
        | Contracts.FilteredEvent
        | Contracts.FilterErrorEvent
        | Contracts.ContractorsLoadedEvent
        | Contracts.OutstandingBalanceLoadedEvent
        | Announcements.LoadingEvent
        | Announcements.LoadedEvent
        | Announcements.LoadErrorEvent
        | Activities.LoadingEvent
        | Activities.LoadedEvent
        | Activities.LoadErrorEvent
        | Activities.ResetEvent
        | Activities.CategoriesLoadedEvent
        | Activities.AllScenariosLoaded
        | Activities.RootTagsLoaded
        | Bills.LoadingEvent
        | Bills.LoadedEvent
        | Bills.LoadErrorEvent
        | Bills.FilteringEvent
        | Bills.FilteredEvent
        | Bills.FilterErrorEvent
        | Bills.FilterLoadedEvent
        | Registrations.LoadingEvent
        | Registrations.LoadedEvent
        | Registrations.LoadErrorEvent
        | Sidebar.InitializePanelStateEvent
        | Sidebar.ToggleContractorPanelEvent
        | Sidebar.ToggleContractPanelEvent
        | Sidebar.ToggleContactPanelEvent;

    export interface CustomerCardState {
        readonly id: System.Guid;
        readonly sidebar: Sidebar.State;
        readonly personal: Personal.State;
        readonly accountInfo: AccountState.State;
        readonly customerInfo: CustomerState.State;
        readonly contractInfo: Contracts.State;
        readonly billInfo: Bills.State;
        readonly activityInfo: Activities.State;
        readonly announcementInfo: Announcements.State;
        readonly activityRegistrationInfo: Activities.Registration.ActivityRegistrationState;
        readonly registrationInfo: Registrations.State;
    }

    function reduce(e: CustomerCardEvent): (s: CustomerCardState) => CustomerCardState {
        switch (e.type) {
            case 'CustomerCardPersonLoadingEvent':
                return s => ({
                    ...s,
                    id: e.id,
                    personal: {
                        loading: true,
                        updating: false,
                        person: null,
                        fields: null,
                        loadError: null,
                        personIsDeletable: false,
                    },
                    contractInfo: null,
                    billInfo: null,
                    announcementInfo: null,
                    activityInfo: {
                        loading: false,
                        cases: null,
                        contractId: null,
                        error: null,
                        filters: {
                            allActivityCategories:
                                s &&
                                s.activityInfo &&
                                s.activityInfo.filters &&
                                s.activityInfo.filters.allActivityCategories,
                            status: s && s.activityInfo && s.activityInfo.filters && s.activityInfo.filters.status,
                            allScenarios:
                                s && s.activityInfo && s.activityInfo.filters && s.activityInfo.filters.allScenarios,
                            rootTags: s && s.activityInfo && s.activityInfo.filters && s.activityInfo.filters.rootTags,
                            activityCategory: '',
                            contractId: null,
                            query: '',
                            selectedScenarioId: null,
                            selectedTagId: null,
                            channel: s && s.activityInfo && s.activityInfo.filters && s.activityInfo.filters.channel
                        }
                    },
                    activityRegistrationInfo: {
                        selectedChannelType: ChannelType.Unknown,
                        selectedTag: null,
                        selectedFaq: null,
                        selectedTargetGroup: null,
                        selectedFollowUpQuestionId: null,
                        selectedScenario: null,
                        selectedPanel: null,
                        query: '',
                        scenarios: [],
                        tagInfo: null,
                        faqInfo: null,
                        contactRegistrationInfo: null,
                        taskRegistrationInfo: null,
                        targetGroupInfo: null
                    },
                    registrationInfo: null
                });

            case 'CustomerCardPersonLoadedEvent':
                return s =>
                    Personal.set(s, {
                        loading: false,
                        updating: s.personal.updating,
                        person: e.person,
                        fields: s.personal.fields,
                        loadError: null,
                        personIsDeletable: s.personal.personIsDeletable
                    });

            case 'CustomerCardPersonUnloadedEvent':
                return s => s;

            case 'CustomerCardPersonLoadErrorEvent':
                console.error(e.type, e.error);
                return s =>
                    Personal.set(s, {
                        loading: false,
                        updating: s.personal.updating,
                        person: s.personal.person,
                        fields: s.personal.fields,
                        loadError: e.error,
                        personIsDeletable: s.personal.personIsDeletable
                    });

            case 'CustomerCardPersonUpdatingEvent':
                return s =>
                    Personal.set(s, {
                        loading: s.personal.loading,
                        updating: true,
                        person: s.personal.person,
                        fields: s.personal.fields,
                        loadError: s.personal.loadError,
                        personIsDeletable: s.personal.personIsDeletable
                    });

            case 'CustomerCardPersonUpdatedEvent':
                return s =>
                    Personal.set(s, {
                        loading: s.personal.loading,
                        updating: false,
                        person: e.person,
                        fields: s.personal.fields,
                        loadError: s.personal.loadError,
                        personIsDeletable: s.personal.personIsDeletable
                    });

            case 'CustomerCardPersonUpdateErrorEvent':
                console.error(e.type, e.error);
                return s =>
                    Personal.set(s, {
                        loading: s.personal.loading,
                        updating: false,
                        person: s.personal.person,
                        fields: s.personal.fields,
                        loadError: e.error,
                        personIsDeletable: s.personal.personIsDeletable
                    });

            case 'CustomerCardPersonWritableFieldsLoadedEvent':
                return s =>
                    Personal.set(s, {
                        loading: s.personal.loading,
                        updating: s.personal.updating,
                        person: s.personal.person,
                        fields: e.fields,
                        loadError: s.personal.loadError,
                        personIsDeletable: s.personal.personIsDeletable
                    });

            case 'CustomerCardPersonSynchronizedObjectsLoadedEvent':
                return s =>
                    Personal.set(s, {
                        loading: s.personal.loading,
                        updating: s.personal.updating,
                        person: s.personal.person,
                        fields: s.personal.fields,
                        loadError: s.personal.loadError,
                        personIsDeletable: e.personIsDeletable
                    });

            case 'CustomerCardContractsLoadingEvent':
                return s =>
                    Contracts.set(s, {
                        ...s.contractInfo,
                        loading: true,
                        error: null,
                        contracts: s.contractInfo && s.contractInfo.contracts,
                        selectedContract: null,
                        outstandingBalance: null
                    });

            case 'CustomerCardContractsLoadedEvent':
                return s =>
                    Contracts.set(s, {
                        ...s.contractInfo,
                        loading: false,
                        error: null,
                        contracts: e.contracts,
                        selectedContract: null
                    });

            case 'CustomerCardAccountLoadErrorEvent':
                return s =>
                    AccountState.set(s, {
                        ...s.accountInfo,
                        loading: false,
                        loadError: e.error
                    });

            case 'CustomerCardAccountLoadedEvent':
                return s =>
                    AccountState.set(s, {
                        ...s.accountInfo,
                        account: e.account,
                        loading: false,
                        loadError: null
                    });

            case 'CustomerCardAccountLoadingEvent':
                return s =>
                    AccountState.set(s, {
                        ...s.accountInfo,
                        account: null,
                        loading: true,
                        loadError: null
                    });

            case 'CustomerCardCustomerLoadErrorEvent':
                return s =>
                    CustomerState.set(s, {
                        ...s.customerInfo,
                        loading: false,
                        loadError: e.error
                    });

            case 'CustomerCardCustomerLoadedEvent':
                return s =>
                    CustomerState.set(s, {
                        ...(s && s.customerInfo),
                        customer: e.customer,
                        loading: false,
                        loadError: null
                    });

            case 'CustomerCardCustomerLoadingEvent':
                return s =>
                    CustomerState.set(s, {
                        ...s.customerInfo,
                        customer: null,
                        loading: true,
                        loadError: null
                    });

            case 'CustomerCardContractsLoadErrorEvent':
                return s =>
                    Contracts.set(s, {
                        ...s.contractInfo,
                        loading: false,
                        error: e.error,
                        contracts: null,
                        selectedContract: null
                    });

            case 'CustomerCardContractsFilteringEvent':
                return s =>
                    Contracts.set(s, {
                        ...s.contractInfo,
                        loading: true,
                        selectedContract: null
                    });

            case 'CustomerCardContractsFilteredEvent':
                return s =>
                    Contracts.set(s, {
                        ...s.contractInfo,
                        loading: false,
                        selectedContract: e.contract
                    });

            case 'CustomerCardContractsFilterErrorEvent':
                return s =>
                    Contracts.set(s, {
                        ...s.contractInfo,
                        loading: false,
                        selectedContract: null,
                        error: e.error
                    });

            case 'CustomerCardContractorsLoadedEvent':
                return s =>
                    Contracts.set(s, {
                        ...s.contractInfo,
                        contractors: e.contractors
                    });

            case 'CustomerCardOutstandingBalanceLoadedEvent':
                return s =>
                    Contracts.set(s, {
                        ...s.contractInfo,
                        outstandingBalance: e.outstandingBalance
                    });

            case 'CustomerCardAnnouncementsLoadingEvent':
                return s =>
                    Announcements.set(s, {
                        loading: true,
                        error: null,
                        announcements: s.announcementInfo && s.announcementInfo.announcements,
                        contractId: e.contractId
                    });

            case 'CustomerCardAnnouncementsLoadedEvent':
                return s =>
                    Announcements.set(s, {
                        loading: false,
                        error: null,
                        announcements: e.announcements,
                        contractId: e.contractId
                    });

            case 'CustomerCardAnnouncementsLoadErrorEvent':
                return s =>
                    Announcements.set(s, {
                        loading: false,
                        error: e.error,
                        announcements: null,
                        contractId: null
                    });

            case 'CustomerCardActivitiesResetEvent':
                return s =>
                    Activities.set(s, {
                        ...s.activityInfo,
                        filters: {
                            ...(s.activityInfo && s.activityInfo.filters)
                        },
                        loading: false,
                        cases: null,
                        contractId: null,
                        error: null
                    });

            case 'CustomerCardActivitiesLoadingEvent':
                return s =>
                    Activities.set(s, {
                        ...s.activityInfo,
                        filters: {
                            ...(s.activityInfo && s.activityInfo.filters),
                            ...e.filters
                        },
                        loading: true,
                        contractId: null,
                        error: null
                    });

            case 'CustomerCardActivitiesLoadedEvent':
                return s =>
                    Activities.set(s, {
                        ...s.activityInfo,
                        filters: {
                            ...(s.activityInfo && s.activityInfo.filters),
                            ...e.filters
                        },
                        loading: false,
                        cases: e.cases,
                        contractId: null,
                        error: null
                    });

            case 'CustomerCardActivitiesLoadErrorEvent':
                return s =>
                    Activities.set(s, {
                        ...s.activityInfo,
                        loading: false,
                        error: e.error
                    });

            case 'CustomerCardActivityCategoriesLoadedEvent':
                return s =>
                    Activities.set(s, {
                        ...s.activityInfo,
                        filters: {
                            ...(s.activityInfo && s.activityInfo.filters),
                            allActivityCategories: e.categories
                        }
                    });

            case 'CustomerCardAllScenariosLoadedEvent':
                return s =>
                    Activities.set(s, {
                        ...s.activityInfo,
                        filters: {
                            ...(s.activityInfo && s.activityInfo.filters),
                            allScenarios: e.scenarios
                        }
                    });

            case 'CustomerCardRootTagsLoadedEvent':
                return s =>
                    Activities.set(s, {
                        ...s.activityInfo,
                        filters: {
                            ...(s.activityInfo && s.activityInfo.filters),
                            rootTags: e.tags
                        }
                    });

            case 'CustomerCardBillsLoadingEvent':
                return s =>
                    Bills.set(s, {
                        loading: true,
                        error: null,
                        contracts: s.billInfo && s.billInfo.contracts,
                        bills: s.billInfo && s.billInfo.bills,
                        contractId: null,
                        hasMultipleContracts: s.billInfo && s.billInfo.hasMultipleContracts
                    });

            case 'CustomerCardBillsLoadedEvent':
                return s =>
                    Bills.set(s, {
                        loading: false,
                        error: null,
                        contracts: s.billInfo && s.billInfo.contracts,
                        bills: Bills.addAddressLinesToBills(e.bills, s.billInfo && s.billInfo.contracts),
                        contractId: null,
                        hasMultipleContracts: s.billInfo && s.billInfo.hasMultipleContracts
                    });

            case 'CustomerCardBillsLoadErrorEvent':
                return s =>
                    Bills.set(s, {
                        loading: false,
                        error: e.error,
                        contracts: s.billInfo && s.billInfo.contracts,
                        bills: null,
                        contractId: null,
                        hasMultipleContracts: s.billInfo && s.billInfo.hasMultipleContracts
                    });

            case 'CustomerCardBillsFilteringEvent':
                return s =>
                    Bills.set(s, {
                        loading: true,
                        error: null,
                        contracts: s.billInfo && s.billInfo.contracts,
                        bills: null,
                        contractId: e.contractId,
                        hasMultipleContracts: s.billInfo.hasMultipleContracts
                    });

            case 'CustomerCardBillsFilteredEvent':
                return s =>
                    Bills.set(s, {
                        loading: false,
                        error: null,
                        contracts: s.billInfo && s.billInfo.contracts,
                        bills: Bills.addAddressLinesToBills(e.bills, s.billInfo && s.billInfo.contracts),
                        contractId: e.contractId,
                        hasMultipleContracts: s.billInfo.hasMultipleContracts
                    });

            case 'CustomerCardBillsFilterErrorEvent':
                return s =>
                    Bills.set(s, {
                        loading: false,
                        error: e.error,
                        contracts: s.billInfo && s.billInfo.contracts,
                        bills: null,
                        contractId: null,
                        hasMultipleContracts: s.billInfo.hasMultipleContracts
                    });

            case 'CustomerCardBillsContractFilterLoaded':
                return s =>
                    Bills.set(s, {
                        loading: s.billInfo && s.billInfo.loading,
                        error: null,
                        contracts: e.contracts,
                        bills: Bills.addAddressLinesToBills(s.billInfo && s.billInfo.bills, e.contracts),
                        contractId: s.billInfo && s.billInfo.contractId,
                        hasMultipleContracts: e.contracts.length > 1
                    });

            case 'CustomerCardRegistrationsLoadingEvent':
                return s =>
                    Registrations.set(s, {
                        loading: true,
                        error: null,
                        registrations: s.registrationInfo && s.registrationInfo.registrations
                    });

            case 'CustomerCardRegistrationsLoadedEvent':
                return s =>
                    Registrations.set(s, {
                        loading: false,
                        error: null,
                        registrations: e.registrations
                    });

            case 'CustomerCardRegistrationsLoadErrorEvent':
                return s =>
                    Registrations.set(s, {
                        loading: false,
                        error: e.error,
                        registrations: null
                    });

            case 'InitializePanelStateEvent':
                return s =>
                    Sidebar.set(s, {
                        contractorPanelCollapsed: e.contractorPanelCollapsed,
                        contractPanelCollapsed: e.contractPanelCollapsed,
                        contactPanelCollapsed: e.contactPanelCollapsed
                    });

            case 'ToggleContractorPanelEvent':
                return s =>
                    Sidebar.set(s, {
                        ...s.sidebar,
                        contractorPanelCollapsed: !s.sidebar.contractorPanelCollapsed
                    });

            case 'ToggleContractPanelEvent':
                return s =>
                    Sidebar.set(s, {
                        ...s.sidebar,
                        contractPanelCollapsed: !s.sidebar.contractPanelCollapsed
                    });

            case 'ToggleContactPanelEvent':
                return s =>
                    Sidebar.set(s, {
                        ...s.sidebar,
                        contactPanelCollapsed: !s.sidebar.contactPanelCollapsed
                    });
        }
    }

    export type CustomerCardStore = ObservableStore.EventStore<CustomerCardState, CustomerCardEvent>;

    export const customerCardStore: CustomerCardStore = rootStore.map<CustomerCardState, CustomerCardEvent>(
        lens<CustomerCardState>('CustomerCard'),
        reduce
    );

    angular.module('CustomerService').value('CustomerCardStore', customerCardStore);
}
