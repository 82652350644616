/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard {
    import throttle = Umbrella.Helpers.throttle;

    angular.module('Umbrella').directive('timelineItem', [
        '$window',
        $window => {
            return {
                link(scope, element: ng.IAugmentedJQuery, attr) {
                    let timelineItem: HTMLElement = document.createElement('div');
                    let timelineItemStyling = getComputedStyle(timelineItem);
                    let elementTransitionDuration = parseFloat(timelineItemStyling.transitionDuration) * 1000;
                    let collapsedTimelineChildrenInViewport: any;
                    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
                    let collapsedStatus = false;
                    let isScrolling = false;
                    const scrollContainer = document.querySelector('.scrollContainer');
                    let positionOfItem: number;
                    let totalHeight = 0;
                    let activityItem: HTMLElement;
                    checkHeaderHight(element);

                    activityItem = element.parent().length > 0 ? element.parent()[0] : null;

                    const domMutationObserver = new MutationObserver(mutations => {
                        mutations.forEach(() => throttle(calculateItemHeight(timelineItem), 100));
                    });

                    const config = {
                        childList: true,
                        subtree: true,
                        characterData: true
                    };

                    attr.$observe('timelineItem', value => {
                        if (value === 'true') {
                            domMutationObserver.observe(activityItem, config);
                            setTimeout(() => {
                                collapsedStatus = true;
                                toggleHeightOfItem(timelineItem);
                            }, 650);
                        } else {
                            domMutationObserver.disconnect();
                        }
                    });

                    element.on('click', () => {
                        setValues();
                        hideFooterIfEmpty(timelineItem);
                        scrollToTopOfElement();
                        isScrollToFinished();
                        toggleHeightOfItem(timelineItem);
                        addCollapsedClass();
                        setWidthFooterAndHeaderItem(timelineItem);
                        addStickyFooterTimelineItems();
                        removeStickyFooterTimelineItems();
                        removeStickyFooterFromViewportTimelineItems();
                    });

                    angular.element($window).bind('resize', () => {
                        if (timelineItem.parentElement && isItemCollapsed(timelineItem)) {
                            setWidthFooterAndHeaderItem(timelineItem);
                        }
                    });

                    scope.$on('toggleCaseFlowStep', (event, args) => {
                        setTimeout(() => {
                            if (attr.$$element[0].parentElement === args.item) {
                                calculateItemHeight(timelineItem);
                            }
                        }, 50);
                    });

                    function setValues() {
                        timelineItem = element[0].parentElement;
                        timelineItemStyling = getComputedStyle(timelineItem.parentElement);
                        elementTransitionDuration = parseFloat(timelineItemStyling.transitionDuration) * 1000;
                        collapsedTimelineChildrenInViewport = buildArray(timelineItem);
                        positionOfItem = timelineItem.parentElement.offsetTop;
                    }

                    function scrollToTopOfElement() {
                        if (isItemCollapsed(timelineItem) && !collapsedStatus) {
                            let itemHasStickyHeader = false;
                            if (timelineItem.parentElement.classList.contains('sticky-header')) {
                                itemHasStickyHeader = true;
                            }
                            if (itemHasStickyHeader) {
                                $(scrollContainer).animate(
                                    {
                                        scrollTop: positionOfItem - 20
                                    },
                                    250
                                );
                            }
                        }
                    }

                    function isScrollToFinished() {
                        if (timelineItem.parentElement.classList.contains('sticky-header')) {
                            isScrolling = true;
                            const checkIfScrollToIsFinished = setInterval(() => {
                                if (positionOfItem === Math.round(scrollContainer.scrollTop) + 20 && !collapsedStatus) {
                                    removeStickyFooterFromItem(timelineItem.parentElement);
                                    removeCollapsedClassFromItem(timelineItem);
                                    resetHeightItem(timelineItem);
                                    clearInterval(checkIfScrollToIsFinished);
                                }
                            }, 25);
                        }
                    }

                    function toggleHeightOfItem(timelineItem: HTMLElement) {
                        if (isItemCollapsed(timelineItem) && !collapsedStatus) {
                            collapsedStatus = false;
                            const isFooterItemInViewport = setInterval(() => {
                                if (
                                    timelineItem.lastElementChild &&
                                    timelineItem.lastElementChild.getBoundingClientRect() &&
                                    isFooterElementInViewport(timelineItem.lastElementChild.getBoundingClientRect())
                                ) {
                                    if (!timelineItem.parentElement.classList.contains('sticky-header')) {
                                        removeCollapsedClassFromItem(timelineItem);
                                        resetHeightItem(timelineItem);
                                    }
                                    removeStickyHeader(timelineItem);
                                    removeStickyFooterFromItem(timelineItem.parentElement);
                                    if (!isScrolling) {
                                        resetHeightItem(timelineItem);
                                    }
                                    clearInterval(isFooterItemInViewport);
                                }
                            }, 25);
                        } else {
                            collapsedStatus = false;
                            calculateItemHeight(timelineItem);
                            const isBottomItemNotInViewport = setInterval(() => {
                                if (!isBottomElementInViewport(timelineItem.parentElement.getBoundingClientRect())) {
                                    addStickyFooterToItem(timelineItem.parentElement);
                                    clearInterval(isBottomItemNotInViewport);
                                }
                            }, 25);
                            setTimeout(() => {
                                clearInterval(isBottomItemNotInViewport);
                            }, elementTransitionDuration);
                        }
                    }

                    function calculateItemHeight(timelineItem) {
                        totalHeight = 0;
                        for (const item of timelineItem.children) {
                            totalHeight += item.clientHeight;
                        }
                        if (totalHeight > 50) {
                            timelineItem.parentElement.style.height = totalHeight + 'px';
                        }
                    }

                    function addStickyFooterTimelineItems() {
                        for (const item of collapsedTimelineChildrenInViewport) {
                            const isBodyInViewportAndBottomNot = setInterval(() => {
                                if (
                                    isTopElementInViewport(item.getBoundingClientRect()) &&
                                    !isBottomElementInViewport(item.getBoundingClientRect())
                                ) {
                                    addStickyFooterToItem(item);
                                    clearInterval(isBodyInViewportAndBottomNot);
                                }
                            }, 25);
                            setTimeout(() => {
                                clearInterval(isBodyInViewportAndBottomNot);
                            }, elementTransitionDuration);
                        }
                    }

                    function removeStickyFooterTimelineItems() {
                        for (const item of collapsedTimelineChildrenInViewport) {
                            const isTopElementNotInViewport = setInterval(() => {
                                if (!isTopElementInViewport(item.getBoundingClientRect())) {
                                    removeStickyFooterFromItem(item);
                                    clearInterval(isTopElementNotInViewport);
                                }
                            }, 25);
                            setTimeout(() => {
                                clearInterval(isTopElementNotInViewport);
                            }, elementTransitionDuration);
                        }
                    }

                    function removeStickyFooterFromViewportTimelineItems() {
                        for (const item of collapsedTimelineChildrenInViewport) {
                            const areTopAndBottomOfElementInViewport = setInterval(() => {
                                if (
                                    isTopElementInViewport(item.getBoundingClientRect()) &&
                                    isBottomElementInViewport(item.getBoundingClientRect())
                                ) {
                                    removeStickyFooterFromItem(item);
                                }
                            }, 25);
                            setTimeout(() => {
                                clearInterval(areTopAndBottomOfElementInViewport);
                            }, elementTransitionDuration);
                        }
                    }

                    function buildArray(timelineItem) {
                        const initialArray = [timelineItem];
                        collapsedTimelineChildrenInViewport = [];

                        for (let i = 0; i < 10; i++) {
                            const lastChild = initialArray.slice(-1)[0];
                            if (!lastChild) break;
                            if (lastChild.parentElement.nextElementSibling !== null) {
                                initialArray.push(lastChild.parentElement.nextElementSibling.firstElementChild);
                                if (lastChild.parentElement.nextElementSibling.classList.contains('collapsed')) {
                                    collapsedTimelineChildrenInViewport.push(
                                        lastChild.parentElement.nextElementSibling
                                    );
                                }
                            }
                        }
                        return collapsedTimelineChildrenInViewport;
                    }

                    function checkHeaderHight(timelineItem) {
                        setTimeout(() => {
                            timelineItem[0].parentElement.parentElement.style.height =
                                timelineItem[0].clientHeight + 'px';
                        }, 50);
                    }

                    function hideFooterIfEmpty(item) {
                        if (
                            item.lastElementChild &&
                            item.lastElementChild.style &&
                            item.lastElementChild.children &&
                            item.lastElementChild.children.length < 1
                        )
                            item.lastElementChild.style.padding = '0px';
                    }

                    function isItemCollapsed(timelineItem) {
                        return (timelineItem.parentElement && timelineItem.parentElement.classList.contains('collapsed')) ? true : false;
                    }

                    function addCollapsedClass() {
                        timelineItem.parentElement.classList.add('collapsed');
                    }

                    function removeStickyHeader(item) {
                        item.parentElement.classList.remove('sticky-header');
                    }

                    function addStickyFooterToItem(item) {
                        item.classList.add('sticky-footer');
                    }

                    function removeCollapsedClassFromItem(item) {
                        item.parentElement.classList.remove('collapsed');
                    }

                    function resetHeightItem(item) {
                        item.parentElement.style.height = item.firstElementChild.clientHeight + 'px';
                    }

                    function removeStickyFooterFromItem(item) {
                        item.classList.remove('sticky-footer');
                    }

                    function setWidthFooterAndHeaderItem(item) {
                        if (!item.parentElement) return;

                        if (item && item.firstChild && item.firstChild.style)
                            item.firstChild.style.width = item.parentElement.clientWidth + 'px';

                        if (item && item.lastElementChild && item.lastElementChild.style)
                            item.lastElementChild.style.width = item.parentElement.clientWidth + 'px';
                    }

                    function isFooterElementInViewport(footerElement) {
                        return Math.round(footerElement.bottom) <= viewportHeight &&
                            Math.round(footerElement.bottom) > 122
                            ? true
                            : false;
                    }

                    function isTopElementInViewport(elementPosition) {
                        return Math.round(elementPosition.top) <= viewportHeight &&
                            Math.round(elementPosition.top) > 122
                            ? true
                            : false;
                    }

                    function isBottomElementInViewport(elementPosition) {
                        return Math.round(elementPosition.bottom) <= viewportHeight &&
                            Math.round(elementPosition.bottom) > 122
                            ? true
                            : false;
                    }
                }
            };
        }
    ]);
}
