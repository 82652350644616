namespace Umbrella.TaskHandling {
    import TaakStatus = Umbrella.Modules.Tasks.TaakStatus;

    @Pipe('TaskHandling', 'taskStatus')
    class TaskstatusFilter {
        public transform(status: TaakStatus | string | number) {
            switch (status) {
                case TaakStatus.Nieuw:
                case 'Nieuw':
                case 0:
                    return 'Nieuw';
                case TaakStatus.Opgepakt:
                case 'Opgepakt':
                case 1:
                    return 'Opgepakt';
                case TaakStatus.Afgerond:
                case 'Afgerond':
                case 2:
                    return 'Afgerond';
                case TaakStatus.Verwijderd:
                case 'Verwijderd':
                case 3:
                    return 'Verwijderd';
                case TaakStatus.Geweigerd:
                case 'Geweigerd':
                case 4:
                    return 'Geweigerd';
                case TaakStatus.Geannuleerd:
                case 'Geannuleerd':
                case 5:
                    return 'Geannuleerd';
            }

            return '';
        }
    }
}
