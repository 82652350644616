namespace Umbrella.CustomerService.UnitCard.Contracts {
    import UnitModel = Modules.Housing.UnitModel;
    import ContractModel = Modules.Housing.ContractModel;

    export interface UnitContractsComponentState {
        readonly loading: boolean;
        readonly contracts: PagedItemsModel<ContractModel>;
        readonly unit: UnitModel;
    }

    interface UnitContractsStateParams extends UnitCardStateParams {
        unitId: System.Guid;
        page: number;
        size: number;
    }

    @Component('CustomerService', {
        selector: 'unit-contracts',
        templateUrl:
            'CustomerService/_UnitCard/_Contracts/UnitContractsComponent/UnitContracts.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('UnitCardService')
    class UnitContractsComponent {
        public state: UnitContractsComponentState;

        constructor(private unitCardService: UnitCardService) {}

        public loadMore() {
            this.unitCardService.loadContracts(
                this.state.contracts.page + 1,
                this.state.contracts.pageSize
            );
        }
    }
}
