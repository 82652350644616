/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Decorators.ts" />
/// <reference path="../../../../../Window.d.ts" />

namespace Umbrella.KCC.CaseFlowDashboard {
    import IUploadService = angular.angularFileUpload.IUploadService;
    import CaseFlowMessageModel = Umbrella.CaseFlow.CaseFlowMessageModel;
    import MediaFileModel = Umbrella.Media.MediaFileModel;

    export interface CaseFlowMessagesComponentState {
        messages: CaseFlowMessageModel[];
    }

    @Component('Dashboard', {
        selector: 'caseflow-messages',
        templateUrl: '/CaseFlowProcessing/_Overview/_Details/_Messages/CaseFlowMessagesComponent/CaseFlowMessages.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('CaseFlowOverviewService', 'ToastMessageService', 'Upload')
    class CaseFlowMessagesComponent {
        public state: CaseFlowMessagesComponentState;
        public caseFlowId: System.Guid;
        public waiting: boolean;
        public user = window.user;
        public remark: string = '';

        public isUploading: boolean;
        public actionUrl: string;
        private uploadedMedia: MediaFileModel[] = [];

        constructor(
            private caseFlowOverviewService: CaseFlowOverviewService,
            private toastMessageService: ToastMessageService,
            private uploadService: IUploadService
        ) {}

        public $onInit() {
            this.actionUrl = Config.makeAbsoluteApiUrl('/api/v1/media?mediaCategory=CaseMedia');
        }

        private addMessage(message: string): void {
            this.waiting = true;
            this.caseFlowOverviewService
                .addMessage({
                    message: message,
                    mediaId: this.uploadedMedia.length > 0 ? this.uploadedMedia[0].id : null
                })
                .then(() => {
                    this.remark = '';
                    this.uploadedMedia = [];
                })
                .catch(() =>
                    this.toastMessageService.error(
                        'Er is iets mis gegaan tijdens het opslaan van het bericht. Probeer het nogmaals. '
                    )
                )
                .then(() => (this.waiting = false));
        }

        private upload(file: File) {
            if (!file)
                return;

            this.isUploading = true;
            this.uploadService
                .upload({
                    url: this.actionUrl,
                    data: { file },
                    method: 'POST'
                })
                .then((result: { data: MediaFileModel }) => {
                    this.uploadedMedia.push(result.data);
                    this.isUploading = false;
                    this.toastMessageService.success('Bestand is succesvol toegevoegd');
                })
                .catch(() => {
                    this.toastMessageService.error('Bestand toevoegen is mislukt');
                    this.isUploading = false;
                });
        }

        private removeUpload(media: MediaFileModel) {
            this.uploadedMedia.splice(this.uploadedMedia.indexOf(media), 1);
        }

        private getImageSrc(imageId: System.Guid): string {
            return Config.makeAbsoluteApiUrl(`api/v1/media/crop?id=${imageId}&width=40&height=40`);
        }

        private viewImage(imageId: System.Guid): string {
            return Config.makeAbsoluteApiUrl('/api/v1/media/view/' + imageId);
        }
    }
}
