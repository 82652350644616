namespace Umbrella.Modules.Colleagues {
    @Pipe('Colleagues', 'isBusy')
    class IsBusyFilter {
        public transform(available: Availability | string | number) {
            switch (available) {
                case Availability.Busy:
                case 'Busy':
                case 3:
                    return true;
                default:
                    return false;
            }
        }
    }
}
