/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../ComponentRouteUtils.ts"/>
/// <reference path="../../../CaseFlowOverviewStore.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Overview {
    angular.module('Dashboard').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.caseflow.overview.details.messages', {
                url: '/messages',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Zaak interne berichten',
                    authorize: ps => [ps.viewCaseFlow, ps.viewDetailedCaseFlow]
                },
                views: {
                    tabcontent: {
                        template: '<caseflow-messages state="state$ | async:this"></caseflow-messages>',
                        controller: Umbrella.createComponentRouteController<
                            CaseFlowState,
                            CaseFlowMessagesComponentState
                        >('CaseFlowOverviewStore', s => ({
                            messages: s && s.caseInfo && s.caseInfo.messages
                        }))
                    }
                }
            });
        }
    ]);
}
