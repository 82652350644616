namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStep = Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;

    @Pipe('CaseFlow', 'activeStep')
    class ActiveStepFilter {
        public transform(input: CaseFlowStep[]): CaseFlowStep {
            if (!input) return undefined;

            const steps = input.filter(this.isStepActive);
            return steps.length > 0 ? steps[0] : null;
        }

        private isStepActive(step: CaseFlowStep): boolean {
            return (
                step.status.toString() === "1" ||
                step.status.toString() === "Started" ||
                step.status.toString() === "2" ||
                step.status.toString() === "PickedUp"
            );
        }
    }
}
