/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowOverviewModel = Umbrella.CaseFlow.CaseFlowOverviewModel;

    @Pipe('Dashboard', 'caseFlowStepDeadline')
    class CaseFlowStepDeadlineFilter {
        public transform(caseflow: CaseFlowOverviewModel): Date {
            if (!caseflow) return null;

            const totalSteps = (caseflow.steps && caseflow.steps.length) || 0;
            if (totalSteps === 0) return caseflow.deadline;

            const finishedSteps = this.getFinishedSteps(caseflow);
            const currentStepNr = totalSteps > finishedSteps ? finishedSteps + 1 : finishedSteps;
            const currentStep = caseflow.steps[currentStepNr - 1];

            if (!currentStep) return caseflow.deadline;

            return currentStep.deadline;
        }

        private getFinishedSteps(item: Umbrella.CaseFlow.CaseFlowOverviewModel): number {
            return (
                item.steps.filter(
                    step =>
                        step.status.toString() === "Finished" || step.status.toString() === "3"
                ).length * 1 || 0
            );
        }
    }
}
