/// <reference path="ActivityRegistrationState.ts"/>
/// <reference path="../../CustomerCardStore.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import ChannelType = Umbrella.Modules.ChannelType;
    import SelfServiceScenarioModel = Umbrella.Modules.SelfService.SelfServiceScenarioModel;
    import FaqModel = Umbrella.Modules.Knowledgebase.FaqModel;
    import ThesaurusTagModel = Umbrella.Modules.Knowledgebase.ThesaurusTagModel;
    import TargetGroupModel = Umbrella.Modules.Knowledgebase.TargetGroupModel;

    export interface ChannelSelected {
        readonly type: 'ActivityRegistrationChannelSelectedEvent';
        readonly channelType: ChannelType;
    }

    export interface ChannelCleared {
        readonly type: 'ActivityRegistrationChannelClearedEvent';
    }

    export interface TagsLoaded {
        readonly type: 'ActivityRegistrationTagsLoadedEvent';
        readonly tags: ThesaurusTagModel[];
    }

    export interface ScenariosForPersonLoaded {
        readonly type: 'ActivityRegistrationScenariosForPersonLoadedEvent';
        readonly scenarios: SelfServiceScenarioModel.Detailed[];
    }

    export interface TagSelected {
        readonly type: 'ActivityRegistrationTagSelectedEvent';
        readonly tag: ThesaurusTagModel;
    }

    export interface FaqsLoading {
        readonly type: 'ActivityRegistrationFaqsLoadingEvent';
        readonly page: number;
        readonly pageSize: number;
    }

    export interface FaqsLoaded {
        readonly type: 'ActivityRegistrationFaqsLoadedEvent';
        readonly faqs: PagedItemsModel<FaqModel>;
    }

    export interface FaqSelected {
        readonly type: 'ActivityRegistrationFaqSelectedEvent';
        readonly faq: FaqModel;
    }

    export interface TargetGroupSelected {
        readonly type: 'ActivityRegistrationTargetGroupSelected';
        readonly targetGroup: TargetGroupModel;
    }

    export interface FollowUpQuestionSelected {
        readonly type: 'ActivityRegistrationFollowUpQuestionSelected';
        readonly followUpQuestionId: System.Guid;
    }

    export interface ScenarioSelected {
        readonly type: 'ActivityRegistrationScenarioSelectedEvent';
        readonly scenario: SelfServiceScenarioModel;
    }

    export interface PanelSelected {
        readonly type: 'ActivityRegistrationPanelSelectedEvent';
        readonly panel: SelectedPanel;
    }

    export interface Searching {
        readonly type: 'ActivityRegistrationSearchingEvent';
        readonly query: string;
    }

    export interface Searched {
        readonly type: 'ActivityRegistrationSearchedEvent';
        readonly faqs: PagedItemsModel<FaqModel>;
    }

    export interface ConceptFaqChanged {
        readonly type: 'ActivityRegistrationFaqConceptChangedEvent';
        readonly concept: FaqConcept;
    }

    export interface ConceptFaqSaving {
        readonly type: 'ActivityRegistrationFaqConceptSavingEvent';
    }

    export interface ConceptFaqSaved {
        readonly type: 'ActivityRegistrationFaqConceptSavedEvent';
        readonly faq: Modules.Knowledgebase.AdminFaqModel;
    }

    export interface ConceptFaqCancelled {
        readonly type: 'ActivityRegistrationFaqConceptCancelledEvent';
    }

    export interface Registered {
        readonly type: 'ActivityRegistrationRegisteredEvent';
    }

    export interface TaskRegistering {
        readonly type: 'TaskRegistrationRegisteringEvent';
        readonly task: NewTask;
    }

    export interface TaskRegistered {
        readonly type: 'TaskRegistrationRegisteredEvent';
    }

    export interface TaskRegisterError {
        readonly type: 'TaskRegistrationRegisterErrorEvent';
        readonly error: any;
    }

    export interface TargetGroupsLoadingEvent {
        readonly type: 'TargetGroupsLoadingEvent';
    }

    export interface TargetGroupsLoadedEvent {
        readonly type: 'TargetGroupsLoadedEvent';
        readonly targetGroups: TargetGroupModel[];
    }

    export type ActivityRegistrationEvent =
        | PanelSelected
        | ChannelSelected
        | ChannelCleared
        | ScenariosForPersonLoaded
        | TagsLoaded
        | TagSelected
        | FaqsLoading
        | FaqsLoaded
        | FaqSelected
        | ScenarioSelected
        | Searching
        | Searched
        | ConceptFaqChanged
        | ConceptFaqSaving
        | ConceptFaqSaved
        | ConceptFaqCancelled
        | Registered
        | TaskRegistering
        | TaskRegistered
        | TaskRegisterError
        | TargetGroupsLoadingEvent
        | TargetGroupsLoadedEvent
        | TargetGroupSelected
        | FollowUpQuestionSelected;

    function reduce(
        e: ActivityRegistrationEvent
    ): (s: ActivityRegistrationState) => ActivityRegistrationState {
        switch (e.type) {
            case 'ActivityRegistrationChannelSelectedEvent':
                return s => ({
                    ...s,
                    selectedChannelType: e.channelType,
                    selectedTag: null,
                    selectedFaq: null,
                    selectedScenario: null,
                    tagInfo: {
                        loading: true,
                        tags: []
                    },
                    faqInfo: null
                });

            case 'ActivityRegistrationChannelClearedEvent':
                return s => ({
                    ...s,
                    selectedChannelType: ChannelType.Unknown,
                    selectedTag: null,
                    selectedFaq: null,
                    selectedScenario: null,
                    query: '',
                    tagInfo: null,
                    faqInfo: null
                });

            case 'ActivityRegistrationScenariosForPersonLoadedEvent':
                return s => ({
                    ...s,
                    scenarios: e.scenarios
                });

            case 'ActivityRegistrationTagsLoadedEvent':
                return s => ({
                    ...s,
                    tagInfo: {
                        loading: false,
                        tags: e.tags
                    }
                });

            case 'ActivityRegistrationTagSelectedEvent':
                return s => ({
                    ...s,
                    selectedTag: e.tag,
                    selectedFaq: null,
                    query: '',
                    tagInfo: {
                        loading: e.tag && !e.tag.parent,
                        tags: []
                    },
                    faqInfo: null
                });

            case 'ActivityRegistrationFaqsLoadingEvent':
                return s => ({
                    ...s,
                    faqInfo: {
                        loading: true,
                        faqs: {
                            total: s.faqInfo && s.faqInfo.faqs.total,
                            page: e.page,
                            pageSize: e.pageSize,
                            items: s.faqInfo && s.faqInfo.faqs.items
                        },
                        faqConcept: s.faqInfo && s.faqInfo.faqConcept
                    }
                });

            case 'ActivityRegistrationFaqsLoadedEvent':
                return s => ({
                    ...s,
                    faqInfo: {
                        loading: false,
                        faqs: e.faqs,
                        faqConcept: s.faqInfo && s.faqInfo.faqConcept
                    }
                });

            case 'ActivityRegistrationFaqSelectedEvent':
                return s => ({
                    ...s,
                    selectedFaq:
                        s.selectedFaq && s.selectedFaq.id === e.faq.id
                            ? null
                            : e.faq,
                    selectedTargetGroup: null,
                    selectedFollowUpQuestionId: null
                });

            case 'ActivityRegistrationTargetGroupSelected':
                return s => ({
                    ...s,
                    selectedTargetGroup: e.targetGroup
                });

            case 'ActivityRegistrationFollowUpQuestionSelected':
                return s => ({
                    ...s,
                    selectedFollowUpQuestionId: e.followUpQuestionId
                });

            case 'ActivityRegistrationScenarioSelectedEvent':
                return s => ({
                    ...s,
                    selectedScenario: e.scenario
                });

            case 'ActivityRegistrationPanelSelectedEvent':
                return s => ({
                    ...s,
                    selectedPanel: e.panel
                });

            case 'ActivityRegistrationSearchingEvent':
                return s => ({
                    ...s,
                    query: e.query,
                    faqInfo: {
                        loading: true,
                        faqs: {
                            total: 0,
                            page: 0,
                            pageSize: 7,
                            items: []
                        },
                        faqConcept: s.faqInfo && s.faqInfo.faqConcept
                    }
                });

            case 'ActivityRegistrationSearchedEvent':
                return s => ({
                    ...s,
                    faqInfo: {
                        loading: false,
                        faqs: e.faqs,
                        faqConcept: s.faqInfo && s.faqInfo.faqConcept
                    }
                });
            case 'ActivityRegistrationFaqConceptChangedEvent':
                return s => ({
                    ...s,
                    faqInfo: {
                        ...s.faqInfo,
                        faqConcept: e.concept
                    }
                });
            case 'ActivityRegistrationFaqConceptSavingEvent':
                return s => ({
                    ...s,
                    faqInfo: {
                        ...s.faqInfo,
                        faqConcept: {
                            ...s.faqInfo.faqConcept,
                            id: null,
                            saving: true
                        }
                    },
                    contactRegistrationInfo: s.contactRegistrationInfo,
                    taskRegistrationInfo: s.taskRegistrationInfo
                });
            case 'ActivityRegistrationFaqConceptSavedEvent':
                return s => ({
                    ...s,
                    selectedFaq: e.faq,
                    faqInfo: {
                        ...s.faqInfo,
                        faqConcept: {
                            id: e.faq.id,
                            question: e.faq.question,
                            answer: e.faq.answer,
                            saving: false
                        }
                    }
                });
            case 'ActivityRegistrationFaqConceptCancelledEvent':
                return s => ({
                    ...s,
                    faqInfo: {
                        ...s.faqInfo,
                        faqConcept: null
                    }
                });
            case 'ActivityRegistrationRegisteredEvent':
                return s => ({
                    ...s,
                    query: '',
                    contactRegistrationInfo: null,
                    selectedTag: null,
                    selectedFaq: null,
                    faqInfo: null,
                    tagInfo: null,
                    selectedPanel: SelectedPanel.None
                });
            case 'TaskRegistrationRegisteringEvent':
                return s => ({
                    ...s,
                    taskRegistrationInfo: {
                        saving: true,
                        task: e.task,
                        error: null
                    }
                });
            case 'TaskRegistrationRegisteredEvent':
                return s => ({
                    ...s,
                    query: '',
                    taskRegistrationInfo: null,
                    selectedTag: null,
                    selectedFaq: null,
                    faqInfo: null,
                    tagInfo: null,
                    selectedPanel: SelectedPanel.None
                });
            case 'TaskRegistrationRegisterErrorEvent':
                return s => ({
                    ...s,
                    taskRegistrationInfo: {
                        saving: false,
                        task: s.taskRegistrationInfo.task,
                        error: e.error
                    }
                });

            case 'TargetGroupsLoadingEvent':
                return s => ({
                    ...s,
                    targetGroupInfo: {
                        loading: true,
                        targetGroups: []
                    }
                });

            case 'TargetGroupsLoadedEvent':
                return s => ({
                    ...s,
                    targetGroupInfo: {
                        loading: false,
                        targetGroups: e.targetGroups
                    }
                });
        }
    }

    function set(
        s: CustomerCardState,
        replacement: ActivityRegistrationState
    ): CustomerCardState {
        return {
            ...s,
            activityRegistrationInfo: replacement
        };
    }

    export type ActivityRegistrationStore = ObservableStore.EventStore<
        ActivityRegistrationState,
        ActivityRegistrationEvent
    >;

    export const activityRegistrationStore = customerCardStore.map<
        ActivityRegistrationState,
        ActivityRegistrationEvent
    >(
        { get: s => s && s.activityRegistrationInfo, set: (s, a) => set(s, a) },
        reduce
    );
}
