/// <reference path="../Decorators.ts" />

namespace Umbrella {
    @Component('Umbrella', {
        selector: 'simple-loading',
        templateUrl: '/SimpleLoadingComponent/SimpleLoadingComponent.html',
        bindings: {
            isLoading: '<'
        }
    })
    class SimpleLoadingComponent {
        public isLoading?: boolean;
    }
}
