namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'isAvailableStatus')
    class IsAvailableStatusFilter {
        public transform(
            status: Umbrella.Telephony.PhoneCallStatus | string
        ): boolean {
            if (!status) return false;

            switch (status.toString().toLowerCase()) {
                case '3':
                case 'offline':
                    return false;
                default:
                    return true;
            }
        }
    }
}
