namespace Umbrella.Modules {
    angular.module('Umbrella').directive('clickable', [
        function() {
            return {
                priority: -1,
                restrict: 'A',
                link(scope, element, attr) {
                    element.addClass('clickable');
                    element.on('click', function(e) {
                        if (
                            e.target.nodeName.toLowerCase() == 'a' ||
                            $(e.target).parents('a').length
                        )
                            return;
                        let href = element.find('a:not(:disabled):first');
                        if (href.length) href.trigger('click');
                    });
                }
            };
        }
    ]);
}
