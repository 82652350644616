/// <reference path="../_Activities/ComplexActivitiesComponent/ComplexActivitiesComponent.ts" />

namespace Umbrella.CustomerService.ComplexCard {
    import IMenuTab = Modules.IMenuTab;
    import IStateMenuTab = Modules.IStateMenuTab;
    import QuickMenuButtonDefinition = CustomerService.QuickMenuButtonDefinition;
    import openComplexCardKnowledgebase$ = Umbrella.CustomerService.ComplexCard.openComplexCardKnowledgebase$;

    @Inject('$stateParams', '$mdDialog', '$state')
    @Component('CustomerService', {
        selector: 'complexcard-menu',
        template: `
            <div class="uc-cardmenu sectionmenu green" md-theme="umb-green">
                <menu-tabs tabs="vm.tabs"></menu-tabs>
                <quick-menu buttons="vm.buttons"></quick-menu>
            </div>
        `
    })
    class ComplexCardMenuComponent {
        public tabs: IMenuTab[];
        public buttons: QuickMenuButtonDefinition[];

        constructor(
            private $stateParams: ng.ui.IStateParamsService,
            private $mdDialog: any,
            private $state: ng.ui.IStateService
        ) {}

        public $onInit() {
            this.createTabs();
            this.createButtons();
        }

        public registerTask(): void {
            if (!this.$stateParams.complexId) return;

            if (this.$state.current.name === 'customerservice.complexcard.activities')
                openComplexCardKnowledgebase$.onNext(true);
            else
                this.$state.go('customerservice.complexcard.activities', {
                    complexId: this.$stateParams.complexId,
                    createtask: true
                });
        }

        public registerAnnouncement(): void {
            if (!this.$stateParams.complexId) return;

            this.$mdDialog.show({
                template: `<register-announcement-popup residential-zone-id="${
                    this.$stateParams.complexId
                }"></register-announcement-popup>`,
                targetEvent: null,
                clickOutsideToClose: false
            });
        }

        private createButtons() {
            this.buttons = [
                {
                    tooltip: 'Nieuwe mededeling',
                    label: 'Mededeling aanmaken',
                    auth: 'announcementWrite',
                    class: 'md-warn',
                    icon: 'volume_up',
                    action: () => this.registerAnnouncement()
                },
                {
                    tooltip: 'Nieuwe taak',
                    label: 'Taak aanmaken',
                    auth: 'createTask',
                    class: 'md-primary',
                    icon: 'check_box',
                    action: () => this.registerTask()
                }
            ];
        }

        private createTabs() {
            this.tabs = [];

            if (!window.user.permissions.customerCard) return;

            if (window.user.permissions.caseRead)
                this.addTab(this.createTab('Tijdlijn', 'customerservice.complexcard.activities'));

            this.addTab(this.createTab('Eenheden', 'customerservice.complexcard.units'));

            if (window.user.permissions.announcementRead)
                this.addTab(this.createTab('Mededelingen', 'customerservice.complexcard.announcements'));
        }

        private createTab(title: string, state: string): IStateMenuTab {
            return {
                title,
                state
            };
        }

        private addTab(tab: IStateMenuTab) {
            this.tabs.push(tab);
        }
    }
}
