﻿namespace Umbrella.Search {
    @Component('Search', {
        selector: 'search-tabs',
        templateUrl: '/Search/SearchTabComponent/SearchTab.html',
        bindings: {
            activetab: '@',
            searchquery: '@'
        }
    })
    class SearchTabComponent {}
}
