namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'ongoingCalls')
    class OngoingCallsFilter {
        public transform(
            phoneCalls: Umbrella.Telephony.PhoneCallModel[]
        ): Umbrella.Telephony.PhoneCallModel[] {
            if (!phoneCalls) return undefined;

            const calls = [];
            for (const call of phoneCalls) {
                switch (call.status.toString()) {
                    case '3':
                    case 'Ringing':
                    case '5':
                    case 'LineClosed':
                        break;
                    default:
                        calls.push(call);
                        break;
                }
            }

            return calls;
        }
    }
}
