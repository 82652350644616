/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.Modules.Chat {
    angular.module('Chat').directive('uiToggleClass', [
        '$timeout',
        '$document',
        ($timeout, $document) => {
            return {
                restrict: 'AC',
                link: (scope, el, attr) => {
                    el.on('click', e => {
                        e.preventDefault();

                        el.toggleClass('obscured');
                    });
                }
            };
        }
    ]);
}
