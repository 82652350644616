/// <reference path="../../../../Window.d.ts" />

namespace Umbrella.ChatConversationHandling {
    export interface ChatConversationDetailsState {
        conversation: ChatOverviewItemModel;
        enabled: boolean;
        registered: boolean;
    }

    @Component('Chat', {
        selector: 'chat-details',
        templateUrl:
            '/ChatConversationHandling/_Overview/_Details/ChatConversationDetailsComponent/ChatConversationDetails.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('ChatService')
    class ChatConversationDetailsComponent {
        public state: ChatConversationDetailsState;
        public isProcessing = false;

        constructor(private chatService: ChatService) {}

        public acceptConversation() {
            this.isProcessing = true;
            this.chatService.accept(this.state.conversation.originId).finally(() => {
                this.isProcessing = false;
            });
        }

        public pickupConversation() {
            this.isProcessing = true;
            this.chatService.pickup(this.state.conversation.id).finally(() => {
                this.isProcessing = false;
            });
        }
    }
}
