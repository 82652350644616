namespace Umbrella.Modules.Chat {
    @Component('Chat', {
        selector: 'chat-initial-faqs',
        templateUrl: '/Modules/Chat/ChatInitialFaqsComponent/ChatInitialFaqs.html',
        bindings: {
            faqs: '<'
        }
    })
    @Inject()
    export class ChatInitialFaqsComponent {}
}
