/// <reference path="./Config/Config.ts" />
/// <reference path="./Decorators.ts" />

namespace Umbrella {
    @Pipe('Umbrella', 'synchronizedObjectPath')
    class SynchronizedObjectPathFilter {
        public transform(id: System.Guid) {
            if (!id) return '';

            return Config.makeManagementSynchronizedObjectPath(id);
        }
    }
}
