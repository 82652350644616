namespace Umbrella.Modules {
    import IScope = angular.IScope;

    export class IfNotMobileDirective implements angular.IDirective {
        priorit = -1;
        restrict = 'A';
        link = (scope: IScope, element, attr) => {
            const isMobile = document.getElementsByClassName('ismobile').length > 0;

            if (isMobile) element.hide();
        };

        static factory(): angular.IDirectiveFactory {
            return () => new IfNotMobileDirective();
        }
    }

    angular.module('Umbrella').directive('ifNotMobile', IfNotMobileDirective.factory());
}
