namespace Umbrella {
    @Pipe('Umbrella', 'trustedAsResourceUrl')
    @Inject('$sce')
    class TrustedAsResourceUrlFilter {
        constructor(private $sce: ng.ISCEService) {}

        public transform(url: string) {
            return this.$sce.trustAsResourceUrl(url);
        }
    }
}
