﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;

    @Pipe('TaskHandling', 'isNewerThan')
    class IsNewerThanFilter {
        public transform(task: TaskModel, hours: number): boolean {
            switch (task.status.toString()) {
                case 'Nieuw':
                case '0':
                    const nowMinusHours = new Date().addHours(-hours);
                    let creationdate = task.created;

                    if (!(creationdate instanceof Date))
                        creationdate = new Date(creationdate);

                    if (creationdate < nowMinusHours) return false;

                    return true;
                default:
                    return false;
            }
        }
    }
}
