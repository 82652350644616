/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Scope.d.ts"/>
/// <reference path="../../../../Window.d.ts" />

namespace Umbrella.KCC.CaseFlowDashboard {
    import CancelationStatusModel = Umbrella.CaseFlow.CancelationStatusModel;

    @Component('CaseFlow', {
        selector: 'cancel-caseflow-popup',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/CancelCaseFlowPopupComponent/CancelCaseFlowPopup.html',
        bindings: {
            caseflowId: '<'
        }
    })
    @Inject(
        '$mdDialog',
        'CaseFlowResource',
        'CaseFlowOverviewService',
        'ToastMessageService'
    )
    class CancelCaseFlowPopupComponent {
        public caseflowId: System.Guid;
        public statuses: CancelationStatusModel[] = [];
        public waiting: boolean;

        constructor(
            private $mdDialog,
            private caseFlowResource: CaseFlowResourceClass,
            private caseFlowOverviewService: CaseFlowOverviewService,
            private toastMessageService: ToastMessageService
        ) {}

        public $onInit() {
            this.caseFlowResource
                .getCancelationStatuses()
                .$promise.then(data => {
                    this.statuses = data;
                });
        }

        public confirm(statusId: System.Guid, reason: string): void {
            this.waiting = true;

            this.caseFlowOverviewService
                .cancel({
                    caseFlowId: this.caseflowId,
                    cancelationStatusId: statusId,
                    reason
                })
                .then(() => this.$mdDialog.hide())
                .catch(e =>
                    this.toastMessageService.error(
                        'Afbreken zaak mislukt. Probeer het nogmaals.'
                    )
                )
                .finally(() => (this.waiting = false));
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }
    }
}
