/// <reference path='../../../../Scripts/TypeScript/angularjs/angular.d.ts'/>
/// <reference path='../../../../Scripts/TypeScript/umbrella/umbrella.d.ts'/>
/// <reference path="../../../../SelectedColleagueOrFunctiongroup.d.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import debounce = Umbrella.Helpers.debounce;
    import TaskTypeModel = Modules.Tasks.TaskTypeModel;
    import IColleagueResourceClass = Modules.Colleagues.IColleagueResourceClass;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;
    import SelectedColleagueOrFunctiongroup = Modules.SelectedColleagueOrFunctiongroup;
    import IPromise = angular.IPromise;
    import toggleTaskListScroll = Umbrella.Shared.toggleTaskListScroll$;
    import isTaskListFilterViewEnabled = Umbrella.TaskHandling.isTaskListFilterViewEnabled$;

    export interface TaskOverviewFiltersComponentState {
        filters: TaskOverviewFilters;
    }

    @Component('Dashboard', {
        selector: 'task-overview-filters',
        templateUrl: '/TaskHandling/_Overview/_Filters/TaskOverviewFiltersComponent/TaskOverviewFilters.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('TaskOverviewService', 'ColleagueResource')
    class TaskOverviewFiltersComponent {
        public state: TaskOverviewFiltersComponentState;

        public searchQuery = '';
        public selectedStatus: string;
        public selectedDeadline: string;
        public allTaskTypes: TaskTypeModel[];
        public selectedTaskTypes: string[] = [];
        public selectedTaskTypesModel: TaskTypeModel[] = [];
        public createdBy: SelectedColleagueOrFunctiongroup;
        public assignedTo: SelectedColleagueOrFunctiongroup;
        private secondChangeHasPassed: boolean;

        constructor(
            private taskOverviewService: TaskOverviewService,
            private colleagueResource: IColleagueResourceClass
        ) {}

        public $onInit() {
            this.setFilters(this.state);
        }

        public $onChanges(bindings: { state: IBindingChange<TaskOverviewFiltersComponentState> }) {
            // NOTE: Implemented to support setting filters by URL query params
            if (
                bindings &&
                bindings.state &&
                !bindings.state.isFirstChange() &&
                !this.secondChangeHasPassed &&
                bindings.state.currentValue
            ) {
                this.setFilters(bindings.state.currentValue);
                this.secondChangeHasPassed = true;
            }
        }

        private setFilters(state: TaskOverviewFiltersComponentState): void {
            this.selectedStatus = (this.state && this.state.filters && this.state.filters.status) || '';
            this.selectedDeadline = (this.state && this.state.filters && this.state.filters.deadline) || '';
            this.searchQuery = this.state && this.state.filters && this.state.filters.query;
            this.assignedTo =
                this.state && this.state.filters && this.state.filters.assignedTo
                    ? { ...this.state.filters.assignedTo }
                    : null;
            this.createdBy =
                this.state && this.state.filters && this.state.filters.createdBy
                    ? { ...this.state.filters.createdBy }
                    : null;
            this.selectedTaskTypes =
                this.state && this.state.filters && this.state.filters.taskTypes
                    ? [...this.state.filters.taskTypes]
                    : [];

            this.taskOverviewService.loadTaskTypes().then(allTaskTypes => {
                this.allTaskTypes = allTaskTypes;
                this.selectedTaskTypesModel = [
                    ...allTaskTypes.filter(t => this.selectedTaskTypes.indexOf(t.name) > -1)
                ];
            });
        }

        public filterByQuery = debounce(
            (query: string) => {
                this.taskOverviewService.filterByQuery(query);
            },
            500,
            false
        );

        public filterByStatus(newValue: string): void {
            this.taskOverviewService.filterByStatus(newValue);
        }

        public filterByDeadline(newValue: string): void {
            this.taskOverviewService.filterByDeadline(newValue);
        }

        public searchCreatedBy(query = ''): IPromise<SummarizedColleagueModel[]> {
            return this.colleagueResource
                .getColleaguesAndFunctiongroups({
                    page: 0,
                    pageSize: 20,
                    id: query,
                    userId: null
                })
                .$promise.then(data => {
                    return data.items;
                });
        }

        public filterByCreatedBy(newSelection: SelectedColleagueOrFunctiongroup): void {
            this.taskOverviewService.filterByCreatedBy(newSelection);
        }

        public searchAssignedTo(query = ''): IPromise<SummarizedColleagueModel[]> {
            return this.colleagueResource
                .getColleaguesAndFunctiongroups({
                    page: 0,
                    pageSize: 20,
                    id: query,
                    userId: null
                })
                .$promise.then(data => {
                    return data.items;
                });
        }

        public filterByAssignedTo(newSelection: SelectedColleagueOrFunctiongroup): void {
            this.taskOverviewService.filterByAssignedTo(newSelection);
        }

        public toggleTaskType(taskType: string): void {
            if (typeof this.selectedTaskTypes === 'string')
                this.selectedTaskTypes = [this.selectedTaskTypes];

            const index = this.selectedTaskTypes.indexOf(taskType);
            if (index !== -1) this.selectedTaskTypes.splice(index, 1);
            else this.selectedTaskTypes.push(taskType);

            this.taskOverviewService.filterByTaskTypes([...this.selectedTaskTypes]);
        }

        public closeTaskListFilterView() {
            toggleNavigationIcon$.onNext('responsive-view-filters');
            isTaskListFilterViewEnabled.onNext(null);
            toggleTaskListScroll.onNext(null);
        }
    }
}
