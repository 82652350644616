namespace Umbrella.CustomerService {
    import ActivityModel = Modules.ActivityModel;

    @Pipe('CustomerService', 'activityChannelIcon')
    class ActivityChannelIconFilter {
        public transform(activity: ActivityModel): string {
            if (!activity) return '';

            if (activity.channel && activity.channel !== 'Unknown')
                return activity.channel.toString().toLowerCase();

            return activity.type;
        }
    }
}
