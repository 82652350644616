namespace Umbrella.Modules {
    import IResource = ng.resource.IResource;
    import AdminMediaModel = Umbrella.Media.AdminMediaModel;

    export class MediaClearParams {
        mediaId: Guid;
        reason: string;
    }

    export interface MediaResourceProvider {
        getByIds(params: IdsRequestParams): IResource<any>;
        getByIdsV2(params: IdsRequestParams): IResource<Umbrella.Media.MediaModel[]>;
        deleteMedia(params: MediaClearParams);
    }

    angular.module('Umbrella').service('MediaResourceProvider', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/media/:action/:id',
                {},
                {
                    getByIds: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'set' }
                    },
                    getByIdsV2: {
                        url: '/api/v2/media/:action/:id',
                        method: 'GET',
                        isArray: true,
                        params: { action: 'set' }
                    },
                    deleteMedia: {
                        url: '/api/v2/media/:mediaId/clear?reason=:reason',
                        method: 'PUT',
                        params: {
                            mediaId: '@mediaId',
                            reason: '@reason'
                        }
                    }
                }
            )
        ]);
}
