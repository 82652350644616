namespace Umbrella.Modules.Telephony {
    import PhoneCallModel = Umbrella.TelephonyControl.PhoneCallModel;

    @Pipe('Telephony', 'hasMultipleCandidates')
    class HasMultipleCandidatesFilter {
        public transform(call: PhoneCallModel): boolean {
            if (!call || !call.candidateRoleIds) return false;

            return call.candidateRoleIds.length > 1;
        }
    }
}
