﻿/// <reference path="../../../src/StripHtmlFilter.ts"/>

namespace Umbrella.CustomerService {
    import FaqResourceClass = Modules.Knowledgebase.Faq.FaqResourceClass;
    import IndicatorEvaluationModel = Umbrella.Indicators.IndicatorEvaluationModel;
    
    export interface IndicatorBindings {
        key: IBindingChange<string>;
        model: IBindingChange<any>;
    }

    @Component('CustomerService', {
        selector: 'indicators',
        templateUrl: '/CustomerService/IndicatorsComponent/IndicatorsComponent.html',
        bindings: {
            key: '@',
            model: '<',
            models: '<'
        }
    })
    @Inject('IndicatorService', '$mdDialog', 'FaqResource', 'ToastMessageService')
    export class IndicatorsComponent extends BaseIndicatorsComponent {
        public model: any;
        public indicators: IndicatorEvaluationModel[];
        public models: any[];
        public key: string;
        private previousModels: any[] = [];

        constructor(
            protected indicatorService: Umbrella.CustomerService.IndicatorService,
            $mdDialog,
            faqResource: FaqResourceClass,
            toastMessageService: ToastMessageService
        ) {
            super($mdDialog, faqResource, toastMessageService);
        }

        public $onChanges() {
            const models = this.models || [];
            if (this.model) models.push(this.model);

            if (models.length && this.isModelChanged(models)) {
                this.indicatorService.getActive(this.key, models).then(indicators => (this.indicators = indicators));
            }
        }

        private isModelChanged(models: any[]): boolean {
            const isChanged = JSON.stringify(this.previousModels) !== JSON.stringify(models);
            this.previousModels = models;
            return isChanged;
        }
    }
}
