/// <reference path="../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    allPhonesHub: any; // Umbrella.Modules.Telephony.IAllPhonesHub
}

namespace Umbrella.Modules.Telephony {
    export declare interface IAllPhonesHub {
        server: {
            register(): JQueryPromise<void>;
            deregister(): JQueryPromise<void>;
        };
        client: {
            registered?: (phone, number) => void;
            updated?: (phone, number) => void;
        };
    }

    export interface PhoneRegisteredEvent {
        type: 'registered';
        phone: any;
        number: any;
    }

    export interface PhoneUpdatedEvent {
        type: 'updated';
        phone: any;
        number: any;
    }

    export type PhoneEvent = PhoneRegisteredEvent | PhoneUpdatedEvent;
    export const phoneEvent$ = new Rx.Subject<PhoneEvent>();

    @Service('Telephony', 'AllPhonesService')
    export class AllPhonesService {
        public register() {
            onSignalRConnected(() =>
                $.connection.allPhonesHub.server.register()
            );
        }

        public deregister() {
            onSignalRConnected(() =>
                $.connection.allPhonesHub.server.deregister()
            );
        }

        public getAllPhones() {
            onSignalRConnected(() => {
                $.connection.allPhonesHub.server.getAllPhones();
            });
        }

        public listen() {
            onSignalRInitialized(() => {
                $.connection.allPhonesHub.on('registered', (phone, nr) => {
                    phoneEvent$.onNext({
                        type: 'registered',
                        phone,
                        number: nr
                    });
                });
                $.connection.allPhonesHub.on('update', (phone, nr) => {
                    phoneEvent$.onNext({ type: 'updated', phone, number: nr });
                });
            });
        }
    }

    angular
        .module('Telephony')
        .run([
            'AllPhonesService',
            allPhonesService => allPhonesService.listen()
        ]);
}
