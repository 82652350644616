/// <reference path="../../../Decorators.ts" />
/// <reference path="../../../CustomerService/_CustomerCard/_Registrations/RegistrationsResourceProvider.ts" />

namespace Umbrella.CustomerService {
    import Detailed = Umbrella.Modules.Customers.CustomerModel.Detailed;
    import RegistrationsResource = Umbrella.CustomerService.CustomerCard.Registrations.RegistrationsResource;
    import RegistrantInfoModel = Umbrella.HousingAllocation.Registrations.RegistrantInfoModel;

    const isHousingAllocationAvailable = () =>
        window &&
        window.config &&
        window.config.facets &&
        window.config.facets.housingAllocation &&
        window.config.facets.housingAllocation[0] &&
        window.config.facets.housingAllocation[0].available;

    @Component('CustomerService', {
        selector: 'customer-header',
        templateUrl: '/CustomerService/_CustomerCard/CustomerHeaderComponent/CustomerHeader.html',
        bindings: {
            person: '<',
            customer: '<'
        }
    })
    @Inject('RegistrationsResource')
    class CustomerHeaderComponent {
        public person: PersonModel;
        public customer: Detailed;

        public registrantInfo: RegistrantInfoModel;
        constructor(private registrationsResource: RegistrationsResource) {}

        public $onInit() {
            if (isHousingAllocationAvailable()) {
                this.getRegistrantInfoByPerson();
            }
        }

        private async getRegistrantInfoByPerson(): Promise<void> {
            const regInfo = await this.registrationsResource.getRegistrantInfoByPerson({
                id: this.person.id
            }).$promise;

            this.registrantInfo = regInfo;
        }
    }
}
