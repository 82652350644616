/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../SelectedColleagueOrFunctiongroup.d.ts"/>
/// <reference path="../CaseFlowProcessing/CaseFlowHub.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import SelectedColleagueOrFunctiongroup = Umbrella.Modules.SelectedColleagueOrFunctiongroup;
    import DetailedCaseFlowModel = Umbrella.CaseFlow.DetailedCaseFlowModel;
    import caseFlowStepFinished$ = Umbrella.CaseFlowProcessing.caseFlowStepFinished$;
    import caseFlowStepPickedUp$ = Umbrella.CaseFlowProcessing.caseFlowStepPickedUp$;
    import caseFlowStepTasksCreated$ = Umbrella.CaseFlowProcessing.caseFlowStepTasksCreated$;
    import caseFlowStepTaskFinished$ = Umbrella.CaseFlowProcessing.caseFlowStepTaskFinished$;
    import caseFlowUpdated$ = Umbrella.CaseFlowProcessing.caseFlowUpdated$;
    import CaseFlowOverviewModel = Umbrella.CaseFlow.CaseFlowOverviewModel;
    import CaseFlowStatus = Umbrella.CaseFlow.CaseFlowStatus;
    import GetEnumValueAsNumber = Umbrella.Helpers.GetEnumValueAsNumber;
    import CaseFlowMessageModel = Umbrella.CaseFlow.CaseFlowMessageModel;
    import FunctionGroupResource = Umbrella.Modules.Colleagues.IFunctionGroupResourceClass;
    import AssignmentModel = Umbrella.CaseFlow.AssignmentModel;
    import CaseFlowAssignmentType = Umbrella.CaseFlow.CaseFlowAssignmentType;
    import CaseFlowModel = Umbrella.CaseFlow.CaseFlowModel;
    import CaseFlowAddMessageModel = Umbrella.CaseFlow.CaseFlowAddMessageModel;
    import TaskHandling = Umbrella.TaskHandling;
    import IUploadService = angular.angularFileUpload.IUploadService;
    import MediaModel = Umbrella.Media.MediaModel;

    @Service('Dashboard', 'CaseFlowOverviewService')
    @Inject(
        '$q',
        'CaseFlowOverviewStore',
        'CaseFlowResource',
        'CaseHistoryResource',
        'SelfServiceReportResource',
        'ColleagueResource',
        'LocalStorageService',
        'FunctionGroupResource',
        'PersonResource',
        'Upload',
        'ToastMessageService'
    )
    export class CaseFlowOverviewService extends BaseStoreService<CaseFlowState, CaseFlowEvent, CaseFlowOverviewStore> {
        constructor(
            private $q: ng.IQService,
            store: CaseFlowOverviewStore,
            private caseFlowResource: CaseFlowResourceClass,
            private caseHistoryResource: Umbrella.Modules.Cases.ICaseHistoryResourceClass,
            private selfSerivceReportResource: Umbrella.Modules.SelfService.SelfServiceReportResource,
            private colleagueResource: IColleagueResourceClass,
            private localStorageService: LocalStorageService,
            private functionGroupResource: FunctionGroupResource,
            private personResource: PersonResource,
            private uploadService: IUploadService,
            private toastMessageService: ToastMessageService
        ) {
            super(store);

            this.observeEvents();
        }

        public async ensureLoaded() {
            await this.load();
        }

        public setToWorkloadView(): void {
            this.setView(View.Workload);
        }

        public setToListView(): void {
            this.setView(View.List);
        }

        public loadSelfServiceReportByCaseId(caseId: System.Guid): void {
            if (!window.user.permissions.viewDetailedCaseFlow) return;

            this.loadingSelfServiceReport(caseId);

            this.selfSerivceReportResource
                .getByCaseId({ id: caseId })
                .$promise.then((selfserviceReport: Umbrella.Modules.SelfService.SelfServiceReportModel) => {
                    this.loadedSelfServiceReport(selfserviceReport);
                });
        }

        public loadByTaskId(taskId: System.Guid): ng.IPromise<Umbrella.CaseFlow.DetailedCaseFlowModel> {
            this.loadingByTask(taskId);

            const promise = this.caseFlowResource.getByTaskId({ id: taskId }).$promise;

            promise.then(c => {
                this.loaded(c, null);
            });

            return promise;
        }

        public async loadByCaseFlowId(caseflowId: System.Guid, force = false): Promise<void> {
            const state = this.getState();
            if (
                !force &&
                state &&
                state.caseInfo &&
                state.caseInfo.caseflow &&
                state.caseInfo.caseflow.id === caseflowId
            )
                return;

            this.loading(caseflowId);

            let caseflow: DetailedCaseFlowModel;

            if (window.user.permissions.viewDetailedCaseFlow)
                caseflow = await this.caseFlowResource.getByIdDetailed({
                    id: caseflowId
                }).$promise;
            else {
                const simpleModel = await this.caseFlowResource.getById({
                    id: caseflowId
                }).$promise;
                caseflow = { ...simpleModel, steps: [] };
            }

            this.loaded(caseflow, await this.getOwner(caseflow.ownerModel));

            this.loadCaseHistory(caseflow.umbrellaCaseId);
            this.loadCaseMedia(caseflow.umbrellaCaseId);
            this.loadSelfServiceReportByCaseId(caseflow.umbrellaCaseId);
            this.loadMessages(caseflow.id);
            this.loadPublicMessages(caseflow.id);
        }

        public async update(c: Umbrella.CaseFlow.EditCaseFlowModel): Promise<CaseFlowModel> {
            const caseFlowModel = await this.caseFlowResource.update(c).$promise;
            const caseFlow = await this.caseFlowResource.getByIdDetailed({
                id: caseFlowModel.id
            }).$promise;

            this.loaded(caseFlow, await this.getOwner(caseFlow.ownerModel));

            return caseFlow;
        }

        public loadCaseHistory(caseId: System.Guid): void {
            this.caseHistoryResource
                .getByIdV2({ id: caseId })
                .$promise.then((c: Umbrella.Modules.Customers.v2.CaseModel) => {
                    this.emit({
                        type: 'CaseFlowHistoryLoadedV2Event',
                        caseHistory: c
                    });
                    if (c.personId) this.loadPerson(c.personId);
                });
        }

        public loadCaseMedia(caseId: System.Guid): void {
            this.caseHistoryResource
                .getCaseMedia({ id: caseId })
                .$promise.then((m: Umbrella.Modules.Customers.caseMedia[]) =>
                    this.emit({
                        type: 'CaseFlowMediaLoadedEvent',
                        media: m
                    })
                );
        }

        public loadPerson(personId: System.Guid): void {
            this.personResource.getById({ id: personId }).$promise.then(p =>
                this.emit({
                    type: 'CaseFlowPersonLoadedEvent',
                    person: p
                })
            );
        }

        public pickUpStep(caseflow: Umbrella.CaseFlow.PickupCaseFlowStepModel): ng.IPromise<boolean> {
            const promise = this.caseFlowResource.pickUpStep(caseflow).$promise;

            promise.then(value => {
                this.loadByCaseFlowId(caseflow.caseFlowId, true);
            });

            return promise;
        }

        public cancel(caseflow: Umbrella.CaseFlow.CancelCaseFlowStepModel): ng.IPromise<boolean> {
            const promise = this.caseFlowResource.cancel({ id: caseflow.caseFlowId }, caseflow).$promise;

            promise.then(value => {
                this.loadByCaseFlowId(caseflow.caseFlowId, true);
                // load from state list and modify status
                const state = this.getState();
                const stateCaseflowList =
                    state && state.overviewInfo && state.overviewInfo.cases && state.overviewInfo.cases.result;
                const caseflowInList =
                    stateCaseflowList && stateCaseflowList.items.filter(t => t.id === caseflow.caseFlowId)[0];

                if (caseflowInList) {
                    this.caseFlowResource.getById({ id: caseflow.caseFlowId }).$promise.then(updatedCaseflow => {
                        this.updateListWhenContainingCaseflow(<CaseFlowOverviewModel>(<unknown>updatedCaseflow));
                    });
                }
            });

            return promise;
        }

        public finishStep(caseflowId: System.Guid): ng.IPromise<boolean> {
            const promise = this.caseFlowResource.finishStep({ id: caseflowId }).$promise;

            promise.then(value => {
                this.loadByCaseFlowId(caseflowId, true);
            });

            return promise;
        }

        public finishTask(taskId: System.Guid, outcomeId?: System.Guid): ng.IPromise<void> {
            const promise = this.caseFlowResource.finishTask({ id: taskId }, { outcomeId }).$promise;

            promise.then(_ => {
                this.emit({ type: 'TaskFinishedEvent', taskId });
            });
            return promise;
        }

        public addMessage(message: CaseFlowAddMessageModel): ng.IPromise<CaseFlowMessageModel> {
            const state = this.getState();
            const caseflowId = state && state.caseInfo && state.caseInfo.id;

            if (!caseflowId) return this.$q.reject('No task selected yet.');

            const promise = this.caseFlowResource.addMessage({ id: caseflowId }, message).$promise;

            promise.then(data => {
                this.addMessageToStore(data);
            });

            return promise;
        }

        public addPublicMessage(publicMessage: CaseFlowAddMessageModel): ng.IPromise<CaseFlowMessageModel> {
            const state = this.getState();
            const caseflowId = state && state.caseInfo && state.caseInfo.id;

            if (!caseflowId) return this.$q.reject('No task selected yet.');

            const promise = this.caseFlowResource.addPublicMessage({ id: caseflowId }, publicMessage).$promise;

            promise.then(data => {
                this.addPublicMessageToStore(data);
            });

            return promise;
        }

        public unload() {
            this.emit({ type: 'CaseFlowUnloadedEvent' });
        }

        public async load(pageSize = 9) {
            const state = this.getState();
            const tasksLoadedCount =
                state &&
                state.overviewInfo &&
                state.overviewInfo.cases &&
                state.overviewInfo.cases.result &&
                state.overviewInfo.cases.result.items
                    ? state.overviewInfo.cases.result.items.length
                    : 0;
            const filters = state && state.overviewInfo && state.overviewInfo.filters;
            const page = Math.floor(tasksLoadedCount / pageSize);

            this.emit({
                type: 'CasesLoadingEvent',
                page,
                pageSize
            });

            await this.search(state.caseInfo.view, {
                page,
                pageSize,
                query: filters && filters.query,
                type: filters && filters.caseType,
                statusses: filters && filters.statusses,
                completion: filters && filters.completion,
                ownerRoleId: filters && filters.owner && filters.owner.id,
                executorRoleId: filters && filters.executor && filters.executor.id
            }).$promise.then((cases: SearchResultsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>) => {
                cases.result.items = this.filterCasesAlreadyInState(cases.result.items);
                this.emit({
                    type: 'CasesLoadedEvent',
                    cases,
                    filters
                });
                this.loadAssignmentNames(cases.result.items);
            });
        }

        public uploadDocument(url: string, file: File) {
            this.emit({ type: 'CaseFlowDocumentUploadingEvent' });
            this.uploadService
                .upload({
                    url,
                    data: { file },
                    method: 'POST'
                })
                .then((result: { data: MediaModel }) => {
                    this.emit({ type: 'CaseFlowDocumentUploadedEvent', mediaUploadId: result.data.id });
                    this.toastMessageService.success('Bestand is succesvol toegevoegd');
                })
                .catch(() => {
                    this.emit({ type: 'CaseFlowDocumentUploadErrorEvent' });
                    this.toastMessageService.error('Bestand toevoegen is mislukt');
                });
        }

        private loadMessages(caseflowId: System.Guid) {
            this.emit({ type: 'CaseFlowMessagesLoadingEvent' });
            this.caseFlowResource.getMessages({ id: caseflowId }).$promise.then(data =>
                this.emit({
                    type: 'CaseFlowMessagesLoadedEvent',
                    messages: data
                })
            );
        }

        private loadPublicMessages(caseflowId: System.Guid) {
            this.emit({ type: 'CaseFlowPublicMessagesLoadingEvent' });
            this.caseFlowResource.getPublicMessages({ id: caseflowId }).$promise.then(data =>
                this.emit({
                    type: 'CaseFlowPublicMessagesLoadedEvent',
                    publicMessages: data
                })
            );
        }

        private async getOwner(owner: AssignmentModel): Promise<AssignmentModel> {
            try {
                if (owner) {
                    const typeString = owner.type.toString();

                    if (
                        owner.type === CaseFlowAssignmentType.Employee ||
                        typeString === CaseFlowAssignmentType.Employee.toString() ||
                        typeString === 'Employee'
                    ) {
                        const colleague = await this.colleagueResource.get({ id: owner.id }).$promise;

                        owner.name = colleague.name;
                        return owner;
                    } else if (
                        owner.type === CaseFlowAssignmentType.FunctionGroup ||
                        typeString === CaseFlowAssignmentType.FunctionGroup.toString() ||
                        typeString === 'FunctionGroup'
                    ) {
                        const functionGroup = await this.functionGroupResource.getById({ id: owner.id }).$promise;

                        owner.name = functionGroup.title;
                        return owner;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            } catch (err) {
                return null;
            }
        }

        private addMessageToStore(message: CaseFlowMessageModel): void {
            this.emit({ type: 'NewCaseFlowMessageEvent', message: message });
        }

        private addPublicMessageToStore(publicMessage: CaseFlowMessageModel): void {
            this.emit({ type: 'NewCaseFlowPublicMessageEvent', publicMessage: publicMessage });
        }

        private loadAssignmentNames(cases: Umbrella.CaseFlow.CaseFlowOverviewModel[]) {
            const employeeIds = this.getAssignmentIdsForType(cases, CaseFlowAssignmentType.Employee);
            if (employeeIds && employeeIds.length) {
                this.colleagueResource.getByIds({ ids: employeeIds }).$promise.then(roles => {
                    const assignments = roles.map(r =>
                        this.createAssignment(r.id, CaseFlowAssignmentType.Employee, r.name)
                    );
                    this.updateCaseFlowAssignment(assignments);
                });
            }

            const functionGroupIds = this.getAssignmentIdsForType(cases, CaseFlowAssignmentType.FunctionGroup);
            if (functionGroupIds && functionGroupIds.length) {
                this.functionGroupResource.getByIds({ ids: functionGroupIds }).$promise.then(functionGroups => {
                    const assignments = functionGroups.map(r =>
                        this.createAssignment(r.id, CaseFlowAssignmentType.FunctionGroup, r.title)
                    );
                    this.updateCaseFlowAssignment(assignments);
                });
            }
        }

        private getAssignmentIdsForType(
            cases: Umbrella.CaseFlow.CaseFlowOverviewModel[],
            type: CaseFlowAssignmentType
        ) {
            return cases.filter(c => c.assignment && c.assignment.type === type).map(c => c.assignment.id);
        }

        private updateCaseFlowAssignment(assignments: AssignmentModel[]): void {
            this.emit({
                type: 'CaseFlowAssignmentLoadedEvent',
                assignments
            });
        }

        private createAssignment(id: System.Guid, type: CaseFlowAssignmentType, name: string): AssignmentModel {
            return { id, type, name };
        }

        private filterCasesAlreadyInState(caseFlows: CaseFlowOverviewModel[]) {
            const state = this.getState();
            if (
                !state ||
                !state.overviewInfo ||
                !state.overviewInfo.cases ||
                !state.overviewInfo.cases.result ||
                !state.overviewInfo.cases.result.items ||
                state.overviewInfo.cases.result.items.length <= 0
            ) {
                return caseFlows;
            }

            const notStoredCases = caseFlows.filter(x =>
                state.overviewInfo.cases.result.items.every(t => t.id !== x.id)
            );

            return notStoredCases;
        }

        public filterCasesByQuery(query: string) {
            const state = this.getState();
            const filters = {
                ...state.overviewInfo.filters,
                query
            };

            if (JSON.stringify(state.overviewInfo.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters, true);
        }

        public filterCasesByCompletion(completion: string) {
            const state = this.getState();
            const filters = {
                ...state.overviewInfo.filters,
                completion
            };

            if (JSON.stringify(state.overviewInfo.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters, true);
        }

        public filterCasesByType(caseType: string) {
            const state = this.getState();
            const filters = {
                ...state.overviewInfo.filters,
                caseType
            };

            if (JSON.stringify(state.overviewInfo.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters, true);
        }

        public filterCasesByStatus(statusses: string[]) {
            const state = this.getState();
            const filters = {
                ...state.overviewInfo.filters,
                statusses
            };

            if (JSON.stringify(state.overviewInfo.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters, true);
        }

        public filterCasesByOwner(colleagueOrFunctiongroup: SelectedColleagueOrFunctiongroup) {
            const state = this.getState();
            const filters = {
                ...state.overviewInfo.filters,
                owner: colleagueOrFunctiongroup
            };

            if (JSON.stringify(state.overviewInfo.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters, true);
        }

        public filterCasesByExecutor(colleagueOrFunctiongroup: SelectedColleagueOrFunctiongroup) {
            const state = this.getState();
            const filters = {
                ...state.overviewInfo.filters,
                executor: colleagueOrFunctiongroup
            };

            if (JSON.stringify(state.overviewInfo.filters) === JSON.stringify(filters)) return;

            this.updateFilters(filters, true);
        }

        public initializeFilters(): void {
            this.updateFiltersWithoutReload(this.generateFiltersQuery());
        }

        private updateListWhenContainingCaseflow(caseflow: CaseFlowOverviewModel): void {
            const state = this.getState();
            const stateCaseflowList =
                state && state.overviewInfo && state.overviewInfo.cases && state.overviewInfo.cases.result;
            const caseflowInList = stateCaseflowList && stateCaseflowList.items.filter(t => t.id === caseflow.id)[0];

            if (!caseflowInList) return;

            if (!this.caseflowPassesCurrentFilters(caseflow)) {
                this.removeCaseflowFromList(caseflow);
                return;
            }

            const caseflowOverviewModel: CaseFlowOverviewModel = {
                ...caseflowInList,
                ...caseflow
            };

            this.emit({
                type: 'CaseFlowOverviewListItemModifiedEvent',
                modifiedCaseflow: caseflowOverviewModel
            });
        }

        /** Must be replaced with a backend endpoint later */
        private caseflowPassesCurrentFilters(caseflow: CaseFlowOverviewModel): boolean {
            const state = this.getState();
            const filters = state && state.overviewInfo && state.overviewInfo.filters;

            if (!filters) return true;

            const caseflowMatchesFilters = filters.statusses.find(
                x => 1 * <any>x === GetEnumValueAsNumber(CaseFlowStatus, caseflow.status)
            );
            if (caseflowMatchesFilters) {
                return true;
            }

            return false;
        }

        private removeCaseflowFromList(caseflow: CaseFlowOverviewModel): void {
            this.emit({
                type: 'CaseFlowOverviewListItemRemovedEvent',
                id: caseflow.id
            });
        }

        private setView(view: View): void {
            const pageSize = view === View.Workload ? 4 : 9;

            this.emit({
                type: 'CaseFlowViewChangedEvent',
                view,
                pageSize
            });
        }

        private loading(id: System.Guid) {
            this.emit({
                type: 'CaseFlowLoadingEvent',
                id
            });
        }

        private loadingByTask(taskId: System.Guid) {
            this.emit({
                type: 'CaseFlowLoadingByTaskEvent',
                taskId
            });
        }

        private loadingSelfServiceReport(id: System.Guid) {
            this.emit({
                type: 'SelfServiceReportLoadingEvent',
                id
            });
        }

        private loadedSelfServiceReport(s: Umbrella.Modules.SelfService.SelfServiceReportModel) {
            this.emit({
                type: 'SelfServiceReportLoadedEvent',
                selfServiceReport: s
            });
        }

        private loaded(c: Umbrella.CaseFlow.DetailedCaseFlowModel, owner: AssignmentModel) {
            this.emit({
                type: 'CaseFlowLoadedEvent',
                caseflow: c,
                owner
            });
        }

        private search(
            view: View,
            params: CaseFlowSearchPagedRequestParams
        ): ng.resource.IResource<SearchResultsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>> {
            switch (view) {
                case View.List:
                    return this.caseFlowResource.search(params);
                case View.Workload:
                    return this.caseFlowResource.searchGroupedByOwner(params);
                default:
                    return this.caseFlowResource.search(params);
            }
        }

        private storeFilterValues(filterValues: Overview.CaseOverviewFilters): void {
            this.localStorageService.store('CaseOverviewFilters', JSON.stringify(filterValues));
        }

        private generateFiltersQuery(): Overview.CaseOverviewFilters {
            const storedFilterValues = this.localStorageService.get('CaseOverviewFilters');
            if (storedFilterValues && storedFilterValues.length) {
                return JSON.parse(storedFilterValues);
            }

            const state = this.getState();
            const filters = state && state.overviewInfo.filters;
            const emptyFilters = {
                statusses: ['0', '3'], // by default load only active cases
                query: null,
                caseType: null,
                completion: null,
                owner: null,
                executor: null
            };

            if (!filters) {
                return emptyFilters;
            }

            return { ...emptyFilters, ...filters };
        }

        private updateFiltersWithoutReload(filters: Overview.CaseOverviewFilters, storeFilters = true): void {
            const state = this.getState();
            const stateFilters = state && state.overviewInfo.filters;

            if (!stateFilters || JSON.stringify(stateFilters) !== JSON.stringify(filters)) {
                if (storeFilters) this.storeFilterValues(filters);
                this.emit({
                    type: 'CaseFlowOverviewFiltersUpdatedEvent',
                    filters
                });
            }
        }

        private updateFilters(filters: Overview.CaseOverviewFilters, storeFilters = true): void {
            this.updateFiltersWithoutReload(filters, true);
            this.ensureLoaded();
        }

        private updateCaseFlowListWhenStepPickedUp(id: Guid): void {
            this.emit({ type: 'UpdateCaseFlowStepPickedUpEvent', id });
        }

        private observeEvents(): void {
            const notNullOrUndefined = x => x !== null && x !== undefined;
            const isSelected = (caseFlowId: System.Guid) => {
                const url = window.location.hash;
                const guidRegExp = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g;
                return url && url.match(guidRegExp)[0] === caseFlowId;
            };

            caseFlowStepFinished$
                .filter(notNullOrUndefined)
                .map(x => x.caseFlowId)
                .subscribe((caseFlowId: System.Guid) => {
                    if (isSelected(caseFlowId)) this.loadByCaseFlowId(caseFlowId, true);
                    this.updateCaseFlowListWhenStepPickedUp(caseFlowId);
                });

            caseFlowStepPickedUp$
                .filter(notNullOrUndefined)
                .map(x => x.caseFlowId)
                .subscribe((caseFlowId: System.Guid) => {
                    if (isSelected(caseFlowId)) this.loadByCaseFlowId(caseFlowId, true);
                    this.updateCaseFlowListWhenStepPickedUp(caseFlowId);
                });

            caseFlowStepTasksCreated$
                .filter(notNullOrUndefined)
                .map(x => x.caseFlowId)
                .filter(isSelected)
                .subscribe((caseFlowId: System.Guid) => {
                    this.loadByCaseFlowId(caseFlowId, true);
                });

            caseFlowStepTaskFinished$
                .filter(notNullOrUndefined)
                .map(x => x.caseFlowId)
                .filter(isSelected)
                .subscribe((caseFlowId: System.Guid) => {
                    this.loadByCaseFlowId(caseFlowId, true);
                });

            caseFlowUpdated$
                .filter(notNullOrUndefined)
                .map(x => x.caseFlowId)
                .filter(isSelected)
                .subscribe((caseFlowId: System.Guid) => {
                    this.loadByCaseFlowId(caseFlowId, true);
                });
        }
    }
}
