/// <reference path="..\..\..\..\Decorators.ts" />
/// <reference path="..\..\..\..\Scripts/TypeScript/umbrella/umbrella.d.ts" />

namespace Umbrella.CustomerService {
    import Dictionary = Umbrella.Indicators.Dictionary;

    @Component('CustomerService', {
        selector: 'card-sidebar-contract-info',
        bindings: {
            collapsed: '<',
            contracts: '<',
            onToggle: '&'
        },
        templateUrl:
            '/CustomerService/_CustomerCard/Sidebar/CardSidebarContractInfoComponent/CardSidebarContractInfo.html'
    })
    @Inject('IndicatorEvaluationResource')
    class CardSidebarContractInfoComponent {
        public collapsed: boolean;
        public contracts: PagedItemsModel<Modules.Housing.ContractModel>;
        public indicators: Dictionary<EvaluationModel[]>;
        public onToggle: () => void;
        private previousModels: Modules.Housing.ContractModel[];

        constructor(private indicatorEvaluationResource: IIndicatorEvaluationResourceClass) {        
        }

        public $onChanges() {
            if (this.contracts && this.contracts.items && this.contracts.items.length && this.isModelChanged(this.contracts.items)) {
                let models = this.contracts.items.map(x => { return { key: x.id.toString(), models: [{ Contract: x }] } });
                this.indicatorEvaluationResource
                    .indicatorsForMultipleKeys(models)
                    .$promise
                    .then(indicators => {
                        this.indicators = indicators;
                    });
            }
        }

        private isModelChanged(models: Modules.Housing.ContractModel[]): boolean {
            const isChanged = JSON.stringify(this.previousModels) !== JSON.stringify(models);
            this.previousModels = models;
            return isChanged;
        }
    }
}
