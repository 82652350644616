/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../../Modules/Repairs/RepairRequestAppointment.d.ts"/>
/// <reference path="../../Scope.d.ts" />

namespace Umbrella.Modules.Repairs {
    import IResource = ng.resource.IResource;
    export interface SimulateParams {
        unitId: System.Guid;
        answerId: System.Guid;
        defectId: System.Guid;
        customerId: System.Guid;
    }
    export interface IdRepairRequestAppointmentParams {
        id: System.Guid;
    }

    export interface ActionSpecificationParams {
        unitId: System.Guid;
        answerId: System.Guid;
        defectId: System.Guid;
    }

    export interface IRepairRequestResourceClass {
        getById(params: IdRequestParams): IResource<RepairRequestModel>;
        getDetailed(
            params: IdRequestParams
        ): IResource<RepairRequestModel.WithResourceAndAppointment>;
        getAppointmentByAppointmentId(
            params: IdRepairRequestAppointmentParams
        ): IResource<RepairRequestAppointmentModel>;
        simulate(
            params: SimulateParams
        ): IResource<SimulationActionSpecificationModel>;
        getActionSpecification(
            params: ActionSpecificationParams
        ): IResource<ActionSpecificationModel>;
        createItem(
            params: CreateRepairItemModel
        ): IResource<RepairRequestModel>;
        createItemAnonymous(
            params: CreateRepairItemModel
        ): IResource<RepairRequestModel>;
        removeItem(
            params: RemoveDefectItemModel
        ): IResource<RepairRequestModel>;
        submit(params: SubmitRepairRequestModel): IResource<RepairRequestModel>;
        plan(params: PlanRepairRequestModel): IResource<RepairRequestModel>;
    }

    angular.module('SelfService').service('RepairRequestResource', [
        '$resource',
        $resource => {
            let prefix = '/api/v1/repairs/:action/:id/:route';
            return $resource(
                prefix,
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getDetailed: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id', route: 'detailed' }
                    },
                    getAppointmentByAppointmentId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'appointment', id: '@id' }
                    },
                    simulate: {
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'simulate',
                            unitId: '@unitId',
                            customerId: '@customerId',
                            answerId: '@answerId',
                            defectId: '@defectId'
                        }
                    },
                    getActionSpecification: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'actionspecification',
                            unitId: '@unitId',
                            answerId: '@answerId',
                            defectId: '@defectId'
                        }
                    },
                    createItem: {
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'item'
                        }
                    },
                    createItemAnonymous: {
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'anonymousitem'
                        }
                    },
                    removeItem: {
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'removeItem'
                        }
                    },
                    submit: {
                        method: 'PUT',
                        isArray: false,
                        params: {
                            action: 'submit'
                        }
                    },
                    plan: {
                        method: 'POST',
                        isArray: false,
                        params: {
                            action: 'plan'
                        }
                    }
                }
            );
        }
    ]);
}
