namespace Umbrella {
    import IActivityResourceClass = Umbrella.Modules.IActivityResourceClass;
    import CustomerCardActivityInfoService = Umbrella.CustomerService.CustomerCard.Activities.CustomerCardActivityInfoService;

    @Component('Umbrella', {
        selector: 'delete-activity-timeline-item',
        templateUrl: '/CustomerService/ActivityTimeline/ActivityTimelineItemComponent/DeleteActivityTimelineItem.html',
        bindings: {
            isCase: '<',
            id: '<'
        }
    })
    @Inject(
        '$mdDialog',
        'ToastMessageService',
        'CustomerCardActivityInfoService')

    export class DeleteActivityTimelineItemComponent {

        public isCase: boolean;
        public reason = '';
        public id: Guid;

        constructor(
            private $mdDialog,
            private toastMessageService: ToastMessageService,
            private customerCardActivityInfoService : CustomerCardActivityInfoService
        ) {
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public deleteCase(): void {
            this.customerCardActivityInfoService.deleteCase(this.id);
            this.$mdDialog.hide();
        }

        public deleteActivity(): void {
            this.customerCardActivityInfoService.deleteActivity(this.id);
            this.$mdDialog.hide();
        }
    }
}
