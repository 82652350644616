namespace Umbrella.Modules.Mail {
    export class PreviewOutgoingMailPopupButtonDirective
        implements angular.IDirective {
        restrict = 'EA';
        templateUrl =
            '/Modules/Mail/Directives/PreviewOutgoingMailPopupButton.html';
        replace = true;
        controller = PreviewOutgoingMailPopupButtonController;
        scope = {
            title: '@',
            outgoingMailActivityModel: '='
        };

        static factory(): angular.IDirectiveFactory {
            return () => new PreviewOutgoingMailPopupButtonDirective();
        }
    }

    angular
        .module('Mail')
        .directive(
            'previewOutgoingMailPopupButton',
            PreviewOutgoingMailPopupButtonDirective.factory()
        );
}
