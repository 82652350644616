﻿/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Decorators.ts" />

namespace Umbrella.TaskHandling {
    import TaskModel = Modules.Tasks.TaskModel;

    export interface TaskContactMomentsComponentState {
        task: TaskModel.Detailed;
        linkedTasks: TaskModel[];
    }

    @Inject('ActivityResource')
    @Component('TaskHandling', {
        selector: 'task-contact-moments',
        templateUrl:
            '/TaskHandling/_Overview/_Details/_ContactMoments/TaskContactMomentsComponent/TaskContactMomentsComponent.html',
        bindings: {
            state: '<'
        }
    })
    class TaskContactMomentsComponent {
        public state: TaskContactMomentsComponentState;
    }
}
