/// <reference path="../../../Decorators.ts" />

namespace Umbrella.CustomerService {
    @Component('CustomerService', {
        selector: 'card-progress-message',
        templateUrl:
            '/CustomerService/_CustomerCard/CardProgressMessageComponent/CardProgressMessage.html',
        bindings: {
            message: '@',
            nested: '<'
        }
    })
    class CardProgressMessageComponent {}
}
