/// <reference path="../../../Scripts/TypeScript/leaflet/index.d.ts" />

namespace Umbrella.CustomerService.UnitCard {
    @Component('CustomerService', {
        templateUrl: '/CustomerService/_UnitCard/MapComponent/Map.html',
        selector: 'map',
        bindings: {
            street: '@',
            houseNumber: '@',
            houseNumberExtension: '@',
            houseNumberLetter: '@',
            city: '@',
            displayMarker: '<'
        }
    })
    export class MapComponent {
        public error: string;
        public streetviewUrl: string;

        private street: string;
        private houseNumber: string;
        private houseNumberExtension: string;
        private houseNumberLetter: string;
        private city: string;
        private displayMarker: boolean;
        private map: L.Map;
        private initialLoad = false;

        public loadData() {
            if (
                this.initialLoad === false &&
                window.document.getElementById('streetmap_tab_osm') !== null &&
                this.map === undefined
            ) {
                this.initialLoad = true;
                $.ajax({
                    url: `https://nominatim.openstreetmap.org/search?format=json&q=${this.street} ${this.houseNumber}${this.houseNumberExtension}${this.houseNumberLetter}, ${this.city}`,
                    dataType: 'json',
                    xhrFields: {
                        withCredentials: false
                    },
                    headers: { 'Access-Control-Allow-Origin': '*' },
                    success: data => {
                        // When no address found then try again without housenumber extension and letter
                        if (!data[0]) {
                            $.ajax({
                                url: `https://nominatim.openstreetmap.org/search?format=json&q=${this.street} ${this.houseNumber}, ${this.city}`,
                                dataType: 'json',
                                xhrFields: {
                                    withCredentials: false
                                },
                                headers: { 'Access-Control-Allow-Origin': '*' },
                                success: dataWithoutExtensions => {
                                    if (!dataWithoutExtensions[0]) {
                                        this.error = 'Geen kaart voor dit adres';
                                        return;
                                    }
                                    this.loadMap(dataWithoutExtensions);
                                }
                            });
                            return;
                        }
                        this.loadMap(data);
                    },
                    error: () => {
                        this.error = 'Geen kaart voor dit adres';
                        return;
                    }
                });
            }
        }

        private loadMap(data: any) {
            const zoomFactor = 18;
            const placemarkLat = data[0].lat;
            const placemarkLon = data[0].lon;
            this.streetviewUrl = `https://maps.google.com/maps?q=&layer=c&cbll=${placemarkLat},${placemarkLon}`;
            $('#streetmap_tab_osm').html('');

            this.map = new L.Map('streetmap_tab_osm').setView([placemarkLat, placemarkLon], zoomFactor);

            L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.map);

            if (this.displayMarker) {
                L.circle([placemarkLat, placemarkLon], {
                    color: 'red',
                    fillColor: '#f03',
                    fillOpacity: 0.1,
                    radius: 5
                }).addTo(this.map);
            }
        }
    }
}
