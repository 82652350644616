namespace Umbrella.Modules.Colleagues {
    @Pipe('Colleagues', 'availabilityUnknown')
    class AvailabilityUnknownFilter {
        public transform(available: Availability | string | number) {
            switch (available) {
                case Availability.Unknown:
                case 'Unknown':
                case 0:
                    return true;
                default:
                    return false;
            }
        }
    }
}
