namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'activeCall')
    class ActiveCallFilter {
        public transform(
            phoneCalls: Umbrella.Telephony.PhoneCallModel[]
        ): Umbrella.Telephony.PhoneCallModel {
            if (!phoneCalls) return undefined;

            let activeCall: Umbrella.Telephony.PhoneCallModel;

            phoneCalls.forEach(call => {
                switch (call.status.toString()) {
                    case '2':
                    case 'Established':
                        activeCall = call;
                        break;
                    default:
                        break;
                }
            });

            if (activeCall) return activeCall;

            phoneCalls.forEach(call => {
                switch (call.status.toString()) {
                    case '1':
                    case 'Dialing':
                        activeCall = call;
                        break;
                    default:
                        break;
                }
            });

            if (activeCall) return activeCall;

            phoneCalls.forEach(call => {
                switch (call.status.toString()) {
                    case '6':
                    case 'OnHold':
                        activeCall = call;
                        break;
                    default:
                        break;
                }
            });

            if (activeCall) return activeCall;

            return phoneCalls[0];
        }
    }
}
