/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.ChatConversationHandling {
    angular.module('Chat').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.chat.overview', {
                url: '/overzicht',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Chat overzicht',
                    authorize: ps => ps.viewChatConversation
                },
                views: {
                    filters: {
                        // component: 'chatConversationFilters'
                    },
                    list: {
                        template: '<chat-conversation-list state="state$ | async:this"></chat-conversation-list>',
                        controller: Umbrella.createComponentRouteController<
                            ChatState,
                            ChatConversationListComponentState
                        >('ChatStore', s => ({
                            loading: false,
                            conversations: s && s.conversations,
                            enabled: s && s.enabled,
                            registered: s && s.registration && s.registration.status === RegisterStatus.Registered
                        }))
                    },
                    details: {
                        template: '<chat-no-chat-selected state="state$ | async:this"></chat-no-chat-selected>',
                        controller: Umbrella.createComponentRouteController<
                            ChatState,
                            ChatNoChatSelectedComponentState
                        >('ChatStore', s => ({
                            registered: s && s.registration && 1 * s.registration.status === RegisterStatus.Registered
                        }))
                    }
                }
            });
        }
    ]);
}
