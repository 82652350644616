﻿angular.module('Housing').factory('PriceComponentResource', [
    '$resource',
    $resource =>
        $resource(
            '/api/v1/pricecomponent/:action/:id',
            {},
            {
                getAll: {
                    method: 'GET',
                    isArray: true,
                    params: {}
                },
                getPaged: {
                    method: 'GET',
                    isArray: false,
                    params: { action: 'paged', page: 0, pageSize: 15 }
                },
                getById: {
                    method: 'GET',
                    isArray: false,
                    params: { id: '@id' }
                },
                delete: {
                    method: 'DELETE',
                    isArray: false,
                    params: { action: 'delete', id: '@id' }
                },
                save: {
                    method: 'POST',
                    isArray: false,
                    params: { action: 'add', id: '' }
                },
                update: {
                    method: 'PUT',
                    isArray: false,
                    params: { action: 'update', id: '' }
                }
            }
        )
]);
