/// <reference path="../../src/Modules/Media/MediaResourceProvider.ts"/>

namespace Umbrella {

    import MediaResourceProvider = Umbrella.Modules.MediaResourceProvider;

    @Component('Umbrella', {
        selector: 'delete-media',
        templateUrl: '/AttachmentListComponent/deleteMedia.html',
        bindings: {
            mediaItem: '<',
        }
    })
    @Inject('$mdDialog', 'MediaResourceProvider', 'ToastMessageService')
    export class DeleteMediaComponent {

        private mediaItem: any;
        public reason = '';

        constructor(
            private $mdDialog,
            private mediaResource: MediaResourceProvider,
            private toastMessageService: ToastMessageService,
        ) {}

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public deleteMedia(): void {
            this.mediaResource
                .deleteMedia({
                    mediaId: this.mediaItem.id,
                    reason: this.reason
                }
                ).$promise.then(() => {
                    this.$mdDialog.hide();
                    this.toastMessageService.success('Bestand is succesvol verwijderd');
                }).catch(() => {
                    this.toastMessageService.error('Bestand verwijderen is mislukt');
                });
        }
    }
}
