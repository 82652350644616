namespace Umbrella.TaskHandling {
    import TaakStatus = Umbrella.Modules.Tasks.TaakStatus;

    @Pipe('TaskHandling', 'taskAssignee')
    class TaskAssigneeFilter {
        public transform(status: TaakStatus | string | number, name: string ) {            
            switch (status) {
                case TaakStatus.Nieuw:
                case 'Nieuw':
                case 0:
                    return `Toegewezen aan ${ name }`;
                case TaakStatus.Opgepakt:
                case 'Opgepakt':
                case 1:
                    return `Opgepakt door ${ name }`;
                case TaakStatus.Afgerond:
                case 'Afgerond':
                case 2:
                    return `Afgerond door ${ name }`;
                case TaakStatus.Verwijderd:
                case 'Verwijderd':
                case 3:
                    return `Verwijderd door ${ name }`;
                case TaakStatus.Geweigerd:
                case 'Geweigerd':
                case 4:
                    return `Toegewezen aan ${ name }`;
                case TaakStatus.Geannuleerd:
                case 'Geannuleerd':
                case 5:
                    return `Geannuleerd door ${ name }`;
            }

            return '';
        }
    }
}