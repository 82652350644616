/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Scope.d.ts"/>
/// <reference path="../../../../../Window.d.ts" />

namespace Umbrella.KCC.CaseFlowDashboard {
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;
    import CaseFlowStep = Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;
    import CaseFlowStepSelfServiceScenario = Umbrella.CaseFlow.CaseFlowStep.CaseFlowStepSelfServiceScenario;

    export interface FinishCaseFlowStepPopupScope extends IPopupScope {
        caseFlowId: System.Guid;
        step: Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;
        waiting: boolean;

        finish(): void;
        cancel(): void;
    }

    @Component('CaseFlow', {
        selector: 'finish-caseflow-step-popup',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/_Steps/FinishCaseFlowStepPopupComponent/FinishCaseFlowStepPopup.html',
        bindings: {
            step: '<',
            caseFlowId: '<'
        }
    })
    @Inject('$mdDialog', 'CaseFlowOverviewService', 'TaskResource', 'ToastMessageService')
    export class FinishCaseFlowStepPopupComponent {
        private caseFlowId: System.Guid;
        public step: CaseFlowStep;
        public tasks: TaskModel[] = [];
        public selfServiceScenarios: CaseFlowStepSelfServiceScenario[];
        public waiting: boolean;

        constructor(
            private $mdDialog,
            private caseFlowOverviewService: CaseFlowOverviewService,
            private taskResource: Umbrella.Modules.Tasks.ITaskResourceClass,
            private toastMessageService: ToastMessageService
        ) {}

        public $onInit() {
            if (!this.step) return;

            const tasks = this.step.tasks.filter(t => t.umbrellaTaskId !== null);

            const ids = tasks.map(x => x.umbrellaTaskId);
            this.taskResource
                .getByIds({ ids })
                .$promise.then((data: Umbrella.Modules.Tasks.TaskModel[]) => {
                    this.tasks = this.getUnfinishedTasks(data);
                })
                .catch(_ => {
                    this.tasks = [];
                });

            this.selfServiceScenarios = this.step.selfServiceScenarios.filter(x => !x.isFinished);
        }

        private getUnfinishedTasks(tasks: TaskModel[]): TaskModel[] {
            return tasks.filter(task => !this.isTaskFinished(task));
        }

        private isTaskFinished(task: TaskModel): boolean {
            return !!task.finished;
        }

        public finish(): void {
            this.waiting = true;

            this.caseFlowOverviewService
                .finishStep(this.caseFlowId)
                .then(() => this.$mdDialog.hide())
                .catch(e => this.toastMessageService.error('Afronden stap mislukt. Probeer het nogmaals.'))
                .finally(() => (this.waiting = false));
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }
    }
}
