/// <reference path="../../../../ComponentRouteUtils.ts" />
/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard.Registrations {
    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;

    export interface ProspectRegistrationsStateParams {
        personId: System.Guid;
    }

    export interface ProspectRegistrationsComponentState {
        person: PersonModel;
        loading: boolean;
        registrations: RegistrationModel[];
        customer: Modules.Customers.CustomerModel.Detailed;
    }

    @Component('CustomerService', {
        selector: 'prospect-registrations',
        bindings: { state: '<' },
        templateUrl:
            '/CustomerService/_CustomerCard/_Registrations/ProspectRegistrationsComponent/ProspectRegistrations.html'
    })
    class ProspectRegistrationsComponent {
        public state: ProspectRegistrationsComponentState;
    }
}
