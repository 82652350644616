/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Decorators.ts" />

namespace Umbrella.KCC.CaseFlowDashboard {
    @Component('Dashboard', {
        selector: 'dashboard-loading-indicator',
        templateUrl:
            '/CaseFlowProcessing/DashboardLoadingIndicatorComponent/DashboardLoadingIndicator.html'
    })
    class DashboardLoadingIndicatorComponent {}
}
