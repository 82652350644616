/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.ComplexCard {
    import ComplexModel = Umbrella.Modules.Housing.ComplexModel;
    import IComplexResource = Umbrella.Modules.Housing.IComplexResource;
    import FaqModel = Modules.Knowledgebase.FaqModel;
    import ICaseHistoryResourceClass = Umbrella.Modules.Cases.ICaseHistoryResourceClass;
    import CaseHistoryModel = Umbrella.Modules.CaseHistoryModel;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;

    export const openComplexCardKnowledgebase$ = new Rx.Subject<boolean>();

    @Component('CustomerService', {
        selector: 'complex-activities',
        templateUrl: 'CustomerService/_ComplexCard/_Activities/ComplexActivitiesComponent/ComplexActivities.html',
        bindings: {
            state: '<'
        }
    })
    @Inject(
        '$stateParams',
        '$mdDialog',
        'ComplexResource',
        'CaseHistoryResource',
        '$location',
        'ActivityRegistrationService'
    )
    class ComplexActivitiesComponent {
        public complex: ComplexModel;
        public faqActions: any;
        public displayKnowledgebase: boolean;
        public cases: PagedItemsModel<CaseHistoryModel>;
        public hasPermissionsToReadActivities: boolean;
        public loading: boolean;
        private page = 0;
        private knowledgebaseSubscription: Rx.Disposable;

        constructor(
            private $stateParams: ComplexCardStateParams,
            private $mdDialog,
            private complexResource: IComplexResource,
            private caseHistoryResource: ICaseHistoryResourceClass,
            private $location: ng.ILocationService,
            private activityRegistrationService: ActivityRegistrationService
        ) {
            this.knowledgebaseSubscription = openComplexCardKnowledgebase$
                .filter(x => !!x)
                .subscribe(_ => {
                    this.openKnowledgebase();
                });
        }

        public $onInit() {
            const complexId = this.$stateParams.complexId;
            this.hasPermissionsToReadActivities = !!window.user.permissions.caseRead;

            this.loadComplex(complexId);
            this.loadCases(complexId);
            this.setFaqActionDefinitions();

            if (this.$stateParams.createtask) {
                this.openKnowledgebase();
                this.$location.search({});
            }
        }

        public $onDestroy() {
            if (this.knowledgebaseSubscription) this.knowledgebaseSubscription.dispose();
        }

        public setFaqActionDefinitions(): any {
            this.faqActions = [
                {
                    name: 'Taak aanmaken',
                    primary: true,
                    onExecute: (faq: FaqModel) => this.registerTask(faq)
                }
            ];
        }

        public openKnowledgebase(): void {
            this.displayKnowledgebase = true;
        }

        public closeKnowledgebase(): void {
            this.displayKnowledgebase = false;
        }

        public loadMoreCases(): void {
            this.loadCases(this.complex.id, this.page + 1);
        }

        public registerAnnouncement(): void {
            if (!this.$stateParams.complexId) return;

            this.$mdDialog.show({
                template: `<register-announcement-popup residential-zone-id="${
                    this.$stateParams.complexId
                }"></register-announcement-popup>`,
                targetEvent: null,
                clickOutsideToClose: false
            });
        }

        private registerTask(faq: FaqModel): void {
            const complexId = (this.complex && this.complex.id) || this.$stateParams.complexId;
            if (!complexId || !faq || !faq.mainTag) return;

            this.$mdDialog
                .show({
                    template: `<register-task-popup residential-zone-id="${complexId}" faq-id="${faq.id}" tag-id="${
                        faq.mainTag.id
                    }" channel-type="${this.activityRegistrationService.getSelectedChannelType()}"></register-task-popup>`,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(() => {
                    this.closeKnowledgebase();
                    this.loadCases(complexId);
                });
        }

        private async loadComplex(complexId: System.Guid): Promise<void> {
            if (!complexId) return;

            this.complex = await this.complexResource.getById({ id: complexId }).$promise;
        }

        private async loadCases(complexId: System.Guid, page = 0, pageSize = 10): Promise<void> {
            if (!complexId) return;

            this.loading = true;
            this.page = page;

            const cases = await this.caseHistoryResource.getByComplexId({
                id: complexId,
                page,
                pageSize
            }).$promise;
            if (page === 0) this.cases = cases;
            else {
                this.cases = {
                    page,
                    pageSize,
                    items: [...this.cases.items, ...cases.items],
                    total: cases.total
                };
            }

            this.loading = false;
        }
    }
}
