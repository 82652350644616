namespace Umbrella.Modules.Log {
    export type LogLevel = 'error' | 'warn' | 'info';

    export interface LogOutput {
        level: LogLevel;
        msg: string;
    }

    export const log$ = new Rx.Subject<LogOutput>();
    log$.subscribe(x => {
        window.console && window.console[x.level](x.msg);
    });

    const send = (level: LogLevel, msg: any) => log$.onNext({ level, msg });

    export const error = msg => send('error', msg);
    export const warn = msg => send('warn', msg);
    export const info = msg => send('info', msg);
}
