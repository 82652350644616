/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.ColleagueCard.Activities {
    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.colleaguecard.activities', {
                data: {
                    tab: 'history',
                    authorize: ps => ps.customerCard
                },
                url: '/history',
                views: {
                    'tab@customerservice': {
                        template:
                            '<colleague-activities></colleague-activities>'
                    }
                }
            });
        }
    ]);
}
