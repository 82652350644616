/// <reference path="../../../ChatConversationHandling/ChatStore.ts" />

namespace Umbrella.Modules.Chat {
    import ChatStore = Umbrella.ChatConversationHandling.ChatStore;
    import FaqResourceClass = Umbrella.Modules.Knowledgebase.Faq.FaqResourceClass;
    import FaqModel = Umbrella.Modules.Knowledgebase.FaqModel;
    import debounce = Umbrella.Helpers.debounce;
    import copyToClipboard = Umbrella.Helpers.copyToClipboard;
    import FaqTargetGroupModel = Umbrella.Modules.Knowledgebase.FaqTargetGroupModel;
    import ChatMessage = Umbrella.ChatConversationHandling.ChatMessage;
    import ChatOverviewItemModel = Umbrella.ChatConversationHandling.ChatOverviewItemModel;
    import ChatService = Umbrella.ChatConversationHandling.ChatService;
    import MessageDirections = Umbrella.ChatConversationHandling.MessageDirections;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;
    import CustomerCardPersonInfoService = Umbrella.CustomerService.CustomerCard.CustomerCardPersonInfoService;
    import ContactActivityModel = Umbrella.Modules.Contacts.ContactActivityModel;

    export interface ChatCustomerInformation {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
    }

    @Component('Chat', {
        selector: 'chat-faq-browser',
        templateUrl: '/Modules/Chat/ChatFaqBrowserComponent/ChatFaqBrowser.html',
        bindings: {
            messages: '<',
            conversationId: '<'
        }
    })
    @Inject(
        'ChatStore',
        'FaqResource',
        'ChatService',
        'TargetGroupResource',
        'ToastMessageService',
        '$filter',
        '$mdDialog',
        'ActivityRegistrationService',
        'CustomerCardPersonInfoService'
    )
    export class ChatFaqBrowserComponent {
        public suggestions: FaqModel[];
        public searchQuery: string;
        public faqs: PagedItemsModel<FaqModel>;
        public selectedFaq: FaqModel;
        public loadingFaqs: boolean;
        public targetGroupChoices: { [id: string]: FaqTargetGroupModel } = {};
        public targetGroupAnswers: { [id: string]: string } = {};
        public selectedFaqId: Guid;
        public selectededThesaurasTag: Guid;
        public portalUrl: string;
        public conversation: ChatOverviewItemModel;

        private activeConversationObserver: Rx.IDisposable;
        private messages: ChatMessage[];
        private conversationId: Guid;
        private lastMessageId: Guid;
        private suggestionsLimit: number = 8;
        private page = 0;
        private pageSize = 10;
        private initializedSuggestions: boolean;

        constructor(
            private chatStore: ChatStore,
            private faqResource: FaqResourceClass,
            private chatService: ChatService,
            private targetGroupResource: Umbrella.Modules.Knowledgebase.TargetGroup.TargetGroupResourceClass,
            private toastMessageService: ToastMessageService,
            private $filter: ng.IFilterService,
            private $mdDialog,
            private activityRegistrationService: ActivityRegistrationService,
            private personInfoService: CustomerCardPersonInfoService
        ) {}

        $onInit() {
            this.generateInitialSuggestions();
            this.loadPortalConfig();
            this.observeActiveConversation();
        }

        $onDestroy() {
            if (this.activeConversationObserver) this.activeConversationObserver.dispose();
        }

        $onChanges(bindings: { messages: IBindingChange<ChatMessage[]> }) {
            const updatedMessagesBinding = bindings && bindings.messages && bindings.messages.currentValue;
            if (this.initializedSuggestions && updatedMessagesBinding) {
                const customerMessages = updatedMessagesBinding.filter(
                    x => x.direction === MessageDirections.toOperator
                );

                if (customerMessages.length < 1) return;

                const lastCustomerMessage = customerMessages[customerMessages.length - 1];

                if (lastCustomerMessage.id === this.lastMessageId) return;

                this.generateSuggestion(lastCustomerMessage);
            }
        }

        private observeActiveConversation() {
            this.activeConversationObserver = this.chatStore.state$
                .filter(x => !!x)
                .map(x => x.activeId && x.conversations && x.conversations.find(c => c.id === x.activeId))
                .distinctUntilChanged()
                .subscribe(conversation => {
                    this.conversation = conversation;
                });
        }

        public loadPortalConfig(): void {
            this.portalUrl = Config.getPortalUrl();
        }

        public applySearch = debounce(
            (query: string) => {
                this.reset();
                this.search(query);
            },
            500,
            false
        );

        public isLoadMoreVisible(): boolean {
            return this.faqs && !this.loadingFaqs && this.getTotalRemainingFaqCount() > 0 && this.isInSearchMode();
        }

        public getTotalRemainingFaqCount(): number {
            if (!this.faqs || !this.faqs.items) return 0;

            return this.faqs.total - this.faqs.items.length;
        }

        public loadMore(): void {
            this.page += 1;

            if (this.searchQuery && this.searchQuery.length) this.search(this.searchQuery);
        }

        public selectFaq(faq: FaqModel): void {
            if (!faq) return;

            this.selectedFaq = faq;

            if (faq.targetGroupAnswers) {
                const ids = faq.targetGroupAnswers.map(x => x.targetGroupId);
                this.targetGroupResource.getByIds({ ids: ids }).$promise.then(data => {
                    data.forEach(x => {
                        this.targetGroupAnswers[x.id.toString()] = x.name;
                    });
                });
            }

            this.selectedFaqId = this.selectedFaqId === faq.id ? null : faq.id;
            this.selectededThesaurasTag = this.selectededThesaurasTag === faq.mainTag.id ? null : faq.mainTag.id;

            if (this.selectedFaqId) this.faqResource.increaseViewCount({ id: this.selectedFaqId });
        }

        public faqIsSelected(): boolean {
            if (this.selectedFaqId == null || this.selectedFaqId == 'undefined') {
                return false;
            }
            return true;
        }

        public selectFaqAnswer(answer: string): void {
            this.chatService.addSelectedAnswer(answer.replace(/(<([^>]+)>)/gi, ''));
        }

        public selectFaqUrl(faq: FaqModel) {
            this.chatService.addSelectedAnswer(this.getFaqUrlString(faq));
        }

        public closeFaq() {
            this.selectedFaqId = null;
        }

        public getFaqAnswer(faq: FaqModel): string {
            if (!faq) return '';
            const savedTargetGroupInfo = this.targetGroupChoices && this.targetGroupChoices[String(faq.id)];
            const returnAnswer = (savedTargetGroupInfo && savedTargetGroupInfo.answer) || faq.answer;

            return this.addBulletPoints(returnAnswer);
        }

        public getNextRemainingFaqCount(): number {
            if (!this.faqs || !this.faqs.items) return 0;

            return Math.min(this.faqs.pageSize, this.getTotalRemainingFaqCount());
        }

        public isInSearchMode(): boolean {
            return !!(this.searchQuery && this.searchQuery.length);
        }

        public cancelSearch() {
            this.reset();
            this.searchQuery = '';
        }

        public copyFaqAnswerToClipboard(faq: FaqModel): void {
            if (!faq || !faq.answer) return;

            const stripHtmlFilter = <any>this.$filter('stripHtml');
            let faqAnswer = stripHtmlFilter(faq.answer);

            const element = document.createElement('div');
            element.innerHTML = faqAnswer;
            faqAnswer = element.textContent;
            element.textContent = '';

            copyToClipboard(faqAnswer, () => {
                this.toastMessageService.info('Antwoord gekopieerd');
            });
        }

        public copyFaqPortalUrlToClipboard(faq: FaqModel): void {
            if (!faq || !this.portalUrl) return;

            let faqUrl = this.getFaqUrlString(faq);

            this.chatService.sendMessage(this.conversationId, faqUrl);
        }

        private addBulletPoints(answer: string): string {
            return answer.split('<li>').join('<li>- ');
        }

        private getFaqUrlString(faq: FaqModel): string {
            let faqUrl = this.portalUrl;
            faqUrl += this.portalUrl[this.portalUrl.length - 1] !== '/' ? '/' : '';
            faqUrl += 'faq/';
            faqUrl += faq.id;
            return faqUrl;
        }

        private async search(searchQuery: string): Promise<void> {
            if (!searchQuery || !searchQuery.length) {
                return;
            }

            this.loadingFaqs = true;
            const model = await this.faqResource.search({
                q: searchQuery,
                page: this.page,
                pageSize: this.pageSize,
                getFaqsWithoutItems: true
            }).$promise;
            this.loadingFaqs = false;

            if (!model || !model.items) return;

            this.updateFaqList(model);
        }

        private updateFaqList(model: PagedItemsModel<FaqModel>): void {
            if (!this.faqs) {
                this.faqs = model;
                return;
            }

            this.faqs = {
                ...this.faqs,
                page: model.page,
                items: [...this.faqs.items, ...model.items]
            };
        }

        private async generateSuggestion(message: ChatMessage): Promise<void> {
            if (!message) return null;

            this.lastMessageId = message.id;
            const suggestions = await this.getSuggestionsForMessage(message);

            if (!suggestions || suggestions.length < 1) return;

            for (let i = suggestions.length - 1; i >= 0; i--) {
                this.addToSuggestions(suggestions[i]);
            }
        }

        private async getSuggestionsForMessage(message: ChatMessage): Promise<FaqModel[]> {
            if (!message) return null;

            const suggestionsQuery = message.text;
            const pageSize = this.suggestions
                ? this.suggestions.length >= this.suggestionsLimit
                    ? 1
                    : this.suggestionsLimit - this.suggestions.length
                : this.suggestionsLimit;

            const suggestions = await this.faqResource.search({
                q: suggestionsQuery,
                page: this.page,
                pageSize: pageSize,
                getFaqsWithoutItems: true
            }).$promise;

            if (!suggestions || suggestions.items.length < 1) return <any>[];

            return <any>suggestions.items;
        }

        private addToSuggestions(suggestion: FaqModel): void {
            if (!suggestion) return;

            if (!this.suggestions) this.suggestions = [];

            const suggestionExists = !!this.suggestions.find(x => x.id === suggestion.id);

            if (suggestionExists) {
                this.suggestions = this.suggestions.filter(x => x.id !== suggestion.id);
                this.suggestions.unshift(suggestion);
                return;
            }

            if (this.suggestions.length >= this.suggestionsLimit)
                this.suggestions = this.suggestions.splice(0, this.suggestionsLimit - 1);

            this.suggestions.unshift(suggestion);
        }

        private async generateInitialSuggestions() {
            if (!this.messages || this.messages.length < 1) {
                this.initializedSuggestions = true;
                return;
            }
            const customerMessages = this.messages.filter(x => x.direction === MessageDirections.toOperator);
            const requestCount =
                customerMessages.length > this.suggestionsLimit ? this.suggestionsLimit : customerMessages.length;

            if (!customerMessages || customerMessages.length < 1) {
                this.initializedSuggestions = true;
                return;
            }

            for (let i = customerMessages.length - requestCount; i < customerMessages.length; i++) {
                const suggestions = await this.getSuggestionsForMessage(customerMessages[i]);

                if (!suggestions || suggestions.length < 1) continue;

                for (let i = suggestions.length - 1; i >= 0; i--) {
                    this.addToSuggestions(suggestions[i]);
                }
            }

            this.initializedSuggestions = true;
        }

        private reset(): void {
            this.page = 0;
            this.faqs = null;
        }

        public launchTaskRegistrationPopup(): void {
            let chatCustomerInformation: ChatCustomerInformation;
            let personId: Guid;
            const chatConversationId = this.conversation.id;
            const selectedTag = this.selectededThesaurasTag;
            const selectedFaqId = this.selectedFaqId;

            if (this.isChatAnonymous()) {
                chatCustomerInformation = this.returnChatCustomerInformation();
            } else {
                personId = this.conversation.customer.id;
            }

            if (!(selectedTag && selectedFaqId) || !(personId || chatCustomerInformation)) return;

            const template = `
                <register-task-popup
                    faq-id="${selectedFaqId}"
                    tag-id="${selectedTag}"
                    ${personId ? `person-id="${personId}"` : ``}
                    ${
                        chatCustomerInformation
                            ? `chat-customer-information='${JSON.stringify(chatCustomerInformation)}'`
                            : ``
                    }
                    channel-type="${this.activityRegistrationService.getSelectedChannelType()}">
                </register-task-popup>`;
            this.$mdDialog
                .show({
                    template,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(task => {
                    this.activityRegistrationService.taskRegistered(task, chatConversationId);
                });
        }

        public registerContactActivity(): void {
            const person = this.personInfoService.getSelectedPerson();
            const selectedTag = this.selectededThesaurasTag;
            const selectedFaq = this.selectedFaq;

            if(!(selectedTag && selectedFaq.id) || !person) return;
            const template = `
                <register-contact-activity-popup
                    person-id="${person.id}"
                    selected-channel-type="${this.activityRegistrationService.getSelectedChannelType() || 0}"
                    selected-faq-id="${selectedFaq.id}"
                    selected-tag-id="${selectedTag}"
                    selected-target-group-id="${this.activityRegistrationService.getSelectedTargetgroupId() || ''}"
                    selected-follow-up-question-id="${this.activityRegistrationService.getSelectedFollowUpQuestionId() ||
                        ''}">
                </register-contact-activity-popup>`;
            this.$mdDialog
                .show({
                    template,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then((activity: ContactActivityModel) => {
                    this.activityRegistrationService.contactActivityRegistered(activity);
                });
        }

        private isChatAnonymous(): boolean {
            return this.conversation.customer.id ? false : true;
        }

        private returnChatCustomerInformation(): ChatCustomerInformation {
            return {
                firstName: this.conversation.customer.firstName,
                lastName: this.conversation.customer.lastName,
                phoneNumber: this.conversation.customer.phoneNumber,
                email: this.conversation.customer.email
            };
        }
    }
}
