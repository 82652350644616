namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStep = Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;

    @Pipe('CaseFlow', 'isActiveStep')
    class IsActiveStepFilter {
        public transform(input: CaseFlowStep): boolean {
            if (!input) return false;

            return this.isStepActive(input);
        }

        private isStepActive(step: CaseFlowStep): boolean {
            return (
                step.status.toString() === "1" ||
                step.status.toString() === "Started" ||
                step.status.toString() === "2" ||
                step.status.toString() === "PickedUp"
            );
        }
    }
}
