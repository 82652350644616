namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStepStatus = Umbrella.CaseFlow.CaseFlowStepStatus;
    @Pipe('CaseFlow', 'stepStatusName')
    class StepStatusNameFilter {
        public transform(input: CaseFlowStepStatus | string): string {
            if (!input) return undefined;

            switch (input.toString()) {
                case "PickedUp":
                case "2":
                    return 'Opgepakt';
                case "Finished":
                case "3":
                    return 'Afgerond';
                case "Canceled":
                case "4":
                    return 'Geannuleerd';
                default:
                    return '';
            }
        }
    }
}
