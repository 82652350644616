namespace Umbrella.Modules.Repairs.Card {
    import AnswerModel = SelfService.Models.AnswerModel;
    import VendorModel = Umbrella.Modules.Vendor.VendorModel;
    import VendorResourceClass = Umbrella.CustomerService.VendorResourceClass;
    import IUnitResource = Umbrella.Modules.Housing.Units.IUnitResource;
    import ILocationTypeCodeResourceClass = Umbrella.Modules.Repairs.ILocationTypeCodeResourceClass;
    import CollectiveObjectModel = Umbrella.Modules.Housing.CollectiveObjectModel;

    export interface IAddDefectPopupScope extends IPopupScope {
        unit: Housing.UnitModel;
    }

    export interface IAddDefectPopupController {
        unit: Housing.UnitModel;
        answerPath: AnswerModel[];
        answer: AnswerModel;
        defect: DefectModel;
        actionSpecification: ActionSpecificationModel;
        remark: string;

        showDefects(): boolean;
        showAnswers(): boolean;
        showSpecification(): boolean;

        answers: AnswerModel[];
        defects: DefectModel[];

        selectAnswer(answer: AnswerModel): void;
        selectDefect(defect: DefectModel): void;
        selectCollectiveObject(collectiveObject: CollectiveObjectModel): void;

        cancel(): void;
        saveAndClose(): void;
        canSaveAndClose(): boolean;
    }

    @Controller('Housing', 'AddDefectPopupController')
    @Inject(
        '$scope',
        'AnswerResource',
        'DefectResource',
        'RepairRequestResource',
        'VendorResource',
        'UnitResource',
        'LocationTypeCodeResource'
    )
    export class AddDefectPopupController implements IAddDefectPopupController {
        public answers: AnswerModel[];
        public defects: DefectModel[];
        public unit: Housing.UnitModel;
        public answerPath: AnswerModel[] = [];
        public answer: AnswerModel = null;
        public defect: DefectModel = null;
        public actionSpecification: ActionSpecificationModel = null;
        public remark = '';
        public vendors: VendorModel[];
        public vendor: VendorModel;
        public collectiveObject: CollectiveObjectModel;
        public collectiveObjects: CollectiveObjectModel[];

        constructor(
            private $scope: IAddDefectPopupScope,
            private answerResource: IAnswerResourceClass,
            private defectResource: IDefectResourceClass,
            private repairRequestResource: IRepairRequestResourceClass,
            private vendorResource: VendorResourceClass,
            private unitResource: IUnitResource,
            private locationTypeCodeResource: ILocationTypeCodeResourceClass
        ) {
            this.unit = $scope.unit;

            this.loadOptions(this.unit, null);
        }

        public showAnswers(): boolean {
            return !this.answer || this.answer.index < 3;
        }

        public showDefects(): boolean {
            return this.answer && this.answer.index === 3;
        }

        public showCollectiveObjects(): boolean {
            return this.defect ? true : false;
        }

        public showSpecification(): boolean {
            return this.actionSpecification !== null;
        }

        public cancel(): void {
            this.$scope.$modalCancel();
        }

        public saveAndClose(): void {
            this.$scope.$modalSuccess(
                this.answerPath,
                this.defect,
                this.actionSpecification,
                this.remark,
                this.vendor ? this.vendor.id : null,
                this.collectiveObject ? this.collectiveObject.id : null
            );
        }

        public canSaveAndClose(): boolean {
            return (
                this.answer &&
                this.defect &&
                this.actionSpecification &&
                this.actionSpecification.canFinish &&
                (!this.actionSpecification.remarkIsRequired ||
                    this.remark !== '')
            );
        }

        public selectAnswer(a: AnswerModel) {
            this.setAnswer(a);
            this.defects = [];
            this.answers = [];
            this.loadOptions(this.unit, this.answer);
        }

        public selectDefect(d: DefectModel) {
            this.setDefect(d);

            const answer = this.answerPath[1];
            if (!answer.locationTypeId) {
                this.loadActionSpecification(this.unit, this.answer, this.defect);
            }
            else {
                this.locationTypeCodeResource.getById({ id: answer.locationTypeId })
                    .$promise
                    .then((locationType: LocationTypeCodeModel) => {
                        const type = locationType.key.split('#')[0];
                        this.loadCollectiveObjects(type);
                    });
            }
        }

        public selectCollectiveObject(collectiveObject: CollectiveObjectModel) {
            this.collectiveObject = collectiveObject;
            this.loadActionSpecification(this.unit, this.answer, this.defect);
        }

        public selectVendor(v: VendorModel) {
            this.vendor = v;
        }

        private loadCollectiveObjects(type: string) {
            this.unitResource.getCollectiveObjectsByCode({ id: this.unit.id, code: type })
                .$promise
                .then((collectiveObjects: CollectiveObjectModel[]) => {
                    if (collectiveObjects.length === 0) {
                        this.loadActionSpecification(this.unit, this.answer, this.defect);
                    } else if (collectiveObjects.length === 1) {
                        this.selectCollectiveObject(collectiveObjects[0]);
                    } else {
                        this.collectiveObjects = collectiveObjects;
                    }
                });
        }

        private loadOptions(unit: Housing.UnitModel, answer: AnswerModel) {
            if (answer && answer.index === 3) {
                // Fetch defects for this answer
                this.defectResource
                    .getByAnswerId({ id: answer.id })
                    .$promise.then(defects => (this.defects = defects));
            } else {
                this.answerResource
                    .getAnswers({
                        parentId: answer ? answer.id : null,
                        housingTypeId: unit.housingTypeId,
                        unitId: unit.id
                    })
                    .$promise.then(answers => (this.answers = answers));
            }
        }

        private loadActionSpecification(
            unit: Housing.UnitModel,
            answer: AnswerModel,
            defect: DefectModel
        ): void {
            this.repairRequestResource
                .getActionSpecification({
                    unitId: this.unit.id,
                    answerId: answer.id,
                    defectId: defect.id
                })
                .$promise.then(actionSpectification =>
                    this.setActionSpecification(actionSpectification)
                );
        }

        private setAnswer(answer: AnswerModel): void {
            const createPath = (answer: AnswerModel) => {
                const ascendAnswer = (decision, func) => {
                    const iter = d => {
                        if (d) {
                            func(d);
                            iter(d.parent);
                        }
                    };

                    iter(decision);
                };

                const path = [];
                ascendAnswer(answer, d => path.push(d));
                return path.reverse();
            };

            this.answerPath = createPath(answer);
            this.answer = answer;
            this.collectiveObject = null;
            this.defect = null;
            this.actionSpecification = null;
        }

        private setDefect(defect: DefectModel): void {
            this.defect = defect;
            this.collectiveObject = null;
            this.actionSpecification = null;
        }

        private setActionSpecification(
            actionSpecification: ActionSpecificationModel
        ): void {
            this.actionSpecification = actionSpecification;

            this.vendor = null;
            if (
                actionSpecification.vendorOptionIds &&
                actionSpecification.vendorOptionIds.length > 0
            )
                this.vendorResource
                    .getByIds({ ids: actionSpecification.vendorOptionIds })
                    .$promise.then(vendors => (this.vendors = vendors));
        }
    }
}
