/// <reference path="../../../../Decorators.ts" />
/// <reference path="../../../../Scripts/TypeScript/chartjs/chartjs.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard.CustomerSatisfaction {
    import CustomerSatifactionScoreGetModel = Umbrella.CustomerService.CustomerSatifactionScoreGetModel;
    import CustomerSatisfactionCategoryGetModel = Umbrella.CustomerService.CustomerSatisfactionCategoryGetModel;
    import PagedRequestWithIdAndCategoryParams = Umbrella.CustomerService.PagedRequestWithIdAndCategoryParams;

    export interface ChartData {
        title: string;
        xAxis: string[];
        yAxis: number[];
    }

    @Inject('CustomerSatisfactionResource', '$filter', '$scope')
    @Component('CustomerService', {
        selector: 'customer-satisfaction-section',
        templateUrl:
            '/CustomerService/_CustomerCard/_CustomerSatisfaction/CustomerSatisfactionSectionComponent/CustomerSatisfactionSectionComponent.html',
        bindings: {
            personId: '<',
            category: '<'
        }
    })
    class CustomerSatisfactionSectionComponent {
        public category: CustomerSatisfactionCategoryGetModel;
        public scores: PagedItemsModel<CustomerSatifactionScoreGetModel>;
        public chartConfiguration: Chart.ChartConfiguration;
        public isLoading = false;
        private personId: Guid;

        constructor(
            private customerSatisfactionResource: ICustomerSatisfactionResource,
            private $filter: ng.IFilterService,
            private $scope: ng.IScope
        ) {}

        $onInit() {
            this.load();
        }

        public loadMore() {
            this.load(++this.scores.page);
        }

        private load(page = 0, pageSize = 4) {
            if (!this.personId) return;

            if (page === 0) this.isLoading = true;

            const request: PagedRequestWithIdAndCategoryParams = { id: this.personId, page, pageSize, sortDesc: false };
            if (this.category) request.category = this.category.category;

            this.customerSatisfactionResource
                .getByPersonId(request)
                .$promise.then(scores => {
                    if (scores.items.length < 1) return;

                    const items =
                        this.scores && this.scores.items
                            ? (this.scores.items = [...this.scores.items, ...scores.items])
                            : scores.items;
                    this.scores = {
                        ...scores,
                        items
                    };
                    const chartData = this.generateChartData(scores.items);
                    this.chartConfiguration = this.generateChartConfiguration(chartData);

                    setTimeout(() => {
                        this.$scope.$emit('ScrollToBottomCommand');
                    }, 50);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        private generateChartData(scores: CustomerSatifactionScoreGetModel[]): ChartData {
            const chartData = {
                xAxis: [],
                yAxis: [],
                title: this.category ? this.category.category : ''
            };

            if (!scores) return chartData;

            const dateFilter = this.$filter('date');

            for (const score of this.scores.items) {
                const date = dateFilter(score.createdOn, 'd-yyyy');
                chartData.xAxis.push(date);
                chartData.yAxis.push(score.score);
            }

            return chartData;
        }

        private generateChartConfiguration(chartData: ChartData): Chart.ChartConfiguration {
            return {
                type: 'line',
                data: {
                    labels: chartData.xAxis,
                    datasets: [
                        {
                            label: chartData.title,
                            data: chartData.yAxis,
                            fill: false,
                            borderColor: ['rgb(76, 175, 80)']
                        }
                    ]
                },
                options: {
                    animation: {
                        duration: 200
                    },
                    legend: {
                        display: false
                    },
                    responsive: true,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: false,
                                    padding: 15,
                                    min: 0,
                                    max: 10
                                },
                                gridLines: {
                                    display: false
                                }
                            }
                        ],
                        xAxes: [
                            {
                                ticks: {
                                    padding: 15
                                },
                                gridLines: {
                                    display: false
                                }
                            }
                        ]
                    },
                    elements: {
                        line: {
                            tension: 0
                        },
                        point: {
                            radius: 5,
                            hoverRadius: 5,
                            backgroundColor: 'rgb(255, 255, 255)',
                            borderWidth: 3,
                            hoverBorderWidth: 4
                        }
                    }
                }
            };
        }
    }
}
