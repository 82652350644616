namespace Umbrella {
    export interface HighlightQuery {
        readonly match: string;
        readonly hide: boolean;
    }

    @Pipe('Umbrella', 'highlight')
    class HighlightFilter {
        public transform(text: any, query: string | HighlightQuery) {
            if (typeof text !== 'string') return text;

            let q = this.toQuery(query);
            const result = this.highlight(text, q.match);

            if (q.hide && result === text) {
                return '';
            }

            return result;
        }

        private toQuery(query: string | HighlightQuery): HighlightQuery {
            if (typeof query === 'string') return { match: query, hide: false };
            else return query;
        }

        private highlight(text: string, match: string): string {
            return match
                .trim()
                .toLowerCase()
                .split(' ')
                .map(w => this.removeSpecialChars(w))
                .reduce((text, word) => this.highlightWord(text, word), text);
        }

        private highlightWord(text: string, word: string): string {
            let re =
                this.regExCache[word] ||
                (this.regExCache[word] = new RegExp(
                    word + '(?!([^<]+)?>)',
                    'mgi'
                ));
            return text.replace(re, '<span class="ui-match">$&</span>');
        }

        private removeSpecialChars(word: string) {
            return word.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        }

        private readonly regExCache = {};
    }
}
