/// <reference path="../../../Decorators.ts" />
/// <reference path="../../../Window.d.ts" />
/// <reference path="../../../CustomerService/ActivityTimeline/ActivityChannelIconFilter.ts" />
/// <reference path="../../../CustomerService/ActivityTimeline/ActivityIconFilter.ts" />

namespace Umbrella.CustomerService {
    import ActivityModel = Modules.ActivityModel;
    import IActivityResourceClass = Modules.IActivityResourceClass;
    import RoleModel = Umbrella.Modules.RoleModel;
    import ActivityCaseModel = Umbrella.Modules.Activities.ActivityCaseModel;
    import TaskTypeCategory = Umbrella.Modules.Tasks.TaskTypeCategory;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;
    import FaqResourceClass = Umbrella.Modules.Knowledgebase.Faq.FaqResourceClass;
    import ContactActivityResource = Umbrella.Modules.Contacts.IContactActivityResource;
    import CustomerCardActivityInfoService = Umbrella.CustomerService.CustomerCard.Activities.CustomerCardActivityInfoService;
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;
    import StatusFilter = Umbrella.Modules.StatusFilter;

    interface FaqExpirationInfo {
        expiresOn: Date;
        isExpired: boolean;
    }

    @Component('CustomerService', {
        selector: 'activity-timeline-item',
        templateUrl: '/CustomerService/ActivityTimeline/ActivityTimelineItemComponent/ActivityTimelineItem.html',
        bindings: {
            case: '<',
            activity: '<',
            scenarioName: '@',
            nrOfFollowUpActivities: '@',
            preventFollowUpActivityLoading: '<',
            disableRegistrationOfFollowUpActivities: '<',
            lastModifiedOn: '<',
            lastModifiedBy: '<',
            disableActivityButton: '<'
        }
    })
    @Inject(
        '$mdDialog',
        '$timeout',
        'CreateDialog',
        'ActivityResource',
        '$stateParams',
        '$state',
        'ActivityRegistrationService',
        'FaqResource',
        'ContactActivityResource',
        'CustomerCardActivityInfoService'
    )
    class ActivityTimelineItemComponent {
        public case: ActivityCaseModel;
        public task: TaskModel;
        public activity: ActivityModel;
        public media = {};
        public mediaIds: Guid[] = [];
        public scenarioName: string;
        public nrOfFollowUpActivities: string;
        public preventFollowUpActivityLoading: boolean;
        public disableRegistrationOfFollowUpActivities: boolean;
        public lastModifiedOn: Date;
        public lastModifiedBy: RoleModel;
        public activities: PagedItemsModel<ActivityModel>;
        public loading: boolean;
        public showDetails: boolean;
        public loadedPersonId: System.Guid;
        public templatesFinishedLoading = 0;
        public templatesDoneLoading = false;
        public timelineConfig: TimelineConfig;
        public faqExpirationDatesRegistrar: {
            [faqId: string]: FaqExpirationInfo;
        } = {};
        public applicationLogUrl: string;
        private statusFilter?: StatusFilter;
        private statusFilterSubscription: Rx.Disposable;

        constructor(
            private $mdDialog,
            private $timeout: ng.ITimeoutService,
            private createDialog,
            private activityResource: IActivityResourceClass,
            private $stateParams: CustomerCard.CardStateParams,
            private $state: ng.ui.IStateService,
            private activityRegistrationService: ActivityRegistrationService,
            private faqResource: FaqResourceClass,
            private contactActivityResource: ContactActivityResource,
            private customerCardActivityInfoService: CustomerCardActivityInfoService
        ) {}

        public $onInit() {
            this.loadedPersonId = this.$stateParams.personId;
            if (this.activity.case && this.activity.case.faqId) this.getFaqExpirationDate(this.activity.case.faqId);

            this.timelineConfig = window.config && window.config.modules && window.config.modules.timeline;

            this.observeStatusFilter();
        }

        $onDestroy(): void {
            if (this.statusFilterSubscription) {
                this.statusFilterSubscription.dispose();
            }

            this.statusFilterSubscription = null;
        }

        public templateFinishedLoading() {
            this.templatesFinishedLoading += 1;
            if (this.templatesFinishedLoading === this.activities.items.length) {
                this.templatesDoneLoading = true;
            }
        }

        public toggle(): void {
            if (this.isAllowedToSeeActivityDetail()) {
                this.showDetails = !this.showDetails;
                if (this.showDetails && !this.activities) {
                    if (this.preventFollowUpActivityLoading) {
                        this.activities = {
                            items: [this.activity],
                            total: 1,
                            page: 0,
                            pageSize: 1
                        };
                    } else this.loadFollowUpActivities(this.activity.case);
                }
            }
        }

        public hasFollowUpActivities(): boolean {
            return !!parseInt(this.nrOfFollowUpActivities);
        }

        public isAllowedToRegisterTask(): boolean {
            return window.user.permissions.createTask;
        }

        public isAllowedToSeeActivityDetail(): boolean {
            return window.user.permissions.activityRead;
        }

        public isAllowedToRegisterContactActivity(): boolean {
            return window.user.permissions.caseCreate;
        }

        public removeActivity(activity: ActivityModel): void {
            if (!activity) return;

            this.contactActivityResource
                .delete({ id: activity.id })
                .$promise.then(() => this.customerCardActivityInfoService.removeCase(activity.case.id));
        }

        public hasPermissionToRemoveActivity = (): boolean => !!window.user.permissions.deleteContactActivity;

        public isAuthorizedToDeleteCasesAndActivities = (): boolean => window.user && window.user.permissions && window.user.permissions.caseDeleteAndRestore;

        public isContactActivityWithinRemovalPeriod(activity: ActivityModel): boolean {
            const now = new Date();
            const diffMs = now.getTime() - new Date(activity.startTime).getTime();
            const diffMins = Math.round(diffMs / 60000);

            return this.timelineConfig.activityModificationTimeLimitInMinutes > diffMins;
        }

        public launchTaskRegistrationPopup(): void {
            const contractId = this.case.contract && this.case.contract.id;
            const template = `<register-task-popup case-id="${this.case.id}" contract-id="${contractId}" person-id="${
                this.loadedPersonId
            }" channel-type="${this.activityRegistrationService.getSelectedChannelType()}"></register-task-popup>`;
            this.$mdDialog
                .show({
                    template,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(() => this.loadFollowUpActivities(this.activity.case));
        }

        public launchContactActivityPopup(): void {
            const contractId = this.case.contract && this.case.contract.id;
            this.$mdDialog
                .show({
                    template: `<register-contact-activity-popup case-id="${
                        this.case.id
                    }" contract-id="${contractId}" person-id="${
                        this.loadedPersonId
                    }" selected-channel-type="${this.activityRegistrationService.getSelectedChannelType()}"></register-contact-activity-popup>`,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(activity => {
                    this.activityRegistrationService.contactActivityRegistered(activity);
                    this.$timeout(() => {
                        this.loadFollowUpActivities(this.activity.case);
                    }, 500);
                });
        }

        public launchTransferPopup() {
            const tagId = this.case.thesaurusTagId;
            const contractId = this.case.contract && this.case.contract.id;

            this.createDialog(
                '/CustomerService/_CustomerCard/_Activities/Registration/TransferPopup/TransferPopup.html',
                {
                    id: 'transferPopup',
                    modalClass: 'popup green popup_1350'
                },
                {
                    tagId,
                    caseId: this.case.id,
                    contractId
                }
            );
        }

        public isOnColleagueCard(): boolean {
            return this.$state.includes('customerservice.colleaguecard');
        }

        public hasDifferentChannelPredecesor(index: number) {
            if (!this.activities || !this.activities.items || index < 1) return false;

            for (let i = index; i > 0; i--) {
                if (this.activities.items[index].channel !== this.activities.items[i].channel) {
                    return true;
                }
            }

            return false;
        }

        public async getFaqExpirationDate(faqId: Guid) {
            if (this.faqExpirationDatesRegistrar[String(faqId)]) return;

            const faq = await this.faqResource.getById({ id: faqId }).$promise;
            const today = new Date();
            const isExpired = faq && faq.expiresOn && today.getTime() > new Date(faq.expiresOn).midnight().getTime();
            const expirationInfo: FaqExpirationInfo = {
                expiresOn: faq && faq.expiresOn,
                isExpired
            };

            this.faqExpirationDatesRegistrar[String(faqId)] = expirationInfo;
        }

        private observeStatusFilter() {
            this.statusFilterSubscription = this.customerCardActivityInfoService
                .getStatusFilter()
                .distinctUntilChanged()
                .subscribe(x => {
                    if (!this.hasPermissionToRemoveActivity) {
                        this.statusFilter = undefined;

                        return;
                    }

                    this.statusFilter = x;

                    this.loadFollowUpActivities(this.activity.case);
                });
        }

        private loadFollowUpActivities(cs: ActivityCaseModel): void {
            const caseId = (cs && cs.id) || (this.case && this.case.id) || this.$stateParams.caseId;
            if (this.loading || !caseId) return;
            this.loading = true;

            this.activityResource
                .getByCaseId({
                    id: caseId,
                    page: 0,
                    pageSize: 150,
                    statusFilter: this.statusFilter
                })
                .$promise.then((model: PagedItemsModel<ActivityModel>) => {
                    setTimeout(() => {
                        angular.forEach(model.items, (item: ActivityModel) => {
                            item.case = cs;
                        });
                        const isCaseFlowTaskActivity = x =>
                            x &&
                            x.task &&
                            x.task.taskType &&
                            (x.task.taskType.category === TaskTypeCategory.CaseFlow ||
                                x.task.taskType.category === 'CaseFlow');
                        const isTaskRelatedActivity = (x: ActivityModel) =>
                            x &&
                            (x.type.toLowerCase().indexOf('taak') === 0 ||
                                x.type.toLowerCase().indexOf('task') === 0) &&
                            !(
                                x.type.toLowerCase().indexOf('taakaangemaakt') === 0 ||
                                x.type.toLowerCase().indexOf('taskanonymouslinked') === 0
                            );
                        const isRestoredDeletedActivity = (x: ActivityModel) =>
                            x &&
                            (x.type === 'ActivityDeletedActivity' ||
                                x.type === 'ActivityRestoredActivity' ||
                                x.type === 'CaseDeletedActivity' ||
                                x.type === 'CaseRestoredActivity');

                        const filteredItems = model.items
                            .filter(x => !isCaseFlowTaskActivity(x))
                            .filter(x => !isTaskRelatedActivity(x))
                            .filter(x => !isRestoredDeletedActivity(x));
                        filteredItems.forEach(x => this.groupTaskActivities(x, model.items));
                        this.activities = {
                            items: filteredItems,
                            total: model.total,
                            page: model.page,
                            pageSize: model.pageSize
                        };
                    }, 25);
                })
                .finally(() => (this.loading = false));
        }

        private groupTaskActivities(activity: any, items: ActivityModel[]): void {
            const isRootActivity = x =>
                (x && x.task && x.type.toLowerCase().indexOf('taakaangemaakt') === 0) ||
                (x && x.task && x.type.toLowerCase().indexOf('taskanonymouslinked') === 0);

            if (!isRootActivity(activity)) return;

            const isLinkedActivity = (x, y) => x.task && x.task.id === y.task.id;
            const linkedActivities = items.filter(x => isLinkedActivity(x, activity));
            activity.linkedActivities = linkedActivities;
        }

        public getDeleteOrRestoreActivityIconTitle = (id: Guid): string =>
            !this.isDeletedActivity(id)
                ? 'Deze gebeurtenis verwijderen van de klantkaart'
                : 'Deze gebeurtenis weer tonen op klantkaart';

        public getDeleteOrRestoreActivityIcon = (id: Guid): string =>
            !this.isDeletedActivity(id) ? 'delete' : 'restore_from_trash';

        public getDeleteOrRestoreCaseIconTitle = (cs: ActivityCaseModel): string =>
            !this.isDeletedCase(cs) ? 'Deze zaak verwijderen van de klantkaart' : 'Deze zaak weer tonen op klantkaart';

        public getDeleteOrRestoreCaseIcon = (cs: ActivityCaseModel): string =>
            !this.isDeletedCase(cs) ? 'delete' : 'restore_from_trash';

        private isDeletedActivity = (id: Guid): boolean => this.activities.items.find(x => x.id == id).isDeleted;

        private isDeletedCase = (cs: ActivityCaseModel): boolean => cs && cs.isDeleted;

        private popupMdDialogSettings(template: string): void {
            this.$mdDialog
                .show({
                    template,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(() => {
                    this.loadFollowUpActivities(this.activity.case);
                });
        }

        public restoreOrDeleteActivity = (id: Guid): void =>
            this.isDeletedActivity(id)
                ? this.popupMdDialogSettings(`<${'restore-activity-timeline-item'}
                    is-Case='${JSON.stringify(false)}'
                    id='${JSON.stringify(id)}'
                    ></${'restore-activity-timeline-item'}>`)
                : this.popupMdDialogSettings(`<${'delete-activity-timeline-item'}
                    is-Case='${JSON.stringify(false)}'
                    id='${JSON.stringify(id)}'
                    ></${'delete-activity-timeline-item'}>`);

        public restoreOrDeleteCaseAction = (id: Guid): void =>
            this.case.isDeleted
                ? this.popupMdDialogSettings(`<${'restore-activity-timeline-item'}
                    is-Case='${JSON.stringify(true)}'
                    id='${JSON.stringify(id)}'
                    ></${'restore-activity-timeline-item'}>`)
                : this.popupMdDialogSettings(`<${'delete-activity-timeline-item'}
                    is-Case='${JSON.stringify(true)}'
                    id='${JSON.stringify(id)}'
                    ></${'delete-activity-timeline-item'}>`);
    }
}
