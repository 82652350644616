/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard {

    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;
    
    export interface CustomerAnnouncementsComponentState {
        loading: boolean;
        person: PersonModel;
        customer: Modules.Customers.CustomerModel.Detailed;
        announcementInfo: Announcements.State;
        registrationInfo: RegistrationModel[];
    }

    @Inject('CustomerCardAnnouncementInfoService')
    @Component('CustomerService', {
        selector: 'customer-announcements',
        templateUrl:
            '/CustomerService/_CustomerCard/_Announcements/CustomerAnnouncementsComponent/CustomerAnnouncements.html',
        bindings: { state: '<' }
    })
    class CustomerAnnouncementsComponent {
        public state: CustomerAnnouncementsComponentState;

        constructor(
            private announcementInfoService: CustomerCardAnnouncementInfoService
        ) {}

        public loadMore() {
            this.announcementInfoService.loadMore();
        }
    }
}
