/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
namespace Umbrella {
    @Pipe('Umbrella', 'replaceYoutubeEmbed')
    class ReplaceYoutubeEmbedFilter {
        public transform(message: string) {
            if (!message) return '';
            if (!message.includes('<iframe')) { return message; }

            const transformToHyperlink = (link) => {
                const clickable = document.createElement('a');
                clickable.setAttribute('href', link);
                clickable.setAttribute('target', '_blank');
                clickable.setAttribute('rel', 'noopener noreferrer');
                clickable.text = link;
                return clickable;
            };

            const domparser = new DOMParser();
            const htmlMessage = domparser.parseFromString(message, 'text/html');

            const aTags = htmlMessage.getElementsByTagName('a');
            const existing: string[] = [];

            for (let i = aTags.length; i > 0; i--) {
                if (aTags[i - 1].href) { existing.push(aTags[i - 1].href); }
            }

            for (let i = htmlMessage.getElementsByTagName('iframe').length; i > 0; i--) {
                if (existing.length === 0) {
                    htmlMessage.getElementsByTagName('iframe')[i - 1].replaceWith(
                        transformToHyperlink(htmlMessage.getElementsByTagName('iframe')[i - 1].src)
                    );
                }

                if (existing.length > 0 && existing.indexOf(htmlMessage.getElementsByTagName('iframe')[i - 1].src) !== -1) {
                    htmlMessage.getElementsByTagName('iframe')[i - 1].remove();
                }
            }

            return htmlMessage.getElementsByTagName('body')[0].innerHTML.toString();
        }
    }
}