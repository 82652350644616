/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scripts/TypeScript/rxjs/rx.lite.es6.d.ts"/>
/// <reference path="../SelectedColleagueOrFunctiongroup.d.ts"/>
/// <reference path="../RootStore.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowOverviewModel = Umbrella.CaseFlow.CaseFlowOverviewModel;
    import DetailedCaseFlowModel = Umbrella.CaseFlow.DetailedCaseFlowModel;
    import CaseFlowMessageModel = Umbrella.CaseFlow.CaseFlowMessageModel;
    import SelectedColleagueOrFunctiongroup = Umbrella.Modules.SelectedColleagueOrFunctiongroup;
    import AssignmentModel = Umbrella.CaseFlow.AssignmentModel;

    export enum View {
        List = 0,
        Workload = 1
    }

    export namespace Overview {
        export interface UpdateCaseFlowStepPickedUpEvent {
            readonly type: 'UpdateCaseFlowStepPickedUpEvent';
            readonly id: Guid;
        }

        export interface CasesSearchedEvent {
            readonly type: 'CasesSearchedEvent';
            readonly cases: SearchResultsModel<CaseFlowOverviewModel>;
        }

        export interface CasesLoadingEvent {
            readonly type: 'CasesLoadingEvent';
            readonly page: number;
            readonly pageSize: number;
        }

        export interface CasesLoadedEvent {
            readonly type: 'CasesLoadedEvent';
            readonly cases: SearchResultsModel<CaseFlowOverviewModel>;
            readonly filters: CaseOverviewFilters;
        }

        export interface CaseFlowOverviewListItemModifiedEvent {
            readonly type: 'CaseFlowOverviewListItemModifiedEvent';
            readonly modifiedCaseflow: CaseFlowOverviewModel;
        }

        export interface CaseFlowOverviewListItemRemovedEvent {
            readonly type: 'CaseFlowOverviewListItemRemovedEvent';
            readonly id: Guid;
        }

        export interface CaseFlowOverviewFiltersUpdatedEvent {
            readonly type: 'CaseFlowOverviewFiltersUpdatedEvent';
            readonly filters: CaseOverviewFilters;
        }

        export interface CaseFlowAssignmentLoadedEvent {
            readonly type: 'CaseFlowAssignmentLoadedEvent';
            readonly assignments: AssignmentModel[];
        }
        export interface CaseOverviewFilters {
            readonly query: string;
            readonly caseType: string;
            readonly statusses: string[];
            readonly completion: string;
            readonly owner: SelectedColleagueOrFunctiongroup;
            readonly executor: SelectedColleagueOrFunctiongroup;
        }

        export interface State {
            readonly loading: boolean;
            readonly updating: boolean;
            readonly error: any;
            readonly page: number;
            readonly pageSize: number;
            readonly cases: SearchResultsModel<CaseFlowOverviewModel>;
            readonly filters: CaseOverviewFilters;
        }

        export function set(s: CaseFlowState, replacement: State): CaseFlowState {
            return {
                caseInfo: s && s.caseInfo,
                overviewInfo: replacement
            };
        }
    }

    export namespace CaseFlow {
        export interface CaseFlowLoadingEvent {
            readonly type: 'CaseFlowLoadingEvent';
            readonly id: System.Guid;
        }

        export interface CaseFlowViewChangedEvent {
            readonly type: 'CaseFlowViewChangedEvent';
            readonly view: View;
            readonly pageSize: number;
        }

        export interface CaseFlowLoadingByTaskEvent {
            readonly type: 'CaseFlowLoadingByTaskEvent';
            readonly taskId: System.Guid;
        }

        export interface CaseFlowLoadedEvent {
            readonly type: 'CaseFlowLoadedEvent';
            readonly caseflow: DetailedCaseFlowModel;
            readonly owner: AssignmentModel;
        }

        export interface CaseFlowUnloadedEvent {
            readonly type: 'CaseFlowUnloadedEvent';
        }

        export interface CaseFlowHistoryLoadedV2Event {
            readonly type: 'CaseFlowHistoryLoadedV2Event';
            readonly caseHistory: Umbrella.Modules.Customers.v2.CaseModel;
        }

        export interface CaseFlowMediaLoadedEvent {
            readonly type: 'CaseFlowMediaLoadedEvent';
            readonly media: Umbrella.Modules.Customers.caseMedia[];
        }

        export interface SelfServiceReportLoadingEvent {
            readonly type: 'SelfServiceReportLoadingEvent';
            readonly id: System.Guid;
        }

        export interface SelfServiceReportLoadedEvent {
            readonly type: 'SelfServiceReportLoadedEvent';
            readonly selfServiceReport: Umbrella.Modules.SelfService.SelfServiceReportModel;
        }

        export interface TaskFinishedEvent {
            readonly type: 'TaskFinishedEvent';
            readonly taskId: System.Guid;
        }

        export interface CaseFlowMessagesLoadingEvent {
            readonly type: 'CaseFlowMessagesLoadingEvent';
        }

        export interface CaseFlowMessagesLoadedEvent {
            readonly type: 'CaseFlowMessagesLoadedEvent';
            readonly messages: CaseFlowMessageModel[];
        }

        export interface NewCaseFlowMessageEvent {
            readonly type: 'NewCaseFlowMessageEvent';
            readonly message: CaseFlowMessageModel;
        }

        export interface CaseFlowPublicMessagesLoadingEvent {
            readonly type: 'CaseFlowPublicMessagesLoadingEvent';
        }

        export interface CaseFlowPublicMessagesLoadedEvent {
            readonly type: 'CaseFlowPublicMessagesLoadedEvent';
            readonly publicMessages: CaseFlowMessageModel[];
        }

        export interface NewCaseFlowPublicMessageEvent {
            readonly type: 'NewCaseFlowPublicMessageEvent';
            readonly publicMessage: CaseFlowMessageModel;
        }

        export interface CaseFlowPersonLoadedEvent {
            readonly type: 'CaseFlowPersonLoadedEvent';
            readonly person: PersonModel;
        }

        export interface CaseFlowDocumentUploading {
            readonly type: 'CaseFlowDocumentUploadingEvent';
        }
        export interface CaseFlowDocumentUploaded {
            readonly type: 'CaseFlowDocumentUploadedEvent';
            readonly mediaUploadId: System.Guid;
        }
        export interface CaseFlowDocumentUploadError {
            readonly type: 'CaseFlowDocumentUploadErrorEvent';
        }

        export interface State {
            readonly id: System.Guid;
            readonly taskId?: System.Guid;
            readonly caseflow?: DetailedCaseFlowModel;
            readonly caseHistory?: Umbrella.Modules.Customers.v2.CaseModel;
            readonly status: CaseFlow.LoadingStatus;
            readonly selfServiceReport: Umbrella.Modules.SelfService.SelfServiceReportModel;
            readonly error: any;
            readonly owner?: AssignmentModel;
            readonly view: View;
            readonly pageSize: number;
            readonly messages: CaseFlowMessageModel[];
            readonly publicMessages: CaseFlowMessageModel[];
            readonly media?: Umbrella.Modules.Customers.caseMedia[];
            readonly person?: PersonModel;
            readonly isUploadingDocument: boolean;
            readonly mediaUploadId: System.Guid;
        }

        export enum LoadingStatus {
            Empty,
            Loading,
            Loaded
        }

        export function set(s: CaseFlowState, replacement: State): CaseFlowState {
            return {
                overviewInfo: s && s.overviewInfo,
                caseInfo: replacement
            };
        }
    }

    export type CaseFlowEvent =
        | CaseFlow.CaseFlowLoadingEvent
        | CaseFlow.CaseFlowLoadingByTaskEvent
        | CaseFlow.CaseFlowLoadedEvent
        | CaseFlow.CaseFlowUnloadedEvent
        | CaseFlow.CaseFlowHistoryLoadedV2Event
        | CaseFlow.CaseFlowMediaLoadedEvent
        | CaseFlow.SelfServiceReportLoadedEvent
        | CaseFlow.SelfServiceReportLoadingEvent
        | CaseFlow.CaseFlowViewChangedEvent
        | CaseFlow.TaskFinishedEvent
        | CaseFlow.CaseFlowMessagesLoadingEvent
        | CaseFlow.CaseFlowMessagesLoadedEvent
        | CaseFlow.NewCaseFlowMessageEvent
        | CaseFlow.CaseFlowPublicMessagesLoadingEvent
        | CaseFlow.CaseFlowPublicMessagesLoadedEvent
        | CaseFlow.NewCaseFlowPublicMessageEvent
        | Overview.CasesSearchedEvent
        | Overview.CasesLoadingEvent
        | Overview.CasesLoadedEvent
        | Overview.CaseFlowOverviewListItemModifiedEvent
        | Overview.CaseFlowOverviewListItemRemovedEvent
        | Overview.CaseFlowOverviewFiltersUpdatedEvent
        | Overview.CaseFlowAssignmentLoadedEvent
        | Overview.UpdateCaseFlowStepPickedUpEvent
        | CaseFlow.CaseFlowPersonLoadedEvent
        | CaseFlow.CaseFlowDocumentUploading
        | CaseFlow.CaseFlowDocumentUploaded
        | CaseFlow.CaseFlowDocumentUploadError;

    export interface CaseFlowState {
        caseInfo: CaseFlow.State;
        overviewInfo: Overview.State;
    }

    function reduce(e: CaseFlowEvent): (s: CaseFlowState) => CaseFlowState {
        switch (e.type) {
            case 'CaseFlowLoadingEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        id: e.id,
                        taskId: null,
                        caseflow: null,
                        caseHistory: null,
                        selfServiceReport: null,
                        status: CaseFlow.LoadingStatus.Loading,
                        owner: null
                    });

            case 'CaseFlowViewChangedEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        view: e.view,
                        pageSize: e.pageSize
                    });

            case 'CaseFlowLoadingByTaskEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        id: null,
                        taskId: e.taskId,
                        caseflow: null,
                        caseHistory: null,
                        selfServiceReport: null,
                        status: CaseFlow.LoadingStatus.Loading,
                        owner: null
                    });

            case 'CaseFlowLoadedEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        caseflow: e.caseflow,
                        status: CaseFlow.LoadingStatus.Loaded,
                        owner: e.owner
                    });

            case 'SelfServiceReportLoadingEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        selfServiceReport: null,
                        status: CaseFlow.LoadingStatus.Loading
                    });

            case 'SelfServiceReportLoadedEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        selfServiceReport: e.selfServiceReport,
                        status: CaseFlow.LoadingStatus.Loaded
                    });

            case 'TaskFinishedEvent':
                const finishTask = (taskId: System.Guid, caseflowState: CaseFlow.State) => {
                    if (!caseflowState || !caseflowState.caseflow) return caseflowState;

                    for (const step of caseflowState.caseflow.steps) {
                        for (const task of step.tasks) {
                            if (task.id === taskId) task.isFinished = true;
                        }
                    }
                    return caseflowState;
                };

                return s =>
                    CaseFlow.set(s, {
                        ...(s && finishTask(e.taskId, s.caseInfo))
                    });

            case 'CaseFlowUnloadedEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        id: null,
                        taskId: null,
                        caseflow: null,
                        caseHistory: null,
                        selfServiceReport: null,
                        status: CaseFlow.LoadingStatus.Empty,
                        owner: null
                    });

            case 'CaseFlowHistoryLoadedV2Event':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        caseHistory: e.caseHistory,
                        status: CaseFlow.LoadingStatus.Loaded
                    });

            case 'CaseFlowMediaLoadedEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        media: e.media
                    });

            case 'CaseFlowMessagesLoadingEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        messages: []
                    });

            case 'CaseFlowMessagesLoadedEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        messages: e.messages
                    });

            case 'NewCaseFlowMessageEvent':
                return s => {
                    const messages = s && s.caseInfo && s.caseInfo.messages;
                    if (messages) messages.push(e.message);

                    return CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        messages: [...messages]
                    });
                };

            case 'CaseFlowPublicMessagesLoadingEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        publicMessages: []
                    });

            case 'CaseFlowPublicMessagesLoadedEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        publicMessages: e.publicMessages
                    });

            case 'NewCaseFlowPublicMessageEvent':
                return s => {
                    const publicMessages = s && s.caseInfo && s.caseInfo.publicMessages;
                    if (publicMessages) publicMessages.push(e.publicMessage);

                    return CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        publicMessages
                    });
                };

            case 'CasesSearchedEvent':
                return s =>
                    Overview.set(s, {
                        ...(s && s.overviewInfo),
                        loading: false,
                        updating: false,
                        error: null,
                        cases: e.cases
                    });

            case 'CasesLoadingEvent':
                return s =>
                    Overview.set(s, {
                        ...(s && s.overviewInfo),
                        loading: true,
                        updating: false,
                        error: null,
                        page: e.page,
                        pageSize: e.pageSize
                    });

            case 'CasesLoadedEvent':
                return s =>
                    Overview.set(s, {
                        ...s.overviewInfo,
                        loading: false,
                        page: e.cases.result.page,
                        pageSize: e.cases.result.pageSize,
                        updating: false,
                        error: null,
                        cases: {
                            ...(s && s.overviewInfo && s.overviewInfo.cases),
                            result: {
                                ...(s && s.overviewInfo && s.overviewInfo.cases && s.overviewInfo.cases.result),
                                page: e.cases.result.page,
                                total: e.cases.result.total,
                                pageSize: e.cases.result.pageSize,
                                items:
                                    s.overviewInfo &&
                                    s.overviewInfo.cases &&
                                    s.overviewInfo.cases.result &&
                                    s.overviewInfo.cases.result.items
                                        ? [...s.overviewInfo.cases.result.items, ...e.cases.result.items]
                                        : [...e.cases.result.items]
                            }
                        },
                        filters: e.filters
                    });

            case 'CaseFlowOverviewListItemModifiedEvent':
                function modifyItemFromCaseFlowOverviewList(
                    list: PagedItemsModel<CaseFlowOverviewModel>,
                    modifiedCaseflow: CaseFlowOverviewModel
                ): PagedItemsModel<CaseFlowOverviewModel> {
                    if (!list) return createEmptyPagedCaseFlowModel();

                    const itemInList = list.items.find(x => x.id === modifiedCaseflow.id);

                    if (!itemInList) return list;

                    return {
                        ...list,
                        items: list.items.map(x => {
                            if (x.id === modifiedCaseflow.id) {
                                x = JSON.parse(JSON.stringify(modifiedCaseflow));
                            }
                            return x;
                        })
                    };
                }
                return s =>
                    Overview.set(s, {
                        ...(s && s.overviewInfo),
                        cases: {
                            ...(s && s.overviewInfo && s.overviewInfo.cases),
                            result: modifyItemFromCaseFlowOverviewList(s.overviewInfo.cases.result, e.modifiedCaseflow)
                        }
                    });

            case 'CaseFlowOverviewListItemRemovedEvent':
                function removeItemFromCaseFlowOverviewList(
                    list: PagedItemsModel<CaseFlowOverviewModel>,
                    id: Guid
                ): PagedItemsModel<CaseFlowOverviewModel> {
                    if (!list) return createEmptyPagedCaseFlowModel();

                    return {
                        ...list,
                        items: [...list.items.filter(x => x.id !== id)],
                        total: list.total - 1
                    };
                }
                return s =>
                    Overview.set(s, {
                        ...(s && s.overviewInfo),
                        cases: {
                            ...(s && s.overviewInfo && s.overviewInfo.cases),
                            result: removeItemFromCaseFlowOverviewList(s.overviewInfo.cases.result, e.id)
                        }
                    });

            case 'CaseFlowOverviewFiltersUpdatedEvent':
                return s =>
                    Overview.set(s, {
                        ...(s && s.overviewInfo),
                        cases: {
                            ...(s && s.overviewInfo && s.overviewInfo.cases),
                            result: {
                                page: 0,
                                total:
                                    s && s.overviewInfo && s.overviewInfo.cases && s.overviewInfo.cases.result
                                        ? s.overviewInfo.cases.result.total
                                        : 0,
                                pageSize:
                                    s && s.overviewInfo && s.overviewInfo.cases && s.overviewInfo.cases.result
                                        ? s.overviewInfo.cases.result.pageSize
                                        : 9,
                                items: []
                            }
                        },
                        loading: true,
                        filters: e.filters
                    });

            case 'CaseFlowAssignmentLoadedEvent':
                function fillAssignmentName(
                    cases: CaseFlowOverviewModel[],
                    assignemnts: AssignmentModel[]
                ): CaseFlowOverviewModel[] {
                    if (!assignemnts) return cases;

                    return [
                        ...cases.map(c => {
                            if (c.assignment && c.assignment.id) {
                                const assignemnt = assignemnts.find(a => a.id === c.assignment.id);
                                if (assignemnt) {
                                    return {
                                        ...c,
                                        assignment: {
                                            ...c.assignment,
                                            name: assignemnt.name
                                        }
                                    };
                                }
                            }
                            return c;
                        })
                    ];
                }

                return s =>
                    Overview.set(s, {
                        ...(s && s.overviewInfo),
                        cases: {
                            ...(s && s.overviewInfo && s.overviewInfo.cases),
                            result: {
                                ...(s && s.overviewInfo && s.overviewInfo.cases && s.overviewInfo.cases.result),
                                items: fillAssignmentName(s.overviewInfo.cases.result.items, e.assignments)
                            }
                        }
                    });

            case 'UpdateCaseFlowStepPickedUpEvent':
                function updateItemFromCaseFlowOverviewList(
                    list: PagedItemsModel<CaseFlowOverviewModel>,
                    id: Guid
                ): PagedItemsModel<CaseFlowOverviewModel> {
                    if (!list) return createEmptyPagedCaseFlowModel();
                    const updatedItems = list.items.map(caseFlowModel => {
                        if (caseFlowModel.id == id) {
                            caseFlowModel.status = Umbrella.CaseFlow.CaseFlowStatus.PickedUp;
                            return caseFlowModel;
                        }
                        return caseFlowModel;
                    });

                    return {
                        ...list,
                        items: updatedItems,
                        total: list.total
                    };
                }
                return s =>
                    Overview.set(s, {
                        ...(s && s.overviewInfo),
                        cases: {
                            ...(s && s.overviewInfo && s.overviewInfo.cases),
                            result: updateItemFromCaseFlowOverviewList(s.overviewInfo.cases.result, e.id)
                        }
                    });

            case 'CaseFlowPersonLoadedEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        person: e.person
                    });

            case 'CaseFlowDocumentUploadingEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        isUploadingDocument: true,
                        mediaUploadId: null
                    });

            case 'CaseFlowDocumentUploadedEvent':
                return s => {
                    const media = (s && s.caseInfo && s.caseInfo.media) || [];

                    return CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        isUploadingDocument: false,
                        mediaUploadId: e.mediaUploadId,
                        media: [
                            ...media,
                            {
                                id: '1',
                                mediaId: e.mediaUploadId,
                                index: 0,
                                tag: ''
                            }
                        ]
                    });
                };

            case 'CaseFlowDocumentUploadErrorEvent':
                return s =>
                    CaseFlow.set(s, {
                        ...(s && s.caseInfo),
                        isUploadingDocument: false
                    });
        }
    }

    function createEmptyPagedCaseFlowModel(): PagedItemsModel<CaseFlowOverviewModel> {
        return <PagedItemsModel<CaseFlowOverviewModel>>{
            items: [],
            total: 0,
            page: 0,
            pageSize: 9
        };
    }

    export type CaseFlowOverviewStore = ObservableStore.EventStore<CaseFlowState, CaseFlowEvent>;
    export const caseFlowOverviewStore: CaseFlowOverviewStore = rootStore.map<CaseFlowState, CaseFlowEvent>(
        lens<CaseFlowState>('CaseFlow'),
        reduce
    );

    angular.module('Dashboard').value('CaseFlowOverviewStore', caseFlowOverviewStore);
}
