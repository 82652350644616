namespace Umbrella.Modules {
    import isDate = Umbrella.Helpers.isDate;
    
    @Pipe('Umbrella', 'isDate')
    class IsDateFilter {
        public transform(value: string): boolean {
            return isDate(value);
        }
    }
}
