namespace Umbrella {
    export interface CreateDialogOptions {
        id: string;
        backdrop?: boolean;
        successCallback: Function;
        cancelCallback?: Function;
        backdropClass?: string;
        modalClass?: string;
        targetElement?: ng.IAugmentedJQuery;
        position?: string;
        draggable?: boolean;
        isModal?: boolean;
        style?: string;
    }

    export type CreateDialog<T> = (
        templateUrl: string,
        options: CreateDialogOptions,
        passedInLocals: T
    ) => void;

    angular.module('Umbrella').factory('CreateDialog', [
        '$document',
        '$compile',
        '$rootScope',
        '$controller',
        '$timeout',
        (
            $document: ng.IDocumentService,
            $compile: ng.ICompileService,
            $rootScope: ng.IRootScopeService,
            $controller: ng.IControllerService,
            $timeout: ng.ITimeoutService
        ) => {
            let defaults: CreateDialogOptions = {
                id: null,
                backdrop: true,
                successCallback: null,
                cancelCallback: null,
                backdropClass: 'popup-backdrop',
                modalClass: 'modal',
                targetElement: $document.find('body'),
                position: 'fixed',
                draggable: false,
                isModal: true,
                style: null
            };

            let body = $document.find('body');

            return function Dialog(
                templateUrl,
                options: CreateDialogOptions,
                passedInLocals: any
            ) {
                options = { ...defaults, ...options };

                let key;

                let idAttr = options.id ? ' id="' + options.id + '" ' : '';
                let modalBody = (() =>
                    '<div class="' +
                    options.modalClass +
                    ' ' +
                    options.position +
                    '"' +
                    idAttr +
                    ' ' +
                    (options.draggable ? 'umbrella-draggable' : '') +
                    ' ' +
                    (options.style ? 'style="' + options.style + '"' : '') +
                    ' ng-include="\'' +
                    templateUrl +
                    '\'"></div>')();
                //We don't have the scope we're gonna use yet, so just get a compile function for modal
                let popupTemplate = angular.element(modalBody);

                let backdropTemplate = angular.element(
                    '<div ng-click="$modalCancelFromBackdrop($event)" class="fade-backdrop"></div>'
                );
                backdropTemplate.addClass(options.backdropClass);
                backdropTemplate.addClass('fade in');

                // We give the popup a new scope, inherited from the current one.
                let popupScope = <IPopupScope>$rootScope.$new();

                // pass local parameters unto popup scope
                for (key in passedInLocals) {
                    if (passedInLocals.hasOwnProperty(key))
                        popupScope[key] = passedInLocals[key];
                }

                let backdropLinker = $compile(backdropTemplate);
                let backdropElement = backdropLinker(popupScope);
                let popupLinker = $compile(popupTemplate);
                let popupElement = popupLinker(popupScope);

                // handle key pressed (esc = close)
                let handleEscPressed = function(event) {
                    if (event.keyCode === 27) {
                        popupScope.$modalCancel();
                    }
                };

                body.bind('keydown', handleEscPressed);
                //  (JH) - Add 'modal-open' css class to body:
                if (options.isModal) {
                    body.addClass('modal-open');
                    body.parent().addClass('modal-open');
                }
                /**
                 * Make sure we do not destroy our scope when a digest is in progress.
                 * http://stackoverflow.com/questions/12729122/prevent-error-digest-already-in-progress-when-calling-scope-apply
                 **/
                let destroyFn = function() {
                    if (!popupScope.$$phase) {
                        popupScope.$destroy();
                    } else {
                        $timeout(
                            function() {
                                destroyFn();
                            },
                            100,
                            true
                        );
                    }
                };

                let closeFn = function(success) {
                    if (options.isModal || success) {
                        body.unbind('keydown', handleEscPressed);
                        //  (JH) - Remove 'modal-open' css class to body:
                        body.removeClass('modal-open');
                        body.parent().removeClass('modal-open');
                        if (options.backdrop) {
                            backdropElement.remove();
                        }

                        if (popupElement.length > 0) {
                            if ($(popupElement[0]).next()) {
                                $(popupElement[0])
                                    .next()
                                    .remove();
                            } else {
                                $(popupElement[0]).remove();
                            }
                        }

                        if (options.cancelCallback) {
                            options.cancelCallback.apply(this, arguments);
                        }

                        destroyFn();
                    }
                };

                popupScope.$modalCancel = closeFn;

                popupScope.$modalCancelFromBackdrop = function(event) {
                    let source = event.target || event.srcElement;
                    if (source && $(source).hasClass('popup-backdrop-inner')) {
                        // popupScope.$modalCancel();
                    }
                };

                popupScope.$modalSuccess = function() {
                    if (options.successCallback) {
                        options.successCallback.apply(this, arguments);
                    } else {
                        closeFn.call(this, true);
                    }

                    popupScope.$modalCancel();
                };
                //  Is requested Popup inline or full-size?
                let isInlineEdit =
                    options.modalClass.indexOf('inline-popup') > -1;

                if (options.backdrop) {
                    if (isInlineEdit) {
                        options.targetElement.append(popupElement);
                        body.append(backdropElement);
                    } else {
                        let backdropInnerElement = jQuery('<div/>', {
                            class: 'popup-backdrop-inner'
                        });
                        backdropElement.append(backdropInnerElement);

                        backdropInnerElement.append(popupElement);
                        body.append(backdropElement);
                    }
                } else {
                    options.targetElement.append(popupElement);
                }

                //window.initFormElements();
            };
        }
    ]);
}
