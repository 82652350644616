namespace Umbrella {

    import MediaModel = Umbrella.Media.MediaModel;

    @Component('Umbrella', {
        selector: 'show-deleted-media-information',
        templateUrl: '/AttachmentListComponent/showdeletedmediainformation.html',
        bindings: {
            mediaItem: '<',
        }
    })
    @Inject('$mdDialog')
    export class ShowDeletedMediaInformation {

        public mediaItem: any;

        constructor(
            private $mdDialog,
        ) {}

        public cancel(): void {
            this.$mdDialog.cancel();
        }
    }
}
