namespace Umbrella.Search.Vendors {
    import AddressModel = Umbrella.Modules.AddressModel;

    @Inject('$scope', '$location', '$state', 'PersonResource')
    @Controller('Search', 'VendorOverviewController')
    class VendorOverviewController {
        constructor(
            $scope,
            $location,
            $state,
            personResource: PersonResource
        ) {
            var pageSize = 50;
            var searchParamName = 'query';

            $scope.searchQuery = $location.search()[searchParamName] || '';
            $scope.searching = false;

            // Function that displays the search result box, or hides it if no results are present.
            let showKlanten = function(results) {
                if ($scope.page === 0) {
                    $scope.personen = results;
                } else {
                    for (let i = 0; i < results.items.length; i++) {
                        $scope.personen.items.push(results.items[i]);
                    }
                }

                $scope.searching = false;
            };

            $scope.clear = function() {
                $scope.personen = [];
                $scope.searching = false;
            };

            // Start by showing no results
            $scope.clear();

            // Execute search query using the current $scope.searchQuery
            $scope.search = function(append) {
                if (append) {
                    $scope.page++;
                } else {
                    $scope.page = 0;
                }

                if ($scope.searchQuery) {
                    $scope.searching = true;

                    let param = $scope.searchQuery;

                    if (param.charAt(param.length - 1) === '.') {
                        param = param.slice(0, -1);
                    }

                    personResource.search({
                        id: param,
                        page: $scope.page,
                        pageSize: pageSize,
                        roles: 'vendor'
                    })
                    .$promise.then(results => {
                        let p = $scope.searchQuery;

                        if (p.charAt(p.length - 1) === '.') {
                            p = p.slice(0, -1);
                        }                            

                        if (param.toLowerCase() === p.toLowerCase()) {
                            showKlanten(results);
                        }
                    });
                } else {
                    $scope.clear();
                }
            };

            $scope.loadMore = () => {
                if (
                    $scope.personen !== undefined &&
                    $scope.personen.items !== undefined &&
                    $scope.personen.items.length !== $scope.personen.total
                )
                    $scope.search(true);
            };

            $scope.getAddress = (addresses: AddressModel[]) => {
                if (!addresses) return '';

                return addresses.reduce((previousValue: AddressModel, currentValue: AddressModel) => {
                    if(currentValue.type && (currentValue.type.toString() === 'Home' || currentValue.type.toString() === '0'))
                        return currentValue.fullAddressLine || `${currentValue.addressLine}, ${currentValue.zipCode} ${currentValue.city}`
                    if(!previousValue && currentValue.type && (currentValue.type.toString() === 'Postal' || currentValue.type.toString() === '1'))    
                        return currentValue.fullAddressLine || `${currentValue.addressLine}, ${currentValue.zipCode} ${currentValue.city}`
                    else    
                        return previousValue 
                }, '')
            };

            $scope.$watch('searchQuery', function(newVal, oldVal) {
                $scope.search(false);
                $location.search(searchParamName, newVal || null);
            });
        }
    }
}