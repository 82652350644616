﻿/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Decorators.ts" />
/// <reference path="../../../../../Window.d.ts" />

namespace Umbrella.TaskHandling {
    import ActivityModel = Modules.ActivityModel;

    export interface TaskMessagesComponentState {
        activities: ActivityModel[];
    }

    @Component('TaskHandling', {
        selector: 'task-messages',
        templateUrl: '/TaskHandling/_Overview/_Details/_Messages/TaskMessagesComponent/TaskMessages.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('TaskOverviewService', 'ToastMessageService')
    class TaskMessagesComponent {
        public state: TaskMessagesComponentState;
        public waiting: boolean;
        public user = window.user;
        public remark: string;

        constructor(
            private taskOverviewService: TaskOverviewService,
            private toastMessageService: ToastMessageService
        ) {}

        public reply(message: string): void {
            if (!message || this.isStringEmpty(message)) return;

            this.waiting = true;
            this.taskOverviewService
                .reply(message)
                .then(() => (this.remark = ''))
                .catch(() =>
                    this.toastMessageService.error(
                        'Er is iets mis gegaan tijdens het opslaan van het bericht. Probeer het nogmaals. '
                    )
                )
                .then(() => (this.waiting = false));
        }

        private isStringEmpty(text: string) {
            if (!text) return false;

            return text.replace(/^\s+|\s+$/g, '').length === 0;
        }
    }
}
