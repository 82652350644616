/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.TaskHandling {
    angular.module('TaskHandling').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state('dashboard.tasks.overview.link', {
                url:
                    '/link/{id:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Taak koppelen',
                    authorize: p => p.editTask
                },
                views: {
                    'details@dashboard.tasks': {
                        template:
                            '<link-task state="state$ | async:this"></link-task>',
                        controller: Umbrella.createComponentRouteController<
                            Overview.State,
                            LinkTaskComponentState
                        >('TaskOverviewStore', s => ({
                            task:
                                s &&
                                s.selectedTask &&
                                JSON.parse(JSON.stringify(s.selectedTask))
                        }))
                    }
                }
            });
        }
    ]);
}
