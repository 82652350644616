namespace Umbrella.Modules.Colleagues {
    @Pipe('Colleagues', 'photoUrl')
    @Inject('PhotoUrlService')
    class PhotoUrlFilter {
        constructor(private photoUrlSvc: PhotoUrlService) {}

        public transform(
            id: System.Guid,
            width?: number,
            heigth?: number,
            noCache?: boolean
        ) {
            return this.photoUrlSvc.photoUrl(id, width, heigth, noCache);
        }
    }

    @Pipe('Colleagues', 'photoBlobUrl')
    @Inject('PhotoBlobUrlService')
    class PhotoBlobUrlFilter {
        constructor(private photoUrlSvc: PhotoBlobUrlService) {}

        public transform(
            id: System.Guid,
            width?: number,
            heigth?: number,
            noCache?: boolean
        ) {
            return this.photoUrlSvc.photoUrl(id, width, heigth, noCache);
        }
    }
}
