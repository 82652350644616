/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../customerservice.colleaguecard.routeconfig.ts" />

namespace Umbrella.CustomerService.ColleagueCard.Telephony {
    import PhoneStatusSummaryModel = Umbrella.TelephonyControl.Statistics.PhoneStatusSummaryModel;
    import IPhoneStatusSummaryResourceClass = Umbrella.CustomerService.IPhoneStatusSummaryResourceClass;
    import PhoneCallRegistrationModel = Umbrella.TelephonyControl.Statistics.PhoneCallRegistrationModel;
    import IPhoneCallResourceClass = Umbrella.CustomerService.IPhoneCallResourceClass;
    import ColleagueModel = Umbrella.Modules.Colleagues.ColleagueModel;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import IRoleResource = Umbrella.Modules.Customers.IRoleResource;
    import SessionColleagueModel = Modules.Colleagues.SessionColleagueModel;

    interface CallWithPersonIdAndName {
        callDetails: PhoneCallRegistrationModel;
        personId?: Guid;
        personName: string;
    }

    @Component('CustomerService', {
        selector: 'colleague-telephony',
        templateUrl: '/CustomerService/_ColleagueCard/_Telephony/ColleagueTelephonyComponent/ColleagueTelephony.html'
    })
    @Inject('$stateParams', 'PhoneStatusSummaryResource', 'PhoneCallResource', 'ColleagueResource', 'RoleResource', '$state')
    export class ColleagueTelephonyComponent {
        public loading: boolean;
        public loadingData: boolean;
        public statusSummary: PhoneStatusSummaryModel[] = [];
        public calls: CallWithPersonIdAndName[] = [];
        public colleague: ColleagueModel.Detailed;
        public day: Date;
        public page = 0;
        public pageSize = 250;
        public user: SessionColleagueModel;

        constructor(
            private $stateParams: ColleagueCardStateParams,
            private phoneStatusSummaryResource: IPhoneStatusSummaryResourceClass,
            private phoneCallResource: IPhoneCallResourceClass,
            private colleagueResource: IColleagueResourceClass,
            private roleResource: IRoleResource,
            private $state: ng.ui.IStateService
        ) {}

        public $onInit() {
            this.user = window.user;

            this.day = new Date(Date.now());
            if (!this.hasPermission()) {
                this.$state.transitionTo('umbrella.error_401');
                return;
            }
            this.loadColleague(this.$stateParams.personId);
        }

        private hasPermission(): boolean {
            return this.user.permissions.phoneViewColleagueHistory || (this.user.id === this.$stateParams.personId);
        }

        public async update(day: Date): Promise<void> {
            this.loadingData = true;
            this.day = new Date(day);
            await this.loadStatusSummary(this.day);
            await this.loadPhoneCalls(this.day, this.page, this.pageSize);
            this.loadingData = false;
        }

        public loadMore(): void {
            this.page += 1;
            this.update(this.day);
        }

        private loadColleague(personId: Guid): void {
            this.loading = true;
            this.colleagueResource.getById({ id: personId }).$promise.then(data => {
                this.colleague = data;
                this.loading = false;
                this.update(this.day);
            });
        }

        private async loadStatusSummary(day: Date): Promise<void> {
            await this.phoneStatusSummaryResource
                .phoneStatusSummary({
                    userId: this.colleague.personId,
                    from: day.toYYYYMMDDString(),
                    until: day.addDays(1).toYYYYMMDDString()
                })
                .$promise.then(data => {
                    this.statusSummary = data;
                });
        }

        private async loadPhoneCalls(day: Date, page: number, pageSize: number): Promise<void> {
            if (page === 0) this.calls = [];

            await this.phoneCallResource
                .phoneCalls({
                    userId: this.colleague.personId,
                    from: day.toYYYYMMDDString(),
                    until: day.addDays(1).toYYYYMMDDString(),
                    page,
                    pageSize
                })
                .$promise.then(calls => {
                    if (!calls || !calls.total) return;

                    const callsWithPersonIdAndName = calls.items.map(c => {
                        return { callDetails: c, personId: null, personName: null };
                    });
                    this.calls = [...this.calls, ...callsWithPersonIdAndName];

                    const ids = Array.from(new Set(calls.items.map(d => d.roleId)));
                    this.roleResource.getAllByIds({ ids }).$promise.then(roles => {
                        this.calls.forEach(c => {
                            const callRole = c.callDetails.roleId && roles.find(r => r.id === c.callDetails.roleId);
                            c.personId = callRole && callRole.personId;
                            c.personName = callRole && callRole.name;
                        });
                    });
                });
        }
    }
}
