/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Window.d.ts" />

namespace Umbrella {
    angular.module('Umbrella').directive('triggerExperimentalArea', [
        'ToastMessageService',
        '$state',
        'LocalStorageService',
        (
            toastMessageService: ToastMessageService,
            $state: ng.ui.IStateService,
            localStorageService: LocalStorageService
        ) => {
            const timeAllowedBetweenClicks = 1000;
            const requiredClicks = 3;
            let lastClickTime: Date;
            let clickCounter = 0;

            function handleClicks(event: Event) {
                if (!(<MouseEvent>event).altKey) {
                    resetClicks();
                    return;
                }

                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();

                const clickTime = new Date();

                if (!lastClickTime) {
                    increaseCounter(clickTime);
                    return;
                }

                const diff = clickTime.getTime() - lastClickTime.getTime();

                if (diff >= timeAllowedBetweenClicks) resetClicks();
                increaseCounter(clickTime);
            }

            function resetClicks() {
                lastClickTime = null;
                clickCounter = 0;
            }

            function increaseCounter(timeClicked: Date) {
                clickCounter++;
                lastClickTime = timeClicked;

                if (
                    clickCounter > requiredClicks &&
                    window.user.permissions.shell
                ) {
                    // handled clicks are +1 from the actual clicks because of the double event handler
                    resetClicks();

                    succeed();
                }
            }

            function succeed() {
                const devAreaUnlocked = localStorageService.get(
                    'ExperimentalAreaUnlocked'
                );
                if (devAreaUnlocked) {
                    Config.changeExperimentalAreaState(false);
                    localStorageService.store(
                        'ExperimentalAreaUnlocked',
                        false
                    );
                    toastMessageService.warning('Config developer area locked');
                    $state.go('dashboard.tasks');
                } else {
                    Config.changeExperimentalAreaState(true);
                    localStorageService.store('ExperimentalAreaUnlocked', true);
                    toastMessageService.warning(
                        'Config developer area unlocked'
                    );
                }
            }

            return {
                link(
                    scope: ng.IScope,
                    el: ng.IAugmentedJQuery,
                    attrs: ng.IAttributes
                ) {
                    el.bind('mouseup click', (event: Event) => {
                        handleClicks(event);
                    });
                }
            };
        }
    ]);
}
