/// <reference path='../Window.d.ts' />
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../TaskHandling/module.ts"/>

namespace Umbrella.Shared {
    export interface InfiniteListComponentState {}

    export interface MaterialInfiniteList {
        // required by material infinite list
        getItemAtIndex(index): any;
        getLength(): number;
    }

    export const toggleTaskListScroll$ = new Rx.Subject();

    @Component('Umbrella', {
        selector: 'infinite-list',
        templateUrl: '/InfiniteListComponent/InfiniteListComponent.html',
        bindings: {
            topItemIndex: '<?',
            startAtIndex: '<?',
            itemsPerPage: '<?',
            externalCollection: '<',
            totalItemsCount: '<',
            itemTemplate: '<',
            pageChangedCallback: '&?onPageChanged',
            loadItemsFunction: '&',
            startAtPage: '<?'
        }
    })
    @Inject('$scope')
    export class InfiniteListComponent implements MaterialInfiniteList {
        private itemsPerPage: number;
        private totalItemsCount: number;
        private pageChangedCallback: (options?: any) => any;
        private loadItemsFunction: (options?: any) => any;
        private itemCountLoaded = 0;
        private externalCollection: any[];
        private isFetching: boolean;
        private fetchTries = 0;
        private indexListener;
        private isTaskListFilterViewEnabledSubscription: Rx.Disposable;
        public isTaskListFilterViewEnabled: boolean;
        public totalItemsCountInternal: number;
        public topItemIndex: number;
        public startAtIndex: number;
        public itemTemplate: string;
        public isListEndReached: boolean;
        public startAtPage: number;

        constructor(private $scope: any) {
            this.topItemIndex = this.topItemIndex || 0;
            this.startAtIndex = this.startAtIndex || 0;
            this.itemsPerPage = this.itemsPerPage || 10;
            this.startAtPage = this.startAtPage && this.startAtPage > 0 ? this.startAtPage : 1;
        }

        public $onInit() {
            this.subscribeEvents();
            if (this.externalCollection) {
                this.itemCountLoaded = this.externalCollection.length;
            }

            this.loadUntilPage(this.startAtPage);

            this.indexListener = this.$scope.$watch('vm.topItemIndex', (newValue, oldValue) => {
                if (!newValue || !oldValue || !this.pageChangedCallback) {
                    return;
                }

                const newPage = this.calculatePageForItemAtIndex(newValue);
                const oldPage = this.calculatePageForItemAtIndex(oldValue);

                if (newPage !== oldPage) {
                    this.pageChangedCallback({ newPage });
                }
            });
        }

        public $onDestroy() {
            this.unsubscribeEvents();
            this.indexListener();
        }

        public $onChanges(bindings: {
            externalCollection: IBindingChange<any[]>;
            totalItemsCount: IBindingChange<number>;
        }) {
            if (bindings.externalCollection && bindings.externalCollection.currentValue) {
                this.onExternalCollectionUpdate(
                    bindings.externalCollection.currentValue,
                    bindings.externalCollection.previousValue
                );
            }

            if (bindings.totalItemsCount && bindings.totalItemsCount.currentValue) {
                this.onTotalItemsCountUpdate(
                    bindings.totalItemsCount.currentValue,
                    bindings.totalItemsCount.previousValue
                );
            }
        }

        private subscribeEvents() {
            this.isTaskListFilterViewEnabledSubscription = toggleTaskListScroll$.subscribe(() => {
                this.isTaskListFilterViewEnabled = !this.isTaskListFilterViewEnabled;
            });
        }

        private unsubscribeEvents() {
            if (this.isTaskListFilterViewEnabledSubscription) {
                this.isTaskListFilterViewEnabledSubscription.dispose();
                this.isTaskListFilterViewEnabledSubscription = null;
            }
        }

        public getItemAtIndex(index): any {
            if (index > this.itemCountLoaded || this.itemCountLoaded === 0) {
                this.fetchMoreItems();
                return null;
            }

            return this.externalCollection[index];
        }

        public getLength() {
            return this.itemCountLoaded;
        }

        private onExternalCollectionUpdate(updatedCollection: any[], previousCollectionState: any[]) {
            if (previousCollectionState && updatedCollection.length !== previousCollectionState.length) {
                this.fetchTries = 0;
                this.isFetching = false;
                this.isListEndReached = false;
            }

            if (updatedCollection.length === 0) {
                this.topItemIndex = 0;
            }

            this.itemCountLoaded = updatedCollection.length;

            if (this.totalItemsCountInternal && this.totalItemsCountInternal === updatedCollection.length) {
                this.isListEndReached = true;
            }
        }

        private onTotalItemsCountUpdate(newCount: number, oldCount: number) {
            this.totalItemsCountInternal = newCount * 1;

            if (oldCount && oldCount !== newCount) {
                this.topItemIndex = 0;
                this.isFetching = false;
            }

            if (this.totalItemsCountInternal <= 0 || this.totalItemsCountInternal === this.itemCountLoaded) {
                this.isListEndReached = true;
            } else {
                this.isListEndReached = false;
            }
        }

        private calculatePageForItemAtIndex(index: number) {
            return Math.ceil(index / this.itemsPerPage);
        }

        private calculateNextPageToRequest() {
            return Math.floor(this.itemCountLoaded / this.itemsPerPage) + 1;
        }

        private async fetchMoreItems() {
            if (this.isListEndReached) {
                return;
            }

            if (!this.isFetching) {
                if (this.fetchTries > 2) {
                    this.isListEndReached = true;
                }
                this.isFetching = true;
                const itemCountBeforeFetch = this.itemCountLoaded * 1;
                await this.loadItemsFunction({
                    page: this.calculateNextPageToRequest(),
                    itemsPerPage: this.itemsPerPage
                });
                this.isFetching = false;

                if (itemCountBeforeFetch === this.itemCountLoaded) {
                    this.fetchTries++;
                }
            }
        }

        private async loadUntilPage(page) {
            const itemsToLoad = page * this.itemsPerPage;

            while (this.itemCountLoaded < itemsToLoad && !this.isListEndReached) {
                await this.fetchMoreItems();
            }

            this.topItemIndex = (page - 1) * this.itemsPerPage;
        }
    }
}
