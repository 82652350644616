namespace Umbrella.Modules.Colleagues {
    @Pipe('Colleagues', 'presenceColor')
    class PresenceColorFilter {
        public transform(stateType: StateType | string | number) {
            switch (stateType) {
                case StateType.Available:
                case 'Available':
                case 1:
                    return 'green';
                case StateType.Unavailable:
                case 'Unavailable':
                case 2:
                    return 'red';
                case StateType.Busy:
                case 'Busy':
                case 3:
                    return 'red';
                case StateType.Offline:
                case 'Offline':
                case 4:
                    return '';
                //case StateType.Away:
                case 'Away':
                case 5:
                    return 'yellow';
                //case StateType.Unknown:
                case 'Unknown':
                case 6:
                    return '';
                //case StateType.ACW:
                case 'ACW':
                case 7:
                    return 'red';
                //case StateType.DND:
                case 'DND':
                case 8:
                    return 'red';
                default:
                    return 'help_outline';
            }
        }
    }
}
