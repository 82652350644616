/// <reference path="../../Decorators.ts" />

namespace Umbrella.CustomerService {
    import FaqResourceClass = Modules.Knowledgebase.Faq.FaqResourceClass;
    import FaqModel = Modules.Knowledgebase.FaqModel;
    enum SuggestionState {
        Default = 0,
        Creating = 1,
        Done = 2
    }

    @Component('CustomerService', {
        selector: 'create-faq-suggestion',
        templateUrl:
            '/CustomerService/CreateFaqSuggestionComponent/CreateFaqSuggestionComponent.html',
        bindings: {
            faqId: '<'
        }
    })
    @Inject('TaskResource', 'FaqResource')
    class CreateFaqSuggestionComponent {
        private faqId: Guid;
        public faq: FaqModel.Detailed;
        public suggestionState: SuggestionState = 0;
        public suggestion: string = '';

        constructor(
            private taskResource: Umbrella.Modules.Tasks.ITaskResourceClass,
            private faqResource: FaqResourceClass
        ) {}

        $onInit() {
            this.faqResource
                .getById({ id: this.faqId })
                .$promise.then(faq => {
                    this.faq = faq;
                })
                .catch(() => {
                    this.faq = null;
                });
        }

        public createSuggestion() {
            switch (this.suggestionState) {
                case 0:
                case SuggestionState.Default:
                    this.suggestionState = SuggestionState.Creating;
                    break;
                case 1:
                case SuggestionState.Creating:
                    this.submitSuggestion();
                    break;
                default:
                    break;
            }
        }

        public hasPermissions() {
            return !!window.user.permissions.knowledgebaseFaqCreateSuggestion;
        }

        private submitSuggestion() {
            this.taskResource
                .createFaqSuggestion({
                    question: this.faq.question,
                    suggestion: this.suggestion,
                    fromOperatorId: window.user.id
                })
                .$promise.then(task => {
                    this.suggestionState = SuggestionState.Done;
                })
                .finally(() => {
                    this.suggestion = '';
                    setTimeout(() => {
                        this.suggestionState = SuggestionState.Default;
                    }, 5000);
                });
        }
    }
}
