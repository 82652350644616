module Umbrella {
    import IResource = angular.resource.IResource;

    export interface IApplicationResource {
        mine(): IResource<ApplicationModel.Detailed>;
    }

    angular.module('Umbrella')
        .factory('ApplicationResource',
        [
            '$resource', ($resource: angular.resource.IResourceService) => $resource(
                'api/v1/application/:action/:id',
                    {            
                    },
                    {
                        mine: {
                            method: 'GET',
                            isArray: false,
                            params: { action: 'mine' }
                        }
                    }
                )
        ]);
}