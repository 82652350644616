﻿/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.Sandbox {
    angular.module('Sandbox').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('sandbox', {
                url: '/sandbox',
                parent: 'root',
                abstract: true,
                redirectTo: 'sandbox.dashboard',
                views: {
                    'new@': { templateUrl: '/Sandbox/Sandbox.html' }
                }
            });
        }
    ]);
}
