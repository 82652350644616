/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../ComponentRouteUtils.ts" />

namespace Umbrella.CustomerService.UnitCard.Activities {
    function mapState(s: UnitCardState): UnitActivitiesComponentState {
        return {
            unit: s.unit,
            activityInfo: s.activityInfo
        };
    }

    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.unitcard.tab.activities', {
                url: '/tijdlijn?createtask',
                reloadOnSearch: false,
                data: {
                    tab: 'tijdlijn',
                    authorize: ps => ps.customerCard && ps.caseRead
                },
                views: {
                    'tab@customerservice': {
                        template:
                            '<unit-activities state="state$ | async:this"></unit-activities>',
                        controller: createComponentRouteController(
                            'UnitCardStore',
                            mapState
                        )
                    }
                },
                onEnter: [
                    'UnitActivitiesService',
                    (unitActivitiesService: UnitActivitiesService) => {
                        unitActivitiesService.ensureLoaded();
                    }
                ]
            });
        }
    ]);
}
