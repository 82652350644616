namespace Umbrella.Modules {
    angular.module('Umbrella').filter('fieldLabel', () => (field: string, defaultValue: string) => {
        const erp = window.config && window.config.facets && window.config.facets.erp && window.config.facets.erp[0];

        if (erp && erp.fieldLabels[field]) {
            return erp.fieldLabels[field];
        }

        return defaultValue;
    });
}
