/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../dashboard.chat.routeconfig.ts"/>

namespace Umbrella.ChatConversationHandling {
    export interface ChatNoChatSelectedComponentState {
        registered: boolean;
    }

    @Component('Chat', {
        selector: 'chat-no-chat-selected',
        templateUrl: '/ChatConversationHandling/_Overview/ChatNoChatSelectedComponent/ChatNoChatSelected.html',
        bindings: {
            state: '<'
        }
    })
    class ChatNoChatSelectedComponent {
        public state: ChatNoChatSelectedComponent;
    }
}
