﻿namespace Umbrella.ChatConversationHandling {
    export enum MessageStatuses {
        Pending = 1,
        Sent = 2,
        Received = 3
    }

    export enum MessageDirections {
        toCustomer = 0,
        toOperator = 1
    }

    export enum ChatProviderType {
        Portal = 0,
        Tracebuzz
    }

    export enum ConversationStatus {
        Pending, // Conversation is in 'Request'-phase, no operator assigned.
        Detached, // Conversation active, no operator assigned
        Active,
        ACW, // after chat work
        Ended
    }
}
