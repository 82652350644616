namespace Umbrella.CustomerService {
    export class EventCalendarHelper {
        private static readonly FIRSTHOUR = 7;
        private static readonly LASTHOUR = 18;

        static firstHourOfDay = (day: Date) =>
            day.midnight().addHours(EventCalendarHelper.FIRSTHOUR);
        static lastHourOfDay = (day: Date) =>
            day.midnight().addHours(EventCalendarHelper.LASTHOUR);
    }
}
