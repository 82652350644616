/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular
        .module('Umbrella')

        .directive('setFocusOnSearchInput', () => {
            return {
                restrict: 'A',
                link(scope, element) {
                    element.on('click', () => {
                        const interval = setInterval(() => {
                            if (
                                document.getElementsByClassName('md-clickable')
                                    .length > 0
                            ) {
                                document
                                    .getElementsByClassName('md-clickable')[0]
                                    .querySelector('md-input-container')
                                    .querySelector('input')
                                    .focus();
                                clearInterval(interval);
                            }
                        }, 5);
                    });
                }
            };
        });
}
