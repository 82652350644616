﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;
    import TaskService = Umbrella.Modules.Tasks.TaskService;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;

    @Pipe('TaskHandling', 'canReject')
    @Inject('TaskService')
    class CanPickupFilter {
        constructor(private taskService: TaskService) {}

        public transform(task: TaskModel.Detailed, employee: SummarizedColleagueModel) {
            if (!task || !employee) return false;
            if (
                !task.completeBy ||
                !task.createdBy ||
                task.status.toString() === 'Afgerond' ||
                task.status.toString() === 'Geannuleerd'
            )
                return false;
            if (
                (task.from && task.completeBy && task.from.id === task.completeBy.id) ||
                (task.createdBy && task.completeBy && task.createdBy.id === task.completeBy.id)
            )
                return false;

            return this.taskService.isAssignedToMeOrOneOfMyFunctionGroups(task, employee);
        }
    }
}
