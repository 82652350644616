namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'isDialingStatus')
    class IsDialingStatusFilter {
        public transform(
            input: Umbrella.Telephony.PhoneCallStatus | string
        ): boolean {
            if (input) {
                switch (input) {
                    case 1:
                    case 104:
                    case 'Dialing':
                    case 'TryPickUp':
                        return true;
                    default:
                        return false;
                }
            }

            return undefined;
        }
    }
}
