/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Window.d.ts"/>

namespace Umbrella.Telephony.LiveStats {
    angular.module('Telephony').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('umbrella.telephony', {
                url: '',
                abstract: true
            });
        }
    ]);
}
