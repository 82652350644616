﻿/// <reference path="../../../../Window.d.ts" />
/// <reference path="../../../../Modules/Umbrella/CreateDialog.ts" />

namespace Umbrella.TaskHandling {
    import TaskModel = Modules.Tasks.TaskModel;
    import BaseTagModel = Modules.Knowledgebase.BaseTagModel;
    import CaseFlowResourceClass = KCC.CaseFlowDashboard.CaseFlowResourceClass;
    import ActivityModel = Umbrella.Modules.ActivityModel;
    import IResidentialZoneResourceClass = Umbrella.Modules.Housing.IResidentialZoneResourceClass;
    import ResidentialZoneModel = Umbrella.Modules.Housing.ResidentialZoneModel;
    import IMenuTab = Modules.IMenuTab;
    import IStateMenuTab = Modules.IStateMenuTab;
    import DetailedCaseFlowModel = Umbrella.CaseFlow.DetailedCaseFlowModel;

    export interface TaskDetailsComponentState {
        task: TaskModel.Detailed;
        loading: boolean;
        activities: ActivityModel[];
        linkedTasks: TaskModel[];
    }

    @Component('TaskHandling', {
        selector: 'task',
        templateUrl: '/TaskHandling/_Overview/_Details/TaskComponent/Task.html',
        bindings: {
            state: '<'
        }
    })
    @Inject(
        '$mdDialog',
        'TaskOverviewService',
        'ToastMessageService',
        'ResidentialZoneResource',
        'CaseFlowResource',
        'CaseFlowOverviewService'
    )
    class TaskComponent {
        public state: TaskDetailsComponentState;
        public user = window.user;
        public isBusyWithPickUp: boolean;
        public isBusyWithFinish: boolean;
        public isBusyWithReject: boolean;
        public hasButton: boolean;
        public residentialZone: ResidentialZoneModel;
        public tabs: IMenuTab[] = [];
        public displayTaskDetailView: boolean;
        public linkedCaseFlow: DetailedCaseFlowModel;

        constructor(
            private $mdDialog,
            private taskOverviewService: TaskOverviewService,
            private toastMessageService: ToastMessageService,
            private residentialZoneResource: IResidentialZoneResourceClass,
            private caseFlowResource: CaseFlowResourceClass
        ) {}

        public $onInit() {
            this.createTabs();
        }

        public $onChanges(bindings: { state: IBindingChange<TaskDetailsComponentState> }) {
            this.updateTab();

            const task = bindings && bindings.state && bindings.state.currentValue && bindings.state.currentValue.task;

            if (task && (task.unitId || task.complexId)) this.loadResidentialZone(task.unitId || task.complexId);

            if (task && task.taskType.category.toString() === 'CaseFlow') this.loadLinkedCaseFlow(task.id);
        }

        public loadLinkedCaseFlow(taskId: Guid): void {
            this.linkedCaseFlow = this.caseFlowResource.getByTaskId({ id: taskId });
        }

        private createTab(title: string, state: string): IStateMenuTab {
            return {
                title,
                state
            };
        }

        private createTabs() {
            this.addTab(this.createTab('Informatie', 'dashboard.tasks.overview.details.information'));
            this.addTab(this.createTab('Berichten', 'dashboard.tasks.overview.details.messages'));
            this.addTab(this.createTab('Gekoppelde taken', 'dashboard.tasks.overview.details.linkedtasks'));
            this.addTab(this.createTab('Contactmomenten', 'dashboard.tasks.overview.details.contactmoments'));
        }

        private updateTab() {
            for (const tab of this.tabs) {
                if (tab.state === 'dashboard.tasks.overview.details.messages' && this.state.activities) {
                    tab.title = 'Berichten' + this.setLengthWithHiddenType('TaakAangemaaktActivity');
                }

                if (tab.state === 'dashboard.tasks.overview.details.linkedtasks' && this.state.linkedTasks) {
                    tab.title = 'Gekoppelde taken' + this.setLength(this.state.linkedTasks.length);
                }
            }
        }

        private addTab(tab: IStateMenuTab) {
            this.tabs.push(tab);
        }

        private setLength(numberOfItems: number) {
            if (numberOfItems && numberOfItems > 0) {
                return '(' + numberOfItems + ')';
            } else {
                return '';
            }
        }

        private setLengthWithHiddenType(valueToHide: string): any {
            if (!this.state.activities || this.state.activities.length === 0) return '';

            let length = 0;
            this.state.activities.forEach(activity => {
                if (activity.type !== valueToHide) length++;
            });

            return this.setLength(length);
        }

        public confirmDelete(ev): void {
            this.$mdDialog
                .show({
                    template: `<multiline-prompt-dialog
                                theme="umb-green"
                                placeholder="Reden"
                                aria-label="Reden"
                                required="true"
                                title="Weet je zeker dat je de taak wilt verwijderen?"
                                text-content="Een verwijderde taak kan niet meer opnieuw geopend worden.">
                            </multiline-prompt-dialog>`,
                    targetEvent: null,
                    clickOutsideToClose: true
                })
                .then((rejectReason: string) => {
                    this.taskOverviewService
                        .delete(rejectReason || '')
                        .then(() => this.toastMessageService.success('Taak succesvol verwijderd'))
                        .catch(() =>
                            this.toastMessageService.error(
                                'Fout opgetreden tijdens verwijderen van de taak. Probeer het nogmaals.'
                            )
                        );
                });
        }

        public tagWithParents(tag): BaseTagModel[] {
            return Modules.Knowledgebase.tagWithParents(tag);
        }

        public pickup(): void {
            this.isBusyWithPickUp = true;
            this.taskOverviewService
                .pickup()
                .then(() => this.toastMessageService.success('Taak succesvol opgepakt'))
                .catch(() =>
                    this.toastMessageService.error(
                        'Fout opgetreden tijdens oppakken van de taak. Probeer het nogmaals.'
                    )
                )
                .finally(() => (this.isBusyWithPickUp = false));
        }

        public confirmReject(): void {
            this.$mdDialog
                .show({
                    template: `<multiline-prompt-dialog
                                theme="umb-green"
                                placeholder="Reden"
                                aria-label="Reden"
                                required="true"
                                title="Weet je zeker dat je de taak wilt weigeren?"
                                text-content="Een geweigerde taak wordt opnieuw toegekend aan de eigenaar.">
                            </multiline-prompt-dialog>`,
                    targetEvent: null,
                    clickOutsideToClose: true
                })
                .then((reason: string) => {
                    this.reject(reason || '');
                });
        }

        public async confirmFinish(taskId: System.Guid): Promise<void> {
            let stepTaskId;
            this.isBusyWithFinish = true;
            if (!window.user.permissions.viewCaseFlow) return this.confirmFinishForDefaultTask();
            this.caseFlowResource
                .getByUmbrellaTaskId({ id: taskId })
                .$promise.then(stepTask => {
                    if (!stepTask.id) {
                        return this.confirmFinishForDefaultTask();
                    }
                    stepTaskId = stepTask.id;
                    return this.confirmFinishForCaseFlowTask(stepTask.outcomes);
                })
                .then((outcomeId: System.Guid) => {
                    if (stepTaskId) {
                        this.caseFlowResource
                            .finishTask({ id: stepTaskId }, { outcomeId })
                            .$promise.then(() => this.toastMessageService.success('Taak succesvol afgerond'))
                            .catch(() =>
                                this.toastMessageService.error(
                                    'Fout opgetreden tijdens afronden van de taak. Probeer het nogmaals.'
                                )
                            )
                            .finally(() => (this.isBusyWithFinish = false));
                    }
                })
                .catch(error => {
                    if (error) {
                        console.log(error);
                        this.toastMessageService.error('Niet mogelijk om de taak af te ronden.');
                    }
                })
                .finally(() => (this.isBusyWithFinish = false));
        }

        public getPersonId(task: TaskModel.Detailed): Guid {
            let relatedPersonId: Guid;
            let casePersonId: Guid;

            if (task && task.relatedPerson && task.relatedPerson.personId) {
                relatedPersonId = task.relatedPerson.personId;
            }
            if (task && task.case && task.case.person && task.case.person.id) {
                casePersonId = task.case.person.id;
            }

            if (!relatedPersonId && !casePersonId) {
                return null;
            } else if (relatedPersonId === casePersonId) {
                return relatedPersonId;
            } else if (relatedPersonId) {
                return relatedPersonId;
            } else {
                return casePersonId;
            }
        }

        private async loadResidentialZone(id: System.Guid): Promise<void> {
            const model = await this.residentialZoneResource.getByIds({
                ids: [id]
            }).$promise;
            this.residentialZone = model && model[0];
        }

        private confirmFinishForCaseFlowTask(
            outcomes: Umbrella.CaseFlow.CaseFlowStepTaskModel.CaseFlowStepTaskOutcome[]
        ) {
            return this.$mdDialog.show({
                template:
                    '<finish-caseflow-step-task-popup md-theme="umb-green" outcomes="outcomes"></finish-caseflow-step-task-popup>',
                locals: {
                    outcomes: outcomes
                },
                controller: [
                    '$scope',
                    'outcomes',
                    ($scope, outcomes) => {
                        $scope.outcomes = outcomes;
                    }
                ],
                clickOutsideToClose: false
            });
        }

        private confirmFinishForDefaultTask() {
            return this.$mdDialog
                .show({
                    template: `<multiline-prompt-dialog
                            theme="umb-green"
                            placeholder="Omschrijving"
                            aria-label="Omschrijving"
                            required="true"
                            title="Taak afronden"
                            text-content="Beschrijf hoe deze taak is afgehandeld.">
                        </multiline-prompt-dialog>`,
                    targetEvent: null,
                    clickOutsideToClose: true
                })
                .then((description: string) => {
                    this.finish(description || '');
                });
        }

        private finish(description: string): void {
            this.isBusyWithFinish = true;
            this.taskOverviewService
                .finish(description)
                .then(() => this.toastMessageService.success('Taak succesvol afgerond'))
                .catch(() =>
                    this.toastMessageService.error(
                        'Fout opgetreden tijdens afronden van de taak. Probeer het nogmaals.'
                    )
                )
                .finally(() => (this.isBusyWithFinish = false));
        }

        private reject(reason: string): void {
            this.isBusyWithReject = true;
            this.taskOverviewService
                .reject(reason)
                .then(() => this.toastMessageService.success('Taak succesvol geweigerd'))
                .catch(() =>
                    this.toastMessageService.error(
                        'Fout opgetreden tijdens weigeren van de taak. Probeer het nogmaals.'
                    )
                )
                .finally(() => (this.isBusyWithReject = false));
        }

        public taskHasButton() {
            this.hasButton = true;
        }
    }
}
