/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Scope.d.ts"/>
/// <reference path="../../../../../Window.d.ts" />

namespace Umbrella.KCC.CaseFlowDashboard {
    import TaskAssignmentModel = Umbrella.CaseFlow.PickupCaseFlowStepModel.TaskAssignmentModel;
    import CaseFlowStepSelfServiceScenario = Umbrella.CaseFlow.CaseFlowStep.CaseFlowStepSelfServiceScenario;

    export interface TaskAssignmentWithInformationModel
        extends Umbrella.CaseFlow.PickupCaseFlowStepModel.TaskAssignmentModel {
        title: string;
        isOptional: boolean;
    }

    @Component('CaseFlow', {
        selector: 'pickup-caseflowstep-popup',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/_Steps/PickUpCaseFlowStepPopupComponent/PickUpCaseFlowStepPopup.html',
        bindings: {
            stepId: '@',
            caseFlowId: '@'
        }
    })
    @Inject(
        '$mdDialog',
        '$filter',
        'CaseFlowOverviewService',
        'CaseFlowOverviewStore',
        'ToastMessageService'
    )
    export class PickUpCaseFlowStepPopupComponent {
        private caseFlowId: System.Guid;
        private stepId: System.Guid;

        public tasks: TaskAssignmentWithInformationModel[];
        public selfServiceScenarios: CaseFlowStepSelfServiceScenario[];
        public user: Umbrella.Modules.Colleagues.SessionColleagueModel;
        public waiting: boolean;

        constructor(
            private $mdDialog,
            private $filter: ng.IFilterService,
            private caseFlowOverviewService: CaseFlowOverviewService,
            private caseFlowOverviewStore: CaseFlowOverviewStore,
            private toastService: ToastMessageService
        ) {}

        public $onInit() {
            this.user = window.user;

            const selectedStep = x =>
                x &&
                x.caseInfo &&
                x.caseInfo.caseflow&&
                x.caseInfo.caseflow.steps.filter(x => x.id === this.stepId)[0];
            const selectedStepLoaded = x => !!selectedStep(x);
            this.caseFlowOverviewStore.state$
                .filter(selectedStepLoaded)
                .map(selectedStep)
                .take(1)
                .subscribe(step => {
                    this.tasks = step.tasks.map(t => {
                        return {
                            id: t.id,
                            employeeId: this.user.id,
                            functionGroupId: null,
                            title: t.title,
                            isOptional: t.isOptional
                        };
                    });
                    this.selfServiceScenarios = step.selfServiceScenarios;
                });
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public pickUp(): void {
            this.waiting = true;

            const step: Umbrella.CaseFlow.PickupCaseFlowStepModel = {
                caseFlowId: this.caseFlowId,
                taskAssignments: this.tasks.map(t => {
                    return {
                        id: t.id,
                        employeeId: t.employeeId,
                        functionGroupId: t.functionGroupId
                    };
                })
            };

            this.caseFlowOverviewService
                .pickUpStep(step)
                .then(() => {
                    this.$mdDialog.hide();
                })
                .catch(e =>
                    this.toastService.error(
                        'Oppakken stap mislukt. Probeer het nogmaals.'
                    )
                )
                .finally(() => (this.waiting = false));
        }

        public assign(
            task: TaskAssignmentModel,
            item: { type: string; id: System.Guid }
        ): void {
            if (!task) return;

            if (!item || !item.id) {
                this.clearAssignedTo(task);
                return;
            }

            const isFunctionGroup = <any>this.$filter('isFunctionGroup');
            if (isFunctionGroup(item.type))
                this.assignToFunctionGroup(task, item.id);
            else this.assignToEmployee(task, item.id);
        }

        private clearAssignedTo(task: TaskAssignmentModel): void {
            task.employeeId = null;
            task.functionGroupId = null;
        }

        private assignToEmployee(
            task: TaskAssignmentModel,
            employeeId: System.Guid
        ) {
            task.employeeId = employeeId;
            task.functionGroupId = null;
        }

        private assignToFunctionGroup(
            task: TaskAssignmentModel,
            functionGroupId: System.Guid
        ) {
            task.functionGroupId = functionGroupId;
            task.employeeId = null;
        }
    }
}
