namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'isOnHoldStatus')
    class IsOnHoldStatusFilter {
        public transform(
            input: Umbrella.Telephony.PhoneCallStatus | string
        ): boolean {
            if (input) {
                switch (input) {
                    case 6:
                    case 103:
                    case 'OnHold':
                    case 'TryHold':
                        return true;
                    default:
                        return false;
                }
            }

            return false;
        }
    }
}
