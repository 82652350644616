﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Decorators.ts" />

namespace Umbrella.Search.Customers {
    angular.module('Search').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('search.customers', {
                url: '/klanten?query',
                data: {
                    pageTitle: 'Huurders',
                    authorize: ps => ps.customerCard
                },
                views: {
                    'center@search': {
                        templateUrl: '/Search/_Customers/CustomerOverview.html',
                        controller: 'CustomerOverviewController'
                    }
                }
            });
        }
    ]);
}
