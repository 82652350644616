/// <reference path="../../../../Scripts/TypeScript/umbrella/Enums.ts"/>

namespace Umbrella.CustomerService.ColleagueCard.Telephony {
    import PhoneStatus = Umbrella.Telephony.PhoneStatus;

    @Pipe('CustomerService', 'phoneStatusDescription')
    class PhoneStatusDescriptionFilter {
        public transform(status: PhoneStatus | string | number) {
            switch (status) {
                case PhoneStatus.Unavailable:
                case 'Unavailable':
                case 1:
                    return 'Niet beschikbaar';
                case PhoneStatus.Available:
                case 'Available':
                case 2:
                    return 'Beschikbaar';
                case PhoneStatus.Offline:
                case 'Offline':
                case 3:
                    return 'Offline';
                case PhoneStatus.Busy:
                case 'Busy':
                case 4:
                    return 'Bezet';
                case PhoneStatus.ACW:
                case 'ACW':
                case 5:
                    return 'Nawerktijd';
                case 'Away':
                    return 'Even weg';
            }

            return status;
        }
    }
}
