﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard {
    import UnitModel = Modules.Housing.UnitModel;
    import IUnitResource = Modules.Housing.Units.IUnitResource;
    import IValuationResourceClass = Modules.Housing.Valuations.IValuationResourceClass;
    import IContractResource = Modules.Housing.IContractResource;

    @Service('CustomerService', 'UnitCardService')
    @Inject('UnitResource', 'EmptyGuid', 'UnitCardStore', 'ValuationResource', 'ContractResource')
    export class UnitCardService {
        constructor(
            private unitResource: IUnitResource,
            private emptyGuid: System.Guid,
            private unitCardStore: UnitCardStore,
            private valuationResource: IValuationResourceClass,
            private contractResource: IContractResource
        ) {}

        public loadByUnitId(unitId: System.Guid): void {
            this.loading(unitId);

            this.unitResource.getById({ id: unitId }).$promise.then(unit => this.loaded(unit));
        }

        public reload(): void {
            const unitId = this.getState().id;

            this.unitResource.getById({ id: unitId }).$promise.then(unit => this.loaded(unit));
        }

        public loadValuations(force = false): void {
            if (!this.getState().valuation || force) {
                this.emit({ type: 'UnitValuationLoadingEvent' });

                this.valuationResource
                    .getByUnitId({ id: this.getState().id })
                    .$promise.then(value =>
                        this.emit({
                            type: 'UnitValuationLoadedEvent',
                            valuation: value
                        })
                    )
                    .catch(e => {
                        if (e.status === 404)
                            this.emit({
                                type: 'UnitValuationLoadedEvent',
                                valuation: null
                            });
                        else
                            this.emit({
                                type: 'UnitValuationLoadErrorEvent',
                                error: e
                            });
                    });
            }
        }

        public unload() {
            this.emit({ type: 'UnitCardUnloadedEvent' });
        }

        public loadContracts = (page = 0, pageSize = 5, force = false): void => {
            const contracts = this.getState().contractInfo && this.getState().contractInfo.contracts;

            if (!contracts || contracts.page !== page || contracts.pageSize !== pageSize || force) {
                const promise = this.contractResource.getAllByUnitIdPaged({
                    id: this.getState().id,
                    page,
                    pageSize
                }).$promise;

                this.emit({
                    type: 'UnitContractsLoadingEvent',
                    page,
                    pageSize
                });

                promise.then(data => {
                    const mergedList = {
                        items:
                            contracts && contracts.page < data.page ? [...data.items, ...contracts.items] : data.items,
                        total: data.total,
                        page: data.page,
                        pageSize: data.pageSize
                    };

                    this.emit({
                        type: 'UnitContractsLoadedEvent',
                        contracts: mergedList
                    });
                });
            }
        };

        private loading(id: System.Guid) {
            this.emit({
                type: 'UnitCardLoadingEvent',
                id
            });
        }

        private loaded(unit: UnitModel) {
            this.emit({
                type: 'UnitCardLoadedEvent',
                unit
            });
        }

        private emit(e: UnitCardEvent) {
            this.unitCardStore.event$.onNext(e);
        }

        private getState(): UnitCardState {
            return this.unitCardStore.getState();
        }
    }
}
