namespace Umbrella {
    angular.module('Umbrella').factory('QuickLinkResource', [
        '$resource',
        $resource =>
            $resource(
                '/api/v1/quicklink/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', id: '' }
                    },
                    getAllActive: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'active', id: '' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'search', id: '@id' }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: { action: '', id: '' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: '', id: '' }
                    },
                    delete: {
                        method: 'DELETE',
                        isArray: false,
                        params: { action: '', id: '@id' }
                    },
                    updateOrder: {
                        method: 'Post',
                        isArray: true,
                        params: { action: 'updateOrder', id: '' }
                    }
                }
            )
    ]);
}
