/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard {
    export class Message {
        unitId: System.Guid;
        element: any;
    }

    @Service('CustomerService', 'UnitJitService')
    @Inject('UnitCardStore', 'UnitHub', 'ToastMessageService', 'UnitCardService')
    export class UnitJitService {
        private message: any;

        constructor(
            store: UnitCardStore,
            private unitHub: IUnitHubProxy,
            private toastMessageService: ToastMessageService,
            private unitCardService: UnitCardService
        ) {
            const selectUnitId = (state: UnitCardState) => state && state.id;

            unitHubOnUpdated$
                .filter(unitId => {
                    return selectUnitId(store.getState()) === unitId;
                })
                .subscribe(unitId => {
                    unitCardService.reload();
                    this.clearToastMessage();
                });

            unitHubOnStarted$
                .filter(unitId => {
                    return selectUnitId(store.getState()) === unitId;
                })
                .subscribe(model => {
                    if (this.message) {
                        return;
                    }

                    this.message = this.toastMessageService.updating('Eenheidgegevens', 60000, {
                        onHidden: () => {
                            this.message = null;
                        }
                    });

                    setTimeout(() => {
                        if (this.message) {
                            this.message = null;
                        }
                    }, 60000);
                });

            store.state$
                .map(selectUnitId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(unitId => this.triggerJitUpdate(unitId));

            store.event$.where(e => e.type === 'UnitCardUnloadedEvent').subscribe(_ => this.clearToastMessage());
        }

        private triggerJitUpdate(unitId: System.Guid) {
            this.clearToastMessage();

            if (unitId)
                this.unitHub.server.startJitUpdate(unitId).fail(e => {
                    this.clearToastMessage();
                    this.message = this.toastMessageService.error('Synchronisatie eenheidgegevens mislukt');
                });
        }

        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }
    }
}
