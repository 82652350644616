namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowOverviewModel = Umbrella.CaseFlow.CaseFlowOverviewModel;

    @Pipe('CaseFlow', 'groupByOwner')
    class GroupByOwnerFilter {
        transform(
            collection: CaseFlowOverviewModel[],
            property: string
        ): { [key: string]: CaseFlowOverviewModel[] } {
            const result: { [key: string]: CaseFlowOverviewModel[] } = {};
            if (collection) {
                angular.forEach(collection, (elm: CaseFlowOverviewModel) => {
                    const prop = elm.owner.toString();
                    if (!result[prop]) {
                        result[prop] = [];
                    }
                    result[prop].push(elm);
                });
            }
            return result;
        }
    }
}
