﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>
/// <reference path="../../Window.d.ts" />
/// <reference path="../../TaskHandling/_Overview/TaskOverviewService.ts"/>

namespace Umbrella.TaskHandling {
    import TaskOverviewService = Umbrella.TaskHandling.TaskOverviewService;
    import showTaskDetailView$ = Umbrella.TaskHandling.showTaskDetailView$;

    export const isTaskListFilterViewEnabled$ = new Rx.Subject();

    @Component('TaskHandling', {
        selector: 'task-overview',
        templateUrl: '/TaskHandling/_Overview/TaskOverview.html'
    })
    @Inject('TaskOverviewService')
    class TaskOverviewComponent {
        public showTaskDetailView: boolean;
        public tabletView: boolean;
        public isTaskListFilterViewEnabled: boolean;

        private taskListFilterViewSubscription: Rx.Disposable;
        private showTaskDetailViewObserver: Rx.IDisposable;

        constructor(private taskOverviewService: TaskOverviewService) {}

        $onInit() {
            if (
                window.device.mobile() ||
                (window.device.surface() && window.localStorage.getItem(DEVICE_STORAGE_KEY) === 'tablet_android') ||
                window.device.tablet()
            ) {
                this.showTaskDetailView = this.taskOverviewService.setDisplayTaskDetailView(
                    this.taskOverviewService.showTaskDetailView
                );
            } else {
                this.isTaskListFilterViewEnabled = true;
                this.showTaskDetailView = true;
            }

            window.device.tablet() ? (this.tabletView = true) : (this.tabletView = false);
            this.subscribeEvents();
        }

        private subscribeEvents() {
            this.showTaskDetailViewObserver = showTaskDetailView$.subscribe(value => {
                this.showTaskDetailView = value;
            });
            this.taskListFilterViewSubscription = isTaskListFilterViewEnabled$.subscribe(() => {
                this.isTaskListFilterViewEnabled = !this.isTaskListFilterViewEnabled;
            });
        }

        $onDestroy() {
            this.showTaskDetailViewObserver.dispose();
            this.taskListFilterViewSubscription.dispose();
        }
    }
}
