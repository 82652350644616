/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Decorators.ts" />
/// <reference path="../../../../Modules/Accounts/AccountService.ts" />

namespace Umbrella.CustomerService {
    import AgentFacetService = Modules.AgentFacetService;
    import EditCustomerDataComponent = Umbrella.CustomerService.CustomerCard.EditCustomerDataComponent;
    import CustomerCardPersonInfoService = CustomerService.CustomerCard.CustomerCardPersonInfoService;
    import NotificationPersonalPreferencesResource = Umbrella.CustomerService.CustomerCard.NotificationPersonalPreferencesResource;
    import PersonalPreferencesModel = Umbrella.Notifications.Preferences.PersonalPreferencesModel;

    @Component('CustomerService', {
        selector: 'customer-notification-settings',
        templateUrl:
            '/CustomerService/_CustomerCard/_Personal/CustomerNotificationSettingsComponent/CustomerNotificationSettings.html',
        bindings: {
            person: '<'
        }
    })
    @Inject(
        'NotificationPersonalPreferencesResource',
        'AgentFacetService',
        'ToastMessageService',
        'CustomerCardPersonInfoService'
    )
    class CustomerNotificationSettingsComponent extends EditCustomerDataComponent {
        public person: PersonModel;
        public personalPreferences: PersonalPreferencesModel;

        constructor(
            private notificationPersonalPreferencesResource: NotificationPersonalPreferencesResource,
            protected agentFacetService: AgentFacetService,
            protected toastMessageService: ToastMessageService,
            protected personInfoService: CustomerCardPersonInfoService
        ) {
            super(agentFacetService, toastMessageService, personInfoService);
        }

        public $onInit() {
            this.notificationPersonalPreferencesResource.get({ id: this.person.id }).$promise.then(prefences => {
                this.personalPreferences = prefences;
            });
        }
    }
}
