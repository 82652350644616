namespace Umbrella.CustomerService {
    @Pipe('CustomerService', 'addressType')
    class AddressTypeFilter {
        public transform(addressType: string | Umbrella.Modules.AddressType) {
            switch (addressType) {
                case 'Home':
                case Umbrella.Modules.AddressType.Home:
                    return 'Woonadres';
                case 'Postal':
                case Umbrella.Modules.AddressType.Postal:
                    return 'Postadres';
                default:
                    return 'Adres';
            }
        }
    }
}
