namespace Umbrella.Modules.Mail {
    import OutgoingMailResource = Umbrella.Modules.Mail.IOutgoingMailResourceClass;

    export interface PreviewOutgoingMailPopupButtonScope {
        title: string;
        outgoingMailActivityModel: Umbrella.Modules.Mail.OutgoingMailActivityModel;
        launchPreviewOutgoingMailPopup(): void;
    }

    @Controller('Mail', 'PreviewOutgoingMailPopupButtonController')
    @Inject('$scope', 'CreateDialog', 'OutgoingMailResource')
    export class PreviewOutgoingMailPopupButtonController {
        constructor(
            public $scope: PreviewOutgoingMailPopupButtonScope,
            createDialog,
            outgoingMailResource: OutgoingMailResource
        ) {
            $scope.launchPreviewOutgoingMailPopup = () => {
                outgoingMailResource
                    .get({
                        id: $scope.outgoingMailActivityModel.outgoingMailId
                    })
                    .$promise.then(mail =>
                        createDialog(
                            '/Modules/Umbrella/Admin/Mail/ViewMailPopup.html',
                            {
                                id: 'previewMailPopup',
                                modalClass: 'popup green popup_1100',
                                controller: 'ViewMailPopupController'
                            },
                            {
                                mail,
                                hideForwardButton: true
                            }
                        )
                    );
            };
        }
    }
}
