/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../ComponentRouteUtils.ts" />
/// <reference path="../../../Window.d.ts"/>
/// <reference path="../../customerservice.routeconfig.ts" />

namespace Umbrella.CustomerService.CustomerCard.CustomerSatisfaction {
    import PermissionsModel = Umbrella.PermissionsModel;

    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) =>
            $stateProvider.state('customerservice.customercard.customersatisfaction', {
                url: '/klantscore',
                reloadOnSearch: false,
                data: {
                    tab: 'Customer satisfaction',
                    authorize: (ps: PermissionsModel) => ps.customerSatisfactionRead
                },
                views: {
                    'tab@customerservice': {
                        template: '<customer-satisfaction state="state$ | async:this"></customer-satisfaction>',
                        controller: createComponentRouteController<
                            CustomerCardState,
                            CustomerSatisfactionComponentState
                        >('CustomerCardStore', s => ({
                            person: s && s.personal && s.personal.person,
                            loading: s && s.personal && s.personal.loading && s.registrationInfo && s.registrationInfo.loading,
                            customer: s && s.customerInfo && s.customerInfo.customer,
                            registrationInfo: s.registrationInfo && s.registrationInfo.registrations,
                        }))
                    }
                }
            })
    ]);
}
