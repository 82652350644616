/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-webstorage.d.ts" />
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella {
    export interface ILocalStorageService {
        get(key: string): any;
        store(key: string, value: any): void;
        remove(key: string): void;
    }

    @Service('Umbrella', 'LocalStorageService')
    @Inject('webStorage')
    export class LocalStorageService {
        constructor(
            private webStorage: angular.webstorage.IWebStorageService
        ) {}

        public get(key: string): any {
            if (this.webStorage === undefined)
                throw new Error(
                    'Not possible to retrieve data. WebStorage not defined.'
                );

            return JSON.parse(this.webStorage.get(key));
        }

        public store(key: string, value: any): void {
            if (this.webStorage === undefined)
                throw new Error(
                    'Not possible to store data. WebStorage not defined.'
                );

            this.webStorage.set(key, JSON.stringify(value));
        }

        public remove(key: string): void {
            if (this.webStorage === undefined)
                throw new Error(
                    'Not possible to remove data. WebStorage not defined.'
                );

            this.webStorage.remove(key);
        }
    }
}
