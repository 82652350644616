namespace Umbrella {
    import ChatState = Umbrella.ChatConversationHandling.ChatState;
    import isAcwConversation = ChatConversationHandling.isAcwConversation;
    import ChatStore = Umbrella.ChatConversationHandling.ChatStore;
    import RegisterStatus = Umbrella.ChatConversationHandling.RegisterStatus;
    import RestoreChannelService = Modules.RestoreChannelService;

    @Component('Umbrella', {
        selector: 'conversation-bar',
        templateUrl: '/Top/ConversationBarComponent/ConversationBar.html'
    })
    @Inject('$filter', 'TopBarStore', 'ChatStore', 'RestoreChannelService')
    class ConversationBarComponent {
        public impersonationInfo: Impersonation.State;
        public telephonyInfo: Telephony.State;
        public chatState: ChatState;
        public acwState: boolean;
        public stateClass = '';

        private onHoldCallsFilter: (
            input: Umbrella.TelephonyControl.PhoneCallModel[]
        ) => Umbrella.TelephonyControl.PhoneCallModel[];
        private ongoingCallsFilter: (
            input: Umbrella.TelephonyControl.PhoneCallModel[]
        ) => Umbrella.TelephonyControl.PhoneCallModel[];

        private phoneObserver: Rx.IDisposable;
        private impersonationObserver: Rx.IDisposable;
        private chatObserver: Rx.IDisposable;
        private mailObserver: Rx.IDisposable;

        constructor(
            $filter: ng.IFilterService,
            private topBarStore: TopBarStore,
            private chatStore: ChatStore,
            private restoreChannelService: RestoreChannelService
        ) {
            this.onHoldCallsFilter = <any>$filter('onHoldCalls');
            this.ongoingCallsFilter = <any>$filter('ongoingCalls');
        }

        public $onInit() {
            const notNullOrUndefined = x => x !== null && x !== undefined;

            this.impersonationObserver = this.topBarStore.state$
                .map(x => x && x.impersonationInfo)
                .filter(notNullOrUndefined)
                .distinctUntilChanged()
                .subscribe(x => {
                    this.impersonationInfo = x;
                    this.applyColoringAccordingToImpersonation(this.impersonationInfo);
                });

            this.phoneObserver = this.topBarStore.state$
                .map(x => x && x.telephonyInfo)
                .filter(notNullOrUndefined)
                .distinctUntilChanged()
                .subscribe(x => {
                    this.telephonyInfo = x;

                    const isTelephonyRegistered = this.telephonyInfo && this.telephonyInfo.phone;
                    const isRegisteredForChat =
                        this.chatState &&
                        this.chatState.registration &&
                        this.chatState.registration.status === RegisterStatus.Registered;

                    if (!this.isImpersonationModeEnabled() && !isRegisteredForChat && isTelephonyRegistered)
                        this.applyColoringAccordingToTelephony(this.telephonyInfo);
                });

            this.chatObserver = this.chatStore.state$
                .map(x => x)
                .filter(notNullOrUndefined)
                .distinctUntilChanged()
                .subscribe(x => {
                    this.chatState = x;

                    const isChatRegistered =
                        this.chatState &&
                        this.chatState.registration &&
                        this.chatState.registration.status === RegisterStatus.Registered;

                    if (
                        this.isImpersonationModeEnabled() &&
                        isChatRegistered &&
                        this.chatState.conversations &&
                        !this.chatState.conversations.length
                    ) {
                        this.applyColoringAccordingToImpersonation(this.impersonationInfo);
                        return;
                    }

                    if (isChatRegistered) {
                        this.applyColoringAccordingToChat(this.chatState);
                        return;
                    }

                    const notLoggedInOnTelephony =
                        this.telephonyInfo &&
                        this.telephonyInfo.availabilityStatus ===
                            Umbrella.TelephonyControl.AvailabilityStatus.NotLoggedIn;
                    if (notLoggedInOnTelephony && !this.isImpersonationModeEnabled()) this.stateClass = '';
                });

            this.restoreChannelService.restoreSavedChannel();
        }

        public $onDestroy() {
            if (this.impersonationObserver) {
                this.impersonationObserver.dispose();
                this.impersonationObserver = null;
            }

            if (this.phoneObserver) {
                this.phoneObserver.dispose();
                this.phoneObserver = null;
            }

            if (this.chatObserver) {
                this.chatObserver.dispose();
                this.chatObserver = null;
            }

            if (this.mailObserver) {
                this.mailObserver.dispose();
                this.mailObserver = null;
            }
        }

        private applyColoringAccordingToImpersonation(impersonationInfo: Impersonation.State) {
            if (impersonationInfo.enabled || (this.impersonationInfo && this.impersonationInfo.enabled)) {
                document.getElementsByClassName('approot')[0].classList.add('impression-container');
                this.stateClass = 'purple';
                return;
            }

            document.getElementsByClassName('approot')[0].classList.remove('impression-container');

            if (this.telephonyInfo && this.telephonyInfo.phone && this.telephonyInfo.availabilityStatus !== 2) {
                this.applyColoringAccordingToTelephony(this.telephonyInfo);
                return;
            }

            if (this.chatState && this.chatState.registration && this.chatState.registration.status !== 0) {
                this.applyColoringAccordingToChat(this.chatState);
                return;
            }

            this.stateClass = '';
        }

        private applyColoringAccordingToChat(chatState: ChatState) {
            const activeConversation =
                chatState &&
                chatState.conversations &&
                ((chatState.activeId && chatState.conversations.find(x => x.id === chatState.activeId)) ||
                    (chatState.conversations.length === 1 && chatState.conversations[0]));
            const isConversationInACWState = activeConversation && isAcwConversation(activeConversation.status);

            this.acwState = isConversationInACWState;

            if (isConversationInACWState) {
                this.stateClass = 'red';
                return;
            }

            const hasPickedupConversations =
                chatState &&
                chatState.conversations &&
                chatState.conversations.filter(x => !!x.id && x.operator).length;
            if (hasPickedupConversations) {
                this.stateClass = 'green';
                return;
            }

            if (this.isImpersonationModeEnabled()) {
                this.applyColoringAccordingToImpersonation(this.impersonationInfo);
                return;
            }

            this.stateClass = '';
        }

        private applyColoringAccordingToTelephony(telephonyInfo: Telephony.State) {
            const phone = telephonyInfo.phone;
            if (!phone) return;

            if (phone.status && (phone.status.toString() === 'ACW' || phone.status === 5)) {
                this.stateClass = 'red';
                return;
            }

            if (!phone.calls) return;

            const ongoingCalls = this.ongoingCallsFilter(phone.calls);
            const onholdCalls = this.onHoldCallsFilter(phone.calls);
            if (ongoingCalls && ongoingCalls.length) {
                this.stateClass = 'green';

                if (onholdCalls && onholdCalls.length === ongoingCalls.length) this.stateClass = 'orange';

                return;
            }

            this.stateClass = '';
        }

        private isImpersonationModeEnabled() {
            return this.impersonationInfo && !!this.impersonationInfo.enabled;
        }
    }
}
