﻿/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../dashboard.tasks.routeconfig.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import TaskOverviewItemModel = Modules.Tasks.TaskOverviewItemModel;

    export interface TaskListComponentState {
        loading: boolean;
        tasks: PagedItemsModel<TaskOverviewItemModel>;
        selectedTask: TaskOverviewItemModel;
    }

    @Component('TaskHandling', {
        selector: 'task-list',
        templateUrl: '/TaskHandling/_Overview/_List/TaskListComponent/TaskList.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$stateParams', '$location', 'TaskOverviewService')
    class TaskListComponent {
        public state: TaskListComponentState;
        public page = this.$stateParams.page || 0;
        public pageSize = this.$stateParams.pageSize || 9;
        public navPage = this.page + 1;
        public width = window.innerWidth;

        constructor(
            private $stateParams: TaskOverviewStateParams,
            private $location: ng.ILocationService,
            private taskOverviewService: TaskOverviewService
        ) {}

        public pageChanged(newPage): void {
            this.page = newPage;
            const query = this.$location.search();
            this.$location.search({ ...query, page: this.page });
        }

        public async requestMoreTasks(pageSize: number) {
            // HACK: Early return when there are no tasks left, fixes issue when remaining task is completed but not removed from the list (because Elastic hasn't deleted it yet)
            if (this.state.tasks.total === 0) return;

            await this.taskOverviewService.load(pageSize);
        }
    }
}
