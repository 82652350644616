/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.Housing {
    import ContractModel = Modules.Housing.ContractModel;

    @Component('CustomerService', {
        selector: 'contract-timeline',
        templateUrl:
            '/CustomerService/_CustomerCard/_Contracts/ContractTimelineComponent/ContractTimeline.html',
        bindings: {
            contracts: '<',
            person: '<',
            loading: '<',
            onLoadMore: '&'
        }
    })
    export class ContractTimelineComponent {
        public contracts: PagedItemsModel<ContractModel>;
        public person: PersonModel;
        public loading: boolean;
        public onLoadMore: () => void;

        public loadMore() {
            this.onLoadMore();
        }
    }
}
