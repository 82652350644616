/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scope.d.ts"/>
/// <reference path="../Window.d.ts" />

namespace Umbrella.Modules {
    angular
        .module('Umbrella')

        .run([
            '$rootScope',
            '$state',
            '$stateParams',
            (
                $rootScope: IUmbrellaRootScope,
                $state: ng.ui.IStateService,
                $stateParams: ng.ui.IStateParamsService
            ) => {
                $rootScope.user = window.user;
                $rootScope.$state = $state;
                $rootScope.$stateParams = $stateParams;
                $rootScope.isloading = false;

                $rootScope.$on(
                    '$stateChangeStart',
                    (
                        event: ng.IAngularEvent,
                        toState: ng.ui.IState,
                        toParams: ng.ui.IStateParamsService,
                        fromState: ng.ui.IState,
                        fromParams: ng.ui.IStateParamsService
                    ) => {
                        $rootScope.isloading = true;
                    }
                );

                $rootScope.$on(
                    '$stateChangeSuccess',
                    (
                        event: ng.IAngularEvent,
                        toState: ng.ui.IState,
                        toParams: ng.ui.IStateParamsService,
                        fromState: ng.ui.IState,
                        fromParams: ng.ui.IStateParamsService
                    ) => {
                        $rootScope.isloading = false;
                    }
                );

                $rootScope.$on(
                    '$stateChangeError',
                    (
                        event: ng.IAngularEvent,
                        toState: ng.ui.IState,
                        toParams: ng.ui.IStateParamsService,
                        fromState: ng.ui.IState,
                        fromParams: ng.ui.IStateParamsService,
                        error
                    ) => {
                        $rootScope.isloading = false;
                    }
                );

                $rootScope.safeApply = function(fn) {
                    let phase = (this.$root || this).$$phase;
                    if (phase === '$apply' || phase === '$digest') {
                        if (fn && typeof fn === 'function') {
                            fn();
                        }
                    } else {
                        this.$apply(fn);
                    }
                };
            }
        ]);
}
