/// <reference path="../../../../Decorators.ts" />
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts" />

namespace Umbrella.CustomerService.UnitCard {
    import UnitModel = Modules.Housing.UnitModel;

    @Component('CustomerService', {
        selector: 'unitcard-sidebar-unit-info',
        templateUrl:
            '/CustomerService/_UnitCard/Sidebar/UnitCardSidebarUnitInfoComponent/UnitCardSidebarUnitInfo.html',
        bindings: {
            unit: '<'
        }
    })
    class UnitCardSidebarUnitInfoComponent {
        public unit: UnitModel;
    }
}
