/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts" />
/// <reference path="../../Scope.d.ts" />

namespace Umbrella.Modules.Accounts {
    import Resource = ng.resource.IResource;
    import AccountModel = Umbrella.Accounts.AccountModel;

    interface AccountRequestParams {
        id?: string;
        action?: string;
        subaction?: string;
    }

    export interface EmailRequestParam {
        email: string;
    }

    export interface AccountResource
        extends ng.resource.IResourceClass<Resource<AccountModel>> {
        getByPersonId(
            params: IdRequestParams
        ): Resource<Umbrella.Accounts.AccountModel.Activation>;
        getPaged(
            params: ResourceSearchParams
        ): Resource<PagedItemsModel<Umbrella.Accounts.AccountModel>>;
        block(
            params: IdRequestParams
        ): Resource<Umbrella.Accounts.AccountModel.Activation>;
        unblock(
            params: IdRequestParams
        ): Resource<Umbrella.Accounts.AccountModel.Activation>;
        startRecovery(params: EmailRequestParam): Resource<{}>;
        createAccounts(): Resource<{}>;
        getBlockedAccounts(
            params: PagedRequestParams
        ): Resource<PagedItemsModel<Umbrella.Accounts.AccountModel>>;
        deleteAccount(params: IdRequestParams);
    }

    class AccountResourceBuilder {
        static $inject = ['$resource'];

        constructor(public $resource: ng.resource.IResourceService) {}

        getAccountResource(): AccountResource {
            return this.$resource<AccountModel, AccountResource>(
                '/api/v1/account/:action/:id/:subaction',
                {},
                {
                    getByPersonId: this.getResource('GET', false, {
                        id: '@id'
                    }),
                    getPaged: this.getResource('GET', false, {
                        action: 'paged'
                    }),
                    block: this.getResource('PUT', false, {
                        action: 'block',
                        id: '@id'
                    }),
                    unblock: this.getResource('PUT', false, {
                        action: 'unblock',
                        id: '@id'
                    }),
                    startRecovery: this.getResource('POST', false, {
                        action: 'recover', subaction: 'start' }),
                    createAccounts: this.getResource('POST', false, {
                        action: 'provision'
                    }),
                    getBlockedAccounts: this.getResource('GET', false, {
                        action: 'blocked'
                    }),
                    deleteAccount: this.getResource('DELETE', false, {
                        id: '@id'
                    })
                }
            );
        }

        private getResource(
            method: string,
            isArray: boolean,
            params?: AccountRequestParams,
            headers?: any
        ): ng.resource.IActionDescriptor {
            return { method, isArray, params, headers };
        }
    }

    angular
        .module('Umbrella')
        .service('AccountResource', [
            '$resource',
            ($resource: any) =>
                new AccountResourceBuilder($resource).getAccountResource()
        ]);
}
