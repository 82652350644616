/// <reference path="../../CustomerCardPersonInfoService.ts"/>
/// <reference path="../../../../Modules/Umbrella/ToastMessageService.ts"/>

namespace Umbrella.CustomerService.CustomerCard {
    import AnnouncementResourceClass = Umbrella.Modules.Announcements.AnnouncementResourceClass;
    import AnnoucementModel = Umbrella.Modules.Announcements.AnnouncementModel;

    @Component('CustomerService', {
        selector: 'register-announcement-popup',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/RegisterAnnouncementPopupComponent/RegisterAnnouncementPopup.html',
        bindings: {
            personId: '@',
            residentialZoneId: '@'
        }
    })
    @Inject(
        '$mdDialog',
        'EmptyGuid',
        'AnnouncementResource',
        'ToastMessageService'
    )
    class RegisterAnnouncementPopupComponent {
        private personId: System.Guid;
        private residentialZoneId: System.Guid;

        public announcement: AnnoucementModel.Edit;

        constructor(
            private $mdDialog,
            private emptyGuid: System.Guid,
            private announcementResource: AnnouncementResourceClass,
            private toastMessageService: ToastMessageService
        ) {}

        public $onInit() {
            this.announcement = {
                id: this.emptyGuid,
                created: new Date(),
                createdBy: null,
                description: '',
                endDate: null,
                isExternal: false,
                lastModifiedBy: null,
                modified: null,
                order: 0,
                personIds: this.personId ? [this.personId] : [],
                properties: null,
                residentialZoneIds: this.residentialZoneId
                    ? [this.residentialZoneId]
                    : [],
                startDate: null,
                title: '',
                url: '',
                applicationIds: null
            };
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public save(): void {
            this.announcement.created = new Date();

            this.announcementResource
                .save(this.announcement)
                .$promise.then(() => {
                    this.toastMessageService.success(
                        'Mededeling succesvol vastgelegd'
                    );
                    this.$mdDialog.hide();
                })
                .catch(() =>
                    this.toastMessageService.error(
                        'Fout opgetreden tijdens vastleggen van de mededeling'
                    )
                );
        }

        public resetStartDate() {
            this.announcement.startDate = null;
        }

        public resetEndDate() {
            this.announcement.endDate = null;
        }
    }
}
