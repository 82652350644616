/// <reference path="../../Decorators.ts" />

namespace Umbrella.CustomerService {
    export interface CustomerServiceComponentState {
        impersonationInfo: Impersonation.State;
    }

    export const isCustomerFilterViewEnabled$ = new Rx.Subject();
    export const toggleAdditionalInformationView$ = new Rx.Subject();
    @Component('CustomerService', {
        selector: 'customer-service',
        templateUrl: '/CustomerService/CustomerServiceComponent/CustomerService.html',
        bindings: {
            state: '<'
        }
    })
    class CustomerServiceComponent {
        public state: CustomerServiceComponentState;
        public displayAdditionalInformationView: boolean;
        public isCustomerFilterViewEnabled: boolean;

        private isCustomerFilterViewSubscription: Rx.Disposable;
        private additionalInformationViewSubscription: Rx.Disposable;

        $onInit() {
            this.subscribeEvents();

            if (!(getStoredDeviceMode() === DEVICE_MODE.MOBILE) && !(getStoredDeviceMode() === DEVICE_MODE.TABLET)) {
                this.displayAdditionalInformationView = true;
                this.isCustomerFilterViewEnabled = true;
            }
        }

        public $onDestroy() {
            this.unsubscribeEvents();
        }

        private subscribeEvents(): void {
            this.additionalInformationViewSubscription = toggleAdditionalInformationView$.subscribe(() => {
                if (getStoredDeviceMode() === DEVICE_MODE.DESKTOP) {
                    this.displayAdditionalInformationView = true;
                } else {
                    this.displayAdditionalInformationView = !this.displayAdditionalInformationView;
                }
            });
            this.isCustomerFilterViewSubscription = isCustomerFilterViewEnabled$.subscribe(() => {
                if (getStoredDeviceMode() === DEVICE_MODE.DESKTOP) {
                    this.isCustomerFilterViewEnabled = true;
                } else {
                    this.isCustomerFilterViewEnabled = !this.isCustomerFilterViewEnabled;
                }
            });
        }

        private unsubscribeEvents() {
            if (this.additionalInformationViewSubscription) {
                this.additionalInformationViewSubscription.dispose();
                this.additionalInformationViewSubscription = null;
            }
            if (this.isCustomerFilterViewSubscription) {
                this.isCustomerFilterViewSubscription.dispose();
                this.isCustomerFilterViewSubscription = null;
            }
        }
    }
}
