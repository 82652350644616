namespace Umbrella.Modules {
    interface NotifyProperties {
        body: string;
        tag: string;
        icon: string;
        notifyShow?: any;
        notifyClose?: any;
        notifyClick?: any;
        notifyError?: any;
        timeout?: number;
        closeOnClick: boolean;
    }

    declare class Notify {
        constructor(name: string, properties: NotifyProperties);
        show(): void;
        close(): void;

        static needsPermission: boolean;
        static isSupported(): boolean;
        static requestPermission(): void;
    }

    declare class Visibility {
        static isSupported(): boolean;
        static change(onChange: () => void): void;
        static hidden(): boolean;
    }

    @Service('Umbrella', 'NotificationService')
    export class NotificationService {
        private myNotification: Notify = null;

        constructor() {
            if (Notify && Notify.needsPermission && Notify.isSupported())
                Notify.requestPermission();

            if (Visibility && Visibility.isSupported()) {
                Visibility.change(() => {
                    if (!Visibility.hidden() && this.myNotification)
                        this.myNotification.close();
                });
            }
        }

        public show(message: string, tag: string) {
            this.hide();
            this.myNotification = new Notify('Umbrella', {
                body: message,
                tag,
                icon: '/images/umbrella-logo-black-80x80-2018.png',
                closeOnClick: true
            });
            this.myNotification.show();
        }

        public hide() {
            if (!Notify.needsPermission && this.myNotification)
                this.myNotification.close();
        }
    }
}
