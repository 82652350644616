﻿@Component('Umbrella', {
    selector: 'tenant-role-label',
    template: `
        <role-label label-text="HU" label-title="Huurder" ng-if="vm.isActive()"></role-label>
        <role-label label-text="OH" label-title="Oud huurder" ng-if="vm.isInActive()"></role-label>
        <role-label label-text="TH" label-title="Toekomstig huurder" ng-if="vm.isFuture()"></role-label>
    `,
    bindings: {
        customerStatus: '@'
    }
})
class TenantRoleLabelComponent {
    public customerStatus: string;

    public isActive(): boolean {
        return this.customerStatus === 'Active' || this.customerStatus === '0';
    }

    public isInActive(): boolean {
        return this.customerStatus === 'InActive' || this.customerStatus === '2';
    }

    public isFuture(): boolean {
        return this.customerStatus === 'Future' || this.customerStatus === '1';
    }
}
