﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>

namespace Umbrella.CustomerService.ComplexCard {
    export interface ComplexCardStateParams extends ng.ui.IStateParamsService {
        complexId: System.Guid;
    }

    angular.module('CustomerService').config([
        '$stateProvider',
        '$urlRouterProvider',
        (
            $stateProvider: angular.ui.IStateProvider,
            $urlRouterProvider: angular.ui.IUrlRouterProvider
        ) => {
            $urlRouterProvider.when(
                '/complexkaart/:complexId',
                '/complexkaart/:complexId/tijdlijn'
            );

            $stateProvider
                .state('customerservice.complexcard', {
                    abstract: true,
                    reloadOnSearch: false,
                    url: '/complexkaart/:complexId',
                    data: {
                        pageTitle: 'Complexkaart'
                    },
                    views: {
                        'right@customerservice': {
                            templateUrl:
                                '/CustomerService/_ComplexCard/ComplexCardSidebar.html',
                            controller: 'ComplexCardSidebarController'
                        }
                    }
                })
                .state('customerservice.complexcard.units', {
                    reloadOnSearch: false,
                    url: '/eenheden',
                    data: {
                        authorize: ps => ps.customerCard
                    },
                    views: {
                        'tab@customerservice': {
                            template: '<complex-units></complex-units>'
                        }
                    }
                })
                .state('customerservice.complexcard.activities', {
                    reloadOnSearch: false,
                    url: '/tijdlijn?createtask',
                    data: {
                        authorize: ps => ps.customerCard && ps.caseRead
                    },
                    views: {
                        'tab@customerservice': {
                            template:
                                '<complex-activities></complex-activities>'
                        }
                    }
                })
                .state('customerservice.complexcard.announcements', {
                    reloadOnSearch: false,
                    url: '/mededelingen',
                    data: {
                        authorize: ps => ps.customerCard && ps.announcementRead
                    },
                    views: {
                        'tab@customerservice': {
                            template:
                                '<complex-announcements></complex-announcements>'
                        }
                    }
                });
        }
    ]);
}
