namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'canPickupCall')
    class CanPickupCallFilter {
        public transform(
            status: Umbrella.Telephony.PhoneCallStatus | string
        ): boolean {
            if (!status) return false;

            switch (status.toString().toLowerCase()) {
                case '104':
                case 'trypickup':
                    return false;
                default:
                    return true;
            }
        }
    }
}
