﻿/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Scope.d.ts"/>

namespace Umbrella.CustomerService.ComplexCard {
    import ComplexModel = Umbrella.Modules.Housing.ComplexModel;
    import UnitModel = Umbrella.Modules.Housing.UnitModel;
    import IComplexResource = Umbrella.Modules.Housing.IComplexResource;
    import IUnitResource = Umbrella.Modules.Housing.Units.IUnitResource;

    @Component('CustomerService', {
        selector: 'complex-units',
        templateUrl:
            'CustomerService/_ComplexCard/_Units/ComplexUnitsComponent/ComplexUnits.html'
    })
    @Inject('$stateParams', 'ComplexResource', 'UnitResource')
    class ComplexUnitsComponent {
        public loadingUnits: boolean;
        public complex: ComplexModel;
        public units: UnitModel.WithCustomerAndContract[];
        public currentYear: number;
        private complexId: System.Guid;

        constructor(
            private $stateParams: ComplexCardStateParams,
            private complexResource: IComplexResource,
            private unitResource: IUnitResource
        ) {}

        public $onInit() {
            this.currentYear = new Date().getFullYear();
            this.complexId = this.$stateParams.complexId;

            this.loadComplex();
            this.loadUnits();
        }

        private async loadComplex(): Promise<void> {
            this.complex = await this.complexResource.getById({
                id: this.complexId
            }).$promise;
        }

        private async loadUnits(): Promise<void> {
            this.loadingUnits = true;
            this.units = await this.unitResource.getAllByComplexId({
                id: this.complexId
            }).$promise;
            this.loadingUnits = false;
        }
    }
}
