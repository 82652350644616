namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import BaseTagModel = Modules.Knowledgebase.BaseTagModel;
    @Component('Knowledgebase', {
        selector: 'card-knowledgebase-faq-bread-crumb',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/CardKnowledgebaseFaqBreadCrumbComponent/CardKnowledgebaseFaqBreadCrumb.html',
        bindings: {
            faq: '<',
            onTagSelected: '&'
        }
    })
    class CardKnowledgebaseFaqBreadCrumbComponent {
        public onTagSelected: (args: { value: BaseTagModel }) => void;
        public faq: Modules.Knowledgebase.FaqModel;

        public tagWithParents(tag: BaseTagModel): BaseTagModel[] {
            return Modules.Knowledgebase.tagWithParents(tag);
        }

        public tagClicked(tag: BaseTagModel) {
            this.onTagSelected({ value: tag });
        }
    }
}
