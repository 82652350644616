namespace Umbrella.ChatConversationHandling {
    @Pipe('Chat', 'isChatAvailable')
    class IsChatAvailableFilter {
        public transform(input: ChatState): boolean {
            if (input && input.registration && input.registration.status === RegisterStatus.Registered) return true;

            return false;
        }
    }
}
