namespace Umbrella.Modules.Colleagues {
    @Pipe('Colleagues', 'presencePhoneIcon')
    class PresencePhoneIconFilter {
        public transform(stateType: StateType | string | number) {
            switch (stateType) {
                case StateType.Available:
                case 'Available':
                case 1:
                    return 'phone_in_talk';
                case StateType.Unavailable:
                case 'Unavailable':
                case 2:
                    return 'phone_paused';
                case StateType.Busy:
                case 'Busy':
                case 3:
                    return 'phone_locked';
                default:
                    return 'phone';
            }
        }
    }
}
