/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.TaskHandling {
    export const taskSelected$ = new Rx.Subject<String>();

    angular
        .module('TaskHandling')
        .filter('hoursagoClass', function() {
            return function(hours: number, warningInXHours: number = -4) {
                if (hours > 0) {
                    return '#f55753'; //@color-danger
                } else if (hours <= 0 && hours >= warningInXHours) {
                    return '#fda12a'; //@color-warning
                }

                return '';
            };
        })
        .filter('toArray', function() {
            return function(obj, addKey) {
                if (!angular.isObject(obj)) return obj;

                if (addKey === false) {
                    return Object.keys(obj).map(function(key) {
                        return obj[key];
                    });
                } else {
                    return Object.keys(obj).map(function(key) {
                        let value = obj[key];
                        return angular.isObject(value)
                            ? Object.defineProperty(value, '$key', {
                                  enumerable: false,
                                  value: key
                              })
                            : { $key: key, $value: value };
                    });
                }
            };
        });
}
