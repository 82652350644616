namespace Umbrella.CustomerService {
    import ActivityModel = Modules.ActivityModel;

    @Pipe('CustomerService', 'activityColor')
    class ActivityColorFilter {
        public transform(activity: ActivityModel): string {
            const activityName =
                activity &&
                activity.type &&
                activity.type.replace('Activity', '').toLowerCase();

            if (!activityName) return '';
            if (
                activityName.substr(0, 4) === 'taak' ||
                activityName.substr(0, 4) === 'task'
            )
                return 'green';

            switch (activityName) {
                case 'contact':
                case 'outgoingmail':
                case 'phonecall':
                    return 'green';
                case 'correspondence':
                case 'document':
                case 'initialinspectionreportsubmitted':
                case 'finalinspectionreportsubmitted':
                case 'stateofcommencementreportsubmitted':
                    return 'orange';
                default:
                    return 'purple';
            }
        }
    }
}
