/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.Modules.Colleagues {

    export function getColleagueEmail(emails: EmailAddressModel[]) : string {
        if (!emails) return '';

        return emails.reduce((previousValue, currentValue) => {
            if(!currentValue.email) {
                return previousValue;  
            }

            return currentValue.email
        }, '') 
    };
}