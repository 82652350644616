namespace Umbrella.Modules.Mail {
    export class ResendOutgoingMailPopupButtonDirective
        implements angular.IDirective {
        restrict = 'EA';
        templateUrl =
            '/Modules/Mail/Directives/ResendOutgoingMailPopupButton.html';
        replace = true;
        controller = ResendOutgoingMailPopupButtonController;
        scope = {
            title: '@',
            outgoingMailActivityModel: '='
        };

        static factory(): angular.IDirectiveFactory {
            return () => new ResendOutgoingMailPopupButtonDirective();
        }
    }

    angular
        .module('Mail')
        .directive(
            'resendOutgoingMailPopupButton',
            ResendOutgoingMailPopupButtonDirective.factory()
        );
}
