﻿/// <reference path="../../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    unitContractHub: Umbrella.CustomerService.UnitCard.Contracts.IUnitContractHubProxy;
}

namespace Umbrella.CustomerService.UnitCard.Contracts {
    import ContractModel = Modules.Housing.ContractModel;

    export interface IUnitContractHubProxy extends HubProxy {
        client: IUnitContractHubClient;
        server: IUnitContractHubServer;
    }

    export interface IUnitContractHubClient {
        updated(unitId: System.Guid);
        started(unitId: System.Guid);
        contractUpdated(contractId: System.Guid);
    }

    export interface IUnitContractHubServer {
        subscribe(unitId: System.Guid);
        unsubscribe(unitId: System.Guid);
    }    

    export const unitContractHubOnUpdated$ = new Rx.Subject<System.Guid>();
    export const unitContractHubOnStarted$ = new Rx.Subject<System.Guid>();
    export const unitContractHubOnContractUpdated$ = new Rx.Subject<System.Guid>();

    angular
        .module('CustomerService')
        .factory('UnitContractHub', () => $.connection.unitContractHub);

    Modules.onSignalRInitialized(() => {
        $.connection.unitContractHub.client.updated = unitId => {
            unitContractHubOnUpdated$.onNext(unitId);
        };

        $.connection.unitContractHub.client.started = unitId => {
            unitContractHubOnStarted$.onNext(unitId);
        };

        $.connection.unitContractHub.client.contractUpdated = contractId => {
            unitContractHubOnContractUpdated$.onNext(contractId);
        };
    });
}
