﻿/// <reference path="../../Decorators.ts" />

namespace Umbrella.CustomerService {
    @Component('CustomerService', {
        selector: 'multiline-prompt-dialog',
        bindings: {
            theme: '@',
            title: '@',
            textContent: '@',
            placeholder: '@?',
            ariaLabel: '@',
            maxRows: '@?',
            cancelText: '@?',
            confirmText: '@?',
            required: '<'
        },
        templateUrl:
            '/CustomerService/MultiLinePromptDialogComponent/MultiLinePromptDialog.html'
    })
    @Inject('$mdDialog')
    export class MultiLinePromptDialogComponent {
        public defaultTheme = 'umb-blue';
        public maxRows = 3;
        public cancelText = 'Annuleren';
        public confirmText = 'Bevestigen';
        public result: string;

        constructor(private $mdDialog) {}

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public confirm(): void {
            this.$mdDialog.hide(this.result);
        }
    }
}
