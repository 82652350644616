namespace Umbrella.CustomerService.CustomerCard.Registrations {
    import RegistrationModelDetailed = Umbrella.HousingAllocation.Registrations.Detailed;
    import UpdateRegistrationModel = Umbrella.HousingAllocation.Registrations.UpdateRegistrationModel;
    import RegistrantInfoModel = Umbrella.HousingAllocation.Registrations.RegistrantInfoModel;

    export interface RegistrationsResource {
        getDetailedRegistrationsByPerson(
            param: IdRequestParams
        ): ng.resource.IResource<RegistrationModelDetailed[]>;
        update(registration: UpdateRegistrationModel): ng.resource.IResource<RegistrationModelDetailed>;
        delete(registrationId: Guid);
        getRegistrantInfoByPerson(
            param: IdRequestParams
        ): ng.resource.IResource<RegistrantInfoModel>;
    }

    angular.module('CustomerService').service('RegistrationsResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) => {
            return $resource(
                '/housingallocation/api/v1/registration/agent/:action/:id',
                {},
                {
                    getDetailedRegistrationsByPerson: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'byPerson', id: '@id' }
                    },

                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: {action: 'update'}
                    },

                    delete: {
                        method: 'DELETE',
                        isArray: false,
                        params: { }
                    },

                    getRegistrantInfoByPerson: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'getRegistrantInfoByPerson', id: '@id' }
                    }
                }
            );
        }
    ]);
}
