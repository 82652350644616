/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Decorators.ts" />

namespace Umbrella.Modules.Sandbox.Dashboard {
    @Component('Sandbox', {
        selector: 'dialog-component',
        templateUrl: '/Sandbox/_Dashboard/Dialog.html'
    })
    @Inject('$mdDialog')
    class DialogComponent {
        constructor(private $mdDialog) {}

        public alert(theme): void {
            const dialog = this.$mdDialog
                .alert()
                .clickOutsideToClose(true)
                .title('Taak kan niet aangepast worden')
                .textContent('Dit type taak kan helaas niet aangepast worden.')
                .ariaLabel('Alert Dialog')
                .ok('OK')
                .theme(theme);

            this.$mdDialog.show(dialog);
        }

        public confirm(theme): void {
            const dialog = this.$mdDialog
                .confirm()
                .title('Taak verwijderen')
                .textContent('Weet je zeker dat je deze taak wilt verwijderen?')
                .ariaLabel('Confirm')
                .ok('Ja')
                .cancel('Nee')
                .theme(theme);

            this.$mdDialog.show(dialog);
        }

        public prompt(theme): void {
            const dialog = this.$mdDialog
                .prompt()
                .title('Taak afronden')
                .textContent('Beschrijf hoe deze taak is afgehandeld.')
                .placeholder('Omschrijving')
                .ariaLabel('Omschrijving')
                .initialValue('')
                .required(true)
                .ok('Bevestigen')
                .cancel('Annuleren')
                .theme(theme);

            this.$mdDialog.show(dialog).then((description: string) => {
                console.log(description);
            });
        }

        public multilinePrompt(theme): void {
            this.$mdDialog
                .show({
                    template:
                        `<multiline-prompt-dialog 
                                theme=` +
                        theme +
                        ` 
                                placeholder="Omschrijving" 
                                aria-label="Omschrijving" 
                                required="true" 
                                title="Taak afronden" 
                                text-content="Beschrijf hoe deze taak is afgehandeld.">
                            </multiline-prompt-dialog>`,
                    targetEvent: null,
                    clickOutsideToClose: true
                })
                .then((description: string) => {
                    console.log(description);
                });
        }

        public customDialog(theme): void {
            this.$mdDialog.show({
                theme,
                template:
                    '<relation-popup md-theme=' + theme + '></relation-popup>',
                targetEvent: null,
                clickOutsideToClose: false
            });
        }
    }
}
