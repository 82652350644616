/// <reference path="../../../CustomerService/_CustomerCard/CustomerCardStore.ts"/>

namespace Umbrella.Modules.Chat {
    import ChatStore = Umbrella.ChatConversationHandling.ChatStore;
    import ChatService = Umbrella.ChatConversationHandling.ChatService;

    @Component('Chat', {
        selector: 'chat-attachment',
        templateUrl: '/Modules/Chat/AttachmentComponent/Attachment.html',
        bindings: {
            attachment: '<'
        }
    })
    @Inject('$state', 'ChatStore', 'ChatService')
    export class AttachmentComponent {
        public attachment: any;
        constructor(
            private $state: angular.ui.IStateService,
            private chatStore: ChatStore,
            private chatService: ChatService
        ) {}

        public isAudio(): boolean {
            switch (this.attachment.mimeType) {
                case 'audio/ogg; codecs=opus':
                case 'audio/wave':
                case 'audio/mpeg':
                    return true;
            }
            return false;
        }

        public isImage(): boolean {
            return this.attachment.mimeType === 'image/jpeg';
        }

        public isMovie(): boolean {
            return this.attachment.mimeType === 'video/mp4';
        }

        public getMimeType() {
            return this.attachment.mimeType;
        }

        public isFile(): boolean {
            return !this.isImage() && !this.isMovie() && !this.isAudio();
        }

        public getMediaUrl() {
            return Umbrella.Config.makeAbsoluteApiUrl(
                `/api/v1/media/view/${this.attachment.umbrellaMediaId}`
            );
        }

        public getDownloadUrl() {
            return `${this.getMediaUrl()}?asDownload=true`;
        }

        public getAttachmentFilename() {
            return this.attachment.filename || 'Bijlage';
        }
    }
}
