﻿/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular-file-upload.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import IUploadService = angular.angularFileUpload.IUploadService;
    import MediaModel = Umbrella.Media.MediaModel;
    import CaseFlowModel = Umbrella.CaseFlow.CaseFlowModel;
    import CaseModel = Umbrella.Modules.Customers.v2.CaseModel;
    import ICaseHistoryResourceClass = Umbrella.Modules.Cases.ICaseHistoryResourceClass;

    export interface CaseFlowDocumentsComponentState {
        case: CaseFlowModel;
        caseHistory: CaseModel;
        mediaIds: System.Guid[];
        isUploadingDocument: boolean;
    }

    @Component('Dashboard', {
        selector: 'caseflow-documents',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/_Documents/CaseFlowDocumentsComponent/CaseFlowDocuments.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$stateParams', 'Upload', 'ToastMessageService', 'CaseFlowOverviewService')
    class CaseFlowDocumentsComponent {
        public state: CaseFlowDocumentsComponentState;

        public id: System.Guid;
        public actionUrl: string;
        public mediaUploadId: Guid;
        public isAuthorizedToViewCaseFlowDetails: boolean;

        constructor(
            private $stateParams: CaseFlowOverviewStateParams,
            private uploadService: IUploadService,
            private toastMessageService: ToastMessageService,
            private caseflowOverviewService: CaseFlowOverviewService
        ) {
            this.id = $stateParams.id;
        }

        public $onInit() {
            this.isAuthorizedToViewCaseFlowDetails = !!(
                window &&
                window.user &&
                window.user.permissions &&
                window.user.permissions.viewDetailedCaseFlow
            );

            this.actionUrl = this.state.case
                ? Config.makeAbsoluteApiUrl('/api/v1/case/media/' + this.state.case.umbrellaCaseId)
                : '';
        }

        public upload = (file: File) => {
            if (!file || !this.state.caseHistory) return;

            this.caseflowOverviewService.uploadDocument(this.actionUrl, file);
        };
    }
}
