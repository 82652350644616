/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.TaskHandling {
    export interface ITaskActivityResource {
        assignChatConversation(
            chatContactMoment: ChatTaskActivity
        ): ng.resource.IResource<ChatTaskActivity>;
    }

    export class ChatTaskActivity {
        constructor(
            public taskId: System.Guid,
            public chatConversationId: System.Guid
        ) {}
    }

    angular.module('TaskHandling').service('TaskActivityResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/activity/task/:action',
                {},
                {
                    assignChatConversation: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'assignChatConversation' }
                    }
                }
            )
    ]);
}
