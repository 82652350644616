/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.Authorization {
    @Service('Authorization', 'AuthorizationService')
    @Inject('ToastMessageService')
    export class AuthorizationService {
        private tokenSub: Rx.Disposable;
        private tokenExpiration: Date;
        private watcher: any;
        constructor(private toastMessageService: ToastMessageService) {}

        public start(): void {
            this.subscribeToTokenUpdates();
        }

        public stop(): void {
            this.unsubscribeFromTokenUpdates();
            this.stopExpirationWatcher();
        }

        public parseJwt(token: string) {
            if (!token || token.length < 1) return;

            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

            return JSON.parse(window.atob(base64));
        }

        private subscribeToTokenUpdates(): void {
            const hasToken = x => x && x.accessToken;

            this.tokenSub = session$
                .distinctUntilChanged()
                .filter(hasToken)
                .map(x => x.accessToken)
                .distinctUntilChanged()
                .subscribe(jwtToken => {
                    const parsedJwtToken = this.parseJwt(jwtToken);
                    this.onTokenUpdated(parsedJwtToken);
                });
        }

        private unsubscribeFromTokenUpdates(): void {
            if (this.tokenSub) this.tokenSub.dispose();
        }

        private onTokenUpdated(jwtToken: any): void {
            const exp = jwtToken && (jwtToken.exp || jwtToken.Exp);
            if (!exp) return;

            // token expiration is in seconds
            const expiration = new Date(exp * 1000);

            this.stopExpirationWatcher();
            this.startExpirationWatcher(expiration);
        }

        private startExpirationWatcher(expiration: Date) {
            this.watcherTickTock(expiration);
        }

        private stopExpirationWatcher() {
            clearInterval(this.watcher);
        }

        private watcherTickTock(expiration: Date) {
            const currentDate = new Date();
            const differenceInMs = expiration.getTime() - currentDate.getTime();
            const intervalWatchTime =
                (differenceInMs <= 1000 && differenceInMs) ||
                Math.ceil(differenceInMs / 10);

            if (differenceInMs <= 60000) {              
                this.stopExpirationWatcher();
                startNewSessionWhenTokenHasExpired();
                return;
            }

            clearInterval(this.watcher);
            this.watcher = setInterval(
                () => this.watcherTickTock(expiration),
                intervalWatchTime
            );
        }
    }
}
