/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.Modules.Housing.Valuations {
    export interface IValuationResource
        extends angular.resource.IResource<ValuationModel> {}

    export interface IValuationResourceClass
        extends angular.resource.IResourceClass<IValuationResource> {
        getByUnitId(id: System.Guid): IValuationResource;
        createTask(model: ValuationRequestModel): IValuationResource;
    }

    angular.module('Housing').service('ValuationResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/housing/valuation/:action/:id',
                {},
                {
                    getByUnitId: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    createTask: {
                        method: 'POST',
                        isArray: false,
                        action: '',
                        params: { id: '' }
                    }
                }
            )
    ]);
}
