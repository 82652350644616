/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard {
    @Service('CustomerService', 'CustomerCardAccountInfoService')
    @Inject('AccountResource', 'CustomerCardStore')
    export class CustomerCardAccountInfoService {
        constructor(
            private accountResource: Modules.Accounts.AccountResource,
            private customerCardStore: CustomerCardStore
        ) {}

        public load(personId: System.Guid): void {
            this.emit({ type: 'CustomerCardAccountLoadingEvent', personId });

            this.accountResource
                .getByPersonId({ id: personId })
                .$promise.then(account => {
                    this.emit({
                        type: 'CustomerCardAccountLoadedEvent',
                        account
                    });
                })
                .catch(e =>
                    this.emit({
                        type: 'CustomerCardAccountLoadErrorEvent',
                        error: e
                    })
                );
        }

        private emit(e: CustomerCardEvent) {
            this.customerCardStore.event$.onNext(e);
        }
    }
}
