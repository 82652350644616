/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella')
        .config(['$stateProvider', ($stateProvider: ng.ui.IStateProvider) => {
            $stateProvider
                .state('dashboard.experimentalarea',
                    {
                        url: '/experimentalarea',
                        reloadOnSearch: false,
                        data: { pageTitle: 'Secret Area' },
                        views: {
                            overview: { templateUrl: '/ExperimentalArea/ExperimentalArea.html' }
                        },
                        onEnter: ['$state', 'LocalStorageService', ($state: ng.ui.IStateService, localStorageService: LocalStorageService) => {
                            const areaUnlocked = localStorageService.get('ExperimentalAreaUnlocked');
                            if (!areaUnlocked)
                                $state.go('dashboard.tasks');
                        }]
                    });
        }]);
}
