/// <reference path="../../../Window.d.ts" />
/// <reference path="../../../Scope.d.ts" />
/// <reference path="../../../Scripts/TypeScript/angularjs/angular-ui-router.d.ts" />
/// <reference path="../../../Modules/Colleagues/Calendar/CalendarResourceProvider.ts" />

namespace Umbrella.CustomerService.ColleagueCard.Calendar {
    import AppointmentModel = Umbrella.Modules.Colleagues.Calendar.AppointmentModel;
    import IUmbrellaRootScope = Umbrella.IUmbrellaRootScope;
    import ICalendarResourceClass = Umbrella.Modules.Colleagues.ICalendarResourceClass;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import ColleagueModel = Umbrella.Modules.Colleagues.ColleagueModel;

    interface ColleagueCalendarTabControllerScope extends IUmbrellaRootScope {
        appointments: AppointmentModel[];
        loading: boolean;
        config: any;
        colleague: ColleagueModel.Detailed;
    }

    interface ColleagueCalendarStateParams extends ng.ui.IStateParamsService {
        personId: System.Guid;
    }

    @Controller('CustomerService', 'ColleagueCalendarTabController')
    @Inject('$scope', '$stateParams', 'ColleagueResource', 'CalendarResource', 'EmptyGuid', 'ToastMessageService')
    class ColleagueCalendarTabController {
        private nrOfDaysToLoad = 8;

        constructor(
            private $scope: ColleagueCalendarTabControllerScope,
            private $stateParams: ColleagueCalendarStateParams,
            private colleagueResource: IColleagueResourceClass,
            private calendarResource: ICalendarResourceClass,
            private emptyGuid: System.Guid,
            private toastMessageService: ToastMessageService
        ) {
            $scope.config = window.config;
            $scope.appointments = null;

            $scope.reloadCalendar = (day: Date) => {
                this.loadCalendar(this.$stateParams.personId, day, this.nrOfDaysToLoad);
            };

            this.initialize();
        }

        private initialize() {
            const colleagueId = this.$stateParams.personId;
            if (!colleagueId || colleagueId === this.emptyGuid) return;

            this.colleagueResource.getById({ id: colleagueId }).$promise.then(data => {
                this.$scope.colleague = data;
            });

            const today = new Date();
            this.loadCalendar(colleagueId, today, this.nrOfDaysToLoad);
        }

        private loadCalendar(colleagueId: System.Guid, day: Date, nrOfDaysToLoad: number) {
            const isFacetAvailable =
                window.config &&
                window.config.facets &&
                window.config.facets.calendar &&
                window.config.facets.calendar[0] &&
                window.config.facets.calendar[0].available;
            if (!isFacetAvailable) return;

            this.$scope.loading = true;
            this.calendarResource
                .getAppointmentsForDay({
                    id: colleagueId,
                    day,
                    days: nrOfDaysToLoad
                })
                .$promise.then(data => {
                    const convertStringsToDates = (a: AppointmentModel) =>
                        <AppointmentModel>{
                            ...a,
                            startTime: new Date(a.startTime.toLocaleString()),
                            endTime: new Date(a.endTime.toLocaleString())
                        };
                    this.$scope.appointments = data.map(convertStringsToDates);
                })
                .catch(() =>
                    this.toastMessageService.error('Er is een fout opgetreden bij het ophalen van de afspraken')
                )
                .finally(() => (this.$scope.loading = false));
        }
    }
}
