namespace Umbrella.CustomerService {
    import BillStatus = Umbrella.Modules.Payments.Billing.BillStatus;

    @Pipe('CustomerService', 'isBillStatusInProgress')
    class IsBillStatusInProgressFilter {
        public transform(status: BillStatus | string | number) {
            switch (status) {
                case BillStatus.InProgress:
                case 'InProgress':
                case 1:
                    return true;
                default:
                    return false;
            }
        }
    }
}
