﻿namespace Umbrella.Modules {
    export class CardAdditionalFieldsDirective implements angular.IDirective {
        restrict = 'E';
        templateUrl =
            '/Modules/Umbrella/CardAdditionalFields/CardAdditionalFields.html';
        replace = true;
        transclude = true;
        controller = CardAdditionalFieldsController;
        scope = {
            card: '@card',
            frame: '@frame',
            block: '@block',
            position: '@position',
            item: '=item',
            classLabel: '@classLabel'
        };

        static factory(): angular.IDirectiveFactory {
            return () => new CardAdditionalFieldsDirective();
        }
    }
    angular
        .module('Umbrella')
        .directive(
            'cardadditionalfields',
            CardAdditionalFieldsDirective.factory()
        );
}
