﻿namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import FollowupQuestionModel = Modules.Knowledgebase.FollowupQuestionModel;
    import FaqResourceClass = Modules.Knowledgebase.Faq.FaqResourceClass;

    @Component('Knowledgebase', {
        selector: 'card-knowledgebase-follow-up-question',
        bindings: {
            faqId: '@',
            followUp: '<',
            lvl: '<'
        },
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/CardKnowledgebaseFollowupQuestionComponent/CardKnowledgebaseFollowupQuestion.html'
    })
    @Inject('FaqResource', 'ActivityRegistrationService')
    class CardKnowledgebaseFollowUpQuestion {
        public followUp: FollowupQuestionModel;
        public chosenOption: FollowupQuestionModel;
        private lvl: number;
        private faqId: System.Guid;

        constructor(
            private faqResource: FaqResourceClass,
            private activityRegistrationService: ActivityRegistrationService
        ) {}

        public $onInit() {
            if (!this.followUp && this.faqId)
                this.faqResource
                    .getById({ id: this.faqId })
                    .$promise.then(
                        data => (this.followUp = data.followupQuestion)
                    );
        }

        public chooseOption(
            $event: ng.IAngularEvent,
            option: FollowupQuestionModel
        ): void {
            this.chosenOption = option;
            this.activityRegistrationService.selectFollowUpQuestion(
                option && option.id
            );
            $event.stopPropagation();
        }
        public hasChosen(): boolean {
            return !!this.chosenOption;
        }
        public isRootLevel(): boolean {
            return this.getLevel() === 1;
        }
        public showQuestion(): boolean {
            return (
                this.followUp &&
                this.followUp.options &&
                this.followUp.options.length > 0
            );
        }
        public getLevel(): number {
            return this.lvl ? this.lvl : 1;
        }
        public getLevelClass(): string {
            return !this.showQuestion() ? 'optionFinal' : 'optionCurrent';
        }
    }
}
