namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import SelfServiceScenarioModel = Modules.SelfService.SelfServiceScenarioModel;

    interface IOnScenarioSelectedParam {
        value: SelfServiceScenarioModel.Detailed;
    }
    @Component('Knowledgebase', {
        selector: 'card-knowledgebase-sss-selection-panel',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/CardKnowledgebaseSssSelectionPanelComponent/CardKnowledgebaseSssSelectionPanel.html',
        bindings: {
            scenarios: '<',
            onScenarioSelected: '&'
        }
    })
    class CardKnowledgebaseSssSelectionPanelComponent {
        public scenarios: SelfServiceScenarioModel.Detailed[];

        public onScenarioSelected: (params: IOnScenarioSelectedParam) => void;

        public selectScenario(
            scenario: SelfServiceScenarioModel.Detailed
        ): void {
            this.onScenarioSelected({ value: scenario });
        }
    }
}
