namespace Umbrella.CustomerService.CustomerCard.CustomerSatisfaction {
    @Pipe('CustomerService', 'satisfactionScoreColor')
    class SatisfactionScoreColorFilter {
        public transform(score: number): string {
            if (score >= 7) {
                return '#4caf50';
            } else if (score >= 4) {
                return '#fda12a';
            } else if (score >= 0) {
                return '#f55753';
            }

            return '#caceca';
        }
    }
}
