namespace Umbrella.Search.Units {
    @Controller('Search', 'UnitOverviewController')
    @Inject('$scope', '$location', 'UnitSearchResource')
    class UnitOverviewController {
        constructor($scope, $location, UnitSearchResource: IUnitSearchClass) {
            const pageSize = 50;
            const searchParamName = 'query';

            $scope.searchQuery = $location.search()[searchParamName] || '';
            $scope.searching = false;

            // Function that displays the search result box, or hides it if no results are present.
            const showKlanten = results => {
                if ($scope.page === 0) {
                    $scope.units = results;
                } else {
                    for (let i = 0; i < results.items.length; i++) {
                        $scope.units.items.push(results.items[i]);
                    }
                }

                $scope.searching = false;
            };

            $scope.clear = function() {
                $scope.units = [];
                $scope.searching = false;
            };

            // Start by showing no results
            $scope.clear();

            // Execute search query using the current $scope.searchQuery
            $scope.search = function(append) {
                if (append) {
                    $scope.page++;
                } else {
                    $scope.page = 0;
                }

                if ($scope.searchQuery) {
                    $scope.searching = true;

                    let param = $scope.searchQuery;

                    if (param.charAt(param.length - 1) === '.') {
                        param = param.slice(0, -1);
                    }

                    UnitSearchResource.units(
                        {
                            id: param,
                            page: $scope.page,
                            pageSize
                        },
                        (results, headers) => {
                            let p = $scope.searchQuery;

                            if (p.charAt(p.length - 1) === '.') {
                                p = p.slice(0, -1);
                            }
                            showKlanten(results);
                        }
                    );
                } else {
                    $scope.clear();
                }
            };

            $scope.loadMore = () => {
                if (
                    $scope.units &&
                    $scope.units.items &&
                    $scope.units.items.length !== $scope.units.total
                )
                    $scope.search(true);
            };

            $scope.search(false);
        }
    }
}
