﻿/// <reference path="../../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    billHub: Umbrella.CustomerService.CustomerCard.Bills.IBillHubProxy;
}

namespace Umbrella.CustomerService.CustomerCard.Bills {
    export interface IBillHubProxy extends HubProxy {
        client: IBillHubClient;
        server: IBillHubServer;
    }

    export const billHubOnUpdated$ = new Rx.Subject<System.Guid>();

    export interface IBillHubClient {
        updated(personId: System.Guid);
    }

    export interface IBillHubServer {
        subscribe(personId: System.Guid);
        unsubscribe(personId: System.Guid);
    }    

    angular
        .module('CustomerService')
        .factory('BillHub', () => $.connection.billHub);

    Modules.onSignalRInitialized(() => {
        $.connection.billHub.client.updated = personId => {
            billHubOnUpdated$.onNext(personId);
        };
    });
}
