namespace Umbrella.Modules {
    export interface CardAdditionalFieldsControllerScope {
        cardAdditionalFields: any;
        card: Umbrella.CardType;
        frame: string;
        block: string;
        position: string;
        classLabel: string;
        item: any;
        getDeepLinkLabel: any;
        getDeepLinkUrl: any;
        getDeepLinkTarget: any;
        checkFieldhasValue(item: any, name: string): boolean;
    }

    @Inject('$scope')
    export class CardAdditionalFieldsController {
        constructor(private $scope: CardAdditionalFieldsControllerScope) {
            this.loadCardAdditionalFields();

            $scope.getDeepLinkLabel = (field, item) => {
                if (!field || (!field.deepLinkLabel && !field.deepLinkUrl)) return '';

                if (!item || !item.properties) {
                    return field.deepLinkLabel.length ? field.deepLinkLabel : field.deepLinkUrl;
                }

                return field.deepLinkLabel.length
                    ? field.deepLinkLabel.replace('FIELD_VALUE', this.getFieldValue(item, field.name))
                    : field.deepLinkUrl.replace('FIELD_VALUE', this.getFieldValue(item, field.name));
            };

            $scope.getDeepLinkUrl = (field, item) => {
                if (!field || !field.deepLinkUrl) return '';

                if (!item || !item.properties) return field.deepLinkUrl;

                return field.deepLinkUrl.replace('FIELD_VALUE', this.getFieldValue(item, field.name));
            };

            $scope.getDeepLinkTarget = (field, item) => {
                return field.deepLinkOpenInSelf ? '_self' : '_blank';
            };

            $scope.checkFieldhasValue = (item: any, name: string): boolean => !!this.getFieldValue(item, name);
        }

        protected getFieldValue(item: any, name: string): string {
            var fieldValue = '';
            if (item && item.properties) {
                angular.forEach(item.properties, (value, key) => {
                    if (key.toLowerCase() === name.toLowerCase()) {
                        fieldValue = value;
                        return;
                    }
                });
            }
            return fieldValue;
        }

        protected loadCardAdditionalFields() {
            const config = window.config;
            this.$scope.cardAdditionalFields = [];

            for (const field of config.cardAdditionalFields) {
                if (
                    field &&
                    field.isActive &&
                    field.card === this.$scope.card &&
                    field.frame === this.$scope.frame &&
                    field.block === this.$scope.block &&
                    field.position === this.$scope.position
                )
                    this.$scope.cardAdditionalFields.push(field);
            }
        }
    }
}
