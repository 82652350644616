/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Decorators.ts" />

namespace Umbrella.Modules.Sandbox.Dashboard {
    @Component('Sandbox', {
        selector: 'select-component',
        templateUrl: '/Sandbox/_Dashboard/Select.html'
    })
    class SelectComponent {
        public selectedValue = '';

        public artists = [
            { name: 'Frans Bauer' },
            { name: 'Jan Smit' },
            { name: 'Wolter Kroes' },
            { name: 'Andre Hazes' },
            { name: 'Jeroen van der Boom' },
            { name: 'Gerard Joling' }
        ];

        public something() {
            alert('something');
        }
    }
}
