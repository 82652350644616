/// <reference path="../../ChatConversationHandling/ChatStore.ts" />

namespace Umbrella.TopBar {
    import PhoneService = Umbrella.Modules.Telephony.PhoneService;

    @Component('Umbrella', {
        selector: 'dial-pad',
        templateUrl: '/Top/DialPadComponent/DialPad.html',
        bindings: {
            DialPad: '<'
        }
    })
    @Inject('PhoneService')
    class DialPadComponent {
        public collapsed = false;
        public phoneNumber: string;

        constructor(private phoneService: PhoneService) {}

        public focus() {
            $('input.phone-number').focus();
        }

        public enterNumber(nummer) {
            if (this.phoneNumber === undefined) this.phoneNumber = '';

            this.phoneNumber = this.phoneNumber + '' + nummer;
            this.focus();
        }

        public call() {
            this.phoneService.callNr(this.phoneNumber);
            this.phoneNumber = '';
        }

        public resetNumber() {
            this.phoneNumber = '';
        }

        public hangup() {
            this.phoneNumber = '';
        }
    }
}
