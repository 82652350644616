/// <reference path="TaskHandling/_Overview/TaskOverviewComponent.ts" />
/// <reference path="CustomerService/CustomerServiceComponent/CustomerServiceComponent.ts"/>

namespace Umbrella {
    import isTaskListFilterViewEnabled = Umbrella.TaskHandling.isTaskListFilterViewEnabled$;
    import toggleAdditionalInformationView = Umbrella.CustomerService.toggleAdditionalInformationView$;
    import isCustomerFilterViewEnabled = Umbrella.CustomerService.isCustomerFilterViewEnabled$;
    export const DEVICE_STORAGE_KEY = 'responsive_status';

    export enum DEVICE_MODE {
        SURFACE = 'desktop_windows',
        DESKTOP = 'desktop_windows',
        TABLET = 'tablet_android',
        MOBILE = 'mobile_android'
    }

    export function isDesktopModeActive(): boolean {
        return window.localStorage.getItem(DEVICE_STORAGE_KEY) === DEVICE_MODE.DESKTOP ? true : false;
    }

    export function getStoredDeviceMode(): string {
        return window.localStorage.getItem(DEVICE_STORAGE_KEY) || DEVICE_MODE.DESKTOP;
    }

    export function switchDeviceMode(): string {
        const storedMode = getStoredDeviceMode();
        const newMode = storedMode === DEVICE_MODE.DESKTOP ? DEVICE_MODE.TABLET : DEVICE_MODE.DESKTOP;
        window.localStorage.setItem(DEVICE_STORAGE_KEY, newMode);
        isSearchActive$.onNext(null);
        isTaskListFilterViewEnabled.onNext(null);
        toggleAdditionalInformationView.onNext(null);
        isCustomerFilterViewEnabled.onNext(null);
        applyStylingToDeviceMode(newMode);
        return newMode;
    }

    export function applyStylingToDeviceMode(mode: string): void {
        switch (mode) {
            case DEVICE_MODE.TABLET: {
                window.document.documentElement.className = window.document.documentElement.className.replace(
                    'desktop',
                    'tablet'
                );
                break;
            }
            case DEVICE_MODE.MOBILE: {
                window.document.documentElement.className = window.document.documentElement.className.replace(
                    'desktop',
                    'mobile'
                );
                break;
            }
            case DEVICE_MODE.DESKTOP: {
                window.document.documentElement.className = window.document.documentElement.className.replace(
                    'tablet',
                    'desktop'
                );
                break;
            }
            case DEVICE_MODE.SURFACE: {
                window.document.documentElement.className = window.document.documentElement.className.replace(
                    'tablet',
                    'desktop'
                );
                break;
            }
        }
    }
}
