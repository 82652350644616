/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.ChatConversationHandling {
    angular.module('Chat').directive('emojifyUrlify', [
        () => {
            return {
                restrict: 'A',
                scope: {
                    emojify: '@'
                },
                priority: -1,
                link: (scope, el, attr) => {
                    const urlify = text => {
                        const urlRegex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g;
                        return text.replace(urlRegex, url => {
                            return '<a href="' + url + '" target="_blank" rel="noopener">' + url + '</a>';
                        });
                    };

                    const $element = $(el[0]);
                    attr.$observe('emojifyUrlify', value => {
                        $element.html($element.html().replace('>', '&gt;'));
                        $element.html($element.html().replace('<', '&lt;'));
                        $element.html(emojifier.emojify($element.html()));
                        $element.html(urlify($element.html()));
                    });
                }
            };
        }
    ]);
}
