/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scripts/TypeScript/rxjs/rx.lite.es6.d.ts"/>
/// <reference path="../../RootStore.ts"/>

namespace Umbrella.CustomerService.UnitCard {
    import UnitModel = Modules.Housing.UnitModel;
    import ContractModel = Modules.Housing.ContractModel;
    import ValuationModel = Modules.Housing.Valuations.ValuationModel;

    export interface UnitCardLoadingEvent {
        type: 'UnitCardLoadingEvent';
        id: System.Guid;
    }

    export interface UnitCardLoadedEvent {
        type: 'UnitCardLoadedEvent';
        unit: UnitModel;
    }

    export interface UnitJitUpdatingEvent {
        type: 'UnitJitUpdatingEvent';
        newUnit: UnitModel;
    }

    export interface UnitJitUpdatedEvent {
        type: 'UnitJitUpdatedEvent';
        newUnit: UnitModel;
    }

    export interface UnitCardUnloadedEvent {
        type: 'UnitCardUnloadedEvent';
    }

    export interface UnitValuationLoadingEvent {
        type: 'UnitValuationLoadingEvent';
    }

    export interface UnitValuationLoadedEvent {
        type: 'UnitValuationLoadedEvent';
        valuation: ValuationModel;
    }

    export interface UnitValuationLoadErrorEvent {
        type: 'UnitValuationLoadErrorEvent';
        error: any;
    }

    export namespace Contracts {
        export interface LoadingEvent {
            readonly type: 'UnitContractsLoadingEvent';
            readonly page: number;
            readonly pageSize: number;
        }

        export interface LoadedEvent {
            readonly type: 'UnitContractsLoadedEvent';
            readonly contracts: PagedItemsModel<ContractModel>;
        }

        export interface LoadErrorEvent {
            readonly type: 'UnitContractsLoadErrorEvent';
            readonly error: any;
        }

        export interface State {
            readonly loading: boolean;
            readonly contracts: PagedItemsModel<ContractModel>;
            readonly error: any;
        }

        export function set(
            s: UnitCardState,
            replacement: State
        ): UnitCardState {
            return {
                id: s.id,
                unit: s.unit,
                status: s.status,
                valuation: s.valuation,
                activityInfo: s.activityInfo,
                contractInfo: replacement
            };
        }
    }

    export namespace Activities {
        import CaseHistoryModel = Umbrella.Modules.CaseHistoryModel;

        export interface LoadingEvent {
            readonly type: 'UnitActivitiesLoadingEvent';
            readonly page: number;
            readonly pageSize: number;
        }

        export interface LoadedEvent {
            readonly type: 'UnitActivitiesLoadedEvent';
            readonly cases: PagedItemsModel<CaseHistoryModel>;
        }

        export interface LoadErrorEvent {
            readonly type: 'UnitActivitiesLoadErrorEvent';
            readonly error: any;
        }

        export interface State {
            readonly loading: boolean;
            readonly cases: PagedItemsModel<CaseHistoryModel>;
            readonly error: any;
        }

        export function set(
            s: UnitCardState,
            replacement: State
        ): UnitCardState {
            return {
                id: s.id,
                unit: s.unit,
                status: s.status,
                valuation: s.valuation,
                activityInfo: replacement,
                contractInfo: s.contractInfo
            };
        }
    }

    export type UnitCardEvent =
        | UnitCardLoadingEvent
        | UnitCardLoadedEvent
        | UnitJitUpdatingEvent
        | UnitJitUpdatedEvent
        | UnitCardUnloadedEvent
        | UnitValuationLoadingEvent
        | UnitValuationLoadedEvent
        | UnitValuationLoadErrorEvent
        | Contracts.LoadingEvent
        | Contracts.LoadedEvent
        | Contracts.LoadErrorEvent
        | Activities.LoadingEvent
        | Activities.LoadedEvent
        | Activities.LoadErrorEvent;

    export enum UnitCardLoadingStatus {
        Empty,
        Loading,
        Jitting,
        Loaded
    }

    export interface UnitValuationState {
        loading: boolean;
        valuation: ValuationModel;
        error: any;
    }

    export interface UnitCardState {
        readonly id: System.Guid;
        readonly unit?: UnitModel;
        readonly status: UnitCardLoadingStatus;
        readonly valuation: UnitValuationState;
        readonly activityInfo: Activities.State;
        readonly contractInfo: Contracts.State;
    }

    function reduce(e: UnitCardEvent): (s: UnitCardState) => UnitCardState {
        switch (e.type) {
            case 'UnitCardLoadingEvent':
                return s => ({
                    id: e.id,
                    unit: null,
                    status: UnitCardLoadingStatus.Loading,
                    valuation: null,
                    contractInfo: null,
                    activityInfo: null
                });

            case 'UnitCardLoadedEvent':
                return s => ({
                    id: s.id,
                    unit: e.unit,
                    status: UnitCardLoadingStatus.Loaded,
                    valuation: s.valuation,
                    contractInfo: s.contractInfo,
                    activityInfo: s.activityInfo
                });

            case 'UnitJitUpdatingEvent':
                return s => ({
                    id: s.id,
                    unit: s.unit,
                    status: UnitCardLoadingStatus.Jitting,
                    valuation: s.valuation,
                    contractInfo: s.contractInfo,
                    activityInfo: s.activityInfo
                });

            case 'UnitJitUpdatedEvent':
                return s => ({
                    id: s.id,
                    unit: e.newUnit,
                    status: UnitCardLoadingStatus.Loaded,
                    valuation: s.valuation,
                    contractInfo: s.contractInfo,
                    activityInfo: s.activityInfo
                });

            case 'UnitCardUnloadedEvent':
                return s => s;

            case 'UnitValuationLoadingEvent':
                return s => ({
                    id: s.id,
                    unit: s.unit,
                    status: s.status,
                    valuation: {
                        loading: true,
                        error: null,
                        valuation: null
                    },
                    contractInfo: s.contractInfo,
                    activityInfo: s.activityInfo
                });

            case 'UnitValuationLoadedEvent':
                return s => ({
                    id: s.id,
                    unit: s.unit,
                    status: s.status,
                    valuation: {
                        loading: false,
                        error: null,
                        valuation: e.valuation
                    },
                    contractInfo: s.contractInfo,
                    activityInfo: s.activityInfo
                });

            case 'UnitValuationLoadErrorEvent':
                return s => ({
                    id: s.id,
                    unit: s.unit,
                    status: s.status,
                    valuation: {
                        loading: false,
                        error: e.error,
                        valuation: null
                    },
                    contractInfo: s.contractInfo,
                    activityInfo: s.activityInfo
                });

            case 'UnitContractsLoadingEvent':
                return s =>
                    Contracts.set(s, {
                        loading: true,
                        contracts: null,
                        error: null
                    });

            case 'UnitContractsLoadedEvent':
                return s =>
                    Contracts.set(s, {
                        loading: false,
                        contracts: e.contracts,
                        error: null
                    });

            case 'UnitContractsLoadErrorEvent':
                return s =>
                    Contracts.set(s, {
                        loading: false,
                        contracts: null,
                        error: e.error
                    });

            case 'UnitActivitiesLoadingEvent':
                return s =>
                    Activities.set(s, {
                        loading: true,
                        cases: null,
                        error: null
                    });

            case 'UnitActivitiesLoadedEvent':
                return s =>
                    Activities.set(s, {
                        loading: false,
                        cases: e.cases,
                        error: null
                    });

            case 'UnitActivitiesLoadErrorEvent':
                return s =>
                    Activities.set(s, {
                        loading: false,
                        cases: null,
                        error: e.error
                    });
        }

        throw new Error('Unknown event ' + (event.type || event));
    }

    export type UnitCardStore = ObservableStore.EventStore<
        UnitCardState,
        UnitCardEvent
    >;

    export const unitCardStore: UnitCardStore = rootStore.map(
        lens<UnitCardState>('unitCard'),
        reduce
    );

    angular.module('CustomerService').value('UnitCardStore', unitCardStore);
}
