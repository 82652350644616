namespace Umbrella {
    @Pipe('Umbrella', 'async')
    class AsyncFilter {
        private values = {};
        private subscriptions = {};

        public transform(
            input:
                | Rx.IObservable<any>
                | ng.IPromise<any>
                | ng.IHttpPromise<any>,
            scope: ng.IScope
        ) {
            if (!scope)
                throw new Error('AsyncFilter cannot work without scope');
            if (!scope.$applyAsync)
                throw new Error(
                    'AsyncFilter supplied with scope that has no $applyAsync function'
                );
            if (!input)
                throw new Error('AsyncFilter cannot subscribe null input');

            // Make sure we have an Observable or a Promise
            const observable = (<any>input).subscribe
                ? <Rx.IObservable<any>>input
                : null;
            const promise = (<any>input).then ? <ng.IPromise<any>>input : null;
            const http = (<any>input).success
                ? <ng.IHttpPromise<any>>input
                : null;
            if (!observable && !promise && !http)
                throw new Error(
                    'AsyncFilter only works with observables or promises'
                );

            const inputId = this.objectId(input);

            if (!(inputId in this.subscriptions)) {
                const callback =
                    (observable && observable.subscribe.bind(input)) ||
                    (http && http.success.bind(input)) ||
                    (promise.then && promise.then.bind(input));

                this.subscriptions[inputId] = callback(value => {
                    this.values[inputId] = value;

                    if (scope && scope.$applyAsync) {
                        scope.$applyAsync(); // Automatic safe apply, if scope provided
                    }
                });

                if (scope && scope.$on) {
                    // Clean up subscription and its last value when the scope is destroyed.
                    scope.$on('$destroy', () => {
                        if (
                            this.subscriptions[inputId] &&
                            this.subscriptions[inputId].dispose
                        ) {
                            this.subscriptions[inputId].dispose();
                        }
                        delete this.subscriptions[inputId];
                        delete this.values[inputId];
                    });
                }
            }

            return this.values[inputId];
        }

        // Need a way to tell the input objects apart from each other (so we only subscribe to them once)
        private nextObjectID = 0;
        private objectId(obj) {
            if (!obj.hasOwnProperty('__asyncFilterObjectID__')) {
                obj.__asyncFilterObjectID__ = ++this.nextObjectID;
            }

            return obj.__asyncFilterObjectID__;
        }

        // So that Angular does not cache the return value
        public static $stateful = true;
    }
}
