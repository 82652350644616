namespace Umbrella.Modules {
    import IResource = ng.resource.IResource;

    export interface NamedParams {
        name: string;
    }

    export interface SectionParams {
        section: string;
    }

    export interface IConfigResourceClass {
        get(params: SectionParams): IResource<any>;
        update(params: SectionParams, section: any): IResource<any>;
        getAgentConfig(params: NamedParams): IResource<any>;
        updateAgentConfig(params: NamedParams, section: any): IResource<any>;
    }

    angular.module('Umbrella').service('ConfigResource', [
        '$resource',
        $resource =>
            $resource('/api/v1/:section/config', null, {
                get: {
                    method: 'GET',
                    isArray: false,
                    params: { section: '@section' }
                },
                update: {
                    method: 'PUT',
                    isArray: false,
                    params: { section: '@section' }
                },
                getAgentConfig: {
                    method: 'GET',
                    isArray: false,
                    url: '/api/v1/agent/config/:name',
                    params: { name: '@name' }
                },
                updateAgentConfig: {
                    method: 'PUT',
                    isArray: false,
                    url: '/api/v1/agent/config/:name',
                    params: { name: '@name' }
                }
            })
    ]);
}
