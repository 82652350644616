/// <reference path="../../ChatConversationHandling/ChatStore.ts" />

namespace Umbrella.TopBar {
    import PhoneService = Umbrella.Modules.Telephony.PhoneService;
    import RoleModel = Umbrella.Modules.RoleModel;
    import CustomerIndicatorModel = Umbrella.CustomerService.CustomerCard.Sidebar.CustomerIndicatorModel;
    import ContractModel = Modules.Housing.ContractModel;

    @Component('Umbrella', {
        selector: 'incoming-call',
        templateUrl: '/Top/IncomingCallComponent/IncomingCall.html',
        bindings: {
            incomingCall: '<',
            subjectRole: '<'
        }
    })
    @Inject('PhoneService', 'PersonResource', 'AccountResource', 'ContractResource', 'CustomerResource', '$q')
    class IncomingCallComponent {
        public collapsed = false;
        public incomingCall: Umbrella.TelephonyControl.PhoneCallModel;
        public subjectRole: RoleModel;
        public personalIndicators: CustomerIndicatorModel;
        public contractIndicators: { Contract: ContractModel }[];

        constructor(
            private phoneService: PhoneService,
            private personResource: PersonResource,
            private accountResource: Modules.Accounts.AccountResource,
            private contractResource: Umbrella.Modules.Housing.IContractResource,
            private customerResource: Modules.Customers.ICustomerResource,
            private $q: ng.IQService
        ) {}

        public $onChanges() {
            if (this.subjectRole && this.subjectRole.personId && this.subjectRole.id) {
                this.setIndicator(this.subjectRole.personId, this.subjectRole.id);
            }
        }

        public pickup() {
            if (this.incomingCall) this.phoneService.pickup(this.incomingCall);
            else this.collapsed = false;
        }

        private setIndicator(personId: System.Guid, roleId: System.Guid) {
            this.personalIndicators = null;
            this.contractIndicators = null;
            const getPersonPromise = this.personResource.getById({ id: personId }).$promise;
            const getContractsPromise = this.contractResource.getPagedByRoleId({
                id: roleId,
                page: 0,
                pageSize: 5
            }).$promise;
            const getAccountPromise = this.accountResource.getByPersonId({
                id: personId
            }).$promise;
            const getCustomerPromise = this.customerResource.getByPersonId({
                id: personId
            }).$promise;
            const outstandingBalancePromise = this.contractResource.getOutstandingBalanceFor({ id: roleId }).$promise;

            this.$q
                .all([
                    getPersonPromise,
                    getContractsPromise,
                    getAccountPromise,
                    getCustomerPromise,
                    outstandingBalancePromise
                ])
                .then(data => {
                    const isPropertiesProperty = key => key.toLowerCase() === 'properties';

                    const person = {};
                    const customer = {};
                    Object.keys(data['0'] || {})
                        .filter(key => !isPropertiesProperty(key))
                        .map(x => (person[x] = data['0'][x]));
                    Object.keys(data['3'] || {})
                        .filter(key => !isPropertiesProperty(key))
                        .map(x => (customer[x] = data['3'][x]));

                    const personProperties = data['0'] && data['0'].properties ? data['0'].properties : {};
                    const customerProperties = data['3'] && data['3'].properties ? data['3'].properties : {};
                    const properties = Object.assign(customerProperties, personProperties);

                    const account: Accounts.AccountModel = data['2'];

                    const contracts = data['1'];
                    const contract = contracts.items.length && contracts.items[0];

                    const unit = contract && contract.unit;

                    this.contractIndicators = contracts.items.map(contract => ({
                        Contract: { ...contract }
                    }));
                    this.personalIndicators = {
                        Account: {
                            ...account
                        },
                        Person: {
                            ...person,
                            ...customer,
                            ...{ properties },
                            ...data['4']
                        },
                        Unit: {
                            ...unit
                        }
                    };
                });
        }
    }
}
