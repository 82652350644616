/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard.CustomerSatisfaction {
    import CustomerSatisfactionCategoryGetModel = Umbrella.CustomerService.CustomerSatisfactionCategoryGetModel;
    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;

    export interface CustomerSatisfactionComponentState {
        person: PersonModel;
        loading: boolean;
        registrationInfo: RegistrationModel[];
        customer: Umbrella.Modules.Customers.CustomerModel.Detailed;
    }

    @Inject('CustomerSatisfactionResource')
    @Component('CustomerService', {
        selector: 'customer-satisfaction',
        templateUrl:
            '/CustomerService/_CustomerCard/_CustomerSatisfaction/CustomerSatisfactionComponent/CustomerSatisfactionComponent.html',
        bindings: {
            state: '<'
        }
    })
    class CustomerSatisfactionComponent {
        public state: CustomerSatisfactionComponentState;
        public scoreCategories: CustomerSatisfactionCategoryGetModel[];
        private initiated = false;

        constructor(private customerSatisfactionResource: ICustomerSatisfactionResource) {}

        $onChanges() {
            if (this.initiated || !this.state || !this.state.person) return;

            this.customerSatisfactionResource
                .getCategoriesForPersonId({ id: this.state.person.id })
                .$promise.then(categories => {
                    this.scoreCategories = categories;
                    this.initiated = true;
                });
        }
    }
}
