/// <reference path="Chat.d.ts" />

namespace Umbrella.ChatConversationHandling {
    @Pipe('Chat', 'customerName')
    class ChatCustomerNameFilter {
        public transform(conversation: ChatConversation) {
            if (!conversation || !conversation.customer) return 'Onbekende relatie';

            let customerName = `${conversation.customer.firstName} ${conversation.customer.lastName}`.trim();

            if (!customerName.length) customerName = 'Onbekende relatie';

            if (!conversation.customer.id) return `"${customerName}"`;

            return customerName;
        }
    }
}
