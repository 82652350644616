/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.ColleagueCard.Telephony {
    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.colleaguecard.telephony', {
                data: {
                    tab: 'telephony',
                    authorize: ps => ps.customerCard
                },
                url: '/telephony',
                views: {
                    'tab@customerservice': {
                        template: '<colleague-telephony></colleague-telephony>'
                    }
                }
            });
        }
    ]);
}
