namespace Umbrella.Modules.Colleagues {
    export class SearchColleaguesDirective implements angular.IDirective {
        restrict = 'E';
        templateUrl = '/Modules/Colleagues/Colleague/SearchColleagues.html';
        controller = SearchColleaguesController;
        replace = true;
        transclude = true;
        scope = { colleagueClick: '=' };

        static factory(): angular.IDirectiveFactory {
            return () => new SearchColleaguesDirective();
        }
    }
    angular
        .module('Colleagues')
        .directive('searchcolleagues', SearchColleaguesDirective.factory());
}
