﻿/// <reference path="../../Decorators.ts" />
/// <reference path="../../Window.d.ts" />

namespace Umbrella.CustomerService {
    import SessionColleagueModel = Modules.Colleagues.SessionColleagueModel;
    import TaskOverviewStore = TaskHandling.TaskOverviewStore;
    import CaseFlowOverviewStore = KCC.CaseFlowDashboard.CaseFlowOverviewStore;
    import ChatStore = Umbrella.ChatConversationHandling.ChatStore;

    @Component('CustomerService', {
        selector: 'dashboard-menu',
        templateUrl: '/Modules/Dashboard/DashboardMenu.html'
    })
    @Inject('$state', 'TaskOverviewStore', 'CaseFlowOverviewStore', 'ChatStore')
    class DashboardMenuComponent {
        public user: SessionColleagueModel;
        public taskTotal: number;
        public caseflowTotal: number;
        public chatConversationsTotal: number;
        public chatIsEnabled: boolean;
        public experimentalAreaUnlocked: boolean;
        private taskTotalObserver: Rx.IDisposable;
        private caseflowTotalObserver: Rx.IDisposable;
        private chatEnabledObserver: Rx.IDisposable;
        private chatTotalObserver: Rx.IDisposable;
        private configObserver: Rx.Disposable;

        constructor(
            private $state: ng.ui.IStateService,
            private taskOverviewStore: TaskOverviewStore,
            private caseFlowOverviewStore: CaseFlowOverviewStore,
            private chatStore: ChatStore
        ) {}

        public $onInit() {
            this.user = window.user;

            const notNullOrUndefined = x => x !== null && x !== undefined;

            this.taskTotalObserver = this.taskOverviewStore.state$
                .map(x => x && x.tasks)
                .filter(notNullOrUndefined)
                .distinctUntilChanged()
                .subscribe(x => {
                    this.taskTotal = x.total;
                });

            this.caseflowTotalObserver = this.caseFlowOverviewStore.state$
                .map(x => x && x.overviewInfo && x.overviewInfo.cases && x.overviewInfo.cases.result)
                .filter(notNullOrUndefined)
                .distinctUntilChanged()
                .subscribe(x => {
                    this.caseflowTotal = x.total;
                });

            this.chatEnabledObserver = this.chatStore.state$
                .map(x => x && x.enabled)
                .distinctUntilChanged()
                .subscribe(x => (this.chatIsEnabled = x));

            this.chatTotalObserver = this.chatStore.state$
                .map(x => x && x.conversations)
                .filter(notNullOrUndefined)
                .distinctUntilChanged()
                .subscribe(x => (this.chatConversationsTotal = x.length));

            const hasConfig = x => x && x.config;
            this.configObserver = Config.configStore.state$
                .filter(hasConfig)
                .map(x => x.config.experimentalAreaEnabled)
                .subscribe(x => (this.experimentalAreaUnlocked = x));
        }

        public displayCaseFlowOverviewSwitch(): boolean {
            return this.$state.includes('dashboard.caseflow');
        }

        public $onDestroy() {
            this.taskTotalObserver.dispose();
            this.caseflowTotalObserver.dispose();
            this.chatTotalObserver.dispose();
            this.chatEnabledObserver.dispose();
            this.configObserver.dispose();
        }
    }
}
