namespace Umbrella.CustomerService.CustomerCard {
    @Service('CustomerService', 'PersonJitService')
    @Inject('CustomerCardStore', 'PersonHub', 'CustomerCardPersonInfoService', 'ToastMessageService')
    export class PersonJitService {
        private message: any;

        constructor(
            store: CustomerCardStore,
            private personHub: IPersonHubProxy,
            personInfoService: CustomerCardPersonInfoService,
            private toastMessageService: ToastMessageService
        ) {
            const selectPersonId = (state: CustomerCardState) =>
                state && state.personal && state.personal.person && state.personal.person.id;

            personHubOnUpdated$
                .filter(model => {
                    return selectPersonId(store.getState()) === model.id;
                })
                .subscribe(model => {
                    personInfoService.addEmptyPhoneNumbersEmailAddressAndPostalAddressWhenNotAvailable(model);
                    store.event$.onNext({
                        type: 'CustomerCardPersonUpdatedEvent',
                        person: model
                    });
                    this.clearToastMessage();
                });

            personHubOnStarted$
                .filter(personId => {
                    return selectPersonId(store.getState()) === personId;
                })
                .subscribe(model => {
                    if (this.message) {
                        return;
                    }

                    this.message = this.toastMessageService.updating('Persoonlijke gegevens', 60000, {
                        onHidden: () => {
                            this.message = null;
                        }
                    });

                    setTimeout(() => {
                        if (this.message) {
                            this.message = null;
                        }
                    }, 60000);
                });

            store.state$
                .map(selectPersonId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(personId => this.triggerJitUpdate(personId));

            store.event$
                .where(e => e.type === 'CustomerCardPersonUnloadedEvent')
                .subscribe((e: Personal.UnloadedEvent) => this.unsubscribe(e.id));
        }

        private triggerJitUpdate(personId: System.Guid) {
            this.clearToastMessage();

            if (personId)
                this.personHub.server.startJitUpdate(personId).fail(e => {
                    this.clearToastMessage();
                    this.message = this.toastMessageService.error('Synchronisatie persoonlijke gegevens mislukt');
                });
        }

        private unsubscribe(personId: System.Guid) {
            this.personHub.server.unsubscribe(personId);
            this.clearToastMessage();
        }

        
        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }
    }
}
