/// <reference path="../../Decorators.ts" />
/// <reference path="../../Scripts/TypeScript/gsap/index.d.ts" />

namespace Umbrella {

    @Component('Umbrella', {
        selector: 'setup-features',
        templateUrl: '/ExperimentalArea/SetupFeaturesComponent/SetupFeaturesComponent.html'
    })
    @Inject('$rootScope')
    class SetupFeaturesComponent {
        public flags: Config.FeatureFlag[];
        private configSub: Rx.Disposable;
        constructor(private $rootScope: ng.IRootScopeService) { }
        $onInit() {
            const hasFeatureFlags = x => x && x.config && x.config.features;
            this.configSub = Config.configStore
                .state$
                .filter(hasFeatureFlags)
                .map(x => x.config.features)
                .subscribe(x => this.flags = x);
        }

        $onDestroy() {
            if (this.configSub)
                this.configSub.dispose();
            this.configSub = null;
        }

        public switchFlag(flag: Config.FeatureFlag) {
            Config.switchFeatureFlag(flag.name, !flag.enabled);
            this.$rootScope.$broadcast(flag.name + 'Changed');
        }
    }
}