namespace Umbrella.KCC.CaseFlowDashboard {
    @Pipe('Dashboard', 'isFunctionGroup')
    class IsFunctionGroupFilter {
        public transform(
            collegueType: Modules.Colleagues.ColleagueType | string | number
        ) {
            switch (collegueType) {
                case Modules.Colleagues.ColleagueType.FunctionGroups:
                case 'FunctionGroups':
                case 10:
                    return true;
                default:
                    return false;
            }
        }
    }
}
