namespace Umbrella {
    import HousingAllocationRegistrationType = Umbrella.Modules.Housing.HousingAllocationRegistrationType;

    @Pipe('Dashboard', 'housingAllocationRegistrationType')
    class HousingAllocationRegistrationTypeFilter {
        public transform(
            housingAllocationRegistrationType:
                | string
                | HousingAllocationRegistrationType
        ) {
            switch (housingAllocationRegistrationType) {
                case 'Garage':
                case HousingAllocationRegistrationType.Garage:
                    return 'Garage';
                case 'House':
                case HousingAllocationRegistrationType.House:
                    return 'Woning';
                default:
                    return 'Onbekend';
            }
        }
    }
}
