/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Scope.d.ts"/>
/// <reference path="../../../../../Window.d.ts" />

namespace Umbrella.KCC.CaseFlowDashboard {
    import SummarizedColleagueModel = Umbrella.Modules.Colleagues.SummarizedColleagueModel;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import IFunctionGroupResourceClass = Modules.Colleagues.IFunctionGroupResourceClass;
    import ColleagueModel = Modules.Colleagues.ColleagueModel;
    import SelectedColleagueOrFunctiongroup = Modules.SelectedColleagueOrFunctiongroup;

    @Component('CaseFlow', {
        selector: 'select-colleague-or-functiongroup',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/_Steps/SelectColleagueOrFunctionGroupComponent/SelectColleagueOrFunctionGroup.html',
        bindings: {
            preSelectCurrentUser: '<',
            required: '<',
            onChange: '&'
        }
    })
    @Inject('ColleagueResource', 'FunctionGroupResource')
    export class SelectColleagueOrFunctionGroupComponent {
        private preSelectCurrentUser: boolean;
        private onChange: (params: { value?: System.Guid }) => void;
        public required: boolean;

        public assignedToList: SummarizedColleagueModel[] = [];
        public selectedValue: SelectedColleagueOrFunctiongroup;

        constructor(
            private colleagueResource: IColleagueResourceClass,
            private functiongroupResource: IFunctionGroupResourceClass
        ) {}

        public $onInit() {
            this.loadAssignedToList();
        }

        public select(item: SelectedColleagueOrFunctiongroup): void {
            this.onChange({ value: item });
        }

        public async searchAssignedTo(query = ''): Promise<void> {
            const pagedList = await this.colleagueResource.getColleaguesAndFunctiongroups(
                { page: 0, pageSize: 20, id: query, userId: null }
            ).$promise;
            this.assignedToList = pagedList.items;
        }

        private async loadAssignedToList(): Promise<void> {
            await this.searchAssignedTo('');

            if (this.preSelectCurrentUser) this.preSelectAssignedTo();
        }

        private preSelectAssignedTo(): void {
            if (!window.user) return;

            const listItem = this.createListItem(window.user);
            const isLoaded = completeById =>
                this.assignedToList.filter(x => x.id === completeById).length;
            if (!isLoaded(listItem.id))
                this.assignedToList = [listItem, ...this.assignedToList];

            this.selectedValue = listItem;
            this.onChange({ value: listItem });
        }

        private createListItem(colleague: SessionColleagueModel): any {
            return {
                type: 'Colleagues',
                id: colleague.id,
                name: colleague.name
            };
        }
    }
}
