/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('focusOnLoad', () => {
        return {
            link(scope, element, attrs) {
                element.focus();
                element.on('load', () => {
                    element.focus();
                });
                scope.$on('$viewContentLoaded', function() {
                    //Here your view content is fully loaded !!
                    element.focus();
                });
            }
        };
    });
}
