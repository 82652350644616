/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../ComponentRouteUtils.ts" />
/// <reference path="../../../Window.d.ts"/>
/// <reference path="../../customerservice.routeconfig.ts" />

namespace Umbrella.CustomerService.CustomerCard.Personal {
    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) =>
            $stateProvider.state('customerservice.customercard.personal', {
                url: '/gegevens',
                reloadOnSearch: false,
                data: { tab: 'personal', authorize: ps => ps.customerCard },
                views: {
                    'tab@customerservice': {
                        template: '<customer-details state="state$ | async:this"></customer-details>',
                        controller: createComponentRouteController<CustomerCardState, CustomerDetailsComponentState>(
                            'CustomerCardStore',
                            s => ({
                                person: s.personal && s.personal.person,
                                writableFields: s.personal && s.personal.fields,
                                personIsDeletable: s.personal && s.personal.personIsDeletable,
                                loading:
                                    s.personal &&
                                    s.personal.loading &&
                                    s.registrationInfo &&
                                    s.registrationInfo.loading,
                                customer: s && s.customerInfo && s.customerInfo.customer,
                                registrationInfo: s.registrationInfo && s.registrationInfo.registrations,
                            })
                        )
                    }
                }
            })
    ]);
}
