namespace Umbrella.CustomerService {
    @Component('CustomerService', {
        selector: 'sss-decision-path',
        templateUrl: 'SSSDecisionPathComponent/SSSDecisionPath.html',
        bindings: {
            decisions: '<'
        }
    })
    export class SSSDecisionPath {}
}
