namespace Umbrella.CustomerService.ColleagueCard.Telephony {
    @Pipe('CustomerService', 'isInboundCall')
    class IsInboundCallFilter {
        public transform(type: number | string): boolean {
            switch (type) {
                case 'Inbound':
                case 1:
                    return true;
                default:
                    return false;
            }
        }
    }
}
