// <reference path="../../Decorators.ts" />
/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('mdPreventFocus', () => {
        return {
            link(scope, element, attrs, $mdOpenMenu) {
                scope.$on('$mdMenuOpen', (ev, element) => {
                    const interval = setInterval(() => {
                        if (
                            document.getElementsByClassName('md-clickable')
                                .length > 0 &&
                            document
                                .getElementsByClassName('md-clickable')[0]
                                .querySelector('.md-focused')
                        ) {
                            document
                                .getElementsByClassName('md-clickable')[0]
                                .querySelector('.md-focused')
                                .classList.remove('md-focused');
                            clearInterval(interval);
                        }
                    }, 5);
                });
            }
        };
    });
}
