/// <reference path="../../../Decorators.ts" />

namespace Umbrella.CustomerService {
    @Component('CustomerService', {
        selector: 'card-feedback-message',
        templateUrl:
            '/CustomerService/_CustomerCard/CardFeedbackMessageComponent/CardFeedbackMessage.html',
        bindings: {
            message: '@'
        }
    })
    class CardFeedbackMessageComponent {}
}
