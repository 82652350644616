/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Workload {
    import CaseFlowOverviewModel = Umbrella.CaseFlow.CaseFlowOverviewModel;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;

    export interface CaseFlowWorkloadComponentState {
        cases: PagedItemsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>;
    }

    @Component('Dashboard', {
        selector: 'case-flow-workload',
        templateUrl:
            '/CaseFlowProcessing/_Workload/_List/CaseFlowWorkloadComponent/CaseFlowWorkload.html',
        bindings: {
            state: '<'
        }
    })
    @Inject(
        '$state',
        '$stateParams',
        'CaseFlowOverviewService',
        'ColleagueResource',
        '$q'
    )
    class CaseFlowWorkloadComponent {
        public state: CaseFlowWorkloadComponentState;
        public selectedId: System.Guid;
        public allLoaded: boolean;

        public cases: { [index: string]: CaseFlowOverviewModel[] };
        public colleagues: any = {};

        constructor(
            private $state: ng.ui.IStateService,
            private $stateParams: CaseFlowOverviewStateParams,
            private caseFlowOverviewService: CaseFlowOverviewService,
            private colleagueResource: IColleagueResourceClass,
            private q: ng.IQService
        ) {
            this.selectedId = this.$stateParams.id;
        }

        public $onChanges(changes: {
            state: IBindingChange<CaseFlowWorkloadComponentState>;
        }) {
            if (
                changes.state &&
                changes.state.currentValue &&
                changes.state.currentValue.cases
            ) {
                this.getColleagues(this.state.cases);

                if (!this.cases || this.state.cases.page == 0) this.cases = {};

                if (this.state.cases.items) {
                    angular.forEach(
                        this.state.cases.items,
                        (elm: CaseFlowOverviewModel) => {
                            if (elm.owner) {
                                const prop = elm.owner.toString();
                                if (!this.cases[prop]) {
                                    this.cases[prop] = [];
                                }

                                this.cases[prop].push(elm);
                            } else {
                                const unsigned = 'Nog niet toegekend';
                                if (!this.cases[unsigned]) {
                                    this.cases[unsigned] = [];
                                }

                                this.cases[unsigned].push(elm);
                            }
                        }
                    );
                }

                this.allLoaded =
                    (this.state.cases.page + 1) * this.state.cases.pageSize >=
                    this.state.cases.total;
            }
        }

        getColleagueIds(
            data: PagedItemsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>
        ): System.Guid[] {
            const flattenedColleagueIds = (<any>[].concat)
                .apply([], (<any>data.items)
                .reduce(function(result, element) {
                    if(element.ownerModel)
                        result.push(element);
                    return result;
                }, [])
                .map(a => a.ownerModel.id))
                .filter(n => n);
            const uniqueColleagueIds = Immutable.fromJS(flattenedColleagueIds)
                .toSet()
                .toList();

            return <any>uniqueColleagueIds.toJS();
        }

        getColleagues(
            data: PagedItemsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>
        ): ng.IPromise<
            PagedItemsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>
        > {
            const deferred = this.q.defer<
                PagedItemsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>
            >();
            const ids = this.getColleagueIds(data);

            if (ids && ids.length > 0) {
                this.colleagueResource
                    .getByIds({ ids })
                    .$promise.then(colleagues => {
                        this.mapColleagues(colleagues);
                        deferred.resolve(data);
                    })
                    .catch(error => deferred.reject());
            } else {
                deferred.resolve(data);
            }

            return deferred.promise;
        }

        public moreCasesAvailable() {
            if (
                this.state &&
                this.state.cases &&
                this.state.cases.total >
                    (this.state.cases.page + 1) * this.state.cases.items.length
            ) {
                return true;
            }

            return false;
        }

        public more(): void {
            this.caseFlowOverviewService.load();
        }

        mapColleagues(
            colleagues: Modules.Colleagues.ColleagueModel.Detailed[]
        ) {
            if (colleagues) {
                colleagues.forEach(
                    colleague =>
                        (this.colleagues[colleague.id.toString()] = colleague)
                );
            }
        }
    }
}
