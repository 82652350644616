namespace Umbrella {
    @Component('Umbrella', {
        selector: 'login-controls',
        templateUrl: '/Top/LoginControlsComponent/LoginControls.html',
        bindings: {
            chatInfo: '<'
        }
    })
    class LoginControlsComponent {}
}
