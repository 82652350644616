namespace Umbrella.CustomerService.UnitCard.Activities {
    import ICaseHistoryResourceClass = Modules.Cases.ICaseHistoryResourceClass;
    import CaseHistoryModel = Modules.CaseHistoryModel;

    @Service('CustomerService', 'UnitActivitiesService')
    @Inject('UnitCardStore', 'CaseHistoryResource')
    export class UnitActivitiesService extends BaseStoreService<
        UnitCardState,
        UnitCardEvent,
        UnitCardStore
    > {
        private pageSize = 20;

        constructor(
            store: UnitCardStore,
            private caseHistoryResource: ICaseHistoryResourceClass
        ) {
            super(store);
        }

        public ensureLoaded(pageSize = this.pageSize, force = false): void {
            const state = this.getState();
            if (!state.activityInfo || force)
                this.loadActivities(0, pageSize, cases => cases);
        }

        public reload(): void {
            this.ensureLoaded(this.pageSize, true);
        }

        public loadMore(): void {
            const state = this.store.getState();
            const pagedItems = state.activityInfo && state.activityInfo.cases;

            this.loadActivities(
                pagedItems.page + 1,
                pagedItems.pageSize,
                cases => ({
                    items: pagedItems
                        ? pagedItems.items.concat(cases.items)
                        : cases.items,
                    total: cases.total,
                    page: cases.page,
                    pageSize: cases.pageSize
                })
            );
        }

        private loadActivities(
            page: number,
            pageSize,
            loaded: ((
                a: PagedItemsModel<CaseHistoryModel>
            ) => PagedItemsModel<CaseHistoryModel>)
        ) {
            const state = this.getState();
            if (state.unit) {
                this.emit({
                    type: 'UnitActivitiesLoadingEvent',
                    page,
                    pageSize
                });

                this.caseHistoryResource
                    .getByUnitId({
                        id: state.id,
                        page,
                        pageSize
                    })
                    .$promise.then(data => {
                        //NOTE: set case client-side for activities: deliberately done to prevent big data responses
                        angular.forEach(
                            data.items,
                            (item: CaseHistoryModel) => {
                                if (item.firstActivity) {
                                    item.firstActivity.case = item.case;
                                }

                                if (item.lastActivity) {
                                    item.lastActivity.case = item.case;
                                }
                            }
                        );

                        const cases = loaded(data);
                        this.emit({ type: 'UnitActivitiesLoadedEvent', cases });
                    })
                    .catch(e =>
                        this.emit({
                            type: 'UnitActivitiesLoadErrorEvent',
                            error: e
                        })
                    );
            } else {
                this.store.state$
                    .map(x => x && x.unit)
                    .filter(x => x != null)
                    .take(1)
                    .subscribeOnCompleted(() =>
                        this.loadActivities(page, pageSize, loaded)
                    );
            }
        }
    }
}
