namespace System {
    export enum UriHostNameType {
        Unknown = 0,
        Basic = 1,
        Dns = 2,
        IPv4 = 3,
        IPv6 = 4
    }
}
namespace System.Net.Mail {
    export enum MailPriority {
        Normal = 0,
        Low = 1,
        High = 2
    }
}
namespace Umbrella {
    export enum CardType {
        CustomerCard = 0,
        ColleagueCard = 2,
        UnitCard = 4,
        CustomerHistoryCard = 5,
        ComplexCard = 6,
        Portal = 7
    }
    export enum EmailAddressProperties {
        None = 0,
        Verified = 1,
        Primary = 2
    }
    export enum JitResponseStatus {
        Success = 0,
        Error = 1
    }
    export enum JitType {
        Person = 1,
        PersonActivity = 2,
        CustomerContracts = 4,
        CustomerBills = 8,
        Unit = 16,
        UnitActivity = 32,
        UnitContracts = 64,
        UnitValuation = 128,
        AllPerson = 3,
        AllCustomer = 12,
        AllUnit = 240,
        All = 255
    }
    export enum PersonCharacteristic {
        None = 0,
        Aggressive = 1,
        ExcludedFromSingleService = 32
    }
    export enum PersonFieldTypes
    {
        Initials = 0,
        FirstName = 1,
        Insertion = 2,
        LastName = 3,
        BirthDate = 4,
        EmailAddress = 5,
        PhoneNumber = 6,
        Nationality = 7,
        Gender = 8,
        PostalAddress = 9,
        LegalPersonName = 10,
        KvkNumber = 11,
        IsDeceased = 12,
        CorrespondenceType = 13,
        IsAggressive = 14,
        AccountName = 15,
        IsActive = 16,
        Function = 17,
        Department = 18,
        Picture = 19,
        PhoneType = 20,
    }
    export enum RepairRequestItemStatus {
        Pending = 0,
        Finished = 10,
        Canceled = -1
    }
    export enum RepairRequestStatus {
        Pending = 0,
        Prepared = 5,
        Submitted = 10,
        Accepted = 15,
        Finished = 20,
        Locked = 25,
        Canceled = -1
    }
    export enum UpdateStatus {
        Succes = 0,
        Failed = 1,
        InProgress = 2
    }
}
namespace Umbrella.Accounts.Login {
    export enum TokenVerificationError {
        Expired = 1,
        NoAccountFound = 2,
        InvalidToken = 3,
        AccountShouldBeActivated = 4,
        AccountShouldBeDeactivated = 5
    }
}
namespace Umbrella.CaseFlow {
    export enum CaseFlowStatus {
        PickedUp = 0,
        Finished = 1,
        Canceled = 2,
        Submitted = 3
    }
    export enum CaseFlowAssignmentType {
        Employee = 0,
        FunctionGroup = 1
    }
    export enum CaseFlowStepStatus {
        NotStarted = 0,
        Started = 1,
        PickedUp = 2,
        Finished = 3,
        Canceled = 4
    }
    export enum CaseFlowStepTaskOutcomeStatus {
        Positive = 0,
        Neutral = 1,
        Negative = 2
    }
}
namespace Umbrella.HousingAllocation.Reactions {
    export enum ReactionPossibility {
        AlreadyReacted = 1,
        NotRegistered = 2,
        InvalidRegistrationType = 2,
        Allowed = 3
    }
    export enum ReactionStatus {
        Verwerken = 0,
        Ontvangen = 1,
        Toegewezen = 2,
        NietToegewezen = 3,
        Geaccepteerd = 4,
        Geweigerd = 5,
        Afgewezen = 6,
        Verwijderd = 7
    }
}
namespace Umbrella.HousingAllocation.Registrations {
    export enum RegistrationType {
        Housing = 1,
        Garage = 2,
        Parking = 3
    }
}
namespace Umbrella.Indicators {
    export enum PriorityLevel {
        Urgent = 0,
        Important = 1,
        Missing = 2,
        System = 3
    }
}
namespace Umbrella.Indicators.Expected {
    export enum ExpectedType {
        Constant = 0,
        Variable = 1,
        Comparison = 2
    }
}
namespace Umbrella.Media {
    export enum MediaCategory {
        KnowledgebaseItemMedia = 0,
        AdvertisementMedia = 1,
        DefectMedia = 2,
        MailMedia = 3,
        InspectionMedia = 4,
        RepairRequestItemMedia = 6,
        TaskMedia = 7,
        CaseMedia = 8,
        SelfServiceMedia = 9
    }
}
namespace Umbrella.Modules {
    export enum StatusFilter {
        All = 0,
        Active = 1,
        Deleted = 2
    }

    export enum ActivityCategory {
        Contact = 0,
        Selfservice = 1,
        Payments = 2,
        Document = 3,
        Correspondence = 4,
        CaseFlow = 5,
        Task = 6,
        Inspection = 7,
        PersonalData = 8,
        Communication = 9,
        Sms = 10
    }
    export enum AddressType {
        Home = 0,
        Postal = 1
    }
    export enum CaseStatus {
        Submitted = 1,
        Closed = 2,
        InProgress = 3
    }
    export enum ChannelType {
        Unknown = 0,
        Phone = 1,
        Visit = 2,
        Desk = 3,
        Email = 4,
        Post = 5,
        Social = 6,
        Tweet = 15,
        Facebook = 16,
        Web = 17,
        Chat = 18,
        Sms = 19,
        Umbrella = 20,
        Survey = 21,
        Backoffice = 22
    }
    export enum CorrespondenceType {
        Unknown = -1,
        Mail = 1,
        Digital = 2
    }
    export enum Gender {
        Male = 0,
        Female = 1,
        Unknown = 2
    }
    export enum PlanningOptionScore {
        Good = 1,
        Average = 5,
        Bad = 10
    }
    export enum PropertyType {
        String = 0,
        Number = 1,
        Boolean = 2,
        Date = 3,
        Enum = 4,
        Object = 5
    }
    export enum RoleFields {
        None = 0,
        PersonInitials = 2,
        PersonFirstName = 4,
        PersonInsertion = 8,
        PersonLastName = 16,
        PersonBirthDate = 32,
        PersonBsn = 64,
        PhoneNumber = 128,
        Email = 256,
        PhoneNumber2 = 512,
        CompanyName = 1024,
        KvkNumber = 2048,
        PersonNationality = 4096,
        PersonGender = 8192,
        PostalAddress = 16384,
        All = 2147483647
    }
}
namespace Umbrella.Modules.Colleagues {
    export enum Availability {
        Unknown = 0,
        Available = 1,
        Away = 2,
        Busy = 3
    }
    export enum ColleagueType {
        Colleagues = 1,
        FunctionGroups = 10
    }
    export enum Presence {
        None = 0,
        Away = 1,
        Online = 2,
        Offline = 5,
        Busy = 6,
        DoNotDisturb = 7,
        BeRightBack = 8
    }
    export enum StateType {
        Available = 1,
        Unavailable = 2,
        Busy = 3,
        Offline = 4
    }
}
namespace Umbrella.Modules.Colleagues.Calendar {
    export enum AppointmentStatus {
        Free = 0,
        Tentative = 1,
        Busy = 2,
        OOF = 3,
        NoData = 4
    }
}
namespace Umbrella.Modules.Complaints {
    export enum ComplaintStatus {
        Pending = 0,
        Submitted = 10,
        InProgress = 15,
        Finished = 20,
        Canceled = -1
    }
}
namespace Umbrella.Modules.Compliments {
    export enum ComplimentStatus {
        Pending = 0,
        Submitted = 10,
        InProgress = 15,
        Finished = 20,
        Canceled = -1
    }
}
namespace Umbrella.Modules.Customers {
    export enum CaseType {
        Contact = 0,
        Question = 1,
        Case = 2,
        PersonalDataChanged = 3,
        BillPayment = 4
    }
    export enum CustomerStatus {
        Active = 0,
        Future = 1,
        InActive = 2,
        Unknown = 3
    }
}
namespace Umbrella.Modules.Disturbance {
    export enum DisturbanceStatus {
        Pending = 0,
        Submitted = 10,
        InProgress = 15,
        Finished = 20,
        Canceled = -1
    }
}
namespace Umbrella.Modules.Forms {
    export enum FormSubmitErrorReason {
        ValueRequired = 0,
        ValueOutOfRange = 1,
        FieldDoesNotExist = 2
    }
}
namespace Umbrella.Modules.Housing {
    export enum ContractPriceComponentStatus {
        Provisionally = 1,
        Definitive = 2,
        Requested = 3
    }
    export enum ContractStatus {
        Active = 1,
        Future = 2,
        Expired = 3
    }
    export enum HouseAdjustmentStatus {
        Pending = 0,
        Submitted = 10,
        InProgress = 15,
        Finished = 20,
        Canceled = -1
    }
    export enum HousingAllocationRegistrationType {
        None = 0, // see if we can eradicate this.
        House = 1,
        Garage = 2,
        Parking = 3,
        Unknown = 4
    }
}
namespace Umbrella.Modules.Housing.Advertisements {
    export enum AdvertisementStatus {
        Published = 1,
        Concept = 2,
        Deleted = 100
    }
}

namespace Umbrella.HousingAllocation.Registration {
    export enum RegistrationActivityModelType {
        Option = 1,
        Reaction = 2
    }
}
namespace Umbrella.Modules.Housing.Inspection {
    export enum InspectedRoomCondition {
        Bad = 0,
        Good = 1
    }
    export enum InspectionProgress {
        CannotStartInitialInspection = 0,
        CanStartInitialInspection = 1,
        InitialInspectionInProgress = 2,
        CanStartFinalInspection = 3,
        FinalInspectionInProgress = 4,
        FinalInspectionFinished = 5,
        StateOfCommencementInProgress = 6,
        StateOfCommencementFinished = 7
    }
    export enum InspectionReportStatus {
        Pending = 0,
        Submitted = 1
    }
}
namespace Umbrella.Modules.Housing.Inspection.Final {
    export enum FinalObservationResolution {
        NotChecked = 0,
        Resolved = 1,
        Unresolved = 2
    }
}
namespace Umbrella.Modules.Housing.Inspection.Initial {
    export enum InitialObservationResolution {
        None = 0,
        FixByTenant = 1,
        FixByCorporation = 2
    }
}
namespace Umbrella.Modules.Knowledgebase {
    export enum FaqType {
        Default = 0,
        WithFollowUpQuestions = 1
    }
    export enum PublicationStatus {
        Published = 1,
        Concept = 2,
        ToReview = 3,
        Imported = 4,
        Expired = 5,
        Deleted = 100
    }
}
namespace Umbrella.Modules.Mail {
    export enum MailMessageStatus {
        New = 0,
        PickedUp = 1,
        Finished = 2,
        Deleted = 3,
        Sent = 4
    }
}
namespace Umbrella.Modules.Monitoring.Messaging {
    export enum UndeliveredMessageStatus {
        Failed = 0,
        Retrying = 1,
        Resolved = 2
    }
}
namespace Umbrella.Modules.Payments {
    export enum BillSortOrder {
        HasOutstandingBalanceThenByCreationDateDesc = 0,
        CreationDateDesc = 1
    }
    export enum PaymentMethod {
        None = 0,
        TransactionForm = 1,
        PreauthorizedDebit = 2,
        Electronic = 3
    }
    export enum PaymentProviderType {
        None = 0,
        RaboProfessional = 1,
        RaboOmnikassa = 2,
        OgoneECommerce = 3
    }
    export enum PaymentStatus {
        New = 0,
        Success = 1,
        Pending = 10,
        Failed = 90,
        Cancelled = 91,
        Expired = 92
    }
    export enum RejectionReason {
        OutstandingBalanceTooHigh = 0,
        OutstandingBalanceTooLow = 1,
        NoRejection = 2,
        NoActiveContract = 3
    }
}
namespace Umbrella.Modules.Payments.Billing {
    export enum BillStatus {
        Open = 0,
        InProgress = 1,
        Closed = 2
    }
}
namespace Umbrella.Modules.Repairs {
    export enum ActionParameterScope {
        Configured = 0,
        Inferred = 1
    }
}
namespace Umbrella.Modules.SelfService {
    export enum SelfServiceStatusType {
        Online = 1,
        Offline = 10,
        KccOnly = 11,
        AdministratorsOnly = 12,
        Faulted = 20
    }
    export enum SelfServiceScenarionStatusType {
        Published = 1,
        Archived = 10
    }
    export enum TimeSlot {
        Morning = 0,
        Afternoon = 1
    }
    export enum Weekday {
        Monday = 1,
        Tuesday = 2,
        Wednesday = 3,
        Thursday = 4,
        Friday = 5
    }
}
namespace Umbrella.Modules.Tasks {
    export enum CompletionState {
        FinishedThisWeek = 0,
        FinishedToday = 1,
        DeadlineExpired = 2
    }
    export enum TaakStatus {
        Nieuw = 0,
        Opgepakt = 1,
        Afgerond = 2,
        Verwijderd = 3,
        Geweigerd = 4,
        Geannuleerd = 5
    }
    export enum TaskOutcomeStatus {
        Positive = 0,
        Neutral = 1,
        Negative = 2
    }
    export enum TaskStatusFilter {
        New = 0,
        PickedUp = 1,
        Finished = 2,
        Removed = 3,
        Rejected = 4,
        FinishedThisWeek = 5,
        FinishedToday = 6,
        DeadlineExpired = 7
    }
    export enum TaskTypeCategory {
        Terugbellen = 0,
        InformatieOpsturen = 1,
        Verzoek = 2,
        Error = 3,
        Voorinspectie = 4,
        Eindinspectie = 5,
        Selfservice = 6,
        StateOfCommencement = 7,
        Valuation = 8,
        CaseFlow = 9,
        Other = 10
    }
}
namespace Umbrella.Modules.Telephony {
    export enum PhoneType {
        Home = 0,
        Mobile = 1,
        Work = 2,
        Unknown = 3
    }
}
namespace Umbrella.Scheduling {
    export enum TriggerType {
        Cron = 0,
        Future = 1,
        Any = 2
    }
}
namespace Umbrella.SelfTests {
    export enum TestResultStatus {
        Fatal = 0,
        Warning = 1,
        Info = 2,
        Pass = 3
    }
}
namespace Umbrella.Telephony {
    export enum PhoneCallStatus {
        Dialing = 1,
        Established = 2,
        Ringing = 3,
        LineOpened = 4,
        LineClosed = 5,
        OnHold = 6,
        Conferenced = 7,
        Transfered = 8,
        Unknown = 0,
        Queued = 201
    }
    export enum PhoneCallType {
        Inbound = 1,
        Outbound = 2,
        Consult = 3,
        Internal = 4,
        Unknown = 100
    }
    export enum PhoneCapabilities {
        None = 0,
        Login = 1,
        NotReady = 2,
        All = 3,
        AuthLogin = 4
    }
    export enum PhoneQueueStatus {
        Unsupported = -1,
        Unavailable = 1,
        Available = 2,
        ACW = 3,
        SignIn = 4,
        SignOut = 5,
        Unknown = 0,
        Disabled = 6
    }
    export enum PhoneStatus {
        Unavailable = 1,
        Available = 2,
        Offline = 3,
        Busy = 4,
        ACW = 5
    }
}
namespace Umbrella.TelephonyControl {
    export enum PhoneCallStatus {
        Dialing = 1,
        Established = 2,
        Ringing = 3,
        LineOpened = 4,
        LineClosed = 5,
        OnHold = 6,
        Conferenced = 7,
        Transfered = 8,
        Unknown = 0,
        Queued = 201
    }
    export enum PhoneCallType {
        Inbound = 1,
        Outbound = 2,
        Consult = 3,
        Internal = 4,
        Unknown = 100
    }
    export enum PhoneCapabilities {
        None = 0,
        Login = 1,
        NotReady = 2,
        All = 3,
        AuthLogin = 4
    }
    export enum PhoneQueueStatus {
        Unsupported = -1,
        Unavailable = 1,
        Available = 2,
        ACW = 3,
        SignIn = 4,
        SignOut = 5,
        Unknown = 0,
        Disabled = 6
    }
    export enum PhoneStatus {
        Unavailable = 1,
        Available = 2,
        Offline = 3,
        Busy = 4,
        ACW = 5
    }
    export enum TelephonyMessageType {
        Info = 0,
        Error = 1,
        Warning = 2,
        Success = 3
    }
    export enum AvailabilityStatus {
        Unavailable = -1,
        Offline = 0,
        InvalidUser = 1,
        NotLoggedIn = 2,
        Online = 3
    }
}
namespace Umbrella.TelephonyControl.Statistics {
    export enum TelephonyQueueKpiState {
        Ok = 0,
        RequiresAttention = 1,
        Critical = 2
    }
}
namespace Umbrella.Vendor {
    export enum EditVendorFields {
        None = 0,
        All = 1
    }
}
namespace Umbrella.Modules.Contacts {
    export enum ContactDirection {
        Inbound,
        Outbound
    }
}
