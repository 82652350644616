namespace Umbrella {
    @Pipe('Umbrella', 'mediaType')
    class MediaTypeFilter {
        public transform(input): string {
            const types = {
                MediaLinkModel: 'Link',
                MediaImageModel: 'Afbeelding',
                MediaVideoModel: 'Video',
                MediaDocumentModel: 'Document'
            };
            return types[input];
        }
    }
}
