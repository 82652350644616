/// <reference path="../../Decorators.ts" />
/// <reference path="../../Window.d.ts" />
namespace Umbrella {
    @Component('Umbrella', {
        selector: 'product-logo',
        templateUrl: '/Top/ProductLogoComponent/ProductLogo.html'
    })
    class UmbrellaLogoComponent {
        public title$: Rx.Observable<string>;

        public $onInit() {
            this.title$ = session$
                .where(x => x.config != null)
                .map(x => x.config.environmentName)
                .startWith('');
        }
    }
}
