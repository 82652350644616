namespace Umbrella.CustomerService.ComplexCard {
    @Component('CustomerService', {
        selector: 'complexcard-header',
        templateUrl:
            'CustomerService/_ComplexCard/ComplexCardHeaderComponent/ComplexCardHeader.html',
        bindings: {
            complex: '<'
        }
    })
    class ComplexCardHeaderComponent {}
}
