namespace Umbrella.CustomerService {
    import PhoneService = Modules.Telephony.PhoneService;
    import ContractorModel = Modules.Housing.ContractorModel;
    import copyToClipboard = Umbrella.Helpers.copyToClipboard;
    import AddressModel = Umbrella.Modules.AddressModel;
    import RegistrationsResource = Umbrella.CustomerService.CustomerCard.Registrations.RegistrationsResource;
    import RegistrantInfoModel = Umbrella.HousingAllocation.Registrations.RegistrantInfoModel;

    interface ContractorRegistrationInfo {
        personId: Guid;
        regInfo: RegistrantInfoModel;
    }

    const isHousingAllocationAvailable = () =>
        window &&
        window.config &&
        window.config.facets &&
        window.config.facets.housingAllocation &&
        window.config.facets.housingAllocation[0] &&
        window.config.facets.housingAllocation[0].available;

    @Component('CustomerService', {
        selector: 'card-sidebar-contractors',
        templateUrl:
            '/CustomerService/_CustomerCard/Sidebar/CardSidebarContractorsComponent/CardSidebarContractors.html',
        bindings: {
            collapsed: '<',
            person: '<',
            contractors: '<',
            onToggle: '&'
        }
    })
    @Inject('$sce', 'PhoneService', 'ToastMessageService', 'RegistrationsResource')
    class CardSidebarContractorsComponent {
        public collapsed: boolean;
        public contractors: ContractorModel[];
        public onToggle: () => void;
        public contractorsRegInfo: ContractorRegistrationInfo[];

        constructor(
            private $sce: ng.ISCEService,
            private phoneService: PhoneService,
            private toast: ToastMessageService,
            private registrationsResource: RegistrationsResource
        ) {}

        public $onInit() {
            if (isHousingAllocationAvailable()) {
                this.getContractorsRegistrantInfo();
            }
        }

        private async getContractorsRegistrantInfo(): Promise<void> {
            this.contractorsRegInfo = [];
            this.contractors.forEach(async c => {
                await this.registrationsResource
                    .getRegistrantInfoByPerson({
                        id: c.person.id
                    })
                    .$promise.then(x => {
                        this.contractorsRegInfo.push({
                            personId: c.person.id,
                            regInfo: x
                        });
                    });
            });
        }

        public hasActiveHousingRegistration(personId: Guid): boolean {
            if (!this.contractorsRegInfo || !this.contractorsRegInfo.length) return false;

            const contractor = this.contractorsRegInfo.find(c => c.personId === personId);
            if (!contractor) return false;

            return contractor.regInfo.hasActiveHousingRegistration;
        }

        public copyToClipboard = (text: string) =>
            copyToClipboard(text, () => this.toast.info('Telefoonnummer gekopieerd'));

        public callNr(nr: string): void {
            this.phoneService.callNr(nr);
        }

        public isOccupantOnly(person: PersonModel): boolean {
            return person && person.roles && Object.keys(person.roles).length === 1 && person.roles['occupant'];
        }

        public getAsTrustedUrl(url: string): string {
            if (!url || !url.length) return url;

            return this.$sce.trustAsResourceUrl(url);
        }

        public showCountry(address: AddressModel): Boolean {
            return (
                address.country && address.country.toLowerCase() != 'nederland' && address.country.toLowerCase() != 'nl'
            );
        }
    }
}
