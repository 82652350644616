/// <reference path="ToastMessageService.ts"/>
/// <reference path="SignalR.ts"/>

namespace Umbrella.Modules {
    @Service('Umbrella', 'SignalRStateNotifyingService')
    @Inject('ToastMessageService', 'OfflineService')
    export class SignalRStateNotifyingService {
        private waitTime = 10000; // wait before toast in ms
        private pendingNotification = null;
        private toastMessage = null;

        constructor(
            private toastMessageService: ToastMessageService,
            private offlineService: OfflineService
        ) {}

        public start() {
            const hasState = x => x && x.state;

            signalRConnectionState$
                .filter(hasState)
                .map(x => x.state)
                .distinctUntilChanged()
                .subscribe(x => {
                    if (x === 'disconnected') {
                        this.pendingNotification = setTimeout(() => {
                            if (
                                !this.offlineService.isOfflineMessageDisplayed()
                            )
                                this.toastMessage = this.toastMessageService.warning(
                                    'Verbinding met de server verbroken.',
                                    3600000
                                );
                        }, this.waitTime);
                    } else {
                        if (this.toastMessage) {
                            this.toastMessageService.clear(this.toastMessage);
                            this.toastMessage = null;
                        }
                        if (this.pendingNotification) {
                            clearTimeout(this.pendingNotification);
                            this.pendingNotification = null;
                        }
                    }
                });
        }
    }
}
