/// <reference path="../../../../Window.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Contracts {
    import ContractModel = Modules.Housing.ContractModel;
    import SessionColleagueModel = Modules.Colleagues.SessionColleagueModel;

    @Component('CustomerService', {
        selector: 'contract-timeline-item',
        templateUrl:
            '/CustomerService/_CustomerCard/_Contracts/ContractTimelineItemComponent/ContractTimelineItem.html',
        bindings: {
            person: '<',
            contract: '<'
        }
    })
    @Inject('$state')
    class ContractTimelineItemComponent {
        public contract: ContractModel;
        public person: PersonModel;
        public currentYear: number;
        public user: SessionColleagueModel;
        public displayCustomerInsteadOfAddress: boolean;
        private toggleStatus: boolean;

        constructor(private $state: ng.ui.IStateService) {
            this.user = window.user;
            this.toggleStatus = this.contract && this.contract.endDate && new Date(this.contract.endDate) < new Date();
        }

        public $onInit() {
            this.displayCustomerInsteadOfAddress = this.isOnUnitCard() && this.contractHasCustomers();
            this.currentYear = new Date().getFullYear();
        }

        public toggleContract() {
            this.toggleStatus = !this.toggleStatus;
        }

        public getFirstPersonId(): System.Guid {
            if (!this.contractHasCustomers()) return null;

            const customer = this.contract.customers[0];
            return customer.personId;
        }

        public contractHasCustomers(): boolean {
            return this.contract && this.contract.customers && this.contract.customers.length > 0;
        }

        public isOnUnitCard(): boolean {
            return this.$state.current.name.indexOf('unit') > -1;
        }
    }
}
