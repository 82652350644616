namespace Umbrella.CustomerService {
    import BillModel = Modules.Payments.Billing.BillModel;
    import BillPaymentModel = Modules.Payments.Billing.BillPaymentModel;
    import BillResource = Umbrella.CustomerService.CustomerCard.Bills.BillResource;
    import PaymentStatus = Modules.Payments.PaymentStatus;

    @Component('CustomerService', {
        selector: 'bill-timeline-item',
        templateUrl:
            '/CustomerService/_CustomerCard/_Bills/BillTimelineItemComponent/BillTimelineItem.html',
        bindings: {
            bill: '<',
            displayAddress: '<'
        }
    })
    @Inject('BillResource')
    class BillTimelineItemComponent {
        public visible: boolean;
        public bill: BillModel;
        public billPayments: BillPaymentModel[];

        constructor(private billResource: BillResource) {
            this.visible = false;
        }

        public toggleVisibility() {
            this.visible = !this.visible;

            if (!this.billPayments && this.visible) {
                this.billResource
                    .getAllPaymentsByBillId({ id: this.bill.id })
                    .$promise.then(
                        results =>
                            (this.billPayments = results.filter(result =>
                                this.filterForSuccessStatus(result.status)
                            ))
                    );
            }
        }

        public filterForSuccessStatus(status): any {
            switch (<PaymentStatus | string>status) {
                case 'Success':
                case PaymentStatus.Success:
                case 1:
                    return true;
                default:
                    return false;
            }
        }
    }
}
