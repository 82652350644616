namespace Umbrella {
    import MediaCategory = Umbrella.Media.MediaCategory;
    import MediaModel = Umbrella.Media.MediaModel;
    @Pipe('Umbrella', 'mediaIsDeletable')
    class MediaIsDeletableFilter {
        public transform(input: MediaModel): boolean {
            if (input) {
                const category: MediaCategory = MediaCategory[input.mediaCategory]
                switch (category) {
                    case MediaCategory.SelfServiceMedia:        // SelfServiceMedia = 9
                    case MediaCategory.CaseMedia:               // CaseMedia = 8,
                    case MediaCategory.TaskMedia:               // TaskMedia = 7,
                    case MediaCategory.RepairRequestItemMedia:  // RepairRequestItemMedia = 6,
                    case MediaCategory.KnowledgebaseItemMedia:  // KnowledgebaseItemMedia = 0,
                        return true;

                    default:                // AdvertisementMedia = 1,
                        return false;       // DefectMedia = 2,
                                            // MailMedia = 3,
                                            // InspectionMedia = 4,

                }
            } else {
                return false;
            }
        }
    }
}
