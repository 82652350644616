/// <reference path="../../../../ComponentRouteUtils.ts" />
/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard.Bills {
    import Detailed = Umbrella.Modules.Customers.CustomerModel.Detailed;
    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;

    export interface CustomerBillsComponentState {
        loading: boolean;
        person: PersonModel;
        customer: Detailed;
        billInfo: Bills.State;
        registrationInfo: RegistrationModel[];
    }

    @Component('CustomerService', {
        selector: 'customer-bills',
        templateUrl:
            '/CustomerService/_CustomerCard/_Bills/CustomerBillsComponent/CustomerBills.html',
        bindings: { state: '<' }
    })
    @Inject('CustomerCardBillInfoService')
    class CustomerBillsComponent {
        public state: CustomerBillsComponentState;

        constructor(private billInfoService: CustomerCardBillInfoService) {}

        public loadMore() {
            this.billInfoService.loadMore();
        }
    }
}
