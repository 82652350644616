namespace Umbrella.Modules.Chat {
    @Pipe('Chat', 'decodeHtml')
    class DecodeHtmlFilter {
        public transform(message: string) {
            if (!message) return '';

            const parse = document.createElement('parse');
            parse.innerHTML = message;
            return parse.textContent || parse.innerText;
        }
    }
}
