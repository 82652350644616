namespace Umbrella.Modules.Telephony {
    export interface IQuickDialScope {
        callNrDirect(nr: string, connectionId: string);
        callNr(nr: string);

        quickDials: any;
    }

    @Controller('Telephony', 'QuickDialController')
    export class QuickDialController {
        static $inject = ['$scope', 'PhoneService', 'QuickDialResource'];
        constructor(
            $scope: IQuickDialScope,
            phoneService,
            quickDialResource: QuickDialResource
        ) {
            $scope.callNrDirect = (nr: string, connectionId: string) => {
                phoneService.callNrDirect(nr, connectionId);
            };

            $scope.callNr = (nr: string) => {
                phoneService.callNr(nr);
            };

            quickDialResource.getAllActive().$promise.then(data => {
                $scope.quickDials = data;
            });
        }
    }
}
