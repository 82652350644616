/// <reference path="../../Decorators.ts" />

namespace Umbrella.CustomerService {
    import AppointmentModel = Umbrella.Modules.Colleagues.Calendar.AppointmentModel;

    class DayWithEvents {
        date: Date;
        events: AppointmentModel[];
    }

    @Component('CustomerService', {
        selector: 'event-calendar',
        templateUrl:
            '/CustomerService/EventCalendarComponent/EventCalendar.html',
        bindings: {
            events: '<',
            hideDatepicker: '<',
            hideTodayAgenda: '<',
            selectedDay: '<',
            onDaySelected: '&'
        }
    })
    class EventCalendarComponent {
        public events: AppointmentModel[];
        public hideDatepicker: boolean;
        public hideTodayAgenda: boolean;
        public onDaySelected: Function;

        public today: Date = new Date();
        public selectedDay: Date;
        public weekDays: Date[];
        public dayEvents: AppointmentModel[];
        public weekWithEvents: DayWithEvents[];
        public workHoursStart = 8;
        public workHoursEnd = 18;
        public workHours: number[];

        public $onInit() {
            this.selectedDay = this.selectedDay || new Date();
            this.workHours = this.generateWorkHours();
            this.weekDays = this.getWeekDays(this.selectedDay, 5);
        }

        public $onChanges() {
            this.events = this.events || [];

            const dayStart =
                (this.selectedDay && this.selectedDay.midnight()) ||
                new Date().midnight();
            const dayEnd = dayStart.addDays(1);
            this.dayEvents = this.events.length
                ? this.getEventsBetween(this.events, dayStart, dayEnd)
                : [];
        }

        public isWeekday(day: Date): boolean {
            return day.isWeekDay();
        }

        public selectDay(day: Date): void {
            if (!day) return;

            this.weekDays = this.getWeekDays(this.selectedDay, 5);
            this.onDaySelected({ value: day.midnight() });
        }

        public previousWeek(): void {
            const previousWeekStart = this.selectedDay;
            previousWeekStart.setDate(this.selectedDay.getDate() - 7);
            this.selectDay(previousWeekStart.midnight());
        }

        public nextWeek(): void {
            const nextWeekStart = this.selectedDay;
            nextWeekStart.setDate(this.selectedDay.getDate() + 7);
            this.selectDay(nextWeekStart.midnight());
        }

        public getMonthAndYear(): string {
            return (
                this.selectedDay.getMonthName('nl') +
                ' ' +
                this.selectedDay.getFullYear()
            );
        }

        public getSelectedDay(): Date {
            return this.selectedDay ? new Date(this.selectedDay) : new Date();
        }

        private generateWorkHours(): number[] {
            const workHoursArray = [];
            for (let i = this.workHoursStart; i <= this.workHoursEnd; i++) {
                workHoursArray.push(i);
            }

            return workHoursArray;
        }

        public isSameDay(d1: Date, d2: Date): boolean {
            return d1.getDate() === d2.getDate();
        }

        private getEventsBetween(
            events: AppointmentModel[],
            start: Date,
            end: Date
        ) {
            if (!events || !events.length) return [];

            return events.filter(
                a => new Date(a.startTime) < end && new Date(a.endTime) > start
            );
        }

        private getWeekDays(day: Date, days = 5): Date[] {
            const result = [];
            if (!day) return result;

            day.setHours(0, 0, 0, 0);
            while (result.length < days) {
                if (day.getDay() !== 0 && day.getDay() !== 6)
                    result.push(day.clone());
                day = day.addDays(1);
            }
            return result;
        }
    }
}
