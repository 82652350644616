/// <reference path="PhoneHub.ts" />
/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../Modules/Umbrella/SignalR.ts" />

namespace Umbrella.Modules.Telephony {
    import IRoleResource = Umbrella.Modules.Customers.IRoleResource;
    import PhoneModel = Umbrella.TelephonyControl.PhoneModel;
    import PhoneCallModel = Umbrella.TelephonyControl.PhoneCallModel;
    import PhoneCallRoleModel = Umbrella.TelephonyControl.PhoneCallRoleModel;
    import PhoneCallStatus = Umbrella.TelephonyControl.PhoneCallStatus;
    import PhoneQueueStatus = Umbrella.TelephonyControl.PhoneQueueStatus;
    import PhoneStatsModel = Umbrella.TelephonyControl.Statistics.PhoneStatsModel;
    import PhoneStatus = Umbrella.TelephonyControl.PhoneStatus;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;

    export interface RoleModelDictionary {
        [roleId: string]: RoleModel;
    }

    const telephonyInstalled = () => window && window.config && window.config.facets && window.config.facets.telephony;
    const telephonyAvailable = () =>
        window &&
        window.config &&
        window.config.facets &&
        window.config.facets.telephony &&
        window.config.facets.telephony[0] &&
        window.config.facets.telephony[0].available;

    @Service('Telephony', 'PhoneService')
    @Inject(
        'TopBarStore',
        '$filter',
        'NotificationService',
        'ToastMessageService',
        'RoleResource',
        'ActivityRegistrationService'
    )
    export class PhoneService extends BaseStoreService<TopBarState, TopBarEvent, TopBarStore> {
        private readonly roleModelCache: RoleModelDictionary = {};
        private inboundCallFilter: (calls: PhoneCallModel[]) => PhoneCallModel;
        private isRingingStatusFilter: (status: PhoneCallStatus) => boolean;

        constructor(
            store: TopBarStore,
            $filter: ng.IFilterService,
            private notificationService: NotificationService,
            private toastMessageService: ToastMessageService,
            private roleResource: IRoleResource,
            private activityRegistrationService: ActivityRegistrationService
        ) {
            super(store);

            this.inboundCallFilter = <any>$filter('inboundCall');
            this.isRingingStatusFilter = <any>$filter('isRingingStatus');

            Umbrella.session$
                .skipWhile(s => !s.config || !s.config.facets)
                .distinctUntilChanged()
                .map(s => s.config.facets)
                .subscribe(facets => {
                    this.emit({
                        type: 'TelephonyAvailabilityChangedEvent',
                        availabilityStatus: this.calculateTelephonyAvailablilty()
                    });
                });

            Umbrella.session$
                .filter(telephonyAvailable)
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .take(1)
                .subscribe(() => {
                    phoneHubOnError$.distinctUntilChanged().subscribe((phone: PhoneModel) => {
                        this.handlePhoneEvent(phone);
                        this.emit({
                            type: 'TelephonyErrorEvent',
                            phone,
                            error: ''
                        });
                    });

                    phoneHubOnUpdated$.distinctUntilChanged().subscribe((phone: PhoneModel) => {
                        this.handlePhoneEvent(phone);
                        this.emit({
                            type: 'TelephonyChangedEvent',
                            phone,
                            availabilityStatus: this.calculateTelephonyAvailablilty(phone)
                        });
                    });

                    phoneHubOnPhoneCallRolesUpdated$
                        .distinctUntilChanged()
                        .subscribe((phoneCallRoleModel: PhoneCallRoleModel) => {
                            this.retrieveRoleModelsForCall(phoneCallRoleModel);
                            this.emit({
                                type: 'PhoneCallRolesChangedEvent',
                                phoneCallRoleModel
                            });
                        });

                    phoneHubOnMessage$.distinctUntilChanged().subscribe((message: any) => {
                        switch (message.type) {
                            case 'Error':
                            case 1:
                                this.toastMessageService.error(message.message);
                                break;
                            case 2:
                            case 'Warning':
                                this.toastMessageService.warning(message.message);
                                break;
                            case 'Success':
                            case 3:
                                this.toastMessageService.success(message.message);
                                break;
                            case 0:
                            case 'Info':
                            default:
                                this.toastMessageService.info(message.message);
                                break;
                        }
                    });

                    phoneHubOnStatsUpdated$.distinctUntilChanged().subscribe((stats: PhoneStatsModel) => {
                        this.emit({
                            type: 'TelephonyStatisticsChangedEvent',
                            statistics: stats
                        });
                    });

                    this.reportUserReconnected();
                });
        }

        public hasActiveCall(roleId: System.Guid): boolean {
            const state = this.getState();
            const hasActiveCallForRoleId =
                state &&
                state.telephonyInfo &&
                state.telephonyInfo.phone &&
                state.telephonyInfo.phone.calls &&
                state.telephonyInfo.phone.calls.filter(x => x.subjectRoleId === roleId).length > 0;

            const hasAcwCallForRoleId =
                state &&
                state.telephonyInfo &&
                state.telephonyInfo.phone &&
                state.telephonyInfo.phone.status &&
                (state.telephonyInfo.phone.status === PhoneStatus.ACW ||
                    state.telephonyInfo.phone.status.toString() === 'ACW') &&
                state.telephonyInfo.phone.lastFinishedCall &&
                state.telephonyInfo.phone.lastFinishedCall.subjectRoleId === roleId;

            return hasActiveCallForRoleId || hasAcwCallForRoleId;
        }

        public callNr(nr: string) {
            if (this.isValidPhoneNr(nr) && window.user && this.allowedToCall(window.user.telephone, true)) {
                this.whenTelephonyAvailableAndSignalRInitialized(() => {
                    $.connection.phoneHub.server.dial(nr);
                });
            }
        }

        public callNrDirect(nr: string) {
            if (window.user && window.user.telephone.calls && this.allowedToCall(window.user.telephone, true)) {
                this.whenTelephonyAvailableAndSignalRInitialized(() => {
                    $.connection.phoneHub.server.transferDirect(nr, window.user.telephone.calls[0].connectionId);
                });
            }
        }

        public logout() {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.logout();
            });
        }

        public logoutMultipleByUserIds(userIds: Guid[]) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.logoutMultipleByUserIds(userIds);
            });
        }

        public releasecall(call: PhoneCallModel) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.releaseCall(call.connectionId);
            });
        }

        public forwardcall(call: PhoneCallModel) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.forwardCall(call.connectionId);
            });
        }

        public pickup(call: PhoneCallModel) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.pickup(call.connectionId);
            });
        }

        public holdcall(call: PhoneCallModel) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                switch (<PhoneCallStatus | string>call.status) {
                    case PhoneCallStatus.Established:
                    case 'Established':
                        $.connection.phoneHub.server.holdCall(call.connectionId);
                        break;
                    case PhoneCallStatus.OnHold:
                    case 'OnHold':
                        $.connection.phoneHub.server.resumeCall(call.connectionId);
                        break;
                }
            });
        }

        public statusChanged(status: PhoneStatus | string) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                switch (status) {
                    case PhoneStatus.Unavailable:
                    case 'Unavailable':
                        $.connection.phoneHub.server.ready();
                        break;
                    case PhoneStatus.Available:
                    case 'Available':
                        $.connection.phoneHub.server.notReady();
                        break;
                }
            });
        }

        public queueStatusChanged(queueStatus: PhoneQueueStatus | string) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                switch (queueStatus) {
                    case PhoneQueueStatus.Available:
                    case 'Available':
                        $.connection.phoneHub.server.leaveQueue();
                        break;
                    default:
                        $.connection.phoneHub.server.enterQueue();
                }
            });
        }

        public login(nr: string, password: string) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.login(nr, password);
                this.activityRegistrationService.loadRootTags();
            });
        }

        public setPhoneRichPresenceState(phoneRichPresenceState: string) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.setPhoneRichPresenceState(phoneRichPresenceState);
            });
        }

        public endAfterCallWork() {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.endAfterCallWork();
            });
        }

        public endAfterCallWorkAndLeaveQueue() {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.endAfterCallWorkAndLeaveQueue();
            });
        }

        public setRoleForCall(call: PhoneCallModel, roleId: System.Guid) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.setRoleForCall(call.phoneCallRegistrationId, roleId);
            });
        }

        public unsetRoleForCall(call: PhoneCallModel) {
            this.whenTelephonyAvailableAndSignalRInitialized(() => {
                $.connection.phoneHub.server.unsetRoleForCall(call.phoneCallRegistrationId);
            });
        }

        public isAvailableToUser(): boolean {
            return window.user && this.allowedToCall(window.user.telephone, false);
        }

        private allowedToCall(telephone: PhoneModel, showAlerts: boolean) {
            const result = telephonyAvailable() && this.isLoggedIn(telephone);

            if (showAlerts) {
                if (!telephonyInstalled()) alert('Telefonie is niet geinstalleerd in uw omgeving.');
                else if (!telephonyAvailable())
                    alert('Telefonie is op dit moment niet beschikbaar. Probeer het later opnieuw.');
            }

            return result;
        }

        private isLoggedIn(telephone: PhoneModel) {
            if (!telephone || !telephone.status) return false;
            const status: PhoneStatus | string = telephone.status;

            return (
                status.toString() === 'Unavailable' ||
                status === PhoneStatus.Unavailable ||
                status.toString() === 'Available' ||
                status === PhoneStatus.Available ||
                status.toString() === 'Busy' ||
                status === PhoneStatus.Busy ||
                status.toString() === 'ACW' ||
                status === PhoneStatus.ACW
            );
        }

        private isValidPhoneNr = (nr: string) => {
            return nr && nr !== 'Niet bekend';
        };

        private retrieveRoleModelsForCall(phoneCall: PhoneCallModel | PhoneCallRoleModel) {
            if (phoneCall.subjectRoleId) this.retrieveRoleModel(phoneCall.subjectRoleId);

            if (phoneCall.candidateRoleIds)
                for (const roleId of phoneCall.candidateRoleIds) this.retrieveRoleModel(roleId);
        }

        private retrieveRoleModel(roleId: System.Guid) {
            if (this.roleModelCache[roleId.toString()]) return;

            this.roleResource.getById({ id: roleId }).$promise.then((roleModel: RoleModel) => {
                this.emit({
                    type: 'TelephonyRoleModelRetrievedEvent',
                    role: roleModel
                });
            });
        }

        private handlePhoneEvent(phone: PhoneModel) {
            const session = session$.getValue();
            const user = (session && session.user) || <any>{};
            user.telephone = phone;

            session$.onNext({
                ...session,
                user: user
            });
            window.user.telephone = phone;

            const call = this.inboundCallFilter(phone && phone.calls);
            if (call && this.isRingingStatusFilter(call.status)) {
                this.notificationService.show('Inkomende oproep van ' + call.number + '.', call.number);
                this.emit({ type: 'TelephonyCallRingingEvent', phone, call });
            } else {
                this.notificationService.hide();
                this.emit({ type: 'TelephonyCallRingingStoppedEvent', phone });
            }
            if (phone && phone.calls) {
                for (const call of phone.calls) {
                    this.retrieveRoleModelsForCall(call);
                }
            }
        }

        private whenTelephonyAvailableAndSignalRInitialized(callback: () => void): void {
            if (telephonyAvailable()) {
                onSignalRInitialized(callback);
            }
        }

        private whenTelephonyAvailableAndSignalRConnected(callback: () => void): void {
            if (telephonyAvailable()) {
                onSignalRConnected(callback);
            }
        }

        private calculateTelephonyAvailablilty(phone?: PhoneModel): Umbrella.TelephonyControl.AvailabilityStatus {
            if (!phone || !telephonyInstalled()) {
                return Umbrella.TelephonyControl.AvailabilityStatus.Unavailable;
            } else if (!telephonyAvailable()) {
                return Umbrella.TelephonyControl.AvailabilityStatus.Offline;
            } else if (phone.phoneCapabilities.toString() === 'None' || phone.phoneCapabilities === 0) {
                return Umbrella.TelephonyControl.AvailabilityStatus.InvalidUser;
            } else if (phone.status.toString() !== 'Offline' && phone.status !== 3) {
                return Umbrella.TelephonyControl.AvailabilityStatus.Online;
            } else {
                return Umbrella.TelephonyControl.AvailabilityStatus.NotLoggedIn;
            }
        }

        private reportUserReconnected() {
            this.whenTelephonyAvailableAndSignalRConnected(() => {
                $.connection.phoneHub.server.reportUserReconnected();
            });
        }
    }
}
