namespace Umbrella.CustomerService.CustomerCard.CustomerSatisfaction {
    @Component('CustomerService', {
        selector: 'customer-score-created-timeline-item',
        templateUrl:
            '/CustomerService/_CustomerCard/_CustomerSatisfaction/CustomerScoreCreatedTimelineItemComponent/CustomerScoreCreatedTimelineItem.html',
        bindings: {
            customerSatisfactionId: '<'
        }
    })
    @Inject('CustomerSatisfactionResource')
    class CustomerScoreCreatedTimelineItemComponent {
        private customerSatisfactionId: System.Guid;

        public customerSatisfactionModel: CustomerSatifactionScoreGetModel;

        constructor(private customerSatisfactionResource: ICustomerSatisfactionResource) {
        }

        public $onInit() {
            this.customerSatisfactionResource.getById({ id: this.customerSatisfactionId })
                .$promise
                .then((model) => this.customerSatisfactionModel = model);
        }
    }
}
