﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;
    import TaskService = Umbrella.Modules.Tasks.TaskService;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;

    @Pipe('TaskHandling', 'canPickup')
    @Inject('TaskService')
    class CanPickupFilter {
        constructor(private taskService: TaskService) {}

        public transform(task: TaskModel, employee: SummarizedColleagueModel) {
            if (!task || !employee) return false;
            if (
                task.pickedUp ||
                task.status.toString() === 'Opgepakt' ||
                task.finished ||
                task.status.toString() === 'Afgerond' ||
                task.status.toString() === 'Verwijderd' ||
                task.status.toString() === 'Geannuleerd'
            )
                return false;
            if (!task.completeBy) return false;

            return this.taskService.isAssignedToMeOrOneOfMyFunctionGroups(task, employee);
        }
    }
}
