/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/angularjs/angular-ui-router.d.ts"/>
/// <reference path="../../../Modules/Repairs/RepairRequestAppointment.d.ts"/>

namespace Umbrella.Modules.Repairs {
    import RepairRequestAppointmentModel = Umbrella.Modules.Repairs.RepairRequestAppointmentModel;

    @Component('SelfService', {
        selector: 'repairs-appointment-timeline-information',
        templateUrl:
            '/Modules/Repairs/RepairsAppointmentTimelineComponent/RepairsAppointmentTimeline.html',
        bindings: {
            appointmentId: '<'
        }
    })
    @Inject('RepairRequestResource')
    export class ApppointmentTimelineComponent {
        public appointmentId: System.Guid;
        public appointment: RepairRequestAppointmentModel;
        constructor(private appointmentResource: IRepairRequestResourceClass) {}
        public $onInit() {
            if (this.appointmentId) {
                this.appointmentResource
                    .getAppointmentByAppointmentId({ id: this.appointmentId })
                    .$promise.then(data => (this.appointment = data));
            }
        }
    }
}
