/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/angularjs/angular-ui-router.d.ts" />
/// <reference path="../../../../Scope.d.ts"/>
/// <reference path="../../../../Window.d.ts"/>

namespace Umbrella.CustomerService {
    import AnnouncementModel = Modules.Announcements.AnnouncementModel;

    @Component('CustomerService', {
        selector: 'announcement-timeline',
        templateUrl:
            '/CustomerService/_CustomerCard/_Announcements/AnnouncementTimelineComponent/AnnouncementTimeline.html',
        bindings: {
            announcements: '<',
            paging: '<',
            pageSize: '<',
            hideFeedback: '<',
            loading: '<',
            onLoadMore: '&'
        }
    })
    export class AnnouncementTimelineComponent {
        public announcements: PagedItemsModel<AnnouncementModel>;
        public paging: boolean;
        public pageSize: number;
        public hideFeedback: boolean;
        public loading: boolean;
        public onLoadMore: () => void;
        public onDisplayAll: () => void;

        public getAnnouncements(): AnnouncementModel[] {
            if (!this.paging && this.announcements.items.length > this.pageSize)
                return this.announcements.items.slice(0, this.pageSize);

            return this.announcements.items;
        }

        public loadMore() {
            this.onLoadMore();
        }
    }
}
