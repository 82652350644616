namespace Umbrella.ChatConversationHandling {
    @Component('Chat', {
        selector: 'chat-default-messages',
        templateUrl: '/Modules/Chat/DefaultMessagesComponent/ChatDefaultMessages.html',
        bindings: {
            customerName: '<',
            onMessageSelected: '&',
            onClose: '&'
        }
    })
    @Inject('ChatResource')
    export class DefaultMessagesComponent {
        public defaultMessages: Umbrella.Chat.ChatDefaultMessageModel[];
        public onMessageSelected: () => (msg: string) => void;
        public onClose: () => () => void;
        private customerName: string;

        constructor(private chatResource: ChatResource) {}

        public $onInit() {
            this.chatResource.getDefaultMessages().$promise.then(data => {
                this.defaultMessages = data.messages;
                this.personalizeDefaultMessagesText();
                this.sortMessages();
            });
        }

        public messageSelected(message: string): void {
            this.onMessageSelected()(message);
        }

        public close(): void {
            this.onClose();
        }

        private personalizeDefaultMessagesText(): void {
            this.defaultMessages.forEach(msg => msg.message.replace('[[username]]', this.customerName));
        }

        private sortMessages(): void {
            this.defaultMessages.sort((a, b) => {
                const x = a.message.toLowerCase();
                const y = b.message.toLowerCase();

                return x < y ? -1 : x > y ? 1 : 0;
            });
        }
    }
}
