namespace Umbrella {
    export function stripHtml(html: string, fineTuning = false): string {
        if (!html.length) return html;
        let output = html;
        if (fineTuning) {
            output = html
                .replace(new RegExp(`</p>\n<(ol|ul)>`, 'g'), '')
                .replace(new RegExp('<li>', 'g'), '- ')
                .replace(new RegExp('</(p|h.)>', 'g'), '\n');
        }
        return output
            .toString()
            .replace(/<[^>]+>/gm, '')
            .replace('&nbsp;', ' ');
    }

    @Pipe('Umbrella', 'stripHtml')
    class StripHtmlFilter {
        public transform(html: string, fineTuning = false) {
            if (!html || !html.length) return '';

            return stripHtml(html, fineTuning);
        }
    }
}
