﻿namespace Umbrella {
    import MediaModel = Umbrella.Media.MediaModel;
    import MediaResourceProvider = Umbrella.Modules.MediaResourceProvider;
    import AdminMediaModel = Umbrella.Media.AdminMediaModel;

    @Component('Umbrella', {
        selector: 'attachment-list',
        templateUrl: '/AttachmentListComponent/AttachmentList.html',
        bindings: {
            media: '=?',
            cancelMediaUploadEnabled: '<?',
            mediaUploadId: '<?',
            mediaIds: '<',
            hideTitle: '<',
            fixedWidth: '<',
            isUploading: '<'
        }
    })
    @Inject('$mdDialog', 'ToastMessageService', 'MediaResourceProvider')
    export class AttachmentListComponent {
        constructor(
            private $mdDialog,
            private toastMessageService: ToastMessageService,
            private mediaResources: MediaResourceProvider
        ) {}

        public mediaUploadId: Guid;
        public mediaIds: Guid[] = [];
        public onlyIconAndFilename: boolean;
        public hideTitle: boolean;
        public fixedWidth: boolean;
        public mediaIsDeletable: boolean;
        public mediaIcon: string;
        public media: MediaModel[] = [];
        public isUploading: boolean;
        public cancelMediaUploadEnabled: boolean;

        public $onInit() {
            this.getMediaObjects(this.mediaIds);
        }

        public $onChanges() {
            if (!this.cancelMediaUploadEnabled) {
                if (this.mediaUploadId) this.mediaIds.push(this.mediaUploadId);
                this.getMediaObjects(this.mediaIds);
            }
        }

        public getImageSrc(imageId: System.Guid): string {
            return Config.makeAbsoluteApiUrl(`api/v1/media/crop?id=${imageId}&width=40&height=40`);
        }

        public viewImage(imageId: System.Guid): string {
            return Config.makeAbsoluteApiUrl('/api/v1/media/view/' + imageId);
        }

        public deleteMediaAction(mediaItem: MediaModel): void {
            if (!window.user.permissions.mediaClear) {
                this.toastMessageService.error('Geen permissie om bijlage te verwijderen');
                return;
            }
            const templateType = this.mediaIsDeleted(mediaItem) ? 'show-deleted-media-information' : 'delete-media';

            const template = `<${templateType}
                    media-item='${JSON.stringify(mediaItem)}'
                    on-delete="vm.deleteMedia()"
                    ></${templateType}>`;

            this.$mdDialog
                .show({
                    template,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(() => {
                    this.getMediaObjects(this.mediaIds);
                });
        }

        public mediaIconFn(mediaItem: MediaModel) {
            if (mediaItem) {
                return this.mediaIsDeleted(mediaItem) ? 'history' : 'delete_outline';
            }
            return '';
        }

        public updateMediaList() {
            this.mediaIcon = this.mediaIsDeletable ? 'history' : 'delete_outline';
        }

        private mediaIsDeleted(mediaItem: MediaModel) {
            return mediaItem.type === 'MediaClearedModel' ? true : false;
        }

        public cancelMediaUploadAction(mediaItem: MediaModel) {
            this.media.forEach((media, index) => {
                if (mediaItem === media) this.media.splice(index, 1);
            });
        }

        private processMedia(mediaToProcess: MediaModel) {
            if (!(this.media.some(existingMedia => existingMedia.id === mediaToProcess.id))) {
                this.media.push(mediaToProcess);
            }
            else {
                const index = this.media.findIndex(existingMedia => existingMedia.id === mediaToProcess.id);
                if (JSON.stringify(this.media[index]) !== JSON.stringify(mediaToProcess)) {
                    this.media[index] = mediaToProcess;
                }
            }
        }

        private getMediaObjects(ids: Guid[]): void {
            this.mediaResources.getByIdsV2({ ids }).$promise.then(mediaObjects => {
                mediaObjects.map((mediaObject: MediaModel) => this.processMedia(mediaObject));
            });
        }
    }
}
