namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import ThesaurusTagModel = Modules.Knowledgebase.ThesaurusTagModel;
    import FaqModel = Modules.Knowledgebase.FaqModel;
    import ChannelType = Modules.ChannelType;
    import SelfServiceScenarioModel = Umbrella.Modules.SelfService.SelfServiceScenarioModel;
    import TargetGroupModel = Modules.Knowledgebase.TargetGroupModel;
    import ContactActivityModel = Umbrella.Modules.Contacts.ContactActivityModel;
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;

    export const contactActivityRegistered$ = new Rx.Subject<ContactActivityModel>();
    export const taskCreated$ = new Rx.Subject<ActivityRegistrationData>();

    export interface ActivityRegistrationData {
        task: TaskModel;
        chatConversationId: Guid;
    }

    interface RecoveryData {
        channel: string;
    }

    @Service('CustomerService', 'ActivityRegistrationService')
    @Inject(
        '$location',
        'LocalStorageService',
        'SelfServiceScenarioResource',
        'ThesaurusResource',
        'FaqResource',
        'TargetGroupResource'
    )
    export class ActivityRegistrationService extends BaseStoreService<
        ActivityRegistrationState,
        ActivityRegistrationEvent,
        ActivityRegistrationStore
    > {
        private recoveryKey = 'recoveryData';

        constructor(
            private $location: ng.ILocationService,
            private localStorageService: LocalStorageService,
            private selfServiceScenarioResource: Umbrella.CustomerService.SelfServiceScenarioResource,
            private thesaurusResource: Modules.Knowledgebase.ThesaurusResource,
            private faqResource: Modules.Knowledgebase.Faq.FaqResourceClass,
            private targetGroupResource: Umbrella.Modules.Knowledgebase.TargetGroup.TargetGroupResourceClass
        ) {
            super(activityRegistrationStore);

            activityRegistrationStore.state$
                .map(x => (x && x.selectedChannelType) || ChannelType.Unknown)
                .where(t => t !== ChannelType.Unknown)
                .distinctUntilChanged()
                .subscribe(t => this.setRecoveryData({ channel: ChannelType[t] }));
        }

        public initialize(
            personId: System.Guid,
            channelName: string,
            tagId: System.Guid,
            faqId: System.Guid,
            query: string
        ) {
            const recoveredData = this.getRecoveryData();
            const channelType: ChannelType =
                ChannelType[channelName || recoveredData.channel || ChannelType[ChannelType.Unknown]];

            if (channelType !== ChannelType.Unknown) {
                this.selectChannel(channelType);
                this.loadRootTags()
                    .then(() => {
                        if (tagId) {
                            this.thesaurusResource.getById({ id: tagId }).$promise.then(tag => this.selectTag(tag));
                        }
                    })
                    .then(() => {
                        if (faqId) {
                            this.faqResource.getById({ id: faqId }).$promise.then(faq => this.selectFaq(faq));
                        }
                    });
            }

            this.loadSelfserviceScenariosForPerson(personId);
            this.loadTargetGroups(personId);
        }

        public search(text: string): void {
            const state = this.getState();
            if (state.query && state.query === text) return;

            this.emit({
                type: 'ActivityRegistrationSearchingEvent',
                query: text
            });
        }

        public getSearchQuery(): string {
            const state = this.getState();
            return (state && state.query) || '';
        }

        public selectPanel(panel: SelectedPanel): void {
            this.emit({
                type: 'ActivityRegistrationPanelSelectedEvent',
                panel
            });
        }

        public clearChannel(): void {
            const currentChannel = this.getState().selectedChannelType;
            if (currentChannel !== ChannelType.Unknown) {
                this.emit({ type: 'ActivityRegistrationChannelClearedEvent' });
            }
        }

        public selectChannel(channel: ChannelType): void {
            if (channel === null || channel === undefined) throw new Error('channel null or undefined');

            const existingParams = this.$location.search();
            this.$location.search({
                ...existingParams,
                channel: ChannelType[channel]
            });

            this.emit({
                type: 'ActivityRegistrationChannelSelectedEvent',
                channelType: channel
            });
        }

        public async loadRootTags(): Promise<void> {
            await this.thesaurusResource.getRootTags().$promise.then(tags =>
                this.emit({
                    type: 'ActivityRegistrationTagsLoadedEvent',
                    tags
                })
            );
        }

        public clearTag(): void {
            this.cacheTag(null);
        }

        public selectTag(tag: ThesaurusTagModel): void {
            if (tag === null || tag === undefined) throw new Error('tag null or undefined');

            this.cacheTag(tag);
        }

        private cacheTag(tag: ThesaurusTagModel): void {
            this.emit({
                type: 'ActivityRegistrationTagSelectedEvent',
                tag
            });
        }

        public selectFaq(faq: FaqModel) {
            if (!faq) throw new Error('Faq null or undefined');

            const s = this.getState();
            if (!s.selectedFaq || s.selectedFaq.id !== faq.id) {
                this.faqResource.increaseViewCount({ id: faq.id });
            }

            this.emit({
                type: 'ActivityRegistrationFaqSelectedEvent',
                faq
            });
        }

        public selectTargetGroup(targetGroup: TargetGroupModel) {
            this.emit({
                type: 'ActivityRegistrationTargetGroupSelected',
                targetGroup
            });
        }

        public selectFollowUpQuestion(followUpQuestionId: System.Guid) {
            this.emit({
                type: 'ActivityRegistrationFollowUpQuestionSelected',
                followUpQuestionId
            });
        }

        public async selectScenarioById(scenarioId: System.Guid): Promise<void> {
            const scenario = await this.selfServiceScenarioResource.getById({
                id: scenarioId
            }).$promise;
            this.selectScenario(scenario);
        }

        public selectScenario(scenario: SelfServiceScenarioModel.Detailed) {
            if (scenario === null || scenario === undefined) throw new Error('scenario null or undefined');

            this.emit({
                type: 'ActivityRegistrationScenarioSelectedEvent',
                scenario
            });

            this.thesaurusResource
                .getById({ id: scenario.thesaurusTagId })
                .$promise.then(thesaurusTag => this.selectTag(thesaurusTag))
                .catch(() => this.clearTag());
        }

        public getSelectedChannelType(): ChannelType {
            const s = this.getState();
            if (s && s.selectedChannelType) return s.selectedChannelType;

            const cachedChannelType = this.getRecoveryData();
            if (cachedChannelType && cachedChannelType.channel) return ChannelType[cachedChannelType.channel];

            return ChannelType.Unknown;
        }

        public getSelectedTagId(): System.Guid | null {
            const s = this.getState();
            return (
                (s.query && s.query.length && s.selectedFaq && s.selectedFaq.mainTag.id) ||
                (s.selectedTag && s.selectedTag.id) ||
                (s.selectedScenario && s.selectedScenario.thesaurusTagId)
            );
        }

        public getSelectedFaqId(): System.Guid | null {
            const s = this.getState();
            return s.selectedFaq && s.selectedFaq.id;
        }

        public getSelectedTargetgroupId(): System.Guid | null {
            const s = this.getState();
            return s.selectedTargetGroup && s.selectedTargetGroup.id;
        }

        public getSelectedFollowUpQuestionId(): System.Guid | null {
            const s = this.getState();
            return s.selectedFollowUpQuestionId;
        }

        public contactActivityRegistered(activity: ContactActivityModel): void {
            this.emit({
                type: 'ActivityRegistrationRegisteredEvent',
                activity
            });
            contactActivityRegistered$.onNext(activity);
        }

        public taskRegistered(task: TaskModel, chatConversationId?: Guid): void {
            this.emit({ type: 'ActivityRegistrationRegisteredEvent' });
            taskCreated$.onNext({ task, chatConversationId });
        }

        public getTargetGroups(): TargetGroupModel[] {
            const s = this.getState();
            return s && s.targetGroupInfo && s.targetGroupInfo.targetGroups;
        }

        private getRecoveryData(): RecoveryData {
            return (
                this.localStorageService.get(this.recoveryKey) || {
                    channel: ChannelType[ChannelType.Unknown]
                }
            );
        }

        private setRecoveryData(data: RecoveryData) {
            this.localStorageService.store(this.recoveryKey, data);
        }

        private loadTargetGroups(personId: System.Guid) {
            this.emit({ type: 'TargetGroupsLoadingEvent' });

            this.targetGroupResource
                .getAllForPersonId({ id: personId })
                .$promise.then((targetgroups: TargetGroupModel[]) => {
                    this.emit({
                        type: 'TargetGroupsLoadedEvent',
                        targetGroups: targetgroups
                    });
                });
        }

        private loadSelfserviceScenariosForPerson(personId: Guid) {
            this.selfServiceScenarioResource.getActiveByPersonId({ personId }).$promise.then(scenarios => {
                this.emit({
                    type: 'ActivityRegistrationScenariosForPersonLoadedEvent',
                    scenarios
                });
            });
        }
    }
}
