/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Decorators.ts" />
/// <reference path="../../../../Window.d.ts" />

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowMessageModel = Umbrella.CaseFlow.CaseFlowMessageModel;
    import MediaModel = Umbrella.Media.MediaModel;
    import MediaResourceProvider = Umbrella.Modules.MediaResourceProvider;
    import AdminMediaModel = Umbrella.Media.AdminMediaModel;

    export interface CaseFlowMessagesComponentState {
        messages: CaseFlowMessageModel[];
    }

    @Component('Dashboard', {
        selector: 'caseflow-message',
        templateUrl: '/CaseFlowProcessing/_Overview/_Details/CaseFlowMessageComponent/CaseFlowMessage.html',
        bindings: {
            message: '<',
        }
    })
    @Inject('MediaResourceProvider')
    export class CaseFlowMessageComponent {
        public message: CaseFlowMessageModel;

        private user: SessionColleagueModel = window.user;
        private media: MediaModel[];

        constructor(
            private mediaResources: MediaResourceProvider
        ) {}

        public $onInit() {
            if(this.message.mediaId)
                this.getMedia();
        }

        public getImageSrc(imageId: System.Guid): string {
            return Config.makeAbsoluteApiUrl(`api/v1/media/crop?id=${imageId}&width=40&height=40`);
        }

        public viewImage(imageId: System.Guid): string {
            return Config.makeAbsoluteApiUrl('/api/v1/media/view/' + imageId);
        }

        private getMedia() {
            const ids = [this.message.mediaId];
            this.mediaResources.getByIdsV2({ids}).$promise
            .then(mediaObjects => {
                this.media = mediaObjects as MediaModel[];
            })
        }
    }
}
