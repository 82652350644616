/// <reference path="../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService {
    import IResource = ng.resource.IResource;
    import PhoneCallRegistrationModel = Umbrella.TelephonyControl.Statistics.PhoneCallRegistrationModel;

    export interface PhoneCallsParams extends PagedRequestParams {
        userId: System.Guid;
        from: string;
        until: string;
    }

    export interface IPhoneCallResource extends IResource<PhoneCallRegistrationModel[]> {}

    export interface IPhoneCallResourceClass extends angular.resource.IResourceClass<PhoneCallRegistrationModel[]> {
        phoneCalls(params: PhoneCallsParams): IResource<PagedItemsModel<PhoneCallRegistrationModel>>;
    }

    angular.module('CustomerService').service('PhoneCallResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/telephony/phonestatistics/phonecalls/:userId?from=:from&until=:until',
                {},
                {
                    phoneCalls: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            userId: '@userId',
                            from: '@from',
                            until: '@until'
                        }
                    }
                }
            )
    ]);
}
