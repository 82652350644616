/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard.Bills {
    import IContractResource = Modules.Housing.IContractResource;
    import ContractFilterModel = Modules.Housing.ContractFilterModel;
    import debounce = Umbrella.Helpers.debounce;

    export interface CustomerBillFiltersComponentState {
        person: PersonModel;
    }

    @Component('CustomerService', {
        selector: 'customer-bill-filters',
        templateUrl:
            '/CustomerService/_CustomerCard/_Bills/CustomerBillFiltersComponent/CustomerBillFilters.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('ContractResource', 'CustomerCardBillInfoService')
    class CustomerBillFiltersComponent {
        public state: CustomerBillFiltersComponentState;
        public displayedContracts: ContractFilterModel[] = [];
        public selectedContract: ContractFilterModel;

        constructor(
            private contractResource: IContractResource,
            private billInfoService: CustomerCardBillInfoService
        ) {}

        public $onChanges() {
            const customerId =
                this.state &&
                this.state.person &&
                this.state.person.roles['customer'];
            const contractsLoaded = this.displayedContracts.length > 0;
            if (customerId && !contractsLoaded) this.loadContracts(customerId);
        }

        public searchContract = debounce(
            (query: string) => {
                const customerId = this.getCustomerId();
                this.loadContracts(customerId, query);
            },
            500,
            false
        );

        public filterByContract(contract: ContractFilterModel) {
            if (contract) this.billInfoService.filterByContract(contract.id);
            else this.billInfoService.ensureLoaded(); 
        }

        private getCustomerId(): System.Guid {
            return (
                this.state &&
                this.state.person &&
                this.state.person.roles['customer']
            );
        }

        private loadContracts(customerId: System.Guid, query?: string): void {
            this.contractResource
                .getFilterValuesByRoleId({
                    id: customerId,
                    page: 0,
                    pageSize: 255,
                    searchQuery: query
                })
                .$promise.then(data => {
                    this.displayedContracts = data.items;
                });
        }
    }
}
