namespace Umbrella.CustomerService {
    import ChannelType = Modules.ChannelType;

    export interface ChannelInfo {
        readonly title: string;
        readonly name: string;
        readonly type: ChannelType;
    }

    export function getChannelTypes(): ChannelInfo[] {
        return [
            {
                title: 'Baliebezoek',
                name: 'desk',
                type: ChannelType.Desk
            },
            {
                title: 'Telefoon',
                name: 'phone',
                type: ChannelType.Phone
            },
            {
                title: 'Huisbezoek',
                name: 'visit',
                type: ChannelType.Visit
            },
            {
                title: 'E-mail',
                name: 'email',
                type: ChannelType.Email
            },
            {
                title: 'Post',
                name: 'post',
                type: ChannelType.Post
            },
            {
                title: 'Social media',
                name: 'social',
                type: ChannelType.Social
            },
            {
                title: 'Chat',
                name: 'chat',
                type: ChannelType.Chat
            },
            {
                title: 'Enquête',
                name: 'survey',
                type: ChannelType.Survey
            },
            {
                title: 'Backoffice',
                name: 'backoffice',
                type: ChannelType.Backoffice
            }
        ];
    }
}
