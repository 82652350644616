/// <reference path="../../Scripts/TypeScript/signalr/signalr.d.ts" />

namespace Umbrella.Modules.Colleagues {
    angular
        .module('Colleagues')
        .filter('appointmentsOnWeekDays', () => appointments => {
            let show = [];

            if (appointments) {
                appointments.forEach(appointment => {
                    let startDate = appointment.item1;

                    if (!(startDate instanceof Date)) {
                        startDate = new Date(startDate);
                    }
                    if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
                        show.push(appointment);
                    }
                });
            }

            return show;
        })
        .filter('appointmentsBetween', () => (appointments, start, end) => {
            let show = [];

            appointments.forEach(appointment => {
                if (appointment.isAllDay) {
                    show.push(appointment);
                    return;
                }

                let startDate = appointment.startTime;

                if (!(startDate instanceof Date)) {
                    startDate = new Date(startDate);
                }

                let startHour = startDate.getHours();
                if (startHour >= start && startHour < end) {
                    show.push(appointment);
                }
            });

            return show;
        })
        .filter(
            'appointmentsBetweenWithThreshold',
            () => (appointments, threshold, end) => {
                let show = [];

                appointments.forEach(appointment => {
                    appointment.duration2 = appointment.duration;

                    if (appointment.isAllDay) {
                        show.push(appointment);
                        return;
                    }
                    //  StartHour:
                    let startDate = appointment.startTime;
                    if (!(startDate instanceof Date)) {
                        startDate = new Date(startDate);
                    }
                    let startHour = startDate.getHours();
                    //  EndHour:
                    let endDate = appointment.endTime;
                    if (!(endDate instanceof Date)) {
                        endDate = new Date(endDate);
                    }
                    let endHour = endDate.getHours();
                    //
                    if (
                        (startHour >= threshold && startHour < end) ||
                        (startHour < threshold &&
                            endHour > threshold &&
                            endHour < end)
                    ) {
                        show.push(appointment);
                    }
                    //  Add extra css-class if appointment starts before and ends after 8am:
                    if (startHour < threshold && endHour > threshold) {
                        appointment.startEarlyClass = ' startsearly';
                        appointment.duration2 =
                            appointment.duration -
                            (parseInt(threshold) - parseInt(startHour)) * 60 +
                            60;
                    } else {
                        appointment.startEarlyClass = '';
                    }

                    //  Add extra css-class if appointment starts before and ends after 6pm:
                    if (startHour < end && endHour > end) {
                        appointment.endsLateClass = ' endslate';
                        appointment.duration2 =
                            appointment.duration -
                            (parseInt(endHour) - parseInt(end)) * 60;
                    } else {
                        appointment.endsLateClass = '';
                    }
                });

                return show;
            }
        );
}
