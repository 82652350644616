﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Window.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Workload {
    angular.module('Dashboard').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.caseflow.workload', {
                url: '/workload',
                data: {
                    pageTitle: 'Zaken',
                    authorize: ps => ps.viewCaseFlow
                },
                views: {
                    'overview@dashboard': {
                        templateUrl:
                            '/CaseFlowProcessing/_Workload/CaseFlowWorkload.html'
                    }
                },
                resolve: {
                    _caseflows: [
                        'CaseFlowOverviewService',
                        (svc: CaseFlowOverviewService) => {
                            if (window.user.permissions.viewCaseFlow) {
                                svc.setToWorkloadView();
                                svc.ensureLoaded();
                            }
                        }
                    ]
                }
            });
        }
    ]);
}
