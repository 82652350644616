/// <reference path="../../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    unitValuationHub: Umbrella.CustomerService.UnitCard.Valuation.IUnitValuationHubProxy;
}

namespace Umbrella.CustomerService.UnitCard.Valuation {
    export interface IUnitValuationHubProxy extends HubProxy {
        client: IUnitValuationHubClient;
        server: IUnitValuationHubServer;
    }
    
    export interface IUnitValuationHubClient {
        updated(unitId: System.Guid);
        started(unitId: System.Guid);
    }

    export interface IUnitValuationHubServer {
        subscribe(unitId: System.Guid);
        unsubscribe(unitId: System.Guid);
    }        

    export const unitValuationHubOnUpdated$ = new Rx.Subject<System.Guid>();
    export const unitValuationHubOnJitStarted$ = new Rx.Subject<System.Guid>();

    angular
        .module('CustomerService')
        .factory('UnitValuationHub', () => $.connection.unitValuationHub);

    Modules.onSignalRInitialized(() => {
        $.connection.unitValuationHub.client.updated = unitId => {
            unitValuationHubOnUpdated$.onNext(unitId);
        };

        $.connection.unitValuationHub.client.started = unitId => {
            unitValuationHubOnJitStarted$.onNext(unitId);
        };
    });
}
