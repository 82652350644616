/// <reference path="../../../../ComponentRouteUtils.ts" />
/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard.Personal {
    import AgentFacetService = Modules.AgentFacetService;
    import EditCustomerDataComponent = Umbrella.CustomerService.CustomerCard.EditCustomerDataComponent;

    @Component('CustomerService', {
        selector: 'customer-characteristics',
        bindings: {
            person: '<',
            writableFields: '<'
        },
        templateUrl:
            '/CustomerService/_CustomerCard/_Personal/CustomerCharacteristicsComponent/CustomerCharacteristics.html'
    })
    @Inject('AgentFacetService', 'ToastMessageService', 'CustomerCardPersonInfoService')
    class CustomerCharacteristicsComponent extends EditCustomerDataComponent {
        public person: PersonModel;

        constructor(
            protected agentFacetService: AgentFacetService,
            protected toastMessageService: ToastMessageService,
            protected personInfoService: CustomerCardPersonInfoService
        ) {
            super(agentFacetService, toastMessageService, personInfoService);
        }

        public canEdit(fieldName: PersonFieldTypes): boolean {
            return this.writableFields.fields.indexOf(fieldName) !== -1;
        }

        public saveAndLeaveEditMode() {
            if (this.updating || !this.hasPermissions()) return;

            this.updating = true;

            this.person = this.personClone;

            const nrOfAddresses = (this.person && this.person.contactDetails.addresses.length) || 0;
            for (let count = 0; count < nrOfAddresses; count++)
                this.person.contactDetails.addresses[count].houseNumber =
                    this.person.contactDetails.addresses[count].houseNumber || 0;

            this.personInfoService
                .updateCharacteristics(this.person)
                .then(() => {
                    this.editMode = false;

                    this.toastMessageService.success('Wijzigingen zijn succesvol opgeslagen.');
                })
                .catch(() => {
                    this.toastMessageService.error('Fout opgetreden bij het opslaan van de wijzigingen.');
                })
                .finally(() => (this.updating = false));
        }

        private hasPermissions(): boolean {
            return !!window.user.permissions.customerManagement;
        }
    }
}
