/// <reference path="../../Top/PresenceHub.ts"/>
/// <reference path="../../AppRootComponent.ts" />
import switchDeviceMode = Umbrella.switchDeviceMode;

namespace Umbrella.Modules {
    export const selectFromSearch$ = new Rx.Subject();

    import debounce = Umbrella.Helpers.debounce;
    import PresenceResource = Umbrella.IPresenceResource;
    import colleagueStatusUpdated$ = Umbrella.colleagueStatusUpdated$;
    import StatusUpdateModel = Umbrella.Modules.Colleagues.StatusUpdateModel;
    import IUnitResource = Umbrella.Modules.Housing.Units.IUnitResource;
    import UnitModel = Umbrella.Modules.Housing.UnitModel;

    @Component('Umbrella', {
        selector: 'top-search',
        templateUrl: '/Top/TopSearchComponent/TopSearch.html'
    })
    @Inject(
        '$rootScope',
        '$location',
        '$state',
        '$filter',
        'PersonResource',
        'UnitResource',
        'PhoneService',
        '$mdDialog',
        'PresenceResource',
        'isMobilePhoneFilter'
    )
    class NewSearchComponent {
        public width = window.innerWidth;
        public obscureSearch: boolean;
        public showresults: boolean;
        public showSearchbox = true;
        public searchResults = null;
        public customers: PersonSearchModel[];
        public vendors: PersonSearchModel[];
        public colleagues: PersonSearchModel[];
        public units: UnitModel[];
        public searchQuery = '';
        public searching = false;
        public noResults = false;
        public applySearch: (query: string) => void;
        public user = window.user;
        public isMobile = false;

        private currentItem = null;
        private currentItemIndex = 0;
        private currentButton = null;
        private currentButtonIndex = 0;
        private searchBox: JQuery;
        private highlightFilter: (addr: string, params: { match: string; hide: boolean }) => string;
        private colleagueStatusUpdatedSubscription: Rx.IDisposable;

        constructor(
            private $rootScope: IUmbrellaRootScope,
            private $location: ng.ILocationService,
            private $state: ng.ui.IStateService,
            $filter: ng.IFilterService,
            private personResource: PersonResource,
            private unitResource: IUnitResource,
            private phoneService: Telephony.PhoneService,
            private $mdDialog,
            private presenceResource: PresenceResource,
            private isMobilePhone
        ) {
            this.isMobile = !window.device.desktop();

            this.searchBox = jQuery('#searchResultsBox');
            this.showresults = false;
            $('#searchQueryInput').bind('focus', () => {
                this.showSearchbox = true;
                $('body').on('click.searchboxClosed', e => {
                    const target = $(e.target);
                    if (
                        !target.is('#searchResultsBox') &&
                        !target.parents().is('#searchResultsBox') &&
                        !target.parents().is('.search-holder')
                    ) {
                        $('body').off('click.searchboxClosed');

                        this.showSearchbox = false;
                        //this.$apply();
                    }
                });
            });

            this.highlightFilter = <any>$filter('highlight');

            this.applySearch = debounce(
                (query: string) => {
                    this.search(query);
                },
                300,
                false
            );

            $rootScope.$on('performSearch', (event, query) => {
                this.searchQuery = query;
                this.search(query);
            });

            this.colleagueStatusUpdatedSubscription = colleagueStatusUpdated$
                .filter(x => !!x) //not null or undefined
                .distinctUntilChanged()
                .subscribe(statusUpdate => {
                    if (!this.colleagues || !this.colleagues.length) return;

                    this.colleagues
                        .filter(x => x.id === statusUpdate.userId)
                        .forEach(colleague => ((<any>colleague).status = statusUpdate.status));
                });

            // Hide results if navigating + update 'toggle CustomerDetails'-button (disabled when not on Customer/Colleague/Vendor Card)
            $rootScope.$on('$stateChangeSuccess', () => {
                this.hideSearchResults();
                this.obscureSearch =
                    $location.path().indexOf('kaart/') >= 0 || $location.path().indexOf('/dashboard/') >= 0;
            });
        }

        public $onDestroy() {
            this.colleagueStatusUpdatedSubscription.dispose();
        }

        public canCreateRelation(): boolean {
            return window.user.permissions.relationWrite;
        }

        public loadCard(stateName: string, personId: System.Guid) {
            this.hideSearchResults();
            if (window.config.facets.calendar && stateName === 'customerservice.colleaguecard.activities')
                stateName = 'customerservice.colleaguecard.calendar';
            this.$state.go(stateName, { personId, faq: null, tag: null, query: null }, { reload: true });
        }

        public hideSearchResults() {
            this.searchQuery = '';
            this.noResults = false;
            this.currentItemIndex = 0;
            this.currentItem = null;
            this.showresults = false;
            $('#searchQueryInput').off('keydown.navigate', e => this.handleKeyPress(e));
            $('body').off('click.searchboxClosed');
        }

        public showAddress(result: RoleModel, field, searchQuery) {
            if (result && result[field]) {
                const name = this.getShownAddressFieldName(result, searchQuery);
                return name === field;
            }

            return false;
        }

        public showBelMet(query): boolean {
            return this.phoneService.isAvailableToUser() && /^\d+$/.test(query);
        }

        public clearSearchbox() {
            this.searchQuery = '';
            return false;
        }

        public callNr(nr: string) {
            this.phoneService.callNr(nr);
            this.clearSearchbox();
        }

        public showRelationPopup(): void {
            this.clearSearchbox();
            this.searching = false;
            this.$mdDialog
                .show({
                    template: '<relation-popup></relation-popup>',
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(customer => {
                    this.loadCard('customerservice.customercard.timeline', customer.personId);
                });
        }

        private fullAddressLine(a: AddressModel) {
            return a.street + ' ' + a.houseNumber + ' ' + a.houseNumberExtension + ' ' + a.zipCode + ' ' + a.city;
        }

        private isMatchingAddress(address, searchQuery) {
            if (address) {
                const fullAddress = this.fullAddressLine(address);
                return (
                    this.highlightFilter(fullAddress, {
                        match: searchQuery,
                        hide: true
                    }) !== ''
                );
            }
            return false;
        }

        private getShownAddressFieldName = (result: RoleModel, searchQuery: string) => {
            if (this.isMatchingAddress(result.homeAddress, searchQuery)) return 'homeAddress';

            if (this.isMatchingAddress(result.postalAddress, searchQuery)) return 'postalAddress';

            return result.homeAddress ? 'homeAddress' : result.postalAddress ? 'postalAddress' : null;
        };

        private search(query: string) {
            this.showSearchbox = true;
            this.searching = true;
            if (query) {
                let searchQuery = query;
                if (searchQuery.charAt(searchQuery.length - 1) === '.') searchQuery = searchQuery.slice(0, -1);

                const toSearchResponseModel = x => {
                    const customers = x[0];
                    const vendors = x[1];
                    const colleagues = x[2];
                    const units = x[3];
                    return { customers, vendors, colleagues, units };
                };

                Rx.Observable.forkJoin(
                    <any>this.personResource.search({
                        id: searchQuery,
                        page: 0,
                        pageSize: 4,
                        roles: 'customer,prospect'
                    }).$promise,
                    <any>this.personResource.search({
                        id: searchQuery,
                        page: 0,
                        pageSize: 3,
                        roles: 'vendor'
                    }).$promise,
                    <any>(
                        this.personResource.search({ id: searchQuery, page: 0, pageSize: 3, roles: 'employee' })
                            .$promise
                    ),
                    <any>this.unitResource.search({ id: searchQuery, page: 0, pageSize: 3 }).$promise
                )
                    .map(toSearchResponseModel)
                    .subscribe(results => {
                        this.colleagues = results.colleagues;
                        this.customers = this.reorderContactDetails(query, results.customers);
                        this.vendors = results.vendors;
                        this.units = results.units;
                        this.noResults =
                            !results.colleagues.items.length &&
                            !results.customers.items.length &&
                            !results.vendors.items.length &&
                            !results.units.items.length;

                        this.showSearchResults();
                        this.searching = false;
                    });
            } else {
                this.hideSearchResults();
                this.searching = false;
            }
        }

        private reorderContactDetails(query: string, customers: any): PersonSearchModel[] {
            const q = query.split(" ").map(x => x.toLowerCase());
            const result = customers.items.map(customer => {
                const addresses = customer.contactDetails.addresses;
                if(addresses && addresses.length > 1) {                                    
                    addresses.forEach(address => {
                        address.hits = q.map(e => address.fullAddressLine.toLowerCase().indexOf(e)).filter(r => r > -1).length;                       
                    })
                    
                    customer.contactDetails.addresses[0] = addresses.sort((a,b) => b.hits-a.hits)[0]
                }

                return customer;
            }) 

            customers.items = result;    
            return customers; 
        }

        private down(currentItemIndex, items): number {
            if (currentItemIndex < items.length - 1)
                if (
                    items.eq(currentItemIndex).hasClass('result-data') ||
                    items.eq(currentItemIndex).hasClass('result-footer') ||
                    items.eq(currentItemIndex).hasClass('result-header')
                )
                    // if (items.eq(currentItemIndex).hasClass('result-data') || items.eq(currentItemIndex).hasClass('result-footer') || items.eq(currentItemIndex).hasClass('result-header'))
                    return this.down((currentItemIndex += 1), items);

            return currentItemIndex;
        }

        private up(currentItemIndex, items): number {
            if (currentItemIndex > 0)
                if (
                    items.eq(currentItemIndex).hasClass('result-data') ||
                    items.eq(currentItemIndex).hasClass('result-footer') ||
                    items.eq(currentItemIndex).hasClass('result-header')
                )
                    return this.up((this.currentItemIndex -= 1), items);

            return currentItemIndex;
        }

        public getMobile(person: PersonSearchModel): string {
            if (
                !person ||
                !person.contactDetails ||
                !person.contactDetails.phoneNumbers ||
                !person.contactDetails.phoneNumbers.length
            )
                return '';

            const firstMobile = person.contactDetails.phoneNumbers.find(x => this.isMobilePhone(x.number));
            return firstMobile && firstMobile.number;
        }

        public hasMobile(person: PersonSearchModel): boolean {
            if (
                !person ||
                !person.contactDetails ||
                !person.contactDetails.phoneNumbers ||
                !person.contactDetails.phoneNumbers.length
            )
                return false;

            return this.getMobile(person) ? true : false;
        }

        public getPhone(person: PersonSearchModel): string {
            if (
                !person ||
                !person.contactDetails ||
                !person.contactDetails.phoneNumbers ||
                !person.contactDetails.phoneNumbers.length
            )
                return '';

            const isValidNumber = x => x && x.length;

            const firstNumber = person.contactDetails.phoneNumbers
                .filter(x => isValidNumber(x.number))
                .find(x => !this.isMobilePhone(x.number));

            return firstNumber && firstNumber.number;
        }

        public hasPhone(person: PersonSearchModel): boolean {
            if (
                !person ||
                !person.contactDetails ||
                !person.contactDetails.phoneNumbers ||
                !person.contactDetails.phoneNumbers.length
            )
                return false;

            return this.getPhone(person) ? true : false;
        }

        public closeSearchOverlay() {
            if (!isDesktopModeActive()) {
                if (window.device.tablet() || window.device.mobile() || window.device.surface()) {
                    isSearchActive$.onNext(null);
                    toggleNavigationIcon$.onNext('search');
                }
            }
        }

        private handleKeyPress(event) {
            const items = this.searchBox
                .find('.result-block > .result-data > .result-row,li.searchQueryItem,div.search-kennisbank-item')
                .filter(':visible');

            if (this.currentItem) this.currentItem.removeClass('hover');

            if (this.currentButton && event.keyCode !== 13) this.currentButton.css('opacity', '1.0');

            if (event.keyCode === 40) {
                // down
                if (this.currentItem === null) {
                    this.currentItemIndex = 1;
                } else {
                    this.currentItemIndex++;
                    this.currentItemIndex = this.down(this.currentItemIndex, items);
                }

                this.currentButtonIndex = 0;
                this.currentButton = null;
            } else if (event.keyCode === 38) {
                // up
                if (this.currentItem === null) {
                    this.currentItemIndex = items.length - 1;
                } else {
                    this.currentItemIndex--;
                    this.currentItemIndex = this.up(this.currentItemIndex, items);
                }

                this.currentButtonIndex = 0;
                this.currentButton = null;
            } else if (event.keyCode === 13) {
                // enter
                if (this.showBelMet(this.searchQuery)) {
                    this.callNr(this.searchQuery);
                } else {
                    if (this.currentItem !== null) {
                        this.currentItem.prevObject[this.currentItemIndex].children[0].click();
                    }
                }
            } else {
                this.currentItem = null;
                this.currentItemIndex = 0;
            }

            if (this.currentItemIndex > items.length - 1) this.currentItemIndex = items.length - 1;
            else if (this.currentItemIndex < 0) this.currentItemIndex = 0;

            if (event.keyCode === 40 || event.keyCode === 38) {
                this.currentItem = items.eq(this.currentItemIndex);
            }

            // highlight the active item if (this.currentItem && !this.currentItem.hasClass('result-footer') && !this.currentItem.hasClass('result-data') && !this.currentItem.hasClass('result-header')) {
            if (
                this.currentItem &&
                !this.currentItem.hasClass('result-footer') &&
                !this.currentItem.hasClass('result-data') &&
                !this.currentItem.hasClass('result-header')
            ) {
                this.currentItem.addClass('hover');

                // handle left/right navigation inside the current item
                if (event.keyCode === 37 || event.keyCode === 39) {
                    const buttons = this.currentItem.find(
                        'a.mobiel:not(.disabled),a.telefoon:not(.disabled),a.contract:not(.disabled)'
                    );
                    if (event.keyCode === 37) {
                        // left
                        if (this.currentButton === null) {
                            this.currentButtonIndex = buttons.length - 1;
                        } else {
                            this.currentButtonIndex--;
                        }
                    } else if (event.keyCode === 39) {
                        // right
                        if (this.currentButton === null) {
                            this.currentButtonIndex = 0;
                        } else {
                            this.currentButtonIndex++;
                        }
                    }

                    if (this.currentButtonIndex > buttons.length - 1) {
                        this.currentButtonIndex = 0;
                        this.currentButton = null;
                    } else if (this.currentButtonIndex < 0) {
                        this.currentButtonIndex = buttons.length;
                        this.currentButton = null;
                    } else {
                        this.currentButton = buttons.eq(this.currentButtonIndex);

                        if (this.currentButton) this.currentButton.css('opacity', '0.6');
                    }
                }
            }

            // only show the call on enter display value if no currentitem is set or the active item is the current item
            if (this.currentItem) {
                if (!this.currentItem.is('li.searchQueryItem'))
                    this.searchBox
                        .find('#belBijEnter')
                        .first()
                        .css('display', 'none');
                else
                    this.searchBox
                        .find('#belBijEnter')
                        .first()
                        .css('display', 'inline');
            }

            // cancel default event handling
            if (this.currentItem && (event.keyCode === 40 || event.keyCode === 38)) return false;

            if (this.currentButton && (event.keyCode === 39 || event.keyCode === 39)) return false;

            return true;
        }

        private showSearchResults() {
            this.currentItem = null;
            this.currentItemIndex = 0;

            const searchbox = $('#searchQueryInput');
            searchbox.off('keydown.navigate');

            if (this.showSearchbox) searchbox.on('keydown.navigate', e => this.handleKeyPress(e));

            this.loadColleaguePresence();
        }

        private loadColleaguePresence() {
            const colleagues = this.colleagues && this.colleagues['items'];
            if (!colleagues || !colleagues.length) return;

            const colleagueId = x => x.id;
            const colleagueIds = colleagues.map(colleagueId);

            this.presenceResource.getStatusByIds({ ids: colleagueIds }).$promise.then(colleaguePhoneStatuses => {
                this.mapPhoneStatusesToColleagues(colleaguePhoneStatuses, colleagues);
            });
        }

        private mapPhoneStatusesToColleagues(
            colleaguePhoneStatuses: StatusUpdateModel[],
            colleagues: PersonSearchModel[]
        ) {
            for (const colleague of colleagues) {
                // tslint:disable-next-line:no-string-literal
                const colleagueId = colleague.id;
                const foundStatus = colleaguePhoneStatuses.filter(x => x.userId === colleagueId)[0];

                if (foundStatus) (<any>colleague).status = foundStatus.status;
            }
        }
    }
}