/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../ComponentRouteUtils.ts" />
/// <reference path="../../customerservice.routeconfig.ts" />

namespace Umbrella.CustomerService.CustomerCard.Activities {
    export interface CustomerActivitiesStateParams {
        openKnowledgebase: boolean;
        openSelfServicePanel: boolean;
        personId: System.Guid;
    }

    function mapState(s: CustomerCardState): CustomerActivitiesComponentState {
        return {
            loading:
                (!s ||
                !s.personal ||
                !s.personal.person ||
                s.personal.loading ||
                (s.personal.person.roles['customer'] && (!s.announcementInfo || s.announcementInfo.loading))) &&
                (!s || !s.registrationInfo || !s.registrationInfo.registrations || s.registrationInfo.loading),
            person: s && s.personal && s.personal.person,
            registrationInfo: s && s.registrationInfo && s.registrationInfo.registrations,
            customer: s && s.customerInfo && s.customerInfo.customer,
            announcementInfo: s && s.announcementInfo,
            activityInfo: s && s.activityInfo,
            activityRegistrationInfo: s && s.activityRegistrationInfo,
            selfServiceScenarios: s && s.activityRegistrationInfo && s.activityRegistrationInfo.scenarios
        };
    }

    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) =>
            $stateProvider.state('customerservice.customercard.timeline', {
                url: '/tijdlijn?openKnowledgebase&openSelfServicePanel',
                reloadOnSearch: false,
                data: { tab: 'tijdlijn', authorize: ps => ps.customerCard },
                views: {
                    'left@customerservice': {
                        template: '<customer-timeline-filters state="state$ | async:this"></customer-timeline-filters>',
                        controller: Umbrella.createComponentRouteController<
                            CustomerCardState,
                            Timeline.CustomerTimelineFiltersComponentState
                        >('CustomerCardStore', s => ({
                            person: s && s.personal && s.personal.person,
                            query: s && s.activityInfo && s.activityInfo.filters && s.activityInfo.filters.query,
                            selectedContract:
                                s && s.activityInfo && s.activityInfo.filters && s.activityInfo.filters.contractId,
                            activityCategories:
                                s &&
                                s.activityInfo &&
                                s.activityInfo.filters &&
                                s.activityInfo.filters.allActivityCategories,
                            selectedCategory:
                                s &&
                                s.activityInfo &&
                                s.activityInfo.filters &&
                                s.activityInfo.filters.activityCategory,
                            allScenarios:
                                s && s.accountInfo && s.activityInfo.filters && s.activityInfo.filters.allScenarios,
                            rootTags: s && s.accountInfo && s.activityInfo.filters && s.activityInfo.filters.rootTags
                        }))
                    },
                    'tab@customerservice': {
                        template: '<customer-activities state="state$ | async:this"></customer-activities>',
                        controller: createComponentRouteController('CustomerCardStore', mapState)
                    }
                },
                onEnter: [
                    '$stateParams',
                    'CustomerCardActivityInfoService',
                    'CustomerCardAnnouncementInfoService',
                    'CustomerCardRegistrationInfoService',
                    (
                        $stateParams: CardStateParams,
                        activityService: CustomerCardActivityInfoService,
                        announcementService: CustomerCardAnnouncementInfoService
                    ) => {
                        if (!$stateParams.personId) return;

                        activityService.ensureLoaded($stateParams.personId);
                        if (window.user.permissions.announcementRead) announcementService.ensureLoaded();
                    }
                ]
            })
    ]);
}
