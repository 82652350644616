/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/angularjs/angular-ui-router.d.ts" />
/// <reference path="../../../../Scope.d.ts"/>
/// <reference path="../../../../Window.d.ts"/>

namespace Umbrella.CustomerService {
    import AnnouncementModel = Modules.Announcements.AnnouncementModel;
    import ResidentialZoneModel = Umbrella.Modules.Housing.ResidentialZoneModel;
    import IResidentialZoneResourceClass = Umbrella.Modules.Housing.IResidentialZoneResourceClass;

    @Component('CustomerService', {
        selector: 'announcement-timeline-item',
        templateUrl:
            '/CustomerService/_CustomerCard/_Announcements/AnnouncementTimelineItemComponent/AnnouncementTimelineItem.html',
        bindings: {
            announcement: '<',
            displayAllAnnouncementsLink: '<'
        }
    })
    @Inject('PersonResource', 'ResidentialZoneResource')
    export class AnnouncementTimelineItemComponent {
        public announcement: AnnouncementModel;
        public displayAllAnnouncementsLink: boolean;

        public residentialZones: ResidentialZoneModel[];
        public people: PersonModel[];
        public visible: boolean;

        constructor(
            private personResource: PersonResource,
            private residentialZoneResource: IResidentialZoneResourceClass
        ) {}

        public toggleVisibility() {
            this.visible = !this.visible;

            if (!this.people && !this.residentialZones && this.visible) {
                this.loadResidentialZonesForSelectedAnnouncement();
                this.loadCustomersForSelectedAnnouncement();
            }
        }

        private loadResidentialZonesForSelectedAnnouncement() {
            this.residentialZones = [];

            if (
                this.announcement &&
                this.announcement.residentialZoneIds.length > 0
            )
                this.residentialZoneResource
                    .getByIds({ ids: this.announcement.residentialZoneIds })
                    .$promise.then(
                        residentialZones =>
                            (this.residentialZones = residentialZones)
                    );
        }

        private loadCustomersForSelectedAnnouncement() {
            this.people = [];

            if (this.announcement && this.announcement.personIds.length > 0)
                this.personResource
                    .getByIds({ ids: this.announcement.personIds })
                    .$promise.then(people => (this.people = people));
        }
    }
}
