/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import SelfServiceReportModel = Umbrella.Modules.SelfService.SelfServiceReportModel;

    export interface CaseFlowInformationComponentState {
        selfServiceReport: SelfServiceReportModel;
    }

    @Component('Dashboard', {
        selector: 'caseflow-information',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/_Information/CaseFlowInformationComponent/CaseFlowInformation.html',
        bindings: {
            state: '<'
        }
    })
    class CaseFlowInformationComponent {
        public state: CaseFlowInformationComponentState;

        public filterType(type): any {
            return function(
                value: Umbrella.Modules.Forms.FormSubmitValueModel
            ) {
                return value.value.type != type;
            };
        }
    }
}
