namespace Umbrella.CustomerService {
    import FaqModel = Umbrella.Modules.Knowledgebase.FaqModel;
    import FaqType = Umbrella.Modules.Knowledgebase.FaqType;

    @Pipe('CustomerService', 'faqHasFollowupQuestion')
    export class FaqHasFollowupQuestionFilter {
        public transform(faq: FaqModel) {
            return (
                faq.faqType.value.toString() ===
                    FaqType[FaqType.WithFollowUpQuestions] ||
                faq.faqType.value === FaqType.WithFollowUpQuestions
            );
        }
    }
}
