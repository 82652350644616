namespace Umbrella {
    import copyToClipboard = Umbrella.Helpers.copyToClipboard;

    @Component('Umbrella', {
        selector: 'umbrella-email',
        templateUrl: '/UmbrellaEmailComponent/UmbrellaEmail.html',
        bindings: {
            emailAddress: '<'
        }
    })
    @Inject('ToastMessageService')
    export class UmbrellaEmail {
        public emailAddress: string;

        constructor(private toast: ToastMessageService) {}

        public copyToClipboard = (text: string) =>
            copyToClipboard(text, () => this.toast.info('E-mailadres gekopieerd'));
    }
}
