/// <reference path="../ObservableStore.ts"/>
/// <reference path="../RootStore.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="Chat.d.ts"/>

namespace Umbrella.ChatConversationHandling {
    import Guid = System.Guid;

    export interface ChatState {
        readonly enabled: boolean;
        readonly isLoggedIn: boolean;
        readonly registration: RegistrationState;
        readonly conversations: ChatOverviewItemModel[];
        readonly conversationsStatistics: ConversationStatistics[];
        readonly selectedOriginId: Guid;
        readonly activeId: Guid;
        readonly loading: boolean;
        readonly selectedAnswer: string;
    }
    export interface ConversationStatistics {
        id: System.Guid;
        lastReadTime: Date;
    }

    export interface EnabledEvent {
        type: 'EnabledEvent';
    }

    export interface RegisteredEvent {
        type: 'RegisteredEvent';
    }

    export interface DeregisteredEvent {
        type: 'DeregisteredEvent';
    }

    export interface ConversationPickedupEvent {
        type: 'ConversationPickedupEvent';
        requestId: Guid;
        conversationId: Guid;
    }
    export interface ConversationEnterEvent {
        type: 'ConversationEnterEvent';
        conversationId: Guid;
    }
    export interface ConversationLeaveEvent {
        type: 'ConversationLeaveEvent';
    }
    export interface StartAfterCallWorkEvent {
        type: 'StartAfterCallWorkEvent';
        conversationId: Guid;
    }
    export interface ConversationEndEvent {
        type: 'ConversationEndEvent';
        conversationId: Guid;
    }
    export interface ConversationsLoadingEvent {
        readonly type: 'ConversationsLoadingEvent';
    }

    export interface ConversationsLoadedEvent {
        readonly type: 'ConversationsLoadedEvent';
        readonly conversations: ChatOverviewItemModel[];
    }

    export interface ConversationselectedEvent {
        readonly type: 'ConversationSelectedEvent';
        readonly originId: System.Guid;
    }

    export interface ConversationMessagesReadEvent {
        readonly type: 'ConversationMessagesReadEvent';
        readonly conversationId: System.Guid;
    }

    export interface ChatOperatorLoginStatusChangedEvent {
        readonly type: 'ChatOperatorLoginStatusChangedEvent';
        readonly isLoggedIn: boolean;
    }

    export interface SelectedAnswerEvent {
        readonly type: 'SelectedAnswerEvent';
        readonly answer: string;
    }

    export type ChatEvent =
        | RegisteredEvent
        | DeregisteredEvent
        | EnabledEvent
        | ConversationsLoadingEvent
        | ConversationsLoadedEvent
        | ConversationselectedEvent
        | ConversationEnterEvent
        | ConversationLeaveEvent
        | StartAfterCallWorkEvent
        | ConversationEndEvent
        | ConversationPickedupEvent
        | ConversationMessagesReadEvent
        | ChatOperatorLoginStatusChangedEvent
        | SelectedAnswerEvent;

    export enum RegisterStatus {
        NotRegistered,
        Registered,
        Deregistered
    }

    export interface RegistrationState {
        status: RegisterStatus;
        error: string;
    }

    export interface WaitingStatistics {
        waitingCustomersCount: number;
        maxWaitingTimeSeconds: number;
        waitingStatsLastUpdated: Date;
        averageWaitingPickupTime: number;
        averageWaitingReplyTime: number;
        operatorLoad: number;
        activeConversations: number;
    }

    export interface ChatConversationFilters {
        readonly query: string;
        readonly status: string;
    }

    function reduce(e: ChatEvent): ObservableStore.Reducer<ChatState> {
        switch (e.type) {
            case 'EnabledEvent':
                return s => ({
                    ...s,
                    isLoggedIn: false,
                    enabled: true,
                    conversations: [],
                    conversationsStatistics: []
                });

            case 'RegisteredEvent':
                return s => ({
                    ...s,
                    registration: {
                        status: RegisterStatus.Registered,
                        error: null
                    }
                });

            case 'DeregisteredEvent':
                return s => ({
                    ...s,
                    registration: {
                        status: RegisterStatus.NotRegistered,
                        error: null
                    },
                    conversations: [],
                    conversationsStatistics: [],    
                    originId: null,
                    activeId: null,
                    isLoggedIn: false
                });

            case 'ConversationsLoadingEvent':
                return s => ({
                    ...s,
                    loading: true
                });

            case 'ConversationsLoadedEvent':
                return s => ({
                    ...s,
                    conversations: [...e.conversations],
                    loading: false
                });

            case 'ChatOperatorLoginStatusChangedEvent':
                return s => ({
                    ...s,
                    isLoggedIn: e.isLoggedIn
                });

            case 'ConversationMessagesReadEvent': {
                return s => ({
                    ...s,
                    conversationsStatistics: s.conversations.map(c => {
                        const stats = s.conversationsStatistics.find(s => s && s.id === c.id);
                        if (c.id === e.conversationId) {
                            return { id: c.id, lastReadTime: new Date() };
                        }
                        return stats;
                    })
                });
            }

            case 'ConversationSelectedEvent':
                return s => ({
                    ...s,
                    selectedOriginId: e.originId
                });

            case 'ConversationPickedupEvent':
                return s => ({
                    ...s,
                    conversations: s.conversations.map(x => (x.originId === e.requestId ? ToActive(x) : x)),
                    conversationsStatistics: [...s && s.conversationsStatistics, {id: e.conversationId, lastReadTime: new Date()}]
                });

            case 'ConversationEnterEvent':
                return s => ({
                    ...s,
                    activeId: e.conversationId
                });

            case 'ConversationLeaveEvent':
                return s => ({
                    ...s,
                    activeId: null
                });

            case 'StartAfterCallWorkEvent':
                return s => ({
                    ...s,
                    conversations: s.conversations.map(x => (x.id === e.conversationId ? ToAcw(x) : x))
                });

            case 'ConversationEndEvent':
                return s => ({
                    ...s,
                    conversations: s.conversations.filter(x => x.id !== e.conversationId)
                });
            
            case 'SelectedAnswerEvent':
                return s => ({
                    ...s,
                    selectedAnswer: e.answer
                });
        }
    }

    export function ToAcw(conversation: ChatOverviewItemModel): ChatOverviewItemModel {
        conversation.status = ConversationStatus.ACW;
        return conversation;
    }

    export function ToActive(conversation: ChatOverviewItemModel): ChatOverviewItemModel {
        conversation.status = ConversationStatus.Active;
        return conversation;
    }

    export type ChatStore = ObservableStore.EventStore<ChatState, ChatEvent>;

    export const chatStore: ChatStore = rootStore.map(lens<ChatState>('chat'), reduce);

    angular.module('Chat').value('ChatStore', chatStore);
}
