/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;
    import TaskService = Umbrella.Modules.Tasks.TaskService;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;

    angular.module('TaskHandling').filter('canFinish', [
        'TaskService',
        (taskService: TaskService): Function => {
            return (task: TaskModel.Detailed, employee: SummarizedColleagueModel): boolean => {
                if (!task || !employee) return false;
                if (!(task.status.toString() === 'Opgepakt')) return false;
                if (!task.completeBy) return false;

                return taskService.isAssignedToMeOrOneOfMyFunctionGroups(task, employee);
            };
        }
    ]);
}
