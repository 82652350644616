/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Decorators.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStep = Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;
    import CaseFlowStepStatus = Umbrella.CaseFlow.CaseFlowStepStatus;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import ColleagueModel = Umbrella.Modules.Colleagues.ColleagueModel;
    import FunctionGroupModel = Umbrella.Modules.Knowledgebase.FunctionGroupModel;
    import IFunctionGroupResourceClass = Umbrella.Modules.Colleagues.IFunctionGroupResourceClass;
    import CaseFlowStepTask = Umbrella.CaseFlow.CaseFlowStep.CaseFlowStepTask;
    import CaseFlowAssignmentType = Umbrella.CaseFlow.CaseFlowAssignmentType;

    @Component('Dashboard', {
        selector: 'case-flow-steps',
        templateUrl: '/CaseFlowProcessing/_Overview/_Details/_Steps/CaseFlowStepsComponent/CaseFlowSteps.html',
        bindings: {
            steps: '<',
            activeStep: '<',
            person: '<',
            personId: '=',
            caseId: '=',
            caseFlowId: '=',
            renderButtons: '<'
        }
    })
    @Inject('$rootScope', 'ColleagueResource', 'FunctionGroupResource', 'ToastMessageService', '$mdDialog')
    class CaseFlowStepsComponent {
        public caseId: System.Guid;
        public caseFlowId: System.Guid;
        public person: PersonModel;
        public personId: System.Guid;
        private steps: CaseFlowStep[];
        private selectedStep: System.Guid;
        private activeStep: System.Guid;
        private colleagues: ColleagueModel[] = [];
        private functionGroups: FunctionGroupModel[] = [];

        constructor(
            private $rootScope: IUmbrellaRootScope,
            private colleagueResource: IColleagueResourceClass,
            private functionGroupResource: IFunctionGroupResourceClass,
            private toastSvc: ToastMessageService,
            private $mdDialog
        ) { }

        public $onInit() {
            this.selectedStep = this.activeStep;

            this.loadColleagues();
            this.loadFunctionGroups();
        }

        public toggleStep(step: CaseFlowStep, event) {
            let el = event.currentTarget;
            while (el.parentNode) {
                el = el.parentNode;
                if (el.tagName && el.tagName.toLowerCase() === 'activity-timeline-item') {
                    this.$rootScope.$broadcast('toggleCaseFlowStep', {
                        item: el
                    });
                    break;
                }
            }
            this.selectedStep = step.id === this.selectedStep ? null : step.id;
        }

        private loadColleagues() {
            if (!this.steps) return;

            const ids = this.steps
                .filter(
                    x => x.pickedUpByModel !== null &&
                        (
                            x.pickedUpByModel.type === CaseFlowAssignmentType.Employee ||
                            x.pickedUpByModel.type.toString() === CaseFlowAssignmentType.Employee.toString() ||
                            x.pickedUpByModel.type.toString() === 'Employee'
                        )
                )
                .map(x => x.pickedUpByModel.id);

            if (ids.length > 0) {
                this.colleagueResource
                    .getByIds({
                        ids
                    })
                    .$promise.then(peoples => {
                        peoples.forEach(p => (this.colleagues[p.id.toString()] = p));
                    });
            }
        }

        private loadFunctionGroups() {
            if (!this.steps) return;

            const ids = this.steps.filter(x => x.functionGroupId).map(x => x.functionGroupId);
            this.functionGroupResource.getByIds({ ids }).$promise.then(functiongroups => {
                functiongroups.forEach(functiongroup => {
                    this.functionGroups[functiongroup.id.toString()] = functiongroup;
                });
            });
        }

        public canPickUpStep(step: CaseFlowStep): boolean {
            return (
                step &&
                step.status &&
                (step.status.toString() === "Started" || step.status.toString() === "1") &&
                step.pickedUpOn === null &&
                step.finishedOn === null
            );
        }

        public pickUpStep(step: CaseFlowStep): void {
            if (!window.user.permissions.pickupCaseFlowStep) {
                this.toastSvc.error('Zaakstap kan niet opgepakt worden. Onvoldoende rechten.');
                return;
            }

            const stepId: System.Guid | string = (step && step.id) || '';
            const caseFlowId: System.Guid | string = this.caseFlowId || '';

            this.$mdDialog.show({
                template: `<pickup-caseflowstep-popup case-flow-id="${caseFlowId}" step-id="${stepId}"></pickup-caseflowstep-popup>`,
                targetEvent: null,
                clickOutsideToClose: false
            });
        }

        public finishStep(step: CaseFlowStep): void {
            if (!window.user.permissions.finishCaseFlowStep) {
                this.toastSvc.error('Onvoldoende rechten om de zaakstap af te ronden');
                return;
            }

            this.$mdDialog.show({
                template:
                    '<finish-caseflow-step-popup step="step" case-flow-id="caseFlowId"></finish-caseflow-step-popup>',
                locals: {
                    step: step,
                    caseFlowId: this.caseFlowId
                },
                controller: [
                    '$scope',
                    'step',
                    'caseFlowId',
                    ($scope, step, caseFlowId) => {
                        $scope.step = step;
                        $scope.caseFlowId = caseFlowId;
                    }
                ],
                clickOutsideToClose: false
            });
        }

        public isAutorizedToFinishStep(): boolean {
            return window.user.permissions.finishCaseFlowStep;
        }

        public isAutorizedToPickUpStep(): boolean {
            return window.user.permissions.pickupCaseFlowStep;
        }

        public allRequiredTasksAreFinished(step: CaseFlowStep): boolean {
            return !(
                this.isStepPickedUp(step) &&
                step.tasks &&
                step.tasks.filter(s => !this.isFinishedTask(s) && s.isOptional === false).length > 0
            );
        }

        public isStepPickedUp(step: CaseFlowStep): boolean {
            return step && step.pickedUpOn !== null && step.finishedOn === null;
        }

        public $onChanges(bindings: {
            activeStep: IBindingChange<System.Guid>;
            steps: IBindingChange<CaseFlowStep[]>;
        }) {
            if (
                bindings.activeStep &&
                bindings.activeStep.currentValue &&
                bindings.activeStep.currentValue !== bindings.activeStep.previousValue
            ) {
                this.selectedStep = bindings.activeStep.currentValue;
            }

            if (
                bindings.steps &&
                bindings.steps.currentValue &&
                bindings.steps.currentValue !== bindings.steps.previousValue
            )
                this.loadColleagues();
        }

        public isFinishedStep(status: CaseFlowStepStatus | string | number): boolean {
            return status && (
                status.toString() === "Finished" ||
                status.toString() === "3" ||
                status.toString() === "Canceled" ||
                status.toString() === "4"
            );
        }

        isFinishedTask(task: CaseFlowStepTask): boolean {
            return task.isFinished;
        }
    }
}
