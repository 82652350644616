/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../customerservice.colleaguecard.routeconfig.ts" />

namespace Umbrella.CustomerService.ColleagueCard.Activities {
    import PermissionsModel = Umbrella.PermissionsModel;
    import PagedItemsModel = Umbrella.PagedItemsModel;
    import ActivityModel = Umbrella.Modules.ActivityModel;
    import IActivityResourceClass = Umbrella.Modules.IActivityResourceClass;
    import ColleagueModel = Umbrella.Modules.Colleagues.ColleagueModel;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;

    @Component('CustomerService', {
        selector: 'colleague-activities',
        templateUrl:
            '/CustomerService/_ColleagueCard/_Activities/ColleagueActivitiesComponent/ColleagueActivities.html'
    })
    @Inject('$stateParams', 'ActivityResource', 'ColleagueResource')
    export class ColleagueActivitiesComponent {
        public permissions: PermissionsModel;
        public loading: boolean;
        public loadingActivities: boolean;
        public activities: PagedItemsModel<ActivityModel>;
        public colleague: ColleagueModel.Detailed;

        constructor(
            private $stateParams: ColleagueCardStateParams,
            private activityResource: IActivityResourceClass,
            private colleagueResource: IColleagueResourceClass
        ) {}

        public $onInit() {
            this.permissions = window.user.permissions;
            this.loadColleague();
            this.loadActivities();
        }

        private loadColleague(): void {
            this.loading = true;
            this.colleagueResource
                .getById({ id: this.$stateParams.personId })
                .$promise.then(data => {
                    this.colleague = data;
                    this.loading = false;
                });
        }
        protected loadActivities(): void {
            this.loadingActivities = true;
            const page = this.activities ? this.activities.page + 1 : 0;
            const pageSize = this.activities ? this.activities.pageSize : 20;
            this.activityResource.getByColleagueId(
                { id: this.$stateParams.personId, page, pageSize },
                activities => {
                    this.activities = {
                        items: this.activities
                            ? this.activities.items.concat(activities.items)
                            : activities.items,
                        total: activities.total,
                        page: activities.page,
                        pageSize: activities.pageSize
                    };
                    this.loadingActivities = false;
                }
            );
        }
    }
}
