﻿/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Window.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowModel = Umbrella.CaseFlow.CaseFlowModel;
    import CaseModel = Umbrella.Modules.Customers.v2.CaseModel;
    import AssignmentModel = Umbrella.CaseFlow.AssignmentModel;

    export interface CaseFlowComponentState {
        case: CaseFlowModel;
        caseHistory: CaseModel;
        messageCount: number;
        publicMessageCount: number;
        person: PersonModel;
        media: Umbrella.Modules.Customers.caseMedia[];
    }

    @Component('Dashboard', {
        selector: 'caseflow',
        templateUrl: '/CaseFlowProcessing/_Overview/_Details/CaseFlowComponent/CaseFlow.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$mdDialog')
    class CaseFlowOverviewDetailsComponent {
        public state: CaseFlowComponentState;
        public user = window.user;

        constructor(private $mdDialog) {}

        public cancel(): void {
            if (!this.state.case) return;

            this.$mdDialog.show({
                template: '<cancel-caseflow-popup caseflow-id="caseflowId"></cancel-caseflow-popup>',
                locals: {
                    caseflowId: this.state.case.id
                },
                controller: [
                    '$scope',
                    'caseflowId',
                    ($scope, caseflowId) => {
                        $scope.caseflowId = caseflowId;
                    }
                ],
                clickOutsideToClose: false
            });
        }
    }
}
