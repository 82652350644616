/// <reference path="ObservableStore.ts"/>
/// <reference path="Scripts/TypeScript/immutable/immutable.d.ts"/>

namespace Umbrella {
    export type RootState = Immutable.Map<string, any>;

    export const rootStore = ObservableStore.create<RootState, {}>(
        Immutable.Map({}),
        e => null
    );

    export function lens<T>(prop: string): ObservableStore.Lens<RootState, T> {
        return {
            get: store => {
                const val = store.get(prop);
                if (val && val.toJS) return val.toJS();

                return val;
            },
            set: (store, v) => store.set(prop, v)
        };
    }

    export const history = [];

    rootStore.state$.subscribe(s => history.push(s.toJS()));
}

let logHistorySubscription: Rx.IDisposable = null;

function enableHistoryLogging() {
    if (logHistorySubscription) {
        console.debug('Already logging history');
    } else {
        console.debug('Started logging. Last state:');
        logHistorySubscription = Umbrella.rootStore.state$.subscribe(s =>
            console.log('Root state: ', s.toJS())
        );
    }
}

function disableHistoryLogging() {
    if (logHistorySubscription) {
        console.debug('Stopped logging.');
        logHistorySubscription.dispose();
        logHistorySubscription = null;
    } else {
        console.debug('Not yet logging history');
    }
}
