/// <reference path="../../../Decorators.ts" />
/// <reference path="../_Activities/CustomerActivitiesComponent/CustomerActivitiesComponent.ts" />

namespace Umbrella.CustomerService {
    import IMenuTab = Modules.IMenuTab;
    import IStateMenuTab = Modules.IStateMenuTab;
    import QuickMenuButtonDefinition = CustomerService.QuickMenuButtonDefinition;
    import CustomerCardAnnouncementInfoService = Umbrella.CustomerService.CustomerCard.CustomerCardAnnouncementInfoService;
    import openKnowledgebase$ = Umbrella.CustomerService.CustomerCard.Activities.openKnowledgebase$;
    import openSelfServicePanel$ = Umbrella.CustomerService.CustomerCard.Activities.openSelfServicePanel$;
    import ICaseHistoryResourceClass = Modules.Cases.ICaseHistoryResourceClass;
    import RegistrationsResource = Umbrella.CustomerService.CustomerCard.Registrations.RegistrationsResource;
    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;

    @Inject(
        '$state',
        '$stateParams',
        '$mdDialog',
        'CustomerCardAnnouncementInfoService',
        'CaseHistoryResource',
        'ToastMessageService',
        'RegistrationsResource'
    )
    @Component('CustomerService', {
        selector: 'customercard-menu',
        template: `
            <div class="uc-cardmenu sectionmenu green">
                <menu-tabs tabs="vm.tabs" md-theme="umb-green"></menu-tabs>
                <quick-menu buttons="vm.buttons"></quick-menu>
            </div>
        `,
        bindings: {
            person: '<',
            registrationInfo: '<'
        }
    })
    class CustomerCardMenuComponent {
        public person: PersonModel;
        public registrationInfo: RegistrationModel[];
        public tabs: IMenuTab[];
        public buttons: QuickMenuButtonDefinition[];

        constructor(
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private $mdDialog: any,
            private announcementInfoService: CustomerCardAnnouncementInfoService,
            private caseHistoryResource: ICaseHistoryResourceClass,
            private toastMessageService: ToastMessageService,
            private registrationsResource: RegistrationsResource
        ) {}

        public $onInit() {
            this.createTabs();
            this.createButtons();
        }

        public $onChanges() {
            this.createTabs();
        }

        private createTabs() {
            this.tabs = [];

            const isCustomer = this.person.roles['customer'];
            const isProspect = this.registrationInfo && this.registrationInfo.length > 0;

            if (!window.user.permissions.customerCard) return;

            this.addTab(this.createTab('Tijdlijn', 'customerservice.customercard.timeline'));
            this.addTab(this.createTab('Klantgegevens', 'customerservice.customercard.personal'));

            if (isCustomer && window.user.permissions.contractRead)
                this.addTab(this.createTab('Contracten', 'customerservice.customercard.contracts'));

            if (isProspect && window.user.permissions.registrationRead)
                this.addTab(this.createTab('Inschrijvingen', 'customerservice.customercard.registrations'));

            if (isCustomer && window.user.permissions.billRead)
                this.addTab(this.createTab('Financieel', 'customerservice.customercard.bills'));

            if (window.user.permissions.announcementRead)
                this.addTab(this.createTab('Mededelingen', 'customerservice.customercard.announcements'));

            if (window.user.permissions.customerSatisfactionRead)
                this.addTab(this.createTab('Klantscore', 'customerservice.customercard.customersatisfaction'));
        }

        public registerAnnouncement(): void {
            if (!this.$stateParams.personId) return;

            this.$mdDialog
                .show({
                    template: `<register-announcement-popup person-id="${this.$stateParams.personId}"></register-announcement-popup>`,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(() => {
                    this.announcementInfoService.reload();
                });
        }

        private createButtons() {
            this.buttons = [
                {
                    tooltip: 'Nieuwe mededeling',
                    label: 'Mededeling aanmaken',
                    auth: 'announcementWrite',
                    class: 'md-warn',
                    icon: 'volume_up',
                    action: () => this.registerAnnouncement()
                },
                {
                    tooltip: 'Direct regelen',
                    label: 'Direct regelen',
                    auth: 'caseCreate',
                    class: 'purple, hide-for-mobile',
                    icon: 'mouse',
                    action: () => this.openSelfServicePanel()
                },
                {
                    tooltip: 'Contact vastleggen',
                    label: 'Contact vastleggen',
                    auth: 'caseCreate',
                    class: 'md-primary',
                    icon: 'assignment_turned_in',
                    action: () => this.openKnowledgebase()
                }
            ];

            const userHasPermissions = window.user.permissions.caseRead && window.user.permissions.exportTimeline;
            if (userHasPermissions) {
                this.buttons.push({
                    tooltip: 'Export tijdlijn',
                    label: 'Tijdlijn exporteren',
                    auth: 'caseCreate',
                    class: 'md-accent md-hue-2',
                    icon: 'save_alt',
                    action: () => this.exportTimeline()
                });
            }
        }

        private exportTimeline(): void {
            this.caseHistoryResource
                .startTimelineExport({ id: this.person.id })
                .$promise.then(() => {
                    this.toastMessageService.info('Tijdlijn exporteren gestart');
                })
                .catch(() => {
                    this.toastMessageService.error('Fout opgetreden tijdens exporteren van de tijdlijn');
                });
        }

        private openKnowledgebase(): void {
            if (this.$state.current.name === 'customerservice.customercard.timeline') openKnowledgebase$.onNext(true);
            else this.$state.go('customerservice.customercard.timeline', { openKnowledgebase: true });
        }

        private openSelfServicePanel(): void {
            if (this.$state.current.name === 'customerservice.customercard.timeline')
                openSelfServicePanel$.onNext(true);
            else this.$state.go('customerservice.customercard.timeline', { openSelfServicePanel: true });
        }

        private createTab(title: string, state: string): IStateMenuTab {
            return {
                title,
                state
            };
        }

        private addTab(tab: IStateMenuTab) {
            this.tabs.push(tab);
        }
    }
}
