/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('mdHideChildElementsOnMenuClose', [
        '$mdSelect',
        $mdSelect => {
            return {
                link(scope, element, attrs) {
                    scope.$on('$mdMenuClose', (ev, element) => {
                        $mdSelect.hide();
                        document
                            .getElementsByClassName('md-clickable')[0]
                            .querySelectorAll('.md-toggle')[0]
                            .setAttribute('style', 'height: 0');
                    });
                }
            };
        }
    ]);
}
