namespace Umbrella.ChatConversationHandling {
    import Guid = System.Guid;

    export interface Emojifier {
        emojify(input: string): string;
    }

    const pendingConversationStatuses = ['0', 'Pending'];
    const detachedConversationStatuses = ['1', 'Detached'];
    const activeConversationStatuses = ['2', 'Active'];
    const acwConversationStatuses = ['3', 'ACW'];
    const endedConversationStatuses = ['4', 'Ended'];

    export const isPendingConversation = (conversationStatus): boolean => {
        return pendingConversationStatuses.some(x => x == conversationStatus);
    };

    export const isDetachedConversation = (conversationStatus): boolean => {
        return detachedConversationStatuses.some(x => x == conversationStatus);
    };

    export const isActiveConversation = (conversationStatus): boolean => {
        return activeConversationStatuses.some(x => x == conversationStatus);
    };

    export const isAcwConversation = (conversationStatus): boolean => {
        return acwConversationStatuses.some(x => x == conversationStatus);
    };

    export const isEndedConversation = (conversationStatus): boolean => {
        return endedConversationStatuses.some(x => x == conversationStatus);
    };

    export const isActiveOrAcwConversation = (conversationStatus): boolean => {
        return isActiveConversation(conversationStatus) || isAcwConversation(conversationStatus);
    };

    export const isAcwOrEndedConversation = (conversationStatus): boolean => {
        return isAcwConversation(conversationStatus) || isEndedConversation(conversationStatus);
    };

    export const isPendingPendingOrEndedConversation = (conversationStatus): boolean => {
        return isPendingConversation(conversationStatus) || isEndedConversation(conversationStatus);
    };

    const newPopupStatuses = ['0', 'New'];
    const openPopupStatuses = ['1', 'Open'];
    const minimizedPopupStatuses = ['2', 'Minimized'];

    export const isConversationPopupStatusNew = (conversationPopupStatus): boolean => {
        return newPopupStatuses.some(x => x == conversationPopupStatus);
    };

    export const isConversationPopupStatusOpen = (conversationPopupStatus): boolean => {
        return openPopupStatuses.some(x => x == conversationPopupStatus);
    };

    export const isConversationPopupStatusMinimized = (conversationPopupStatus): boolean => {
        return minimizedPopupStatuses.some(x => x == conversationPopupStatus);
    };

    export const isConversationPopupStatusNewOrMinimized = (conversationPopupStatus): boolean => {
        return (
            newPopupStatuses.some(x => x == conversationPopupStatus) ||
            minimizedPopupStatuses.some(x => x == conversationPopupStatus)
        );
    };

    export const allowedEmoticons = [':)', ':(', ';)', ':D', ':O', ':P', ':d', ':o', ':p', ';('];

    export const emojifier = (() => {
        // :) is emoticon
        // smile.png is emoji

        const emoticonToEmojiMap = {
            ':)': '😃',
            ':(': '😟',
            ';)': '😉',
            ':D': '😁',
            ':d': '😁',
            ':O': '😱',
            ':o': '😱',
            ':P': '😋',
            ':p': '😋',
            ';(': '😢'
        };

        const self: Emojifier = {
            emojify: (input: string): string => {
                let emojifiedText: string = input;
                allowedEmoticons.forEach((currentEmoticon: string, index: number) => {
                    if (input.indexOf(currentEmoticon) > -1)
                        emojifiedText = input.replace(currentEmoticon, emoticonToEmojiMap[currentEmoticon]);
                });
                return emojifiedText;
            }
        };

        return self;
    })();
}
