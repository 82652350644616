﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>

namespace Umbrella.CustomerService.UnitCard {
    export interface UnitCardStateParams extends ng.ui.IStateParamsService {
        unitId: System.Guid;
    }

    angular.module('CustomerService').config([
        '$stateProvider',
        '$urlRouterProvider',
        ($stateProvider: angular.ui.IStateProvider, $urlRouterProvider: angular.ui.IUrlRouterProvider) => {
            $urlRouterProvider.when('/objectkaart/:unitId', '/objectkaart/:unitId/tijdlijn');

            $stateProvider
                .state('customerservice.unitcard', {
                    abstract: true,
                    reloadOnSearch: false,
                    url: '/objectkaart/:unitId',
                    data: { pageTitle: 'Eenheidkaart' },
                    views: {
                        'right@customerservice': {
                            template: '<unitcard-sidebar state="state$ | async:this"></unitcard-sidebar>',
                            controller: createComponentRouteController<UnitCardState, UnitCardSidebarComponentState>(
                                'UnitCardStore',
                                s => ({
                                    unit: s && s.unit,
                                    contracts: s && s.contractInfo && s.contractInfo.contracts
                                })
                            )
                        }
                    },
                    onEnter: [
                        '$stateParams',
                        'UnitCardService',
                        ($stateParams: UnitCardStateParams, unitCardService: UnitCardService) => {
                            unitCardService.loadByUnitId($stateParams.unitId);
                            unitCardService.loadContracts();
                        }
                    ],
                    onExit: [
                        'UnitCardService',
                        (unitCardService: UnitCardService) => {
                            unitCardService.unload();
                        }
                    ]
                })
                .state('customerservice.unitcard.tab', {
                    reloadOnSearch: false,
                    abstract: true
                });
        }
    ]);
}
