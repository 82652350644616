namespace Umbrella {
    export declare class AdminPersonResource {
        update(model: PersonModel): ng.resource.IResource<PersonModel>;
    }

    angular.module('Umbrella').service('AdminPersonResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/people/admin',
                {},
                {
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: {}
                    }
                }
            )
    ]);
}
