namespace Umbrella.CustomerService {
    import ICaseHistoryResourceClass = Modules.Cases.ICaseHistoryResourceClass;
    import IActivityResourceClass = Modules.IActivityResourceClass;
    import ActivityModel = Modules.ActivityModel;
    import TaskTypeCategory = Umbrella.Modules.Tasks.TaskTypeCategory;

    @Inject(
            'ActivityResource',
            'CaseHistoryResource'
    )
    @Component('CustomerService', {
        selector: 'case-activities',
        templateUrl: 'CaseActivitiesComponent/CaseActivitiesComponent.html',
        bindings: {
            caseId: '<',
            loadedPersonId: '<'
        }
    })
    export class CaseActivitiesComponent {
        public activities: ActivityModel[];
        public loadedPersonId: Guid;
        private caseId: Guid;
        private templatesFinishedLoading: number;
        private templatesDoneLoading: boolean;
        constructor(
            private activityResource: IActivityResourceClass,
            private caseHistoryResource: ICaseHistoryResourceClass,
        ) {}

        public $onInit() {
            this.loadActivities();
        }

        private loadActivities() {
            this.activityResource.getByCaseId({ id: this.caseId, pageSize: 150 }).$promise.then(activities => {
                const isCaseFlowTaskActivity = x =>
                    x &&
                    x.task &&
                    x.task.taskType &&
                    (x.task.taskType.category === TaskTypeCategory.CaseFlow || x.task.taskType.category === 'CaseFlow');
                const isTaskRelatedActivity = (x: ActivityModel) =>
                    x &&
                    x.type.toLowerCase().indexOf('taak') === 0 &&
                    !(x.type.toLowerCase().indexOf('taakaangemaakt') === 0);
                const filteredItems = activities.items
                    .filter(x => !isCaseFlowTaskActivity(x))
                    .filter(x => !isTaskRelatedActivity(x));
                filteredItems.forEach(x => this.groupTaskActivities(x, activities.items));
                this.activities = filteredItems;
            });
        }

        public templateFinishedLoading() {
            this.templatesFinishedLoading += 1;
            if (this.templatesFinishedLoading === this.activities.length) {
                this.templatesDoneLoading = true;
            }
        }

        private groupTaskActivities(activity: any, items: ActivityModel[]): void {
            const isTaskCreatedActivity = x => x && x.task && x.type.toLowerCase().indexOf('taakaangemaakt') === 0;

            if (!isTaskCreatedActivity(activity)) return;

            const isLinkedActivity = (x, y) => x.task && x.task.id === y.task.id;
            const linkedActivities = items.filter(x => isLinkedActivity(x, activity));
            activity.linkedActivities = linkedActivities;
        }
    }
}
