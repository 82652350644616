namespace Umbrella.Modules.Mail {
    import OutgoingMailActivityModel = Umbrella.Modules.Mail.OutgoingMailActivityModel;
    import OutgoingMailResource = Umbrella.Modules.Mail.IOutgoingMailResourceClass;

    export interface ResendOutgoingMailPopupButtonScope {
        title: string;
        outgoingMailActivityModel: OutgoingMailActivityModel;
        launchResendOutgoingMailPopup(): void;
    }

    @Controller('Mail', 'ResendOutgoingMailPopupButtonController')
    @Inject('$scope', 'CreateDialog', 'OutgoingMailResource')
    export class ResendOutgoingMailPopupButtonController {
        constructor(
            public $scope: ResendOutgoingMailPopupButtonScope,
            createDialog,
            outgoingMailResource: OutgoingMailResource
        ) {
            $scope.launchResendOutgoingMailPopup = () => {
                outgoingMailResource
                    .get({
                        id: $scope.outgoingMailActivityModel.outgoingMailId
                    })
                    .$promise.then(mail =>
                        createDialog(
                            '/Modules/Umbrella/Admin/Mail/ForwardMailPopup.html',
                            {
                                id: 'resendMailPopup',
                                modalClass: 'popup green popup_800',
                                controller: 'ForwardMailPopupController'
                            },
                            {
                                mail
                            }
                        )
                    );
            };
        }
    }
}
