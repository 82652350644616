/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts" />
/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts" />
/// <reference path="../../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.Modules.Colleagues {
    export interface IColleagueResource
        extends angular.resource.IResource<Modules.Colleagues.ColleagueModel.Detailed>,
            Modules.Colleagues.ColleagueModel.Detailed {}
    export interface ISearchPagedParams extends PagedRequestParams {
        id?: string;
    }
    export interface ISearchPagedWithRoleParams extends ISearchPagedParams {
        authorizationRoleId?: System.Guid;
    }
    export interface ISearchPagedWithFunctiongroupsParams extends ISearchPagedParams {
        userId?: System.Guid;
    }

    export interface IGetByIdsParams {
        ids: System.Guid[];
    }

    export interface IGetByUnitAndTagRequestParams extends PagedRequestWithIdParams {
        unitId: System.Guid;
    }

    export interface IColleagueResourceClass extends angular.resource.IResourceClass<IColleagueResource> {
        getById(params: IdRequestParams): ng.resource.IResource<Modules.Colleagues.ColleagueModel.Detailed>;
        getByIds(params: IGetByIdsParams): ng.resource.IResource<Modules.Colleagues.ColleagueModel.Detailed[]>;
        getAll(
            params: PagedRequestParams,
            succes?: Function,
            error?: Function
        ): PagedItemsModel<Modules.Colleagues.SummarizedColleagueModel>;
        getAllWithRoles(
            params: PagedRequestParams,
            succes?: Function,
            error?: Function
        ): PagedItemsModel<Modules.Colleagues.SummarizedColleagueModel>;
        getAllWithRolesByRoleId(
            params: ISearchPagedWithRoleParams,
            succes?: Function,
            error?: Function
        ): PagedItemsModel<Modules.Colleagues.SummarizedColleagueModel>;
        update(model: ColleagueModel.Detailed): IColleagueResource;
        add(model: IColleagueResource, succes: Function, error: Function);
        search(
            params: ISearchPagedParams,
            succes?: Function,
            error?: Function
        ): PagedItemsModel<Modules.Colleagues.SummarizedColleagueModel>;
        searchWithRoles(
            params: ISearchPagedWithRoleParams,
            succes?: Function,
            error?: Function
        ): PagedItemsModel<Modules.Colleagues.SummarizedColleagueModel>;
        getColleaguesByTagId(
            params: PagedRequestWithIdParams
        ): ng.resource.IResource<Modules.Colleagues.SummarizedColleagueModel[]>;
        getColleaguesByTagAndUnitId(
            params: IGetByUnitAndTagRequestParams
        ): ng.resource.IResource<Modules.Colleagues.SummarizedColleagueModel[]>;
        getColleaguesAndFunctiongroups(
            params: ISearchPagedWithFunctiongroupsParams
        ): ng.resource.IResource<PagedItemsModel<Modules.Colleagues.SummarizedColleagueModel.WithFunctionGroup>>;
    }

    interface IResourceParams {
        action?: string;
        page?: number;
        pageSize?: number;
        id?: string;
        userId?: string;
    }

    class ColleagueResource {
        static $inject = ['$resource'];

        constructor(public $resource: angular.resource.IResourceService) {}

        getColleagueResource(): IColleagueResourceClass {
            return this.$resource<Modules.Colleagues.ColleagueModel.Detailed, IColleagueResourceClass>(
                '/api/v1/colleague/:action/:id',
                {},
                {
                    getById: this.getResource('GET', false, { action: '@id' }),
                    getByIds: this.getResource('GET', true, { action: 'set' }),
                    getAll: this.getResource('GET', false, {
                        page: 0,
                        pageSize: 10
                    }),
                    getAllWithRoles: this.getResource('GET', false, {
                        action: 'pagedwithroles',
                        page: 0,
                        pageSize: 10
                    }),
                    getAllWithRolesByRoleId: this.getResource('GET', false, {
                        action: 'pagedwithrolesbyroleid',
                        page: 0,
                        pageSize: 10
                    }),
                    add: this.getResource('POST', false, {}),
                    update: this.getResource('PUT', false, {}),
                    search: this.getResource('GET', false, {
                        action: 'search',
                        pageSize: 10,
                        page: 0
                    }),
                    searchWithRoles: this.getResource('GET', false, {
                        action: 'searchWithRoles',
                        pageSize: 10,
                        page: 0
                    }),
                    getColleaguesByTagId: this.getResource('GET', true, {
                        action: 'colleaguesbytag',
                        id: '@id',
                        page: 0,
                        pageSize: 5
                    }),
                    getColleaguesByTagAndUnitId: this.getResource('GET', true, {
                        action: 'bytagandunitid',
                        id: '@id',
                        page: 0,
                        pageSize: 5
                    }),
                    getColleaguesAndFunctiongroups: this.getResource('GET', false, {
                        action: 'searchcolleaguesandfunctiongroups',
                        id: '@id',
                        userId: '',
                        page: 0,
                        pageSize: 5
                    })
                }
            );
        }

        private getResource(
            method: string,
            isArray: boolean,
            params?: IResourceParams,
            headers?: any
        ): angular.resource.IActionDescriptor {
            return { method, isArray, params, headers };
        }
    }

    angular
        .module('Colleagues')
        .service('ColleagueResource', [
            '$resource',
            $resource => new ColleagueResource($resource).getColleagueResource()
        ]);
}
