/// <reference path="../../../../ComponentRouteUtils.ts" />
/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard.Contracts {
    import ContractModel = Modules.Housing.ContractModel;
    import SelfServiceScenarioResource = Umbrella.CustomerService.SelfServiceScenarioResource;
    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;

    export interface CustomerContractsStateParams {
        page: number;
        size: number;
        personId: System.Guid;
    }

    export interface CustomerContractsComponentState {
        person: PersonModel;
        contracts: PagedItemsModel<ContractModel>;
        selectedContract: ContractModel;
        customer: Modules.Customers.CustomerModel.Detailed;
        loading: boolean;
        registrationInfo: RegistrationModel[];
    }

    @Component('CustomerService', {
        selector: 'customer-contracts',
        bindings: { state: '<' },
        templateUrl:
            '/CustomerService/_CustomerCard/_Contracts/CustomerContractsComponent/CustomerContracts.html'
    })
    @Inject(
        '$stateParams',
        'SelfServiceScenarioResource',
        'CustomerCardContractInfoService'
    )
    class CustomerContractsComponent {
        public state: CustomerContractsComponentState;
        public changePaymentMethodSssIsActive: boolean;
        public contracts: PagedItemsModel<ContractModel>;

        constructor(
            private $stateParams: CustomerContractsStateParams,
            private selfServiceScenarioResource: SelfServiceScenarioResource,
            private contractInfoService: CustomerCardContractInfoService
        ) {}

        public $onChanges() {
            const contracts =
                this.state &&
                this.state.contracts &&
                this.state.contracts.items;
            this.contracts =
                this.state && this.state.selectedContract
                    ? {
                          page: 0,
                          pageSize: 1,
                          total: 1,
                          items: [this.state.selectedContract]
                      }
                    : this.state.contracts;
        }

        private cbStartSelfServiceScenario: (T: any) => void;

        public loadMore(page: number) {
            this.contractInfoService.loadMore();
        }

        private checkChangePaymentMethodIsActive() {
            this.selfServiceScenarioResource
                .getByName({ id: 'ChangePaymentMethod' })
                .$promise.then(
                    () => (this.changePaymentMethodSssIsActive = true)
                );
        }
    }
}
