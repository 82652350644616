angular.module('Umbrella').filter('shortUrl', function() {
    return function(input) {
        if (input === undefined || input === null) return input;

        let index = input.indexOf('>');
        if (index === -1) return input;

        let ahref = input.substr(0, index + 1);
        let urlTitle = input.substr(index + 1, input.length - index);

        index = urlTitle.indexOf('<');
        if (index > -1) urlTitle = urlTitle.substr(0, index);
        if (urlTitle.length > 30) urlTitle = urlTitle.substr(0, 30) + '..';

        return ahref + urlTitle + '</a>';
    };
});
