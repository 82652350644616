/// <reference path="../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    announcementHub: Umbrella.Modules.Announcements.IAnnouncementHubProxy;
}

namespace Umbrella.Modules.Announcements {
    export interface IAnnouncementHubClient {
        created(announcement: AnnouncementModel);
        deleted(announcement: AnnouncementModel);
        contentsModified(announcement: AnnouncementModel);
    }

    export interface IAnnouncementHubProxy extends HubProxy {
        client: IAnnouncementHubClient;
    }

    export const announcementHubOnCreated$ = new Rx.Subject<
        AnnouncementModel
    >();
    export const announcementHubOnUpdated$ = new Rx.Subject<
        AnnouncementModel
    >();
    export const announcementHubOnDeleted$ = new Rx.Subject<
        AnnouncementModel
    >();

    Modules.onSignalRInitialized(() => {
        $.connection.announcementHub.client.created = model => {
            announcementHubOnCreated$.onNext(model);
        };
        $.connection.announcementHub.client.contentsModified = model => {
            announcementHubOnUpdated$.onNext(model);
        };
        $.connection.announcementHub.client.deleted = model => {
            announcementHubOnDeleted$.onNext(model);
        };
    });
}
