namespace Umbrella.CustomerService.CustomerCard {
    import SelfServiceScenarioModel = Modules.SelfService.SelfServiceScenarioModel;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;
    import CustomerCardPersonInfoService = Umbrella.CustomerService.CustomerCard.CustomerCardPersonInfoService;

    @Service('SelfService', 'ImpersonationService')
    @Inject(
        '$location',
        '$state',
        'SelfServiceScenarioResource',
        'TopBarStore',
        'ActivityRegistrationService',
        'CustomerCardPersonInfoService',
        'ToastMessageService'
    )
    export class ImpersonationService extends BaseStoreService<TopBarState, TopBarEvent, TopBarStore> {
        portalUrl: string;
        private returnToCase: boolean;

        constructor(
            private $location: ng.ILocationService,
            private $state: ng.ui.IStateService,
            private selfServiceScenarioResource: SelfServiceScenarioResource,
            protected topBarStore: TopBarStore,
            private activityRegistrationService: ActivityRegistrationService,
            private personInfoService: CustomerCardPersonInfoService,
            private toastMessageService: ToastMessageService
        ) {
            super(topBarStore);
        }

        public start(
            scenarioId: System.Guid | string,
            personId: System.Guid,
            channel?: Umbrella.Modules.ChannelType,
            caseId?: System.Guid,
            caseFlowId?: System.Guid,
            returnToCase?: boolean,
        ): void {
            this.returnToCase = returnToCase;
            this.$state.go('customerservice.customercard.impersonation', {
                personId,
                scenarioId,
                channel: channel
                    ? Umbrella.Modules.ChannelType[channel].toString()
                    : this.activityRegistrationService.getSelectedChannelType(),
                caseId,
                caseFlowId
            });
        }

        public async updateImpersonationState(
            scenarioId: System.Guid | string,
            personId: System.Guid,
            caseFlowId?: System.Guid
        ): Promise<void> {
            const lastLoadedPerson = this.personInfoService.getSelectedPerson();
            if (!lastLoadedPerson || lastLoadedPerson.id !== personId) await this.personInfoService.load(personId);

            const scenario = await this.selfServiceScenarioResource.getByName({
                id: scenarioId.toString()
            }).$promise;

            if (!scenario) {
                this.toastMessageService.error(`Fout opgetreden tijdens starten selfservice scenario`);
                return;
            }

            if (scenario.status && !scenario.status.active) {
                this.toastMessageService.error(`Selfservice scenario momenteel niet actief`);
                return;
            }

            this.activityRegistrationService.selectScenario(scenario);

            this.emit({
                type: 'ImpersonationEnterEvent',
                person: this.personInfoService.getSelectedPerson(),
                scenario,
                caseFlowId
            });
        }

        public leaveAndReturn(): void {
            const state = this.getState();
            const caseflowId = state && state.impersonationInfo && state.impersonationInfo.caseFlowId;

            this.leave();

            if (caseflowId && this.returnToCase) {
                // Changed in commit c6a0e6aa05539db5d4c9cb40e84e4a495155c837 for fixing a bug.
                this.$location.url('/dashboard/zaken/overzicht/' + caseflowId + '/stappen');
                // this.$state.go('dashboard.caseflow.overview.details.steps', {
                //     id: caseflowId
                // });
                return;
            }

            const person = this.personInfoService.getSelectedPerson();
            if (person) {
                this.$state.go('customerservice.customercard.timeline', {
                    personId: person.id
                });
                return;
            }


            this.$state.go('dashboard.tasks');
        }

        public leave(): void {
            this.emit({ type: 'ImpersonationLeaveEvent' });
        }

        public switchOnFullScreen(): void {
            this.emit({ type: 'ImpersonationFullScreenSwitchEvent', enabled: true });
        }

        public switchOffFullScreen(): void {
            this.emit({ type: 'ImpersonationFullScreenSwitchEvent', enabled: false });
        }

        public isFullScreen(): boolean {
            const state = this.getState();
            const fullscreenMode = state && state.impersonationInfo && state.impersonationInfo.fullscreenMode;

            if (fullscreenMode) return fullscreenMode;
            else return false;
        }

        public setPortalUrl(portalUrl: string): void {
            this.portalUrl = portalUrl;
        }

        public getPortalUrl(): string {
            return this.portalUrl;
        }
    }
}
