namespace Umbrella.CustomerService {
    import FaqResourceClass = Modules.Knowledgebase.Faq.FaqResourceClass;
    import IndicatorEvaluationModel = Umbrella.Indicators.IndicatorEvaluationModel;

    @Component('CustomerService', {
        selector: 'preloaded-indicators',
        templateUrl: '/CustomerService/IndicatorsComponent/IndicatorsComponent.html',
        bindings: {
            indicators: '<',
        }
    })
    @Inject('IndicatorService', '$mdDialog', 'FaqResource', 'ToastMessageService')
    export class PreloadedIndicatorsComponent extends BaseIndicatorsComponent {
        public indicators: IndicatorEvaluationModel[];

        constructor(
            $mdDialog: any,
            faqResource: FaqResourceClass,
            toastMessageService: ToastMessageService
        ) {
            super($mdDialog, faqResource, toastMessageService);
        }
    }
}
