/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Scope.d.ts"/>

namespace Umbrella.CustomerService.UnitCard.Valuation {
    import ValuationModel = Modules.Housing.Valuations.ValuationModel;
    import UnitModel = Modules.Housing.UnitModel;

    export interface HousingValuationScope extends angular.IScope {
        unit: UnitModel;
        valuation: ValuationModel;
        requestForUnitId(unit: UnitModel.Extended): void;
        loading: boolean;
        errors: string[];
    }

    @Inject(
        '$scope',
        '$stateParams',
        'CreateDialog',
        'UnitCardStore',
        'UnitCardService'
    )
    export class HousingValuationController {
        constructor(
            public $scope: HousingValuationScope,
            $stateParams,
            createDialog,
            unitCardStore: UnitCardStore,
            unitCardService: UnitCardService
        ) {
            $scope.requestForUnitId = unit => {
                createDialog(
                    '/CustomerService/_UnitCard/_Valuation/UnitValuationComponent/RequestValuationPopup.html',
                    {
                        id: 'contactPopup',
                        modalClass: 'popup popup_725 green'
                    },
                    {
                        unit
                    }
                );
            };

            $scope.hasTaskCreatePermissions = () => {
                return window.user.permissions.createTask;
            };

            const subscription = unitCardStore.state$.subscribe(x => {
                $scope.unit = x.unit;
                $scope.valuation = x.valuation && x.valuation.valuation;
                $scope.errors = x.valuation && x.valuation.error;
                $scope.loading = x.valuation && x.valuation.loading;
            });

            $scope.$on('$destroy', () => subscription.dispose());
        }
    }
}
