﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import ITaskResourceClass = Modules.Tasks.ITaskResourceClass;

    export interface TaskOverviewStateParams {
        page: number;
        pageSize: number;
        query: string;
        status: string;
        deadline: string;
        taskTypes: string[];
        createdById: System.Guid;
        assignedToId: System.Guid;
    }

    angular.module('TaskHandling').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.tasks.overview', {
                url: '/overzicht?page&query&status&deadline&taskTypes&createdById&assignedToId',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Taken overzicht',
                    authorize: p => p.viewTask
                },
                views: {
                    filters: {
                        template: '<task-overview-filters state="state$ | async:this"></task-overview-filters>',
                        controller: Umbrella.createComponentRouteController<
                            Overview.State,
                            TaskOverviewFiltersComponentState
                        >('TaskOverviewStore', s => ({
                            filters: s && s.filters
                        }))
                    },
                    list: {
                        template: '<task-list state="state$ | async:this"></task-list>',
                        controller: Umbrella.createComponentRouteController<Overview.State, TaskListComponentState>(
                            'TaskOverviewStore',
                            s => ({
                                loading: s && s.loading,
                                tasks: s && s.tasks,
                                selectedTask: s && s.selectedTask
                            })
                        )
                    },
                    details: {
                        templateUrl: 'TaskHandling/_Overview/TaskOverviewNoTaskSelected.html'
                    }
                },
                onEnter: [
                    '$stateParams',
                    'TaskOverviewService',
                    (params: TaskOverviewStateParams, taskOverviewService: TaskOverviewService) => {
                        taskOverviewService.initializeFilters();
                        taskOverviewService.filterByStateParams(params);
                    }
                ]
            });
        }
    ]);
}
