﻿/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/angularjs/angular-webstorage.d.ts" />
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scope.d.ts"/>
/// <reference path="../Window.d.ts"/>
/// <reference path="../Modules/Colleagues/Colleague/ColleagueResourceProvider.ts" />
/// <reference path="TaskResourceProvider.ts" />

namespace Umbrella.Modules.Tasks {
    import CaseModel = Modules.Customers.CaseModel;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;
    import SessionColleagueModel = Umbrella.Modules.Colleagues.SessionColleagueModel;
    import EmployeeService = Umbrella.Modules.Colleagues.EmployeeService;
    import ChatCustomerInformation = Umbrella.Modules.Chat.ChatCustomerInformation;

    export interface IStatusFilter {
        id: number;
        text: string;
    }

    @Service('TaskHandling', 'TaskService')
    @Inject('TaskResource', 'FunctionGroupResource', 'ColleagueResource', '$filter', 'EmployeeService')
    export class TaskService {
        constructor(
            private taskResource: ITaskResourceClass,
            private functionGroupResource: Colleagues.IFunctionGroupResourceClass,
            private colleagueResource: Modules.Colleagues.IColleagueResourceClass,
            private $filter: angular.IFilterService,
            private employeeService: EmployeeService
        ) {}

        public createNewTask(
            cas: CaseModel,
            completeBy: SummarizedColleagueModel,
            chatCustomerInformation?: ChatCustomerInformation
        ): Detailed.WithChannel {
            const chatCustomerDescription = chatCustomerInformation ? this.createChatDescriptionWithChatCustomerInformation(chatCustomerInformation)
                : null;

            const task = new this.taskResource({
                subject: '',
                completeBy: completeBy ? completeBy : undefined,
                createdBy: null,
                lastModifiedBy: null,
                description: chatCustomerDescription || '',
                assignedTo: cas && cas.person && cas.person.roles && cas.person.roles[0],
                from: window.user,
                case: cas,
                mediaIds: []
            });

            this.loadTaskData(task);
            this.fixForTransferCallPopup(task);

            return task;
        }

        private createChatDescriptionWithChatCustomerInformation(chatCustomerInformation: ChatCustomerInformation): string {
            const { firstName, lastName, phoneNumber, email } = chatCustomerInformation;
            const emailString = (email.length > 0 ? 'E-mailadres: ' + email + '\n' : '');
            let chatDescription: string;

            if (firstName.length > 0 && lastName.length > 0) {
                chatDescription =
                    (firstName.length > 0 ? 'Voornaam: ' + firstName + '\n' : '') +
                    (lastName.length > 0 ? 'Achternaam: ' + lastName + '\n' : '') +
                    (phoneNumber.length > 0 ? 'Telefoonnummer: ' + phoneNumber + '\n' : '') +
                    emailString;
            }

            if (firstName.length > 0 && lastName.length === 0) {
                chatDescription =
                    (firstName.length > 0 ? 'Naam: ' + firstName + '\n' : '') +
                    (phoneNumber.length > 0 ? 'Telefoonnummer: ' + phoneNumber + '\n' : '') +
                    emailString;
            }

            return chatDescription;
        }

        public isAssignedToMe(task: TaskModel, user: SummarizedColleagueModel | SessionColleagueModel): boolean {
            if (!task || !task.completeBy || !user) return false;

            return task.completeBy && task.completeBy.id === user.id;
        }

        public isAssignedToMeOrOneOfMyFunctionGroups(
            task: TaskModel,
            user: SummarizedColleagueModel | SessionColleagueModel
        ): boolean {
            if (!task || !task.completeBy || !user) return false;

            return (
                this.isAssignedToMe(task, user) ||
                this.employeeService.isAssignedToFunctionGroup(user, task.completeBy.id)
            );
        }

        public isCreatedByMe(task: TaskModel.Detailed, user: SummarizedColleagueModel): boolean {
            return task.createdBy && task.createdBy.id === user.id;
        }

        private loadTaskData(task: TaskModel.Detailed): void {
            this.colleagueResource
                .getColleaguesAndFunctiongroups({ userId: window.user.id })
                .$promise.then((data: PagedItemsModel<SummarizedColleagueModel.WithFunctionGroup>) => {
                    if (data && data.items) {
                        const functiongroups = this.$filter('filter')(data.items, { type: 'FunctionGroups' });
                        if (functiongroups.length > 0) task.from = functiongroups[0];
                    }
                });

            if (task && task.case && task.case.thesaurusTagId)
                this.functionGroupResource
                    .getFunctiongroupsByTagId({ id: task.case.thesaurusTagId })
                    .$promise.then(data => {
                        if (data && data.items && data.items.length > 0) task.completeBy = <any>data.items[0];
                    });

            this.taskResource.getDefaultTaskType().$promise.then(result => {
                task.taskType = result;

                this.taskResource.getDefaultDeadlineForTaskType(
                     {
                        name: result.name,
                        category: result.category
                    },
                        ret => {
                        task.ends = new Date(ret.deadline.toLocaleString());
                    });
            });

        }

        private fixForTransferCallPopup(item: TaskModel.Detailed): void {
            if (item.completeBy) item.completeBy.type = 1;
            if (item.from) item.from.type = 1;
        }
    }
}
