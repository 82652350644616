/// <reference path="../../../Scripts/TypeScript/toastr/toastr.d.ts" />

namespace Umbrella.CustomerService.CustomerCard.Activities {
    import ICaseHistoryResourceClass = Modules.Cases.ICaseHistoryResourceClass;
    import CaseHistoryModel = Modules.CaseHistoryModel;
    import IActivityResourceClass = Modules.IActivityResourceClass;
    import ActivityCategoryModel = Modules.ActivityCategoryModel;
    import ThesaurusResource = Umbrella.Modules.Knowledgebase.ThesaurusResource;
    import StatusFilter = Umbrella.Modules.StatusFilter;
    import ChannelType = Umbrella.Modules.ChannelType;

    @Service('CustomerService', 'CustomerCardActivityInfoService')
    @Inject(
        'CustomerCardStore',
        'CaseHistoryResource',
        'ActivityResource',
        'ToastMessageService',
        'SelfServiceScenarioResource',
        'ThesaurusResource'
    )
    export class CustomerCardActivityInfoService extends BaseStoreService<
        CustomerCardState,
        CustomerCardEvent,
        CustomerCardStore
    > {
        private pageSize = 10;

        constructor(
            store: CustomerCardStore,
            private caseHistoryResource: ICaseHistoryResourceClass,
            private activityResource: IActivityResourceClass,
            private toastMessageService: ToastMessageService,
            private selfServiceScenarioResource: Umbrella.CustomerService.SelfServiceScenarioResource,
            private thesaurusResource: ThesaurusResource
        ) {
            super(store);
        }

        public ensureLoaded(personId: System.Guid, pageSize = 10, force = false): void {
            this.pageSize = pageSize;
            const state = this.getState();

            const isDifferentPerson = state.personal && state.personal.person && state.personal.person.id !== personId;
            if (isDifferentPerson) this.resetActivities();

            if (isDifferentPerson || !state.activityInfo || !state.activityInfo.cases || force)
                this.loadActivities(personId, null, 0, pageSize, cases => cases);

            this.loadFilters();
        }

        public deleteCase(id: Guid): void {
            const currentState = this.getState();

            this.caseHistoryResource
                .deleteItem({
                    id: id
                })
                .$promise.then(() => {
                    this.ensureLoaded(
                        currentState.personal.person.id,
                        currentState.activityInfo.cases.items.length,
                        true
                    );
                    this.reload();
                    this.toastMessageService.success('Zaak succesvol verwijderd');
                })
                .catch(() => {
                    this.toastMessageService.error('Verwijderen van de zaak is mislukt');
                });
        }

        public restoreCase(id: Guid): void {
            const currentState = this.getState();

            this.caseHistoryResource
                .restoreItem({
                    id: id
                })
                .$promise.then(() => {
                    this.ensureLoaded(
                        currentState.personal.person.id,
                        currentState.activityInfo.cases.items.length,
                        true
                    );
                    this.reload();
                    this.toastMessageService.success('Zaak succesvol herstelt');
                })
                .catch(() => {
                    this.toastMessageService.error('Herstellen van de zaak is mislukt.');
                });
        }

        public deleteActivity(id: Guid): void {
            const currentState = this.getState();

            this.activityResource
                .deleteItem({
                    id: id
                })
                .$promise.then(() => {
                    this.ensureLoaded(
                        currentState.personal.person.id,
                        currentState.activityInfo.cases.items.length,
                        true
                    );
                    this.reload();
                    this.toastMessageService.success('Gebeurtenis succesvol verwijderd');
                })
                .catch(() => {
                    this.toastMessageService.error('Verwijderen van de gebeurtenis is mislukt');
                });
        }

        public restoreActivity(id: Guid): void {
            const currentState = this.getState();

            this.activityResource
                .restoreItem({
                    id: id
                })
                .$promise.then(() => {
                    this.ensureLoaded(
                        currentState.personal.person.id,
                        currentState.activityInfo.cases.items.length,
                        true
                    );
                    this.reload();
                    this.toastMessageService.success('Gebeurtenis succesvol herstelt');
                })
                .catch(() => {
                    this.toastMessageService.error('herstellen van de gebeurtenis is mislukt.');
                });
        }

        public filterByQuery(query: string) {
            const state = this.getState();
            if (!state.personal || !state.personal.person || !state.activityInfo) return;

            const filters: Filters = {
                ...state.activityInfo.filters,
                query
            };

            if (JSON.stringify(state.activityInfo.filters) === JSON.stringify(filters)) return;

            this.loadActivities(state.personal.person.id, filters, 0, this.pageSize, cases => cases);
        }

        public filterByContract(contractId: System.Guid) {
            const state = this.getState();
            if (!state.personal || !state.personal.person || !state.activityInfo) return;

            const filters: Filters = {
                ...state.activityInfo.filters,
                contractId
            };

            if (JSON.stringify(state.activityInfo.filters) === JSON.stringify(filters)) return;

            this.loadActivities(state.personal.person.id, filters, 0, this.pageSize, cases => cases);
        }

        public filterByActivityCategory(category: string) {
            const state = this.getState();
            if (!state.personal || !state.personal.person || !state.activityInfo) return;

            const filters: Filters = {
                ...state.activityInfo.filters,
                activityCategory: category
            };

            if (JSON.stringify(state.activityInfo.filters) === JSON.stringify(filters)) return;

            this.loadActivities(state.personal.person.id, filters, 0, this.pageSize, cases => cases);
        }

        public filterByScenarioId(scenarioId: System.Guid): void {
            const state = this.getState();
            if (!state.personal || !state.personal.person || !state.activityInfo) return;

            const filters: Filters = {
                ...state.activityInfo.filters,
                selectedScenarioId: scenarioId
            };

            if (JSON.stringify(state.activityInfo.filters) === JSON.stringify(filters)) return;

            this.loadActivities(state.personal.person.id, filters, 0, this.pageSize, cases => cases);
        }

        public filterByTagId(tagId: System.Guid): void {
            const state = this.getState();
            if (!state.personal || !state.personal.person || !state.activityInfo) return;

            const filters: Filters = {
                ...state.activityInfo.filters,
                selectedTagId: tagId
            };

            if (JSON.stringify(state.activityInfo.filters) === JSON.stringify(filters)) return;

            this.loadActivities(state.personal.person.id, filters, 0, this.pageSize, cases => cases);
        }

        public filterByStatus(statusFilter: StatusFilter): void {
            const state = this.getState();
            if (!state.personal || !state.id || !state.activityInfo) return;

            const filters: Filters = {
                ...state.activityInfo.filters,
                status: statusFilter
            };
            if (JSON.stringify(state.activityInfo.filters) === JSON.stringify(filters)) return;
            this.loadActivities(state.id, filters, 0, this.pageSize, cases => cases);
        }

        public filterByChannel(channelFilter: string) {
            const state = this.getState();
            if (!state.personal || !state.personal.person || !state.activityInfo) return;

            const filters: Filters = {
                ...state.activityInfo.filters,
                channel: ChannelType[channelFilter]
            };

            if (JSON.stringify(state.activityInfo.filters) === JSON.stringify(filters)) return;

            this.loadActivities(state.personal.person.id, filters, 0, this.pageSize, cases => cases);
        }

        public loadMore(): void {
            const state = this.store.getState();
            if (!state.personal || !state.personal.person || !state.activityInfo) return;

            this.loadActivities(
                state.personal.person.id,
                state.activityInfo.filters,
                state.activityInfo.cases.page + 1,
                state.activityInfo.cases.pageSize,
                cases => ({
                    items:
                        state.activityInfo && state.activityInfo.cases
                            ? state.activityInfo.cases.items.concat(cases.items)
                            : cases.items,
                    total: cases.total,
                    page: cases.page,
                    pageSize: cases.pageSize
                })
            );
        }

        public reload(): void {
            const state = this.store.getState();
            if (!state.personal || !state.personal.person || !state.activityInfo || !state.activityInfo.cases) return;

            const currentNrOfActivities = state.activityInfo.cases.items.length;
            this.loadActivities(
                state.personal.person.id,
                state.activityInfo.filters,
                0,
                currentNrOfActivities < 10 ? 10 : currentNrOfActivities,
                cases => cases
            );
        }

        private resetActivities() {
            this.emit({
                type: 'CustomerCardActivitiesResetEvent'
            });
        }

        private loadActivities(
            personId: System.Guid,
            filters: Filters,
            page: number,
            pageSize,
            loaded: (a: PagedItemsModel<CaseHistoryModel>) => PagedItemsModel<CaseHistoryModel>
        ) {
            const displayActivityCount =
                window.clientConfig &&
                window.clientConfig.features &&
                window.clientConfig.features.find(x => x.name === 'timelineActivityCount');

            this.emit({
                type: 'CustomerCardActivitiesLoadingEvent',
                filters,
                page,
                pageSize
            });

            const promise = this.caseHistoryResource.filterByPersonIdAndFilters({
                page,
                pageSize,
                id: personId,
                query: filters ? filters.query : '',
                category: filters ? filters.activityCategory : '',
                contractId: filters ? filters.contractId : '',
                sssIds: filters && filters.selectedScenarioId ? [filters.selectedScenarioId] : [],
                tagIds: filters && filters.selectedTagId ? [filters.selectedTagId] : [],
                status: filters && filters.status,
                channel: filters && filters.channel
            }).$promise;

            promise
                .then(data => {
                    this.linkActivitiesToCaseAsSolutionToPreventBigDataResponse(data);
                    const cases = loaded(data);

                    this.emit({
                        type: 'CustomerCardActivitiesLoadedEvent',
                        filters,
                        cases: {
                            ...cases,
                            page,
                            pageSize
                        }
                    });
                })
                .catch(this.onError);
        }

        private onError(e: any): void {
            this.toastMessageService.error('Fout opgetreden tijdens ophalen van gebeurtenissen');
            this.emit({
                type: 'CustomerCardActivitiesLoadErrorEvent',
                error: e
            });
        }

        public removeCase(caseId: Guid): void {
            if (!caseId) return;

            const state = this.store.getState();
            const activityInfo = state && state.activityInfo;
            const cases = activityInfo && activityInfo.cases;
            const updatedCases: PagedItemsModel<CaseHistoryModel> = {
                ...cases,
                items: cases.items.filter(x => x.case.id !== caseId),
                total: cases.total - 1
            };

            const filters = activityInfo && activityInfo.filters;

            this.emit({
                type: 'CustomerCardActivitiesLoadedEvent',
                filters,
                cases: updatedCases
            });
        }

        public getStatusFilter(): Rx.Observable<StatusFilter> {
            return this.store.state$.map(x => x.activityInfo && x.activityInfo.filters && x.activityInfo.filters.status);
        }

        private linkActivitiesToCaseAsSolutionToPreventBigDataResponse(data: PagedItemsModel<CaseHistoryModel>): void {
            for (const item of data.items) {
                if (item.firstActivity) item.firstActivity.case = item.case;

                if (item.lastActivity) item.lastActivity.case = item.case;
            }
        }

        private loadFilters(): void {
            const state = this.getState();
            const filters = state.activityInfo && state.activityInfo.filters;

            if (!filters || !filters.allActivityCategories) this.loadActivityCategories();

            if (!filters || !filters.allScenarios) this.loadAllSelfserviceScenarios();

            if (!filters || !filters.rootTags) this.loadRootTags();
        }

        private loadActivityCategories(): void {
            this.activityResource.getCategories().$promise.then(categories => {
                this.emit({
                    type: 'CustomerCardActivityCategoriesLoadedEvent',
                    categories: this.filterActivityCategories(categories)
                });
            });
        }

        private loadAllSelfserviceScenarios() {
            this.selfServiceScenarioResource.getAll().$promise.then(scenarios => {
                const filteredScenarios = scenarios.filter(scenario => scenario.showInOverview);
                this.emit({
                    type: 'CustomerCardAllScenariosLoadedEvent',
                    scenarios: filteredScenarios
                });
            });
        }

        private loadRootTags() {
            this.thesaurusResource.getRootTags().$promise.then(tags => {
                this.emit({
                    type: 'CustomerCardRootTagsLoadedEvent',
                    tags
                });
            });
        }

        private filterActivityCategories(categories: ActivityCategoryModel[]) {
            return categories.filter(x => x.name !== 'Zaakgericht Werken').filter(x => x.name !== 'Document');
        }
    }
}
