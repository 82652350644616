namespace Umbrella.TaskHandling {

    @Pipe('TaskHandling', 'makeEmailClickable')
    class MakeEmailClickable {
        public transform(message: string) {
            if (!message) return '';

            const shouldBeClickable = (email: string, index: number) => {
                if (
                    message.substring(index, index-7) == 'mailto:' ||
                    message.substring(index-2, index-(2+email.length)) == email
                ) { return false; }
                else { return true; }
            }

            const makeClickable = (email) => {
                const htmlEmail = new Document;
                let emailHref = htmlEmail.createElement('a');
                emailHref.setAttribute('href', 'mailto:' + email);
                emailHref.innerHTML = email;
                return emailHref.outerHTML.toString();
            }

            const emailRegex = new RegExp('[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+');

            let finding = true;
            let subMessage = message;
            let subIndex = 0;
            while (finding) {
                let foundEmail = subMessage.match(emailRegex);
                    if (!foundEmail) {
                        finding = false;
                        break;
                    }

                let extraLength;
                if (shouldBeClickable(foundEmail[0], foundEmail.index + subIndex)) {
                    message = message.substr(0, foundEmail.index + subIndex) + makeClickable(foundEmail[0]) + message.substr(foundEmail.index + subIndex + foundEmail[0].length, message.length);
                    extraLength = makeClickable(foundEmail[0]).length;
                } else { extraLength = foundEmail[0].length; }

                subIndex += foundEmail.index + extraLength;
                subMessage = message.substring(subIndex, message.length);
            }

            return message;
        }
    }
}
