namespace Umbrella.CustomerService {
    // Filter should be used only to retrieve a random roleId linked to a person
    @Pipe('CustomerService', 'firstRoleId')
    class FirstRoleIdFilter {
        public transform(person: PersonModel) {
            if (!person || !person.roles) return null;

            // For safety do not change ordering of return values below
            return (
                person.roles['customer'] ||
                person.roles['vendor'] ||
                person.roles['prospect'] ||
                person.roles['occupant'] ||
                null
            );
        }
    }
}
