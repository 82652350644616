/// <reference path="../_Activities/UnitActivitiesComponent/UnitActivitiesComponent.ts" />

namespace Umbrella.CustomerService.UnitCard {
    import IMenuTab = Modules.IMenuTab;
    import IStateMenuTab = Modules.IStateMenuTab;
    import QuickMenuButtonDefinition = CustomerService.QuickMenuButtonDefinition;
    import openUnitCardKnowledgebase$ = Umbrella.CustomerService.UnitCard.Activities.openUnitCardKnowledgebase$;

    @Inject('$stateParams', '$mdDialog', '$state')
    @Component('CustomerService', {
        selector: 'unitcard-menu',
        template: `
            <div class="uc-cardmenu sectionmenu green">
                <menu-tabs tabs="vm.tabs" md-theme="umb-green"></menu-tabs>
                <quick-menu buttons="vm.buttons"></quick-menu>
            </div>
        `
    })
    class UnitCardMenuComponent {
        public tabs: IMenuTab[];
        public buttons: QuickMenuButtonDefinition[];

        constructor(private $stateParams: any, private $mdDialog: any, private $state: ng.ui.IStateService) {}

        public $onInit() {
            this.createTabs();
            this.createButtons();
        }

        public registerTask(): void {
            if (!this.$stateParams.unitId) return;

            if (this.$state.current.name === 'customerservice.unitcard.tab.activities')
                openUnitCardKnowledgebase$.onNext(true);
            else
                this.$state.go('customerservice.unitcard.tab.activities', {
                    unitId: this.$stateParams.unitId,
                    createtask: true
                });
        }

        public registerAnnouncement(): void {
            if (!this.$stateParams.unitId) return;

            this.$mdDialog.show({
                template: `<register-announcement-popup residential-zone-id="${
                    this.$stateParams.unitId
                }"></register-announcement-popup>`,
                targetEvent: null,
                clickOutsideToClose: false
            });
        }

        private createButtons() {
            this.buttons = [
                {
                    tooltip: 'Nieuwe mededeling',
                    label: 'Mededeling aanmaken',
                    auth: 'announcementWrite',
                    class: 'md-warn',
                    icon: 'volume_up',
                    action: () => this.registerAnnouncement()
                },
                {
                    tooltip: 'Nieuwe taak',
                    label: 'Taak aanmaken',
                    auth: 'createTask',
                    class: 'md-primary',
                    icon: 'check_box',
                    action: () => this.registerTask()
                }
            ];
        }

        private createTabs() {
            this.tabs = [];

            if (!window.user.permissions.customerCard) return;

            if (window.user.permissions.caseRead)
                this.addTab(this.createTab('Tijdlijn', 'customerservice.unitcard.tab.activities'));

            this.addTab(this.createTab('Contracten', 'customerservice.unitcard.tab.contracts'));

            if (window.user.permissions.announcementRead)
                this.addTab(this.createTab('Mededelingen', 'customerservice.unitcard.tab.announcements'));
                
            this.addTab(this.createTab('Woningwaardering', 'customerservice.unitcard.tab.valuation'));

            if (window.user.permissions.anonymousRepairRequestWrite)
                this.addTab(this.createTab('Reparatieverzoek', 'customerservice.unitcard.tab.repairrequest'));
        }

        private createTab(title: string, state: string): IStateMenuTab {
            return {
                title,
                state
            };
        }

        private addTab(tab: IStateMenuTab) {
            this.tabs.push(tab);
        }
    }
}
