/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts" />
namespace Umbrella {
    export interface IPresenseResource
        extends angular.resource.IResource<Umbrella.Presence.PresenceModel> {}

    export interface IPresenceParams {
        ids: System.Guid[];
    }

    interface IResourceParams {
        action?: string;
        page?: number;
        pageSize?: number;
        id?: string;
        userId?: string;
    }

    export interface IPresenceResource
        extends angular.resource.IResourceClass<IPresenceResource> {
        getStatusByIds(
            params: IPresenceParams
        ): ng.resource.IResource<
            Umbrella.Modules.Colleagues.StatusUpdateModel[]
        >;
    }

    class PresenceResource {
        static $inject = ['$resource'];

        constructor(public $resource: angular.resource.IResourceService) {}

        getPresenceResource(): IPresenceResource {
            return this.$resource<
                Modules.Colleagues.ColleagueModel.Detailed,
                IPresenceResource
            >(
                '/api/v1/presence/:action/:id',
                {},
                {
                    getStatusByIds: this.getResource('GET', true, {
                        action: 'getstatusbyids'
                    })
                }
            );
        }

        private getResource(
            method: string,
            isArray: boolean,
            params?: IResourceParams,
            headers?: any
        ): angular.resource.IActionDescriptor {
            return { method, isArray, params, headers };
        }
    }
    angular
        .module('Umbrella')
        .service('PresenceResource', [
            '$resource',
            $resource => new PresenceResource($resource).getPresenceResource()
        ]);
}
