﻿namespace Umbrella.Search.Complexes {
    import IComplexResource = Umbrella.Modules.Housing.IComplexResource;

    export interface ComplexOverviewControllerScope extends IUmbrellaRootScope {
        searchQuery: string;
        searching: boolean;
        page: number;
        complexes: { items: Modules.Housing.ComplexModel[] };
        search(append: boolean): void;
        clear(): void;
        navigate(id: string): void;
        loadMore(): void;
    }

    @Inject('$scope', '$location', 'ComplexResource')
    @Controller('Search', 'ComplexOverviewController')
    export class ComplexOverviewController {
        constructor(
            private $scope: ComplexOverviewControllerScope,
            $location: ng.ILocationService,
            complexResource: IComplexResource
        ) {
            const pageSize = 50;
            const searchParamName = 'query';
            let resultsTotal: number;

            $scope.navigate = id => $location.path(`/complexkaart/${id}`);
            $scope.searchQuery = $location.search()[searchParamName] || '';
            $scope.searching = false;
            $scope.clear = () => {
                $scope.complexes = { items: [] };
                $scope.searching = false;
            };

            $scope.search = append => {
                $scope.page = append ? $scope.page + 1 : 0;

                if (!$scope.searchQuery) {
                    $scope.clear();
                    return;
                }

                complexResource.search(
                    angular.extend({
                        id: $scope.searchQuery,
                        page: $scope.page,
                        pageSize
                    }),
                    results => {
                        resultsTotal = results.total;
                        this.showComplexen(results);
                        $scope.searching = false;
                    }
                );
            };

            $scope.loadMore = () => {
                if ($scope.complexes.items.length < resultsTotal) {
                    $scope.searching = true;
                    $scope.search(true);
                } else {
                    $scope.searching = false;
                }
            };

            $scope.search(false);

            $scope.clear();
        }

        showComplexen(results) {
            if (this.$scope.page === 0) this.$scope.complexes = results;
            else
                for (const complex of results.items)
                    this.$scope.complexes.items.push(complex);
            if (results.page === 0) {
                this.$scope.searching = false;
            }
        }
    }
}
