/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowOverviewModel = Umbrella.CaseFlow.CaseFlowOverviewModel;

    @Pipe('Dashboard', 'caseFlowStepSummary')
    @Inject('$filter')
    class CaseFlowStepSummaryFilter {
        constructor(private $filter: ng.IFilterService) { }

        public transform(caseflow: CaseFlowOverviewModel) {
            if (!caseflow) return '';

            const totalSteps = (caseflow.steps && caseflow.steps.length) || 0;
            if (totalSteps === 0) return '';

            const finishedSteps = this.getFinishedSteps(caseflow);
            if (finishedSteps === totalSteps) return `Afgerond (${finishedSteps}/${totalSteps})`;

            let currentStepNr = finishedSteps;
            const currentStep = caseflow.steps[currentStepNr];

            if (!currentStep) return (<any>this.$filter('caseFlowStatus'))(caseflow);

            const isPickedUp = (step: CaseFlowOverviewModel.Step): boolean =>
                step.status.toString() === 'PickedUp' || step.status.toString() === '2';

            if (isPickedUp(currentStep)) currentStepNr += 1;

            return `${currentStep.title} (${currentStepNr}/${totalSteps})`;
        }

        private getFinishedSteps(item: Umbrella.CaseFlow.CaseFlowOverviewModel): number {
            const isFinished = (step: CaseFlowOverviewModel.Step): boolean =>
                step.status.toString() === 'Finished' || step.status.toString() === '3';

            return item.steps.filter(isFinished).length || 0;
        }
    }
}
