﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../Window.d.ts"/>
/// <reference path="CaseFlowComponent/CaseFlowComponent.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Overview {
    angular.module('Dashboard').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.caseflow.overview.details', {
                url: '/{id:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}',
                abstract: true,
                data: {
                    pageTitle: 'Zaakdetails',
                    authorize: ps => ps.viewCaseFlow
                },
                views: {
                    'details@dashboard.caseflow': {
                        template: '<caseflow state="state$ | async:this" class="reset-box-sizing"></caseflow>',
                        controller: Umbrella.createComponentRouteController<CaseFlowState, CaseFlowComponentState>(
                            'CaseFlowOverviewStore',
                            s => {
                                return {
                                    case: s && s.caseInfo.caseflow,
                                    caseHistory: s && s.caseInfo.caseHistory,
                                    messageCount:
                                        s && s.caseInfo && s.caseInfo.messages ? s.caseInfo.messages.length : 0,
                                    publicMessageCount:
                                        s && s.caseInfo && s.caseInfo.publicMessages
                                            ? s.caseInfo.publicMessages.length
                                            : 0,
                                    person: s && s.caseInfo.person,
                                    media: s && s.caseInfo.media
                                };
                            }
                        )
                    }
                },
                resolve: {
                    zaak: [
                        '$stateParams',
                        'CaseFlowOverviewService',
                        (
                            $stateParams: CaseFlowOverviewStateParams,
                            caseFlowOverviewService: CaseFlowOverviewService
                        ) => {
                            return caseFlowOverviewService.loadByCaseFlowId($stateParams.id);
                        }
                    ]
                }
            });
        }
    ]);
}
