namespace Umbrella.Modules.Knowledgebase {
    export interface IKnowledgebaseItemResource {
        getByTagId(params: {
            id: System.Guid;
            targetGroups?: string;
        }): ng.resource.IResource<KnowledgebaseItemModel.WithMedia>;
    }

    angular.module('Knowledgebase').factory('KnowledgebaseItemResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/knowledgebase/:action/:id',
                {},
                {
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { page: 0, pageSize: 10, mediaUrl: Config.makeAbsoluteApiUrl('api/v1/media/view/') }
                    },
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id', mediaUrl: Config.makeAbsoluteApiUrl('api/v1/media/view/') }
                    },
                    findByName: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'Find',
                            id: '@id',
                            page: 0,
                            pageSize: 25,
                            mediaUrl: Config.makeAbsoluteApiUrl('api/v1/media/view/')
                        }
                    },
                    getByTagId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'ByTag', id: '@id' }
                    }
                }
            )
    ]);
}
