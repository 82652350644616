namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'isRingingStatus')
    class IsRingingStatusFilter {
        public transform(
            input: Umbrella.Telephony.PhoneCallStatus | string
        ): boolean {
            if (input) {
                switch (input) {
                    case 3:
                    case 'Ringing':
                        return true;
                    default:
                        return false;
                }
            }

            return undefined;
        }
    }
}
