/// <reference path="EventCalendarHelper.ts" />
namespace Umbrella.CustomerService {
    import AppointmentModel = Umbrella.Modules.Colleagues.Calendar.AppointmentModel;

    @Pipe('CustomerService', 'calendarEventItemClasses')
    class CalendarEventItemClassesFilter {
        public transform(app: AppointmentModel, day: Date): string {
            if (!app || !day) return '';

            try {
                const clz = [app.status.toString()];

                clz.push(locale.nl.day_names[day.getDay()]);

                if (app.isAllDay) clz.push('allday');
                if (app.isRecurring) clz.push('recurring');

                if (!app.isAllDay) {
                    const hr = app.startTime.getHours();
                    const min =
                        (Math.round(app.startTime.getMinutes() / 30) * 30) % 60;
                    clz.push(
                        `hour${('00' + hr).slice(-2)}${('00' + min).slice(-2)}`
                    );

                    const firstHourOfDay = EventCalendarHelper.firstHourOfDay(
                        day
                    );
                    const early = app.startTime < firstHourOfDay;
                    const late =
                        app.endTime > EventCalendarHelper.lastHourOfDay(day);

                    if (early) clz.push('startsearly');
                    if (late) clz.push('endslate');

                    if (!late) {
                        const start = early ? firstHourOfDay : app.startTime;
                        const duration =
                            Math.ceil(
                                app.endTime.minutesDifference(start) / 30
                            ) * 30;
                        clz.push(`duration${duration}`);
                    }
                }
                return clz.join(' ');
            } catch (e) {
                return '';
            }
        }
    }
}
