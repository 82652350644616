/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella {
    import generateGuid = Umbrella.Helpers.generateGuid;
    import AppointmentModel = Umbrella.Modules.Colleagues.Calendar.AppointmentModel;

    interface CalendarModel {
        [day: string]: CalendarAppointmentModel[];
    }

    interface CalendarAppointmentModel extends AppointmentModel {
        calendarId?: Guid;
        overlappingWithEvents: CalendarAppointmentModel[];
        columnsCount?: number;
        columnPosition?: number;
        groupNumber: number;
        fromPreviousDay: boolean;
    }

    angular
        .module('CustomerService')

        .directive('attachCalendarEvents', [
            '$timeout',
            '$filter',
            ($timeout, $filter) => {
                function attachEvents(element: ng.IAugmentedJQuery, calendarModel: CalendarModel, startFromHour = 8) {
                    Object.keys(calendarModel).forEach((key, index) => {
                        const dayEvents = calendarModel[key];

                        for (const agendaEvent of dayEvents) {
                            let target: ng.IAugmentedJQuery;

                            const eventStartHour = agendaEvent.startTime.getHours();
                            const eventEndHour = agendaEvent.endTime.getHours();
                            let customAttachTime: Date;
                            if (
                                (eventStartHour < startFromHour && eventEndHour > startFromHour) ||
                                agendaEvent.fromPreviousDay
                            ) {
                                customAttachTime = new Date(agendaEvent.startTime);
                                customAttachTime.setHours(startFromHour);
                                customAttachTime.setMinutes(0);
                                customAttachTime.setSeconds(0);

                                const dayInfo = key.split('.');
                                const day = Number(dayInfo[0]);
                                const month = Number(dayInfo[1]) - 1;

                                customAttachTime.setDate(day);
                                customAttachTime.setMonth(month);
                            }

                            if (agendaEvent.isAllDay) {
                                target = findHeaderElementMatchingDate(element, customAttachTime || agendaEvent.startTime);
                            } else {
                                target = findElementMatchingDateAndHour(
                                    element,
                                    agendaEvent.startTime,
                                    customAttachTime
                                );
                            }

                            if (target && target.length > 0) {
                                const eventElement = createEventElement(
                                    agendaEvent,
                                    target,
                                    agendaEvent.fromPreviousDay,
                                    customAttachTime
                                );

                                appentStylingAccordingToColumn(
                                    eventElement,
                                    agendaEvent.columnPosition,
                                    agendaEvent.columnsCount
                                );

                                eventElement.mouseenter((event: JQueryMouseEventObject) => {
                                    const tooltip: ng.IAugmentedJQuery = createEventElementTooltip(agendaEvent);
                                    angular.element('body').append(tooltip);

                                    angular.element(document).on('mousemove', (event: JQueryMouseEventObject) => {
                                        const mouseX = event.clientX;
                                        const mouseY = event.clientY;
                                        const bb = tooltip[0].getBoundingClientRect();
                                        const left = mouseX - bb.width > 0 ? mouseX - bb.width : 0;
                                        const top = mouseY - bb.height > 0 ? mouseY - bb.height : 0;
                                        tooltip.css({
                                            left,
                                            top
                                        });
                                    });

                                    eventElement.mouseleave(event => {
                                        tooltip.remove();
                                    });
                                });
                                target.append(eventElement);
                            }
                        }
                    });
                }

                function appentStylingAccordingToColumn(
                    element: ng.IAugmentedJQuery,
                    currentColumn: number,
                    totalColumns: number
                ): void {
                    if (currentColumn === null || totalColumns === null) return;

                    const width = Math.floor(100 / totalColumns);
                    const margin = currentColumn + 1;
                    element.css({
                        'max-width': `calc(${width}% - 2px)`,
                        left: `calc((${width * currentColumn}%) + ${margin}px)`
                    });
                }

                function findElementMatchingDateAndHour(
                    parent: ng.IAugmentedJQuery,
                    date: Date,
                    customAttachTime?: Date
                ): ng.IAugmentedJQuery | null {
                    const attachDate = customAttachTime ? customAttachTime : date;

                    const daySelector = '.day' + attachDate.getDate();
                    const monthSelector = '.month' + (attachDate.getMonth() + 1);
                    const yearSelector = '.year' + attachDate.getFullYear();
                    const hourSelector = '.hour' + attachDate.getHours();

                    const matchingExactElements = parent[0].querySelectorAll(
                        daySelector + monthSelector + yearSelector + hourSelector
                    );

                    if (matchingExactElements && matchingExactElements.length > 0) {
                        return angular.element(matchingExactElements[0]);
                    }

                    return null;
                }

                function findHeaderElementMatchingDate(
                    parent: ng.IAugmentedJQuery,
                    date: Date
                ): ng.IAugmentedJQuery | null {
                    const daySelector = '.day' + date.getDate();
                    const monthSelector = '.month' + (date.getMonth() + 1);
                    const yearSelector = '.year' + date.getFullYear();

                    const matchingExactElements = parent[0].querySelectorAll(
                        'th' + daySelector + monthSelector + yearSelector
                    );

                    if (matchingExactElements && matchingExactElements.length > 0) {
                        return angular.element(matchingExactElements[0].children[2]);
                    }

                    return null;
                }

                function createEventElement(
                    agendaEvent: CalendarAppointmentModel,
                    parent: ng.IAugmentedJQuery,
                    fromPreviousDay: boolean,
                    customAttachTime?: Date
                ): ng.IAugmentedJQuery {
                    if (!agendaEvent || !parent || !parent.length) return null;

                    const attachStartTime = customAttachTime ? customAttachTime : agendaEvent.startTime;

                    const element = angular.element('<div class="agenda-item"></div>');
                    const parentHeight = parent.outerHeight();
                    const eventMinutes = attachStartTime.getMinutes();
                    const eventMinutesPercentage = (100 / 60) * eventMinutes;

                    const topDistance = (eventMinutesPercentage / 100) * parentHeight;
                    element.css('top', topDistance + 'px');

                    const messageSpan = angular.element('<p class="message"></p>');
                    messageSpan.text(agendaEvent.subject);
                    element.append(messageSpan);

                    const eventStartMs = attachStartTime.getTime();
                    const eventEndMs = agendaEvent.endTime.getTime();
                    const durationInMinutes = Math.ceil((eventEndMs - eventStartMs) / 1000 / 60);
                    const heightPercentage = ((100 / 60) * durationInMinutes) / 100;
                    const height = parentHeight * heightPercentage;

                    element.css({
                        height: height - 2 + 'px',
                        'margin-top': '1px'
                    });

                    if (durationInMinutes < 30) element.addClass('short-event');

                    if (agendaEvent.isAllDay) {
                        element.addClass('allDay');
                        element.css({
                            height: parentHeight * 0.35 + 'px',
                            top: 'auto',
                            bottom: 0
                        });
                    } else {
                        element.addClass(agendaEvent.status.toString());
                    }

                    if (fromPreviousDay) {
                        element.addClass('startsEarly');
                    }

                    return element;
                }

                function createEventElementTooltip(agendaEvent: CalendarAppointmentModel): ng.IAugmentedJQuery {
                    const element = angular.element('<div class="calendar-tooltip"></div>');

                    element.append(
                        `<div class="info-row subject"><span class="desc">Onderwerp:</span><span class="val">${agendaEvent.subject}</span></div>`
                    );
                    element.append(
                        `<div class="info-row location"><span class="desc">Locatie:</span><span class="val">${agendaEvent.location}</span></div>`
                    );

                    element.append('</br>');

                    const startTime = $filter('date')(agendaEvent.startTime, 'HH:mm');
                    element.append(
                        `<div class="info-row start-time"><span class="desc">Startijd:</span><span class="val">${startTime}</span></div>`
                    );

                    const endTime = $filter('date')(agendaEvent.endTime, 'HH:mm');
                    element.append(
                        `<div class="info-row end-time"><span class="desc">Eindtijd:</span><span class="val">${endTime}</span></div>`
                    );

                    element.append(
                        `<div class="info-row duration"><span class="desc">Duur:</span><span class="val">${agendaEvent.duration} minuten</span></div>`
                    );

                    return element;
                }

                function generateCalendarModel(events: AppointmentModel[]): CalendarModel {
                    const calendarModel: CalendarModel = splitEventsByDays(events);

                    // build overlapping relations
                    Object.keys(calendarModel).forEach((key, index) => {
                        const eventsInTheDay = calendarModel[key];

                        for (const agendaEvent of eventsInTheDay) {
                            const otherEvents = eventsInTheDay.filter(x => x.calendarId !== agendaEvent.calendarId);
                            agendaEvent.overlappingWithEvents = [];

                            for (const otherEvent of otherEvents) {
                                if (areEventsOverlapping(agendaEvent, otherEvent) && !otherEvent.isAllDay)
                                    agendaEvent.overlappingWithEvents.push(otherEvent);
                            }
                        }
                    });

                    // calculate column info
                    Object.keys(calendarModel).forEach((key, index) => {
                        const eventsInTheDay = calendarModel[key];
                        let visited: CalendarAppointmentModel[] = [];

                        for (const agendaEvent of eventsInTheDay) {
                            if (visited.find(x => x.calendarId === agendaEvent.calendarId)) continue;
                            let overlappingGroup = calculateGroupClutter(agendaEvent);
                            visited = visited.concat(overlappingGroup);

                            overlappingGroup = overlappingGroup.sort(
                                (a, b) => a.startTime.getTime() - b.startTime.getTime()
                            );

                            // fill the column position
                            let highestColumn = 0;
                            for (const ev of overlappingGroup) {
                                ev.columnPosition = calculateColumnPosition(ev);
                                if (ev.columnPosition > highestColumn) highestColumn = ev.columnPosition;
                            }

                            // fill the total column count for the group
                            for (const ev of overlappingGroup) {
                                ev.columnsCount = highestColumn + 1;
                            }
                        }
                    });

                    return calendarModel;

                    function splitEventsByDays(events: AppointmentModel[]): CalendarModel {
                        const calendarModel: CalendarModel = {};

                        const sortedEvents = events.sort((a, b) => b.startTime.getTime() - a.startTime.getTime());

                        for (const agendaEvent of sortedEvents) {
                            const eventStartMs = agendaEvent.startTime.getTime();
                            const eventEndMs = agendaEvent.endTime.getTime();
                            const durationInDays = Math.ceil((eventEndMs - eventStartMs) / 1000 / 60 / 60 / 24);
                            const calendarAppointmentModel: CalendarAppointmentModel = {
                                ...agendaEvent,
                                overlappingWithEvents: [],
                                groupNumber: 0,
                                fromPreviousDay: false,
                                columnPosition: null,
                                columnsCount: null
                            };

                            for (let i = 0; i < durationInDays; i++) {
                                const model: CalendarAppointmentModel = {
                                    ...calendarAppointmentModel
                                };
                                const day = $filter('date')(agendaEvent.startTime.addDays(i), 'dd.MM');
                                if (!calendarModel[day]) calendarModel[day] = [];

                                // We fill the Guids here so same event in each day has a unique identifier
                                model.calendarId = generateGuid();

                                if (i > 0) {
                                    model.fromPreviousDay = true;
                                }

                                calendarModel[day].push(model);
                            }
                        }

                        return calendarModel;
                    }

                    function calculateGroupClutter(
                        agendaEvent: CalendarAppointmentModel,
                        visited: CalendarAppointmentModel[] = []
                    ): CalendarAppointmentModel[] {
                        const isVisited = !!visited.find(x => x.calendarId === agendaEvent.calendarId);

                        if (isVisited || agendaEvent.isAllDay) return visited;

                        visited.push(agendaEvent);

                        for (const relationEvent of agendaEvent.overlappingWithEvents) {
                            visited = calculateGroupClutter(relationEvent, visited);
                        }

                        return visited;
                    }

                    function areEventsOverlapping(event1: AppointmentModel, event2: AppointmentModel): boolean {
                        const firstFactor = event1.startTime < event2.endTime;
                        const secondFactor = event2.startTime < event1.endTime;

                        if (firstFactor && secondFactor) return true;

                        return false;
                    }

                    function calculateColumnPosition(agendaEvent: CalendarAppointmentModel): number {
                        if (
                            !agendaEvent ||
                            !agendaEvent.overlappingWithEvents ||
                            agendaEvent.overlappingWithEvents.length < 1
                        )
                            return 0;

                        const usedPositions: number[] = [];
                        let highestColumnPosition = null;

                        for (const ev of agendaEvent.overlappingWithEvents) {
                            if (ev.columnPosition !== null) {
                                usedPositions.push(ev.columnPosition);

                                if (highestColumnPosition === null || highestColumnPosition < ev.columnPosition)
                                    highestColumnPosition = ev.columnPosition;
                            }
                        }

                        if (highestColumnPosition === null) return 0;

                        let columnPosition = 0;
                        let columnPositionSet = false;
                        for (let i = 0; i < highestColumnPosition; i++) {
                            if (usedPositions.indexOf(i) === -1) {
                                columnPosition = i;
                                columnPositionSet = true;
                                break;
                            }
                        }

                        if (!columnPositionSet) {
                            columnPosition = highestColumnPosition + 1;
                        }

                        return columnPosition;
                    }
                }
                return {
                    restrict: 'A',
                    priority: -10,
                    scope: {
                        events: '<',
                        startFromHour: '<'
                    },
                    link(scope: ng.IScope, element: ng.IAugmentedJQuery) {
                        let calendarModel: CalendarModel;

                        scope.$watch('events', (newValue, oldValue) => {
                            $timeout(() => {
                                calendarModel = generateCalendarModel(scope.events);
                                attachEvents(element, calendarModel, scope.startFromHour);
                            });
                        });
                    }
                };
            }
        ]);
}
