namespace Umbrella.CustomerService {
    @Pipe('CustomerService', 'activitySummary')
    class ActivitySummaryFilter {
        public transform(activity): string {
            //repair request
            if (activity.repairRequest) {
                let title = '';
                if (
                    activity.repairRequest.items &&
                    activity.repairRequest.items.length === 1
                )
                    title += activity.repairRequest.items[0].defectName;
                else if (
                    activity.repairRequest &&
                    activity.repairRequest.items &&
                    activity.repairRequest.items.length > 1
                )
                    title += 'Meerdere gebreken';
                title += ' - ' + activity.repairRequest.unit.name;
                return title;
            }

            //task or contact activity
            if (activity.description) {
                let description = activity.description.substr(0, 90);
                if (activity.description.length >= 90) description += '..';
                return description;
            }

            //document
            if (activity.correspondence)
                return `Bestandnaam: ${activity.correspondence.filename}`;

            return '';
        }
    }
}
