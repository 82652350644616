/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>

namespace Umbrella.Modules.Housing {
    export interface TerminateContractReportResource
        extends angular.resource.IResource<TerminateContractReportModel> {}

    export interface TerminateContractReportResourceClass {
        getByContractId(
            contractId: IdRequestParams,
            succes?: (data: TerminateContractReportModel) => void,
            error?: (reason: any) => void
        ): TerminateContractReportResource;
        getAll(
            params: ResourceSearchParams,
            succes?: (
                data: PagedItemsModel<TerminateContractReportResource>
            ) => void,
            error?: (reason: any) => void
        ): angular.IPromise<PagedItemsModel<TerminateContractReportResource>>;
    }

    angular.module('Housing').service('TerminateContractReportResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/terminatecontract/report/:action/:contractId',
                {},
                {
                    getAll: {
                        method: 'GET',
                        isArray: false
                    },
                    getByContractId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'bycontract', contractId: '@id' }
                    }
                }
            )
    ]);
}
