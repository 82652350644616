/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts" />
/// <reference path="../Scope.d.ts" />import { config } from "rx.lite";

interface SignalR {
    phoneHub: Umbrella.Modules.Telephony.PhoneHubProxy;
    allPhonesHub: any;
}

namespace Umbrella.Modules.Telephony {
    import PhoneModel = Umbrella.TelephonyControl.PhoneModel;
    import PhoneStatsModel = Umbrella.TelephonyControl.Statistics.PhoneStatsModel;
    import PhoneCallRoleModel = Umbrella.TelephonyControl.PhoneCallRoleModel;

    export interface PhoneHubServerResponse {
        done(callback: () => void): PhoneHubServerResponse;
        fail(callback: (error: string) => void): PhoneHubServerResponse;
    }

    export interface PhoneHubProxy extends HubProxy {
        client: PhoneHubClient;
        server: PhoneHubServer;
    }

    export interface PhoneHubClient {
        update(phone: PhoneModel);
        updatePhoneCallRoles(phoneCallRoleModel: PhoneCallRoleModel);
        onMessage(message: any);
        stats(stats: PhoneStatsModel);
    }

    export interface PhoneHubServer {
        login(nr: string, password: string): PhoneHubServerResponse;
        logout(): PhoneHubServerResponse;
        logoutMultipleByUserIds(userIds: Guid[]): PhoneHubServerResponse;
        reportUserReconnected(): PhoneHubServerResponse;

        setRoleForCall(
            connectionId: System.Guid,
            roleId: System.Guid
        ): PhoneHubServerResponse;
        unsetRoleForCall(connectionId: System.Guid): PhoneHubServerResponse;

        ready(): PhoneHubServerResponse;
        notReady(): PhoneHubServerResponse;
        endAfterCallWork(): PhoneHubServerResponse;
        endAfterCallWorkAndLeaveQueue(): PhoneHubServerResponse;
        leaveQueue(): PhoneHubServerResponse;
        enterQueue(): PhoneHubServerResponse;
        setPhoneRichPresenceState(
            phoneRichPresenceState: string
        ): PhoneHubServerResponse;
        dial(nr: string): PhoneHubServerResponse;
        transferDirect(
            nr: string,
            connectionId: string
        ): PhoneHubServerResponse;
        pickup(connectionId: string): PhoneHubServerResponse;
        holdCall(connectionId: string): PhoneHubServerResponse;
        resumeCall(connectionId: string): PhoneHubServerResponse;
        forwardCall(connectionId: string): PhoneHubServerResponse;
        releaseCall(connectionId: string): PhoneHubServerResponse;
    }

    export const phoneHubOnError$ = new Rx.Subject<PhoneModel>();
    export const phoneHubOnUpdated$ = new Rx.Subject<PhoneModel>();
    export const phoneHubOnPhoneCallRolesUpdated$ = new Rx.Subject<
        PhoneCallRoleModel
    >();
    export const phoneHubOnMessage$ = new Rx.Subject<any>();
    export const phoneHubOnStatsUpdated$ = new Rx.Subject<PhoneStatsModel>();

    function isInDebugMode() {
        return window.clientConfig && window.clientConfig.debug;
    }

    function writeToConsoleWhenInDebugMode(eventName: string, params: any[]) {
        if (isInDebugMode()) console.debug(eventName, ...params);
    }

    Modules.onSignalRInitialized(() => {
        $.connection.phoneHub.client.update = (phone: PhoneModel) => {
            phoneHubOnUpdated$.onNext(phone);
            writeToConsoleWhenInDebugMode('phoneHub.update: ', [phone]);
        };
        $.connection.phoneHub.client.updatePhoneCallRoles = (
            phoneCallRoleModel: PhoneCallRoleModel
        ) => {
            phoneHubOnPhoneCallRolesUpdated$.onNext(phoneCallRoleModel);
            writeToConsoleWhenInDebugMode('phoneHub.updatePhoneCallRoles: ', [
                phoneCallRoleModel
            ]);
        };
        $.connection.phoneHub.client.onMessage = (message: any) => {
            phoneHubOnMessage$.onNext(message);
            writeToConsoleWhenInDebugMode('phoneHub.onMessage: ', [message]);
        };
        $.connection.phoneHub.client.stats = (stats: PhoneStatsModel) => {
            phoneHubOnStatsUpdated$.onNext(stats);
            writeToConsoleWhenInDebugMode('phoneHub.stats: ', [stats]);
        };
    });
}
