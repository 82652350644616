/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService {
    angular.module('CustomerService').directive('scrollCommandsReceiver', [
        () => {
            function scrollToElement(container: ng.IAugmentedJQuery, selector: string, speed = 1000) {
                if (!container || !selector) return;

                const element = container.find(selector);

                if (!element || element.length < 1) return;

                const elPosTop = element.position().top;
                const containerScrollTop = container.scrollTop();
                const scrollTop = containerScrollTop - Math.abs(elPosTop);

                container.stop().animate({ scrollTop }, speed);
            }

            function scrollToBottom(container: ng.IAugmentedJQuery, speed = 1000) {
                if (!container || container.length < 1) return;

                const scrollTop = container[0].scrollHeight;

                container.stop().animate({ scrollTop }, speed);
            }

            return {
                restrict: 'A',
                link: (scope: ng.IScope, el: ng.IAugmentedJQuery, attr: ng.IAttributes) => {
                    scope.$on('ScrollToCommand', (event, args) => {
                        if (!event.defaultPrevented) {
                            event.defaultPrevented = true;
                            scrollToElement(el, args.selector);
                        }
                    });

                    scope.$on('ScrollToBottomCommand', (event, args) => {
                        if (!event.defaultPrevented) {
                            event.defaultPrevented = true;
                            scrollToBottom(el);
                        }
                    });
                }
            };
        }
    ]);
}
