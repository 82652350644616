/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.ChatConversationHandling {
    angular.module('Chat').config([
        '$stateProvider',
        ($stateProvider: ng.ui.IStateProvider) => {
            $stateProvider.state('dashboard.chat.overview.details', {
                url: '/{id:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}',
                data: {
                    pageTitle: 'Chat details',
                    authorize: ps => ps.viewChatConversation
                },
                views: {
                    'details@dashboard.chat': {
                        template: '<chat-details state="state$ | async:this"></chat-details>',
                        controller: Umbrella.createComponentRouteController<ChatState, ChatConversationDetailsState>(
                            'ChatStore',
                            s => ({
                                conversation:
                                    s &&
                                    s.conversations &&
                                    s.selectedOriginId &&
                                    s.conversations.find(x => x.originId === s.selectedOriginId),
                                enabled: s && s.enabled,
                                registered: s && s.registration && s.registration.status === RegisterStatus.Registered
                            })
                        )
                    }
                },
                onEnter: [
                    '$stateParams',
                    'ChatService',
                    ($stateParams: ng.ui.IStateParamsService, chatService: ChatService) => {
                        chatService.select($stateParams.id);
                    }
                ]
            });
        }
    ]);
}
