/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('setHeightForResponsive', [
        '$window',
        $window => {
            return {
                restrict: 'A',
                replace: true,
                link($scope, element) {
                    const isMobile = window.device.tablet() || window.device.mobile() || window.device.surface();

                    if (isMobile) {
                        setHeight();
                    }

                    angular.element($window).bind('resize', () => {
                        if (isMobile) {
                            setHeight();
                        }
                    });

                    angular.element($window).bind('orientationchange', () => {
                        if (isMobile) {
                            setHeight();
                        }
                    });

                    function setHeight() {
                        setTimeout(() => {
                            Array.from(document.querySelectorAll('.responsive-height'), element => setStyle(element));
                        }, 500);
                    }

                    function setStyle(element) {
                        const bodyHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                        if (window.device.tablet() || window.device.surface()) {
                            if (element.id === 'searchResultsBox') {
                                element.setAttribute('style', 'height:' + (bodyHeight - 48) + 'px;');
                                return;
                            }
                        }

                        if (window.device.mobile()) {
                            if (element.id === 'searchResultsBox') {
                                element.setAttribute('style', 'height:' + (bodyHeight - 113) + 'px;');
                                return;
                            }
                        }

                        element.setAttribute('style', 'height:' + bodyHeight + 'px;');
                    }
                }
            };
        }
    ]);
}
