/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('minimizePopup', () => {
        return {
            link(scope, element) {
                let displayPopUp = false;

                element.on('click', () => {
                    displayPopUp = !displayPopUp;
                    togglePopUpStatus();

                    function togglePopUpStatus(): void {
                        const mdDialogIsActive = getElementByClassName('md-dialog-is-showing');
                        if (displayPopUp && mdDialogIsActive) {
                            hidePopup();
                        } else {
                            if (mdDialogIsActive) {
                                showPopup();
                            }
                        }
                    }

                    function getElementByClassName(className: string): HTMLElement {
                        return angular.element(document.getElementsByClassName(className))[0];
                    }

                    function hidePopup(): void {
                        const mdDialogIsShowing = getElementByClassName('md-dialog-is-showing');
                        const mdDialogContainer = getElementByClassName('md-dialog-container');
                        const mdDialogBackdrop = getElementByClassName('md-dialog-backdrop');
                        const mdScrollMask = getElementByClassName('md-scroll-mask');

                        window.requestAnimationFrame(checkMdDialogContainerPosition);
                        window.requestAnimationFrame(cancelMdDialogContainerAnimation);
                        mdDialogContainer.classList.add('animate_backdrop');
                        mdDialogContainer.classList.add('position-bottom');
                        mdDialogContainer.style.overflow = 'visible';
                        mdDialogIsShowing.classList.add('overflowHidden');
                        mdDialogBackdrop.classList.add('animate_dialog_backdrop');
                        mdScrollMask.style.display = 'none';
                    }

                    function showPopup(): void {
                        const mdDialogIsShowing = getElementByClassName('md-dialog-is-showing');
                        const mdDialogContainer = getElementByClassName('md-dialog-container');
                        const mdDialogBackdrop = getElementByClassName('md-dialog-backdrop');
                        const mdScrollMask = getElementByClassName('md-scroll-mask');
                        const mdTransitionIn = getElementByClassName('md-transition-in');

                        mdTransitionIn.removeAttribute('style');
                        window.requestAnimationFrame(checkMdDialogContainerPosition);
                        mdDialogContainer.classList.remove('position-bottom');
                        mdDialogBackdrop.classList.remove('animate_dialog_backdrop');
                        mdScrollMask.style.display = '';
                        setTimeout(() => {
                            mdDialogIsShowing.classList.remove('overflowHidden');
                        }, 260);
                    }

                    function cancelMdDialogContainerAnimation(): void {
                        const mdDialogContainer = getElementByClassName('md-dialog-container');
                        const mdToolbarTransitions = getElementByClassName('_md-toolbar-transitions');
                        const mdTransitionIn = getElementByClassName('md-transition-in');
                        const mdTransitionInOffsetTop = mdTransitionIn.offsetTop;
                        const transitionAnimation = window.requestAnimationFrame(cancelMdDialogContainerAnimation);
                        const mdDialogContainerPosition = Number(mdDialogContainer.offsetTop);
                        const mdToolbarTransitionsHeight = Number(mdToolbarTransitions.style.height);

                        if (mdDialogContainerPosition > mdTransitionInOffsetTop + mdToolbarTransitionsHeight) {
                            mdTransitionIn.setAttribute(
                                'style',
                                'position: absolute; margin-top:' +
                                    '-' +
                                    mdToolbarTransitions.clientHeight +
                                    'px!important'
                            );
                            cancelAnimationFrame(transitionAnimation);
                        }
                    }

                    function checkMdDialogContainerPosition(): void {
                        const setValueAnimation = window.requestAnimationFrame(checkMdDialogContainerPosition);
                        const mdDialogContainer = getElementByClassName('md-dialog-container');
                        const mdDialogContainerPosition = Number(mdDialogContainer.offsetTop);

                        if (mdDialogContainerPosition <= 0) {
                            mdDialogContainer.classList.remove('animate_backdrop');
                            cancelAnimationFrame(setValueAnimation);
                        } else {
                            mdDialogContainer.classList.add('animate_backdrop');
                            cancelAnimationFrame(setValueAnimation);
                        }
                    }
                });
            }
        };
    });
}
