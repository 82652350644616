/// <reference path="../../../Scope.d.ts" />
/// <reference path="ColleagueHelpers.ts" />

namespace Umbrella.Modules.Colleagues {
    import PersonSearchModel = Umbrella.Modules.PersonSearchModel;

    export interface AdminColleagueControllerScope extends IUmbrellaRootScope {
        searchResults: string;
        searchQuery: string;
        searching: boolean;
        noResults: boolean;
        showSearchbox: boolean;

        callNr(nr: string): void;
        clearSearchbox(): void;
        getEmailAdress(emails: EmailAddressModel[]) : string;
        colleagueSelected(colleague: PersonSearchModel): void;
        colleagueClick(colleague: PersonSearchModel): void;
    }

    @Inject('$scope', 'PersonResource', 'PhotoUrlService', 'PhoneService')
    export class SearchColleaguesController {
        private searchBox: JQuery;
        private currentItem: JQuery;
        private currentItemIndex: number;

        constructor(
            public $scope: AdminColleagueControllerScope,
            public personResource: PersonResource,
            photoUrlService: PhotoUrlService,
            phoneService: Telephony.PhoneService,
        ) {
            'use strict';

            $scope.searchResults = null;
            $scope.searchQuery = '';
            $scope.searching = false;
            this.searchBox = jQuery('#popupSearchResultsBox');

            jQuery('.popup-search-query').bind('focus', function() {
                if (this.value !== '') {
                    $scope.searchQuery = this.value;

                    this.doShowSearchBox();
                }
            });

            $scope.$watch('searchQuery', () => {
                this.doShowSearchBox();
            });

            $scope.callNr = (nr: string): void => {
                if (nr) phoneService.callNr(nr);
            };

            $scope.getEmailAdress = (emails: EmailAddressModel[]) : string => getColleagueEmail(emails);       

            $scope.clearSearchbox = () => {
                $scope.searchQuery = '';
            };

            $scope.colleagueSelected = (
                colleague: PersonSearchModel
            ) => {
                this.hideSearchResults();
                $scope.colleagueClick(colleague);
            };

            $scope.$on('$destroy', () => {
                $('body').off('click.searchboxClosed');
            });
        }

        doShowSearchBox() {
            this.$scope.showSearchbox = true;

            $('body').on('click.searchboxClosed', e => {
                let target = $(e.target);
                if (
                    !target.is('#popupSearchResultsBox') &&
                    !target.parents().is('#popupSearchResultsBox') &&
                    !target.parents().is('.top-search')
                ) {
                    $('body').off('click.searchboxClosed');

                    this.$scope.showSearchbox = false;
                    this.$scope.$apply();
                }
            });

            this.$scope.searching = true;
            this.search();
        }

        search() {
            if (this.$scope.searchQuery) {
                let param = this.$scope.searchQuery;

                if (param.charAt(param.length - 1) === '.')
                    param = param.slice(0, -1);

                this.personResource.search({
                        id: param,
                        pageSize: 5,
                        roles: 'employee'
                    }).$promise.then((results) => {
                        let innerParam = this.$scope.searchQuery;
                        if (innerParam.charAt(innerParam.length - 1) === '.')
                            innerParam = innerParam.slice(0, -1);

                        if (param === innerParam)
                            this.showSearchResults(results.items);
                    },
                    () => {
                        this.hideSearchResults();
                    }
                );
            } else this.hideSearchResults();
        }       

        hideSearchResults() {
            this.showSearchResults({
                colleagues: []
            });

            this.$scope.searchQuery = '';
            this.$scope.noResults = false;
            this.currentItemIndex = 0;
            this.currentItem = null;
            jQuery('.popup-search-query').unbind(
                'keydown',
                this.handleKeyPress
            );
        }

        showSearchResults(results) {
            this.$scope.searchResults = results;
            this.$scope.searching = false;

            results.hide = results.length === 0;

            this.$scope.noResults = results.hide;

            this.currentItem = null;
            this.currentItemIndex = 0;

            jQuery('.popup-search-query').unbind(
                'keydown',
                this.handleKeyPress
            );

            if (this.$scope.showSearchbox) {
                jQuery('.popup-search-query').bind(
                    'keydown',
                    this.handleKeyPress
                );
                this.searchBox
                    .find('#popupBelBijEnter')
                    .first()
                    .css('display', 'inline');
            }
        }

        handleKeyPress(event) {
            let telephone = this.searchBox
                .find('li.popup-searchQueryItem.callNumberTop:visible')
                .first();
            let items = this.searchBox
                .find('li.popup-colleague,li.popup-searchQueryItem')
                .filter(':visible');

            if (this.currentItem)
                this.currentItem.css('background-color', '#ffffff');

            if (event.keyCode === 40) {
                // down
                if (this.currentItem == null) this.currentItemIndex = 0;
                else {
                    this.currentItemIndex++;
                    if (this.currentItemIndex < items.length - 1)
                        if (items.eq(this.currentItemIndex).hasClass('header'))
                            this.currentItemIndex++;
                }
            } else if (event.keyCode === 38) {
                // up
                if (this.currentItem == null)
                    this.currentItemIndex = items.length - 1;
                else {
                    this.currentItemIndex--;
                    if (this.currentItemIndex > 0) {
                        if (items.eq(this.currentItemIndex).hasClass('header'))
                            this.currentItemIndex--;
                    } else this.currentItemIndex = 0;
                }
            } else if (event.keyCode === 13) {
                // enter
                if (this.currentItem) {
                    this.currentItem
                        .find('a')
                        .first()
                        .trigger('click'); // first href should be clickable!
                } else if (telephone.length > 0) {
                    telephone
                        .find('a')
                        .first()
                        .trigger('click');
                    return false;
                }
            }

            if (this.currentItemIndex > items.length - 1)
                this.currentItemIndex = items.length - 1;
            else if (this.currentItemIndex < 0) this.currentItemIndex = 0;

            if (event.keyCode === 40 || event.keyCode === 38) {
                this.currentItem = items.eq(this.currentItemIndex);
            }

            // highlight the active item
            if (this.currentItem)
                this.currentItem.css('background-color', '#D8D8D8');

            // only show the call on enter display value if no currentitem is set or the active item is the current item
            if (this.currentItem) {
                if (!this.currentItem.is('li.popup-searchQueryItem'))
                    this.searchBox
                        .find('#popupBelBijEnter')
                        .first()
                        .css('display', 'none');
                else
                    this.searchBox
                        .find('#popupBelBijEnter')
                        .first()
                        .css('display', 'inline');
            }

            // cancel default event handling
            if (
                this.currentItem &&
                (event.keyCode === 40 || event.keyCode === 38)
            )
                return false;

            return true;
        }
    }
}
