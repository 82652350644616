angular.module('Umbrella').constant('resources', {
    /**
     * Example of defining a generic key/value pair.
     */
    generic: [
        {
            key: 'prefix.keyname',
            value: 'value returned',
            description: 'description for the translator (optional)'
        }
    ],

    /**
     * Zaken
     */
    case: [
        {
            key: 'type.question',
            value: 'Vraag'
        },
        {
            key: 'type.case',
            value: 'Zaak'
        }
    ],

    media: [
        { key: 'filter.knowledgebaseitem', value: 'Kennisbank' },
        { key: 'filter.advertisement', value: 'Advertentie' },
        { key: 'filter.defect', value: 'Gebrek' },
        { key: 'filter.mail', value: 'Mail' },
        { key: 'filter.image', value: 'Afbeelding' },
        { key: 'filter.document', value: 'Document' },
        { key: 'filter.link', value: 'Link' },
        { key: 'filter.mediacategory', value: 'Category' },
        { key: 'filter.mediatype', value: 'Type' },
        { key: 'filter.all', value: 'Alle' },
        { key: 'filter.selfservice', value: 'Selfservice' }
    ],

    advertisement: [
        { key: 'filter.address.untokenizedcity', value: 'Stad' },
        { key: 'filter.externalsystem.name', value: 'Bronsysteem' },
        { key: 'filter.housingsupplyType', value: 'Aanbodtype' },
        { key: 'filter.price', value: 'Prijs' },
        { key: 'filter.size', value: 'Omvang' },
        { key: 'filter.type', value: 'Advertentietype' },
        { key: 'filter.unittype.lowercasekeyword', value: 'Eenheid type' }
    ],

    customer: [
        { key: 'filter.characteristics', value: 'Labels' },
        { key: 'filter.0', value: 'Alles' },
        { key: 'filter.1', value: 'Aggressief' },
        { key: 'filter.16', value: 'Geblokkeerd' },
        { key: 'filter.32', value: 'Uitgesloten van postcode login' }
    ],

    /**
     * Status
     */

    status: [
        {
            key: 'type.published',
            value: 'Gepubliceerd'
        },
        {
            key: 'type.concept',
            value: 'Concept'
        },
        {
            key: 'type.toreview',
            value: 'Nog te beoordelen'
        },
        {
            key: 'type.deleted',
            value: 'Verwijderd'
        },
        {
            key: 'type.imported',
            value: 'Geïmporteerd'
        },
        {
            key: 'type.expired',
            value: 'Verlopen'
        }
    ],

    /**
     * Status Colleague
     */

    statuscolleague: [
        {
            key: 'availability.busy',
            value: 'bezet'
        },
        {
            key: 'availability.available',
            value: 'beschikbaar'
        },
        {
            key: 'availability.away',
            value: 'afwezig'
        },
        {
            key: 'availability.unknown',
            value: 'onbekend'
        }
    ],

    log: [
        {
            key: 'filter.application',
            value: 'Applicaties'
        },
        {
            key: 'filter.level',
            value: 'Niveaus'
        }
    ],

    weekdays: [
        {
            key: 'd.0',
            value: 'Zondag'
        },
        {
            key: 'd.1',
            value: 'Maandag'
        },
        {
            key: 'd.2',
            value: 'Dinsdag'
        },
        {
            key: 'd.3',
            value: 'Woensdag'
        },
        {
            key: 'd.4',
            value: 'Donderdag'
        },
        {
            key: 'd.5',
            value: 'Vrijdag'
        },
        {
            key: 'd.6',
            value: 'Zaterdag'
        }
    ],

    person: [
        {
            key: 'title.male',
            value: 'Dhr.'
        },
        {
            key: 'title.female',
            value: 'Mevr.'
        },
        {
            key: 'title.unknown',
            value: ''
        }
    ],

    gender: [
        {
            key: 'type.male',
            value: 'Man'
        },
        {
            key: 'type.female',
            value: 'Vrouw'
        },
        {
            key: 'type.unknown',
            value: 'Onbekend'
        }
    ],

    customerupdate: [
        {
            key: 'status.updatingfailed',
            value: "Het email-adres '{0}' is niet beschikbaar."
        },
        {
            key: 'status.recoveryfailed',
            value:
                "Herstellen mislukt, het email-adres '{0}' is gekoppeld aan meerdere accounts."
        }
    ]
});
