namespace Umbrella.CustomerService {
    import ChannelType = Modules.ChannelType;

    @Pipe('CustomerService', 'activityChannel')
    class ActivityChannelFilter {
        public transform(channelType: string | ChannelType): string {
            switch (channelType) {
                case ChannelType.Phone:
                case ChannelType[ChannelType.Phone]:
                    return 'Telefoongesprek: ';
                case ChannelType.Post:
                case ChannelType[ChannelType.Post]:
                    return 'Post: ';
                case ChannelType.Desk:
                case ChannelType[ChannelType.Desk]:
                    return 'Baliebezoek: ';
                case ChannelType.Email:
                case ChannelType[ChannelType.Email]:
                    return 'E-mail: ';
                case ChannelType.Facebook:
                case ChannelType[ChannelType.Facebook]:
                    return 'Facebook: ';
                case ChannelType.Social:
                case ChannelType[ChannelType.Social]:
                    return 'Social media: ';
                case ChannelType.Tweet:
                case ChannelType[ChannelType.Tweet]:
                    return 'Tweet: ';
                case ChannelType.Visit:
                case ChannelType[ChannelType.Visit]:
                    return 'Huisbezoek: ';
                case ChannelType.Web:
                case ChannelType[ChannelType.Web]:
                    return 'Klantportaal: ';
                case ChannelType.Chat:
                case ChannelType[ChannelType.Chat]:
                    return 'Chat: ';
                case ChannelType.Survey:
                case ChannelType[ChannelType.Survey]:
                    return 'Enquête: ';
                case ChannelType.Backoffice:
                case ChannelType[ChannelType.Backoffice]:
                    return 'Backoffice: ';
                default:
                    return '';
            }
        }
    }
}
