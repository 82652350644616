/// <reference path="../../../../Modules/Knowledgebase/Faq/FaqService.ts"/>
/// <reference path="../../../../Modules/Knowledgebase/Faq/AdminFaqResourceProvider.ts"/>
/// <reference path="../../../../Modules/Umbrella/ToastMessageService.ts"/>

namespace Umbrella.CustomerService {
    import AdminFaqModel = Umbrella.Modules.Knowledgebase.AdminFaqModel;
    import AdminFaqResourceClass = Umbrella.Modules.Knowledgebase.Faq.AdminFaqResourceClass;
    import FaqService = Umbrella.Modules.Knowledgebase.FaqService;
    import ThesaurusTagModel = Umbrella.Modules.Knowledgebase.ThesaurusTagModel;

    @Component('CustomerService', {
        selector: 'register-faq-popup',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/RegisterFaqPopupComponent/RegisterFaqPopup.html',
        bindings: {
            tag: '<'
        }
    })
    @Inject(
        '$mdDialog',
        'EmptyGuid',
        'AdminFaqResource',
        'ToastMessageService',
        'FaqService',
        'ApplicationResource'
    )
    class RegisterFaqPopupComponent {
        public faq: AdminFaqModel;
        private tag: ThesaurusTagModel;

        constructor(
            private $mdDialog,
            private emptyGuid: System.Guid,
            private adminFaqResource: AdminFaqResourceClass,
            private toastMessageService: ToastMessageService,
            private faqService: FaqService,
            private applicationResource: Umbrella.IApplicationResource
        ) {}

        public $onInit() {
            this.faq = this.faqService.createEmptyFaq();
            this.faq.status = Modules.Knowledgebase.PublicationStatus.ToReview;
            this.applicationResource.mine()
                .$promise
                .then(app => this.faq.applicationIds = [app.id]);
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public save(faq: AdminFaqModel): void {
            if (!faq || !this.tag) return;

            this.faq.mainTag = this.tag;
            this.faq.tags = [this.tag];
            
            this.adminFaqResource
                .save(faq)
                .$promise.then(faq => {
                    this.toastMessageService.success(
                        'Vraag succesvol vastgelegd'
                    );
                    this.$mdDialog.hide(faq);
                })
                .catch(() =>
                    this.toastMessageService.error(
                        'Fout opgetreden tijdens vastleggen van de vraag'
                    )
                );
        }
    }
}
