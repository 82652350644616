﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.ColleagueCard.Calendar {
    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.colleaguecard.calendar', {
                data: {
                    tab: 'calendar',
                    authorize: ps => ps.customerCard
                },
                url: '/calendar',
                views: {
                    'tab@customerservice': {
                        templateUrl:
                            '/CustomerService/_ColleagueCard/_Calendar/Tab.html',
                        controller: 'ColleagueCalendarTabController'
                    }
                }
            });
        }
    ]);
}
