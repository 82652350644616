/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../SelectedColleagueOrFunctiongroup.d.ts"/>
/// <reference path="../../../../Decorators.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Overview {
    import debounce = Umbrella.Helpers.debounce;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;
    import SelectedColleagueOrFunctiongroup = Modules.SelectedColleagueOrFunctiongroup;
    import IPromise = angular.IPromise;

    export interface CaseFlowOverviewFiltersComponentState {
        filters: CaseOverviewFilters;
    }

    @Component('Dashboard', {
        selector: 'caseflow-overview-filters',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Filters/CaseFlowOverviewFiltersComponent/CaseFlowOverviewFilters.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('CaseFlowOverviewService', 'ColleagueResource')
    class CaseFlowOverviewFiltersComponent {
        public state: CaseFlowOverviewFiltersComponentState;

        public searchQuery: string;
        public selectedStatus: string;
        public selectedCompletion: string;
        public selectedType: string;
        public owner: SelectedColleagueOrFunctiongroup;
        public executor: SelectedColleagueOrFunctiongroup;

        constructor(
            private caseFlowOverviewService: CaseFlowOverviewService,
            private colleagueResource: IColleagueResourceClass
        ) {}

        public $onInit(): void {
            this.searchQuery =
                this.state && this.state.filters && this.state.filters.query;
            this.owner =
                this.state && this.state.filters && this.state.filters.owner
                    ? { ...this.state.filters.owner }
                    : null;
            this.executor =
                this.state && this.state.filters && this.state.filters.executor
                    ? { ...this.state.filters.executor }
                    : null;
            this.selectedStatus =
                this.state &&
                this.state.filters &&
                this.state.filters.statusses &&
                this.state.filters.statusses.length === 1 &&
                this.state.filters.statusses[0];
            this.selectedCompletion =
                this.state &&
                this.state.filters &&
                this.state.filters.completion
            this.selectedType =
                this.state && this.state.filters && this.state.filters.caseType;
        }

        public filterByQuery = debounce(
            (query: string) => {
                this.caseFlowOverviewService.filterCasesByQuery(query);
            },
            500,
            false
        );

        public filterByCompletion(newSelection: string) {
            this.caseFlowOverviewService.filterCasesByCompletion(newSelection);
        }

        public filterByType(newSelection: string): void {
            this.caseFlowOverviewService.filterCasesByType(newSelection);
        }

        public filterByStatus(newSelection: string): void {
            this.caseFlowOverviewService.filterCasesByStatus(
                newSelection.split(',')
            );
        }

        public searchOwner(query = ''): IPromise<SummarizedColleagueModel[]> {
            return this.colleagueResource
                .getColleaguesAndFunctiongroups({
                    page: 0,
                    pageSize: 10,
                    id: query,
                    userId: null
                })
                .$promise.then(data => {
                    return data.items;
                });
        }

        public filterByOwner(
            newSelection: SelectedColleagueOrFunctiongroup
        ): void {
            this.caseFlowOverviewService.filterCasesByOwner(newSelection);
        }

        public searchExecutor(
            query = ''
        ): IPromise<SummarizedColleagueModel[]> {
            return this.colleagueResource
                .getColleaguesAndFunctiongroups({
                    page: 0,
                    pageSize: 10,
                    id: query,
                    userId: null
                })
                .$promise.then(data => {
                    return data.items;
                });
        }

        public filterByExecutor(
            newSelection: SelectedColleagueOrFunctiongroup
        ): void {
            this.caseFlowOverviewService.filterCasesByExecutor(newSelection);
        }
    }
}
