/// <reference path="../../Decorators.ts" />
/// <reference path="../../Window.d.ts" />
/// <reference path="../PhoneHub.ts" />

namespace Umbrella {
    import PhoneStatsModel = Umbrella.TelephonyControl.Statistics.PhoneStatsModel;
    import TelephonyQueueKpiState = Umbrella.TelephonyControl.Statistics.TelephonyQueueKpiState;

    @Component('Umbrella', {
        selector: 'top-live-stats',
        templateUrl: '/Top/TopLiveStatsComponent/TopLiveStats.html'
    })
    @Inject('$timeout', 'TopBarStore')
    class TopLiveStatsComponent {
        public telephonyInfo: Telephony.State;
        public supportsStatistics: boolean;
        public telephonyStatistics: PhoneStatsModel;
        public queueLength: string;
        public queueTime: string;

        private timeout: ng.IPromise<void> = null;
        private start = Date.now();
        private phoneObserver: Rx.IDisposable;

        constructor(private $timeout: ng.ITimeoutService, private topBarStore: TopBarStore) {}

        public $onInit() {
            const notNullOrUndefined = x => x !== null && x !== undefined;
            this.phoneObserver = this.topBarStore.state$
                .map(x => x && x.telephonyInfo)
                .filter(notNullOrUndefined)
                .distinctUntilChanged()
                .subscribe(x => {
                    this.telephonyStatistics = x.statistics;
                    this.telephonyInfo = x;
                });

            this.supportsStatistics =
                window.config &&
                window.config.facets &&
                window.config.facets.telephony &&
                window.config.facets.telephony[0] &&
                window.config.facets.telephony[0].available;

            this.scheduleQueueTimer();
        }

        public $onDestroy() {
            this.$timeout.cancel(this.timeout);

            if (this.phoneObserver) {
                this.phoneObserver.dispose();
                this.phoneObserver = null;
            }
        }

        public isQueueLengthAboveAverage() {
            return (
                this.telephonyStatistics.queueLengthKpiState.toString() ===
                    TelephonyQueueKpiState[TelephonyQueueKpiState.RequiresAttention] ||
                this.telephonyStatistics.queueLengthKpiState.toString() ===
                    TelephonyQueueKpiState.RequiresAttention.toString()
            );
        }

        public isQueueLengthLong() {
            return (
                this.telephonyStatistics.queueLengthKpiState.toString() ===
                    TelephonyQueueKpiState[TelephonyQueueKpiState.Critical] ||
                this.telephonyStatistics.queueLengthKpiState.toString() === TelephonyQueueKpiState.Critical.toString()
            );
        }

        public isQueueTimeAboveAverage() {
            return (
                !this.isQueueTimeLong() &&
                this.telephonyStatistics.queueTime > this.telephonyStatistics.queueTimeRequiresAttentionCap
            );
        }

        public isQueueTimeLong() {
            return this.telephonyStatistics.queueTime > this.telephonyStatistics.queueTimeCriticalCap;
        }

        private incrementQueueTime() {
            const difference = (Date.now() - this.start) / 1000;

            if (
                this.telephonyStatistics &&
                this.telephonyStatistics.queueLength &&
                this.telephonyStatistics.queueLength > 0
            )
                this.telephonyStatistics.queueTime += difference;

            this.start = Date.now();
        }

        private scheduleQueueTimer() {
            this.timeout = this.$timeout(
                () => {
                    this.incrementQueueTime();
                    this.scheduleQueueTimer();
                },
                1000,
                true
            );
        }
    }
}
