/// <reference path="../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    taskHub: Umbrella.TaskHandling.ITaskHubProxy;
}

namespace Umbrella.TaskHandling {
    import TaskModel = Modules.Tasks.TaskModel;

    export interface ITaskHubClient {
        created(task: TaskModel.Detailed);
        completed(task: TaskModel.Detailed);
        contentsModified(task: TaskModel.Detailed);
        discussed(task: TaskModel.Detailed);
        executorChanged(task: TaskModel.Detailed);
        pickedUp(task: TaskModel.Detailed);
        rejected(task: TaskModel.Detailed);
        deleted(task: TaskModel.Detailed);
    }

    export interface ITaskHubProxy extends HubProxy {
        client: ITaskHubClient;
    }

    export const taskHubOnCreated$ = new Rx.Subject<TaskModel.Detailed>();
    export const taskHubOnUpdated$ = new Rx.Subject<TaskModel.Detailed>();
    export const taskHubOnDeleted$ = new Rx.Subject<TaskModel.Detailed>();
    export const taskHubOnActivitiesUpdated$ = new Rx.Subject<
        TaskModel.Detailed
    >();

    angular
        .module('TaskHandling')
        .factory('TaskHub', () => $.connection.taskHub);

    Modules.onSignalRInitialized(() => {
        $.connection.taskHub.client.created = task =>
            taskHubOnCreated$.onNext(task);
        $.connection.taskHub.client.completed = task =>
            taskHubOnUpdated$.onNext(task);
        $.connection.taskHub.client.contentsModified = task =>
            taskHubOnUpdated$.onNext(task);
        $.connection.taskHub.client.executorChanged = task =>
            taskHubOnUpdated$.onNext(task);
        $.connection.taskHub.client.pickedUp = task =>
            taskHubOnUpdated$.onNext(task);
        $.connection.taskHub.client.rejected = task =>
            taskHubOnUpdated$.onNext(task);
        $.connection.taskHub.client.deleted = task =>
            taskHubOnDeleted$.onNext(task);
        $.connection.taskHub.client.discussed = task =>
            taskHubOnActivitiesUpdated$.onNext(task);
    });
}
