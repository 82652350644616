namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'onHoldCalls')
    class OnHoldCallsFilter {
        public transform(
            phoneCalls: Umbrella.Telephony.PhoneCallModel[]
        ): Umbrella.Telephony.PhoneCallModel[] {
            if (!phoneCalls) return undefined;

            const calls = [];
            for (const value of phoneCalls) {
                switch (value.status.toString().toLowerCase()) {
                    case '6':
                    case '106':
                    case 'onhold':
                    case 'tryresume':
                        calls.push(value);
                        break;
                    default:
                        break;
                }
            }

            return calls;
        }
    }
}
