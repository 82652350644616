namespace Umbrella.CustomerService.CustomerCard.Sidebar {
    @Service('CustomerService', 'CustomerCardSidebarService')
    @Inject('CustomerCardStore', 'LocalStorageService')
    export class CustomerCardSidebarService extends BaseStoreService<
        CustomerCardState,
        CustomerCardEvent,
        CustomerCardStore
    > {
        constructor(
            store: CustomerCardStore,
            private localStorageService: LocalStorageService
        ) {
            super(store);

            this.initializePanelState();
        }

        public toggleContractPanel(): void {
            const sidebarState = this.getSidebarState();
            const isCollapsed =
                sidebarState && sidebarState.contractPanelCollapsed;
            this.emit({ type: 'ToggleContractPanelEvent' });
            this.localStorageService.store(
                'ContractPanelCollapsed',
                !isCollapsed
            );
        }

        public toggleContractorPanel(): void {
            const sidebarState = this.getSidebarState();
            const isCollapsed =
                sidebarState && sidebarState.contractorPanelCollapsed;
            this.emit({ type: 'ToggleContractorPanelEvent' });
            this.localStorageService.store(
                'ContractorPanelCollapsed',
                !isCollapsed
            );
        }

        public toggleContactPanel(): void {
            const sidebarState = this.getSidebarState();
            const isCollapsed =
                sidebarState && sidebarState.contactPanelCollapsed;
            this.emit({ type: 'ToggleContactPanelEvent' });
            this.localStorageService.store(
                'ContactPanelCollapsed',
                !isCollapsed
            );
        }

        private initializePanelState(): void {
            this.emit({
                type: 'InitializePanelStateEvent',
                contractPanelCollapsed: this.getStoredValue(
                    'ContractPanelCollapsed'
                ),
                contractorPanelCollapsed: this.getStoredValue(
                    'ContractorPanelCollapsed'
                ),
                contactPanelCollapsed: this.getStoredValue(
                    'ContactPanelCollapsed'
                )
            });
        }

        private getStoredValue(storageKey: string): boolean {
            const isNullOrUndefined = value =>
                value === null || value === undefined;
            const storedValue = this.localStorageService.get(storageKey);
            const isCollapsed = isNullOrUndefined(storedValue)
                ? true
                : storedValue;
            return isCollapsed;
        }

        private getSidebarState(): Sidebar.State {
            const state = this.store.getState();
            return state && state.sidebar;
        }
    }
}
