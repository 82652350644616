namespace Umbrella.Modules.Announcements {
    @Pipe('Umbrella', 'stripHtmlExceptHrefs')
    class StripHtmlExceptHrefsFilter {
        public transform(html: string) {
            return html
                ? html.replace(/<(?!\/?a(?=>|\s.*>))\/?.*?>/gm, '')
                : '';
        }
    }
}
