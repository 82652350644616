/// <reference path="../../ChatConversationHandling/ChatStore.ts" />

namespace Umbrella.TopBar {
    import RegisterStatus = ChatConversationHandling.RegisterStatus;
    import ChatStore = Umbrella.ChatConversationHandling.ChatStore;
    import ChatState = Umbrella.ChatConversationHandling.ChatState;
    import ChatResource = Umbrella.ChatConversationHandling.ChatResource;
    import ChatService = Umbrella.ChatConversationHandling.ChatService;
    import OperatorModel = Umbrella.Chat.OperatorModel;
    import ChatOverviewItemModel = Umbrella.ChatConversationHandling.ChatOverviewItemModel;
    import ConversationRequestModel = Umbrella.Chat.ConversationRequestModel;
    import RestoreChannelService = Umbrella.Modules.RestoreChannelService;
    import ChannelType = Modules.ChannelType;

    @Component('Umbrella', {
        selector: 'chat-availability-controls',
        templateUrl: '/Top/ChatAvailabilityControlsComponent/ChatAvailabilityControls.html',
        bindings: {
            chatInfo: '<'
        }
    })
    @Inject('ChatStore', 'ChatService', 'ChatResource', 'RestoreChannelService')
    class ChatAvailabilityControlsComponent {
        public collapsed = false;
        public chatInfo: ChatState;
        public chatAvailability;
        public chatIconClass: string;
        public chatIconTooltip: string;
        public nrOfChatOperators = 0;
        public nrOfPendingRequests = 0;
        private operatorObserver: Rx.IDisposable;
        private requestObserver: Rx.IDisposable;
        private loggedIn: boolean;
        private loggedInPreset = false;

        constructor(
            private chatStore: ChatStore,
            private chatService: ChatService,
            private chatResource: ChatResource,
            private restoreChannelService: RestoreChannelService
        ) { }

        public $onInit() {
            this.observeActiveOperators();
            this.observePendingRequests();

            this.chatStore.state$
                .filter(x => !!x)
                .distinctUntilChanged()
                .subscribe((s: ChatState) => {
                    this.chatAvailability = {
                        isAvailable: s && s.registration && s.registration.status === RegisterStatus.Registered,
                        isEnabled: s && s.enabled
                    };
                    this.loggedIn = s.isLoggedIn;
                    this.setIconColorAndLabel(
                        (s && s.conversations) || [],
                        this.chatAvailability.isEnabled,
                        this.chatAvailability.isAvailable
                    );
                });
        }

        public toggleAvailability() {
            if (this.loggedIn) {
                this.chatService.logoutOperator();
                this.loggedIn = false;
            } else {
                this.chatService.loginOperator();
                this.loggedIn = true;
            }
        }

        public toggleAvailabilityPreset() {
            this.loggedInPreset = !this.loggedInPreset;
        }

        public getCheckedPreset(): boolean {
            return this.loggedInPreset;
        }

        public getChecked(): boolean {
            return this.loggedIn;
        }

        public $onDestroy() {
            if (this.operatorObserver) this.operatorObserver.dispose();
            if (this.requestObserver) this.requestObserver.dispose();
        }

        public openMenu($mdMenu): void {
            if (
                (this.chatAvailability && !this.chatAvailability.isEnabled) ||
                !window.user.permissions.viewChatConversation
            ) {
                return;
            }

            $mdMenu.open();
            this.collapsed = true;
        }

        public hide(): void {
            this.collapsed = false;
        }

        public toggleChat(value: boolean) {
            if (value) {
                this.chatService.registerOperator(this.loggedInPreset);
                this.restoreChannelService.saveChannel(ChannelType.Chat)
            } else {
                this.chatService.deregisterOperator();
                this.restoreChannelService.removeChannelInfo();
            }
        }

        private observeActiveOperators(): void {
            this.operatorObserver = Rx.Observable.interval(5000)
                .filter(() => this.collapsed)
                .flatMapLatest(() =>
                    Rx.Observable.fromPromise(<any>this.chatResource.getAllOperators().$promise).take(1)
                )
                .subscribe((operators: OperatorModel[]) => {
                    this.nrOfChatOperators = (operators && operators.filter(x => x.isLoggedIn).length) || 0;
                });
        }

        private observePendingRequests(): void {
            this.requestObserver = Rx.Observable.interval(5000)
                .filter(() => this.collapsed)
                .flatMapLatest(() =>
                    Rx.Observable.fromPromise(<any>(
                        this.chatResource.getPendingConversations().$promise
                    )).take(1)
                )
                .subscribe((requests: ConversationRequestModel[]) => {
                    this.nrOfPendingRequests = (requests && requests.length) || 0;
                });
        }

        private setIconColorAndLabel(
            conversations: ChatOverviewItemModel[],
            isEnabled: boolean,
            isAvailable: boolean
        ): void {
            const hasPickedUpConversations = conversations && !!conversations.find(x => !!x.id && !!x.operator);

            if (!isEnabled) {
                this.chatIconClass = 'grijs';
                this.chatIconTooltip = 'Chat niet beschikbaar';
                return;
            }

            if (!window.user.permissions.viewChatConversation) {
                this.chatIconClass = 'grijs';
                this.chatIconTooltip = 'Geen permissies om chat te gebruiken';
                return;
            }

            if (hasPickedUpConversations) {
                this.chatIconClass = 'white';
                this.chatIconTooltip = 'Chat is actief';
                if (isAvailable) {
                    this.chatIconClass = this.loggedIn ? 'white' : 'oranje';
                    this.chatIconTooltip = 'Afmelden voor chat';
                    return;
                }
                return;
            }

            if (isAvailable) {
                this.chatIconClass = this.loggedIn ? 'groen' : 'oranje';
                this.chatIconTooltip = 'Afmelden voor chat';
                return;
            }

            this.chatIconClass = 'blauw';
            this.chatIconTooltip = 'Aanmelden voor chat';
        }
    }
}
