﻿/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Window.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.CaseFlowOverview {
    import CaseFlowModel = Umbrella.CaseFlow.CaseFlowModel;
    import SelfServiceScenarioModel = Modules.SelfService.SelfServiceScenarioModel;

    export interface CaseStepSelfServiceScenario {
        selfServiceScenarioId?: System.Guid;
        isFinished: boolean;
        title: string;
        icon: string;
        startAutomatically: boolean;
    }

    @Component('Dashboard', {
        selector: 'case-flow-step-self-service-scenarios',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/_Steps/CaseFlowStepSelfServiceScenariosComponent/CaseFlowStepSelfServiceScenarios.html',
        bindings: {
            caseId: '<',
            caseFlowId: '<',
            person: '<',
            personId: '<',
            completeBy: '=',
            step: '<'
        }
    })
    @Inject('$mdDialog', 'SelfServiceScenarioResource', 'ToastMessageService')
    class CaseFlowStepSelfServiceScenariosComponent {
        public selfServiceScenarios: CaseStepSelfServiceScenario[];
        public completeBy: string;
        private caseId: System.Guid;
        private caseFlowId: System.Guid;
        private person: PersonModel;
        private personId: System.Guid;
        private step: CaseFlowModel.CaseFlowStep;

        constructor(
            private $mdDialog,
            private selfServiceScenarioResource: Umbrella.CustomerService.SelfServiceScenarioResource,
            private toastSvc: ToastMessageService
        ) {}

        public $onChanges = (bindings: { step: IBindingChange<CaseFlowModel.CaseFlowStep> }) => {
            if (bindings.step && bindings.step.currentValue) {
                this.loadStepSelfServiceScenarios();
            }
        };

        public startSelfserviceScenario(scenario: CaseStepSelfServiceScenario) {
            this.$mdDialog.show({
                template:
                    '<caseflow-step-self-service-scenario-popup step="step" case-id="caseId" case-flow-id="caseFlowId" person="person" person-id="personId" scenario-id="scenarioId"></caseflow-step-self-service-scenario-popup>',
                locals: {
                    step: this.step,
                    caseId: this.caseId,
                    caseFlowId: this.caseFlowId,
                    person: this.person,
                    personId: this.personId,
                    scenarioId: scenario.selfServiceScenarioId
                },
                controller: [
                    '$scope',
                    'step',
                    'caseId',
                    'caseFlowId',
                    'person',
                    'personId',
                    'scenarioId',
                    function($scope, step, caseId, caseFlowId, person, personId, scenarioId) {
                        $scope.step = step;
                        $scope.caseId = caseId;
                        $scope.caseFlowId = caseFlowId;
                        $scope.person = person;
                        $scope.personId = personId;
                        $scope.scenarioId = scenarioId;
                    }
                ],
                clickOutsideToClose: false
            });
        }

        public loadStepSelfServiceScenarios(): void {
            this.selfServiceScenarios = [];

            const stepSelfServiceScenarios = this.step && this.step.selfServiceScenarios;
            if (!stepSelfServiceScenarios || !stepSelfServiceScenarios.length) return;

            const selfServiceScenarioIds = stepSelfServiceScenarios
                .map(s => s.selfServiceScenarioId)
                .filter(id => id !== null);

            this.selfServiceScenarioResource
                .getByIds({ ids: selfServiceScenarioIds })
                .$promise.then((scenarios: SelfServiceScenarioModel.Detailed[]) => {
                    const list: CaseStepSelfServiceScenario[] = [];
                    for (const stepSelfServiceScenario of this.step.selfServiceScenarios) {
                        const detailedSelfServiceScenario = this.filterSelfServiceScenario(
                            scenarios,
                            stepSelfServiceScenario.selfServiceScenarioId
                        );
                        list.push({
                            selfServiceScenarioId: stepSelfServiceScenario.selfServiceScenarioId,
                            isFinished: stepSelfServiceScenario.isFinished,
                            title: stepSelfServiceScenario.title,
                            icon: (detailedSelfServiceScenario && detailedSelfServiceScenario.icon) || '',
                            startAutomatically: stepSelfServiceScenario.startAutomatically
                        });
                    }

                    this.selfServiceScenarios = list;
                })
                .catch(e =>
                    this.toastSvc.error(
                        `Selfservice scenario's voor stap \'${this.step.title}\' kunnen niet geladen worden`
                    )
                );
        }

        public isStepPickedUp(): boolean {
            return this.step && this.step.pickedUpOn !== null && this.step.finishedOn === null;
        }

        private filterSelfServiceScenario(
            selfServiceScenarios: SelfServiceScenarioModel.Detailed[],
            selfServiceScenarioId: System.Guid
        ): SelfServiceScenarioModel.Detailed {
            const selection = selfServiceScenarios.filter(
                selfSerivceScenario => selfSerivceScenario.id === selfServiceScenarioId
            );
            return selection.length > 0 ? selection[0] : null;
        }
    }
}
