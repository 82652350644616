/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService {
    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;

    @Component('CustomerService', {
        selector: 'registration-timeline',
        templateUrl:
            '/CustomerService/_CustomerCard/_Registrations/RegistrationTimelineComponent/RegistrationTimeline.html',
        bindings: {
            registrations: '<',
            loading: '<'
        }
    })
    class RegistrationTimelineComponent {
        public registrations: RegistrationModel[];
        public loading: boolean;
    }
}
