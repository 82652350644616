namespace Umbrella.Modules.Chat {
    import IResource = ng.resource.IResource;
    import ChatConfiguration = Umbrella.Chat.ChatConfigurationModel;

    export interface NamedParams {
        name: string;
    }

    export interface ChatConfigResource {
        get(): IResource<ChatConfiguration>;
        update(section: ChatConfiguration): IResource<any>;
    }

    angular.module('Chat').service('ChatConfigResource', [
        '$resource',
        $resource =>
            $resource('chat/api/v1/chat/config', null, {
                get: {
                    method: 'GET',
                    isArray: false
                },
                update: {
                    method: 'PUT',
                    isArray: false
                }
            })
    ]);
}
