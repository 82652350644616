/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard.Announcements {
    function mapState(s: UnitCardState): UnitAnnouncementsComponentState {
        return {
            unit: s && s.unit,
            loading: !s || !s.unit
        };
    }

    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.unitcard.tab.announcements', {
                url: '/mededelingen',
                reloadOnSearch: false,
                data: {
                    tab: 'Mededelingen',
                    authorize: ps => ps.customerCard && ps.announcementRead
                },
                views: {
                    'tab@customerservice': {
                        template:
                            '<unit-announcements state="state$ | async:this"></unit-announcements>',
                        controller: createComponentRouteController(
                            'UnitCardStore',
                            mapState
                        )
                    }
                }
            });
        }
    ]);
}
