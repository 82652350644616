namespace Umbrella.CustomerService {
    import FaqResourceClass = Modules.Knowledgebase.Faq.FaqResourceClass;
    import IndicatorEvaluationModel = Umbrella.Indicators.IndicatorEvaluationModel;
    export abstract class BaseIndicatorsComponent {
        constructor(
            private $mdDialog: any,
            private faqResource: FaqResourceClass,
            private toastMessageService: ToastMessageService
        ) {}

        public displayExplanation(indicator: IndicatorEvaluationModel) {
            if (!indicator || !indicator.faqId) return;

            this.faqResource
                .getById({ id: indicator.faqId })
                .$promise.then(
                    faq => faq.expiresOn && new Date().getTime() > new Date(faq.expiresOn).midnight().getTime() 
                        ? this.toastMessageService.warning("Geen toelichting beschikbaar, publicatieperiode is verlopen") 
                        : this.displayPopup(indicator.title, faq.answer), 
                    e => this.toastMessageService.error("Geen toelichting beschikbaar, gekoppelde vraag is niet gevonden of niet gepubliceerd")
                );
        }

        private displayPopup(title: string, answer: string) {
            this.$mdDialog.show(
                this.$mdDialog
                    .alert()
                    .clickOutsideToClose(true)
                    .title(title)
                    .textContent(stripHtml(answer))
                    .ariaLabel(title)
                    .ok('Sluiten')
            );
        }
    }
}