namespace Umbrella.ChatConversationHandling {
    @Pipe('Umbrella', 'isAcwConversation')
    class IsAcwConversationFilter {
        public transform(conversation: ChatConversation): boolean {
            if (!conversation) return false;

            return isAcwConversation(conversation.status);
        }
    }
}
