﻿/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.CustomerService {
    import IndicatorModel = Umbrella.Indicators.IndicatorModel;
    import IndicatorSuppressionModel = Umbrella.Indicators.IndicatorSuppressionModel;
    import IResource = angular.resource.IResource;
    import IResourceClass = angular.resource.IResourceClass;
    import IResourceService = angular.resource.IResourceService;
    import Guid = System.Guid;

    export interface IIndicatorResource
        extends IResource<IndicatorModel>,
            IndicatorModel {}
    export interface IIndicatorResourceClass
        extends IResourceClass<IIndicatorResource> {
        getAll(params: {
            query: string;
            page: number;
            pageSize: number;
        }): IndicatorModel[];
        getById(params: { id: Guid }): IIndicatorResource;
        removeIndicator(params: { id: Guid }): IIndicatorResource;
        update(model: IndicatorModel): IIndicatorResource;
        mute(model: IndicatorSuppressionModel): IIndicatorResource;
    }

    angular.module('CustomerService').service('IndicatorResource', [
        '$resource',
        ($resource: IResourceService) =>
            $resource(
                '/api/v1/indicator/:id/:action',
                {},
                {
                    getAll: { method: 'GET', isArray: true },
                    getById: { method: 'GET', params: { id: '@id' } },
                    removeIndicator: {
                        method: 'DELETE',
                        params: { id: '@id' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: '' }
                    },
                    mute: { method: 'POST', url: '/api/v1/indicator/mute' }
                }
            )
    ]);
}
