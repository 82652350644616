﻿/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../ObservableStore.ts"/>
/// <reference path="../RootStore.ts"/>
/// <reference path="../DateExtensions.ts"/>
/// <reference path="../Top/NotificationService.ts" />
/// <reference path="../CustomerService/_CustomerCard/_Activities/Registration/ActivityRegistrationService.ts" />
/// <reference path="ChatStore.ts"/>

namespace Umbrella.ChatConversationHandling {
    import Guid = System.Guid;
    import ConversationRequestModel = Umbrella.Chat.ConversationRequestModel;
    import ActiveConversationModel = Umbrella.Chat.ActiveConversationModel;
    import MessageModel = Umbrella.Chat.MessageModel;
    import contactActivityRegistered$ = Umbrella.CustomerService.CustomerCard.Activities.Registration.contactActivityRegistered$;
    import taskCreated$ = Umbrella.CustomerService.CustomerCard.Activities.Registration.taskCreated$;
    import ContactActivityModel = Umbrella.Modules.Contacts.ContactActivityModel;
    import IContactActivityResource = Umbrella.Modules.Contacts.IContactActivityResource;
    import NotificationService = Umbrella.Modules.NotificationService;
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;
    import ITaskActivityResource = Umbrella.TaskHandling.ITaskActivityResource;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import ActivityRegistrationData = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationData;

    @Service('Chat', 'ChatService')
    @Inject(
        '$state',
        'ToastMessageService',
        'ChatResource',
        'PersonResource',
        'ContactActivityResource',
        'NotificationService',
        'TaskActivityResource',
        'ColleagueResource'
    )
    export class ChatService extends BaseStoreService<ChatState, ChatEvent, ChatStore> {
        private chatObserver: Rx.IDisposable;
        private cachedCustomers: ChatCustomerModel[] = [];
        private previousConversationState = '';
        private selectedAnswer: string;

        constructor(
            private $state: ng.ui.IStateService,
            private toastMesssageService: ToastMessageService,
            private chatResource: ChatResource,
            private personResource: PersonResource,
            private contactActivityResource: IContactActivityResource,
            private notificationService: NotificationService,
            private taskActivityResource: ITaskActivityResource,
            private colleagueResource: IColleagueResourceClass
        ) {
            super(chatStore);

            const isModuleEnabled = true;
            if (isModuleEnabled) {
                this.emit({ type: 'EnabledEvent' });
                this.applyPolling();

                contactActivityRegistered$.distinctUntilChanged().subscribe((activity: ContactActivityModel) => {
                    this.linkActivityToChatConversation(activity);
                });

                taskCreated$.distinctUntilChanged().subscribe((activityRegistrationData: ActivityRegistrationData) => {
                    this.linkTaskToChatConversation(activityRegistrationData);
                });
            }
        }

        public async load() {
            this.emit({ type: 'ConversationsLoadingEvent' });

            const toList = response => {
                const list: ChatOverviewItemModel[] = [
                    ...response[0].map(x => this.mapActiveConversation(x)),
                    ...response[1].map(x => this.mapPendingRequest(x))
                ];
                return list;
            };

            const operators = await this.chatResource.getAllOperators().$promise;
            const currentOperator = operators.find(x => x.personId == window.user.personId);
            if (!currentOperator) return;

            this.emit({ type: 'ChatOperatorLoginStatusChangedEvent', isLoggedIn: currentOperator.isLoggedIn });

            Rx.Observable.forkJoin(
                <any>this.chatResource.getActiveConversations().$promise,
                <any>this.chatResource.getPendingConversations().$promise
            )
                .map(toList)
                .tapOnError(_ => this.resetChatObserver(10000))
                .take(1)
                .subscribe(conversations => {
                    this.resetChatObserver(1000);

                    if (!this.hasConversationStateChanged(conversations)) return;

                    if (
                        this.nrOfConversationsHasIncreased(conversations) &&
                        currentOperator &&
                        currentOperator.isLoggedIn
                    )
                        this.displayNotificationWhenBrowserIsHiddenOrNotInChatDashboard();

                    this.emit({ type: 'ConversationsLoadedEvent', conversations });

                    this.loadAndCacheCustomers(conversations);
                });
        }

        public select(originId: Guid): void {
            this.emit({ type: 'ConversationSelectedEvent', originId });
        }

        public accept(requestId: Guid): ng.IPromise<ConversationIdResponseModel> {
            const promise = this.chatResource.accept({ id: requestId }).$promise;
            promise
                .then((response: ConversationIdResponseModel) => {
                    this.emit({
                        type: 'ConversationPickedupEvent',
                        requestId,
                        conversationId: response.kccConversationId
                    });
                    this.openConversation(response.kccConversationId.kccConversationId);
                })
                .catch(() => {
                    this.toastMesssageService.error(
                        'Fout opgetreden tijdens aannemen gesprek. Chat gesprek reeds opgepakt? Probeer het anders nogmaals.'
                    );
                });

            return promise;
        }

        public pickup(conversationId: Guid): ng.IPromise<void> {
            const promise = this.chatResource.pickup({ id: conversationId }).$promise;
            promise
                .then(() => {
                    this.emit({ type: 'ConversationEnterEvent', conversationId });
                })
                .catch(() => {
                    this.toastMesssageService.error(
                        'Fout opgetreden tijdens oppakken gesprek. Chat gesprek reeds opgepakt? Probeer het anders nogmaals.'
                    );
                });

            return promise;
        }

        public openConversation(id: Guid): void {
            this.emit({ type: 'ConversationEnterEvent', conversationId: id });
            this.conversationMessagesRead(id);
        }

        public closeConversation(conversation?: ChatConversation): void {
            this.emit({ type: 'ConversationLeaveEvent' });
            if (conversation) this.conversationMessagesRead(conversation.id);
        }

        public startAfterCallWork(id: Guid): void {
            this.chatResource
                .leave({ id })
                .$promise.then(() => {
                    this.emit({ type: 'StartAfterCallWorkEvent', conversationId: id });
                })
                .catch(() => {
                    this.toastMesssageService.error('Fout opgetreden tijdens afronden gesprek. Probeer het nogmaals.');
                });
        }

        public detachOperator(id: Guid): void {
            this.chatResource
                .detach({ id })
                .$promise.then(() => {
                    this.emit({ type: 'ConversationEndEvent', conversationId: id });
                    this.closeConversation();
                })
                .catch(() => {
                    this.toastMesssageService.error('Fout opgetreden tijdens vrijgeven gesprek. Probeer het nogmaals.');
                });
        }

        public endAfterCallWork(id: Guid): void {
            this.chatResource
                .endAcw({ id })
                .$promise.then(() => {
                    this.emit({ type: 'ConversationEndEvent', conversationId: id });
                })
                .catch(() => {
                    this.toastMesssageService.error(
                        'Fout opgetreden tijdens beeindigen gesprek. Probeer het nogmaals.'
                    );
                });
        }

        public async registerOperator(login = false) {
            const colleague = await this.colleagueResource.getById({ id: window.user.id }).$promise;
            const firstName = colleague.naturalPerson ? colleague.naturalPerson.firstName : colleague.name;
            const lastName = colleague.naturalPerson
                ? `${colleague.naturalPerson.insertion} ${colleague.naturalPerson.lastName}`.trim()
                : '';

            const loginModel = {
                firstName: firstName,
                lastName: lastName,
                avatar: ''
            };

            this.chatResource
                .register(loginModel) //polling
                .$promise.then(() => {
                    if (login) {
                        this.loginOperator();
                    }
                    this.emit({ type: 'RegisteredEvent' });
                })
                .catch(() => {
                    this.toastMesssageService.error('Fout opgetreden tijdens aanmelden. Probeer het nogmaals.');
                });
        }

        public deregisterOperator() {
            this.chatResource
                .logout()
                .$promise.then(() => {
                    this.emit({ type: 'DeregisteredEvent' });
                    this.clearConversationState();
                })
                .catch(() => {
                    this.toastMesssageService.error('Fout opgetreden tijdens afmelden. Probeer het nogmaals.');
                });
        }

        public addSelectedAnswer(answer: string): void {
            this.emit({ type: 'SelectedAnswerEvent', answer: answer });
        }

        public loginOperator() {
            this.chatResource
                .login()
                .$promise.then(() => {
                    return;
                })
                .catch(() => {
                    this.toastMesssageService.error(
                        'Fout opgetreden tijdens inloggen voor beschikbaarheid. Probeer het nogmaals.'
                    );
                });
        }

        public logoutOperator() {
            this.chatResource
                .logout()
                .$promise.then(() => {
                    return;
                })
                .catch(() => {
                    this.toastMesssageService.error(
                        'Fout opgetreden tijdens uitloggen voor beschikbaarheid. Probeer het nogmaals.'
                    );
                });
        }

        public sendMessage = (conversationId: System.Guid, messageText: string) => {
            this.chatResource.sendMessage({ id: conversationId }, { messageText }).$promise.catch(() => {
                this.toastMesssageService.error('Bericht kon niet verzonden worden');
            });
        };

        public conversationMessagesRead = (conversationId: System.Guid) => {
            this.emit({ type: 'ConversationMessagesReadEvent', conversationId });
        };

        public hasActiveConversation(personId: System.Guid): boolean {
            const state = this.getState();
            const personConversations =
                state && state.conversations && state.conversations.filter(x => x.customer.id === personId);
            return personConversations && !!personConversations.length;
        }

        public toCustomer(person: PersonModel): ChatCustomerModel {
            return {
                id: person.id,
                firstName: person.name,
                lastName: '',
                email:
                    (person.contactDetails &&
                        person.contactDetails.emailAddresses &&
                        person.contactDetails.emailAddresses.length &&
                        person.contactDetails.emailAddresses[0].email) ||
                    '',
                phoneNumber: ''
            };
        }

        public link(conversationId: System.Guid, person: PersonModel) {
            this.chatResource
                .linkCustomer({ id: conversationId, customerId: person.id })
                .$promise.then(() => {
                    this.cachedCustomers.push(this.toCustomer(person));
                })
                .catch(() => {
                    this.toastMesssageService.error(
                        'Er is een fout opgetreden bij het koppelen. Probeer het nogmaals.'
                    );
                });
        }

        public unlinkCustomer(conversationId: System.Guid) {
            this.chatResource.unlinkCustomer({ id: conversationId }).$promise.catch(() => {
                this.toastMesssageService.error('Er is een fout opgetreden bij het ontkoppelen. Probeer het nogmaals.');
            });
        }

        public getInitialQuestionText = (c: ChatConversation) => {
            if (c && c.messages) {
                const initialQuestion = c.messages.filter(m => m.direction == MessageDirections.toOperator)[0];
                if (initialQuestion) {
                    return initialQuestion.text;
                }
            }
            return null;
        };

        private hasConversationStateChanged(conversations: ChatOverviewItemModel[]): boolean {
            const currentConversationState = JSON.stringify(conversations);
            const result = this.previousConversationState !== currentConversationState;

            this.previousConversationState = currentConversationState;
            return result;
        }

        private clearConversationState(): void {
            this.previousConversationState = '';
        }

        private loadAndCacheCustomers(conversations: ChatOverviewItemModel[]): void {
            const notCachedCustomerIds = conversations
                .filter(x => x.customer.id)
                .filter(x => !this.cachedCustomers.find(c => c.id === x.id))
                .map(x => x.customer.id);

            if (!notCachedCustomerIds.length) return;

            this.personResource.getByIds({ ids: notCachedCustomerIds }).$promise.then(persons => {
                const foundIds = persons.map(x => x.id);
                const notFoundIds = notCachedCustomerIds.filter(id => foundIds.indexOf(id) === -1);

                this.cachedCustomers.push(...persons.map(x => this.toCustomer(x)));
                this.cachedCustomers.push(...notFoundIds.map(id => this.getCachedCustomerOrUnknown(id)));
            });
        }

        private nrOfConversationsHasIncreased(conversations: ChatOverviewItemModel[]): boolean {
            const nrOfPendingRequests = conversations.length;

            const state = this.getState();
            const previousNrOfPendingRequests = state && state.conversations && state.conversations.length;

            return nrOfPendingRequests > previousNrOfPendingRequests;
        }

        private displayNotificationWhenBrowserIsHiddenOrNotInChatDashboard(): void {
            const inChatOverview = this.$state.current.name.indexOf('dashboard.chat') > -1;
            const isBrowserHiddenOrNotHavingFocus = document.visibilityState === 'hidden' || !document.hasFocus();

            if (!inChatOverview || isBrowserHiddenOrNotHavingFocus)
                this.notificationService.show('Inkomend chatgesprek', '');
        }

        private linkActivityToChatConversation(activity: ContactActivityModel): void {
            const conversation = activity && activity.role && this.getConversation(activity.role.personId);
            if (!conversation) return;

            this.contactActivityResource.assignChatConversation({
                chatConversationId: conversation.id,
                contactMomentId: activity.id
            });
        }

        private linkTaskToChatConversation(activityRegistrationData: ActivityRegistrationData): void {
            const conversation =
                activityRegistrationData.task &&
                activityRegistrationData.task.relatedPerson &&
                this.getConversation(activityRegistrationData.task.relatedPerson.personId);
            if (!conversation && !activityRegistrationData) return;
            const chatConversationId =
                (activityRegistrationData && activityRegistrationData.chatConversationId) ||
                (conversation && conversation.id);
            this.taskActivityResource.assignChatConversation({
                chatConversationId,
                taskId: activityRegistrationData.task.id
            });
        }

        private getConversation(personId: System.Guid): ChatOverviewItemModel {
            const state = this.getState();
            const filteredByPersonId =
                state && state.conversations && state.conversations.filter(x => x.customer.id === personId);
            return filteredByPersonId.length && filteredByPersonId[0];
        }

        private applyPolling(): void {
            this.setChatObserver(1000);
        }

        private resetChatObserver(interval: number) {
            this.chatObserver.dispose();
            this.setChatObserver(interval);
        }

        private setChatObserver(interval: number) {
            const isLoggedIn = () => {
                const state = this.getState();
                return (
                    state &&
                    state.enabled &&
                    state.registration &&
                    state.registration.status === RegisterStatus.Registered
                );
            };

            this.chatObserver = Rx.Observable.interval(interval)
                .filter(isLoggedIn)
                .subscribe(() => {
                    this.load();
                });
        }

        private getCustomer(conversation: ActiveConversationModel): ChatCustomerModel {
            if (conversation.customerId) return this.getCachedCustomerOrUnknown(conversation.customerId);

            const nonOperatorParticipant =
                conversation && conversation.participantInfo && conversation.participantInfo.find(x => !x.chatOperator);

            if (nonOperatorParticipant.authenticatedCustomer) {
                return this.getCachedCustomerOrUnknown(nonOperatorParticipant.authenticatedCustomer.customerId);
            } else if (nonOperatorParticipant.anonymousCustomer) {
                return {
                    id: null,
                    firstName: nonOperatorParticipant.anonymousCustomer.firstName || 'Onbekende',
                    lastName: nonOperatorParticipant.anonymousCustomer.lastName || 'relatie',
                    email: nonOperatorParticipant.anonymousCustomer.emailAddress || '',
                    phoneNumber: ''
                };
            } else if (nonOperatorParticipant.externalChannelParticipant) {
                return {
                    id: null,
                    firstName: nonOperatorParticipant.externalChannelParticipant.displayName,
                    lastName: '',
                    email: '',
                    phoneNumber: nonOperatorParticipant.externalChannelParticipant.profileId
                };
            }
            throw { messageText: 'Could not extract non-operator participant.' };
        }

        private mapActiveConversation(conversation: ActiveConversationModel): ChatOverviewItemModel {
            return {
                createdOn: conversation.createdOn,
                id: conversation.kccConversationId,
                requestId: conversation.kccConversationRequestId,
                externalConversationId: conversation.externalConversationId,
                originId: conversation.kccConversationRequestId || conversation.externalConversationId,
                customer: this.getCustomer(conversation),
                operator: conversation.currentChatOperator
                    ? {
                        id: conversation.currentChatOperator.participantId,
                        name:
                            conversation.currentChatOperator.firstName +
                            ' ' +
                            conversation.currentChatOperator.lastName
                    }
                    : null,
                initialQuestion:
                    (conversation.messages && conversation.messages.length && conversation.messages[0].text) || '',
                isAnonymous: !!!conversation.customerId,
                status: conversation.afterCallWorkStartedOn
                    ? ConversationStatus.ACW
                    : conversation.currentChatOperator
                        ? ConversationStatus.Active
                        : ConversationStatus.Detached,
                messages: this.mapMessages(conversation.messages, conversation.participantInfo),
                afterCallWorkStartedOn: conversation.afterCallWorkStartedOn,
                rating: conversation.rating
            };
        }

        private mapPendingRequest(conversation: ConversationRequestModel): ChatOverviewItemModel {
            const customerId = conversation.authenticated && conversation.authenticated.customerId;
            const customer: ChatCustomerModel = customerId
                ? this.getCachedCustomerOrUnknown(customerId)
                : {
                    id: null,
                    firstName: conversation.anonymous && conversation.anonymous.firstName,
                    lastName: conversation.anonymous && conversation.anonymous.lastName,
                    email: conversation.anonymous && conversation.anonymous.emailAddress,
                    phoneNumber: ''
                };

            return {
                createdOn: conversation.createdOn,
                id: undefined,
                originId: conversation.kccConversationRequestId || conversation.externalConversationId,
                isAnonymous: !!conversation.anonymous,
                customer,
                initialQuestion: conversation.initialQuestion,
                status: ConversationStatus.Pending,
                messages: this.mapMessages([], null)
            };
        }

        private getCachedCustomerOrUnknown(customerId: System.Guid): ChatCustomerModel {
            return (
                (customerId && this.cachedCustomers.find(x => x.id === customerId)) || {
                    id: customerId,
                    firstName: 'Onbekende',
                    lastName: 'relatie',
                    email: '',
                    phoneNumber: ''
                }
            );
        }

        private mapMessages(messages: MessageModel[], participants: Chat.ParticipantModel[]): ChatMessage[] {
            const operators = participants && participants.filter(x => !!x.chatOperator);
            const operatorParticipantIds = operators && operators.map(x => x.participantId);

            return messages.map(x => {
                return {
                    id: x.id,
                    sentOn: new Date(x.timestamp),
                    direction: operatorParticipantIds.find(y => y === x.participantId)
                        ? MessageDirections.toCustomer
                        : MessageDirections.toOperator,
                    text: x.text,
                    status: null,
                    isRead: false,
                    isSystemMessage: !x.participantId,
                    attachments: x.attachments
                };
            });
        }
    }
}
