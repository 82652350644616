/// <reference path="../Decorators.ts" />
/// <reference path="../Scripts/TypeScript/gsap/index.d.ts" />

namespace Umbrella {
    export interface TopComponentState {
        impersonationInfo: Impersonation.State;
    }

    @Component('Umbrella', {
        selector: 'top',
        templateUrl: '/Top/Top.html',
        bindings: {
            state: '<'
        }
    })
    class TopComponent {
        public state: TopComponentState;
        public sessionInfo: SessionState;
        private sessionInfoSub: Rx.Disposable;

        $onInit() {
            this.sessionInfoSub = session$.subscribe(x => (this.sessionInfo = x));
        }

        $onDestroy() {
            if (this.sessionInfoSub) {
                this.sessionInfoSub.dispose();
                this.sessionInfoSub = null;
            }
        }

        public isInFullScreenMode(): boolean {
            return this.state && this.state.impersonationInfo && this.state.impersonationInfo.fullscreenMode;
        }

        public isInTabletMode(): boolean {
            return window.navigator && window.navigator.pointerEnabled && window.navigator.maxTouchPoints > 1;
        }
    }
}
