/// <reference path="../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService {
    import IResource = ng.resource.IResource;
    import PhoneStatusSummaryModel = Umbrella.TelephonyControl.Statistics.PhoneStatusSummaryModel;

    export interface PhoneStatusSummaryParams {
        userId: System.Guid;
        from: string;
        until: string;
    }

    export interface IPhoneStatusSummaryResource extends IResource<PhoneStatusSummaryModel[]> {}

    export interface IPhoneStatusSummaryResourceClass
        extends angular.resource.IResourceClass<PhoneStatusSummaryModel[]> {
        phoneStatusSummary(params: PhoneStatusSummaryParams): IResource<PhoneStatusSummaryModel[]>;
    }

    angular.module('CustomerService').service('PhoneStatusSummaryResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/telephony/phonestatistics/phonestatussummary/:userId?from=:from&until=:until',
                {},
                {
                    phoneStatusSummary: {
                        method: 'GET',
                        isArray: true,
                        params: {
                            userId: '@userId',
                            from: '@from',
                            until: '@until'
                        }
                    }
                }
            )
    ]);
}
